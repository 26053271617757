import React, { ReactElement, useState } from 'react';
import { CardModel } from '../../types/cards/cardModel';
import { tableStatusCell } from '../TableStatusCell';
import { ReactComponent as EyeClosed } from '../../assets/eye-closed.svg';
import { ReactComponent as EyeOpen } from '../../assets/eye-open.svg';

type CardDetailsProps = {
  selectedCard: CardModel | null;
};
export function CardDetails({ selectedCard }: CardDetailsProps): ReactElement {
  const [proxyValueVisible, setProxyValueVisible] = useState(false);
  return (
    <>
      <h2 className="mb-4 text-lg font-semibold">Card Info</h2>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
          <p className="text-text-gray">Card Number</p>
          <p>{selectedCard?.last_four}</p>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
          <p className="mt-1 text-text-gray">Proxy Value</p>
          <div className="flex w-[180px] items-center justify-end">
            <div className="flex">
              <p className="">
                {proxyValueVisible && selectedCard?.proxy_value}
              </p>
              <p className="">{!proxyValueVisible && '****-****-****'}</p>
            </div>
            <button
              type="button"
              className="ml-2 mt-1"
              onClick={() => setProxyValueVisible(!proxyValueVisible)}
            >
              {proxyValueVisible ? <EyeOpen /> : <EyeClosed />}
            </button>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between sm:flex-row">
          <p className="text-text-gray">Card Status</p>
          <div>{tableStatusCell(selectedCard?.card_status || '')}</div>
        </div>
      </div>
    </>
  );
}
