import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { Modal, Upload } from 'antd';
import type { UploadProps } from 'antd';
import { csvToJson, jsonToCsv } from '../../../utils/stringUtils';
import { ReactComponent as UploadIcon } from '../../../assets/document-upload.svg';
import { useBulkCreateUser } from '../../../api/rq/mutations/userMutations';
import { CreateUserRequestDTO } from '../../../types/user/createUserRequestDTO';
import { BulkFileProcessing } from '../../BulkActionHelpers/BulkFileProcessing';
import { BulkAllRecordsSuccess } from '../../BulkActionHelpers/BulkAllRecordsSuccess';
import { BulkAllRecordsFailed } from '../../BulkActionHelpers/BulkAllRecordsFailed';
import { BulkRecordsAttempted } from '../../BulkActionHelpers/BulkRecordsAttempted';
import { BulkCreateUserResponseDTO } from '../../../types/user/bulkCreateUserResponseDTO';

const { Dragger } = Upload;

/**
 * AntD uploader props and action definitions
 */
const props: UploadProps = {
  name: 'file',
  multiple: false,
  accept: '.csv',
  style: { border: 'none' },
  className:
    'flex items-center justify-center rounded-lg border border-2 border-dashed border-surface-primary bg-blue-50 h-56',
};

/**
 * Modal for uploading a file for bulk user creation
 */
export function BulkUserCreationModal({
  isFilterModalOpen,
  setIsFilterModalOpen,
}: {
  isFilterModalOpen: boolean;
  setIsFilterModalOpen: Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [progress, setProgress] = useState<{ percent: number; status: string }>(
    {
      percent: 10,
      status: 'Validating your uploaded file.',
    },
  );
  const {
    data: updatedUsers,
    mutateAsync: mutateBulkCreateUsers,
    isLoading: isBulkCreateUsersLoading,
    isSuccess: isBulkCreateUsersSuccess,
    isError: isBulkCreateUsersError,
  } = useBulkCreateUser();

  const downloadReport = (records: BulkCreateUserResponseDTO) => {
    const afterActionReport =
      records
        ?.filter((usr: BulkCreateUserResponseDTO[0]) => usr.status === 'failed')
        .map((usr: BulkCreateUserResponseDTO[0]) => {
          /**
           * Create CSV file to download
           * remove the `user_type_id` or other fields
           * we dont want the user to set
           */
          const res = {
            error: usr.message,
            ...usr.user,
          };

          delete res.user_type_id;
          return res;
        }) || [];

    const file = new File(
      [jsonToCsv(afterActionReport)],
      'failed_users_import_report.csv',
      {
        type: 'text/csv',
      },
    );
    const csvFileUrl = URL.createObjectURL(file);

    window.location.assign(csvFileUrl);
    URL.revokeObjectURL(csvFileUrl);
  };

  useEffect(() => {
    /**
     * Request has not started but file is uploaded
     */
    if (!isBulkCreateUsersLoading && !isBulkCreateUsersSuccess)
      setProgress({ percent: 27, status: 'Creating bulk users request' });

    /**
     * API request is started and running
     */
    if (isBulkCreateUsersLoading)
      setTimeout(
        () =>
          setProgress({
            percent: 52,
            status: 'Importing user data from your file into the system.',
          }),
        2000,
      );

    /**
     * API request has succeeded or failed
     */
    if (isBulkCreateUsersSuccess || isBulkCreateUsersError) {
      setProgress({
        percent: 92,
        status: 'User import is complete. Generating summary.',
      });
    }
  }, [
    isBulkCreateUsersLoading,
    isBulkCreateUsersSuccess,
    isBulkCreateUsersError,
    setProgress,
  ]);

  return (
    <Modal
      open={isFilterModalOpen}
      onCancel={() => {
        setCurrentStep(1);
        setProgress({ percent: 0, status: '' });
        setIsFilterModalOpen(false);
      }}
      footer=""
      width={406}
    >
      {currentStep === 1 && (
        <>
          <h2 className="mb-6 text-xl font-medium">Create Multiple Users</h2>
          <div className="p-6 pb-0">
            <Dragger
              fileList={[]}
              {...props}
              beforeUpload={(file) => {
                // run some validation checks?
                if (file) {
                  csvToJson(file, async (err, content) => {
                    setProgress({
                      percent: 15,
                      status: 'Upload file validated.',
                    });
                    /**
                     * Add user specific metadata before request
                     */
                    const normalizedUsers: CreateUserRequestDTO[] =
                      content?.map((item: CreateUserRequestDTO) => ({
                        ...item,
                        user_type_id: 1,
                      })) || [];

                    /**
                     * Set user at step 2 - file processing loading screen
                     */
                    setCurrentStep(2);

                    /**
                     * Try the bulk action and send user to correct step
                     * based on response
                     */
                    try {
                      const addedUsers = await mutateBulkCreateUsers(
                        normalizedUsers,
                      );

                      const allPasses = addedUsers?.filter(
                        (usr) => usr.status === 'success',
                      );
                      const allFailed = addedUsers?.filter(
                        (usr) => usr.status === 'failed',
                      );

                      /**
                       * Some failed, some passed => records attemped screen
                       */
                      if (allPasses?.length && allFailed?.length)
                        setTimeout(() => setCurrentStep(3), 2000);

                      /**
                       * No fails and no partial fails => records success screen
                       */
                      if (allPasses?.length && !allFailed?.length)
                        setTimeout(() => setCurrentStep(4), 2000);

                      /**
                       * No users (api call timeout) or all users failed =>
                       * records failed screen
                       */
                      if (
                        addedUsers !== undefined &&
                        allFailed?.length &&
                        !allPasses?.length
                      )
                        setTimeout(() => setCurrentStep(5), 2000);
                    } catch (error) {
                      setCurrentStep(5);
                    }
                  });
                }

                return false;
              }}
            >
              <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-surface-icon-bg-light">
                <UploadIcon />
              </div>
              <p className="mt-6 text-center text-base font-semibold text-surface-primary hover:text-surface-primary-light">
                Click here or drag file to upload
              </p>
            </Dragger>
            <p className="mb-6 mt-4 text-sm text-text-gray">
              Supported formats: .csv
            </p>
            <div className="flex justify-center">
              <a
                href="/files/tern_bulk_user_upload_template.csv"
                download="Tern Bulk User Import Template"
                target="_blank"
                className="mb-6 text-center text-base font-semibold tracking-wide text-surface-primary transition duration-300 ease-in-out hover:text-surface-primary-light"
              >
                Download Multiple Users Template
              </a>
            </div>
          </div>
        </>
      )}

      {/**
       * Process bulk users file
       */}
      {currentStep === 2 && (
        <div className="pb-6">
          <BulkFileProcessing
            percent={progress.percent}
            status={progress.status}
          />
        </div>
      )}

      {/**
       * Some users have failed and some users have succeeded
       */}
      {currentStep === 3 && (
        <BulkRecordsAttempted
          records={updatedUsers || []}
          isLoading={isBulkCreateUsersLoading}
          downloadAction={downloadReport}
          onCancel={() => {
            setCurrentStep(1);
            setIsFilterModalOpen(false);
          }}
        />
      )}

      {/**
       * All users have succeeded
       */}
      {currentStep === 4 && (
        <BulkAllRecordsSuccess
          records={updatedUsers || []}
          isLoading={isBulkCreateUsersLoading}
          onCancel={() => {
            setCurrentStep(1);
            setIsFilterModalOpen(false);
          }}
        />
      )}

      {/**
       * All users have failed
       */}
      {currentStep === 5 && (
        <BulkAllRecordsFailed
          records={updatedUsers || []}
          isLoading={isBulkCreateUsersLoading}
          downloadAction={downloadReport}
          onCancel={() => {
            setCurrentStep(1);
            setIsFilterModalOpen(false);
          }}
        />
      )}
    </Modal>
  );
}

export default BulkUserCreationModal;
