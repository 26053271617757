import { Table, flexRender } from '@tanstack/react-table';
import React from 'react';
import { ReactComponent as ChevronDown } from '../../assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../assets/chevron-up.svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function TableHeader<T>({ table }: { table: Table<T> }) {
  return table.getHeaderGroups().map((headerGroup) => (
    <tr key={headerGroup.id} className="rounded-t-lg">
      {headerGroup.headers.map((header) => {
        return (
          <th
            key={header.id}
            colSpan={header.colSpan}
            className="px-2 py-3 text-left text-xs font-semibold text-neutral-gray-600 sm:px-2"
          >
            <div
              {...{
                className: header.column.getCanSort()
                  ? 'cursor-pointer select-none flex items-center'
                  : '',
                onClick: header.column.getToggleSortingHandler(),
              }}
              aria-hidden="true"
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              <span className="ml-2">
                {{
                  asc: <ChevronUp />,
                  desc: <ChevronDown />,
                }[header.column.getIsSorted() as string] ?? (
                  <span className="ml-2"> </span>
                )}
              </span>
            </div>
          </th>
        );
      })}
    </tr>
  ));
}
