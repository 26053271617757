import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function DashboardHome() {
  const navigator = useNavigate();

  useEffect(() => {
    navigator('/dashboard/users');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
