import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
// eslint-disable-next-line max-len
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import reportWebVitals from './reportWebVitals';
import './styles/ant-d-overrides.css';
import { AuthAPIProvider } from './context/authContext';
import './index.css';
import router from './router';
/**
 * Extend dayjs with plugins
 * so we can format a date with
 * AM/PM and timezone labels.
 */
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

/**
 * datadog logging client init
 */
datadogLogs.init({
  clientToken: 'pube33c5e13af9c6dda1f1a4d3d7ef1baef',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

/**
 * tanstack/react-query client
 */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);
root.render(
  <AuthAPIProvider>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </AuthAPIProvider>,
);

/**
 * Conditionally load RUM session recording to only production instances
 * Start the session replay recording
 */
if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '935f0314-db83-4eb8-bef6-9bbd30fc468a',
    clientToken: 'pube33c5e13af9c6dda1f1a4d3d7ef1baef',
    site: 'datadoghq.com',
    service: 'rad',
    env: 'prod',
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
