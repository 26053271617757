import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  addFundsToUserAccount,
  bulkAddFundsToUserAccount,
  bulkCreateUser,
  createUser,
  updateUser,
} from '../../UsersApi';
import { TransferFundsResponseDTO } from '../../../types/transfers/tranferFundsResponseDTO';
import { TransferFundsRequestDTO } from '../../../types/transfers/transferFundsRequestDTO';
import { UpdateUserRequestDTO } from '../../../types/user/updateUserRequestDTO';
import { UserDTO } from '../../../types/user/userDTO';
import { CreateUserRequestDTO } from '../../../types/user/createUserRequestDTO';
import { CreateUserResponseDTO } from '../../../types/user/createUserResponseDTO';
import { BulkCreateUserResponseDTO } from '../../../types/user/bulkCreateUserResponseDTO';
import { BulkTransferFundsResponseDTO } from '../../../types/transfers/bulkTransferFundsResponseDTO';
import { createRadUser, updateRadUser } from '../../DashboardApi';
import { CreateRadUserResponseDTO } from '../../../types/user/createRadUserResponseDTO';
import { CreateRadUserRequestDTO } from '../../../types/user/createRadUserRequestDTO';
import { UpdateRadUserRequestDTO } from '../../../types/user/updateRadUserRequestDTO';

/**
 * React Query Mutations are used to update data on the server,
 * and then update the cache with the new data.
 *
 * This allows invalidating certain queries that become
 * stale after the create/update requests succeed.
 *
 * @docs https://tanstack.com/query/v4/docs/react/guides/mutations
 *
 */

export const useAddFundsToUserAccount = (): UseMutationResult<
  TransferFundsResponseDTO | null,
  unknown,
  TransferFundsRequestDTO,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    async (body: TransferFundsRequestDTO) => addFundsToUserAccount(body),
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: ['userAccountTransactions'] });
        client.invalidateQueries({ queryKey: ['userAccounts'] });
      },
    },
  );
};

/**
 * This one doesnt make a ton of sense. We are using TransferFunds types
 * to hit a different endpoint that has different request body
 */
export const useBulkAddFundsToUserAccount = (): UseMutationResult<
  BulkTransferFundsResponseDTO | null,
  unknown,
  TransferFundsRequestDTO[],
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    async (body: TransferFundsRequestDTO[]) => bulkAddFundsToUserAccount(body),
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: ['userAccountTransactions'] });
        client.invalidateQueries({ queryKey: ['userAccounts'] });
      },
    },
  );
};

export const useUpdateUser = (): UseMutationResult<
  UserDTO | null,
  unknown,
  { userId: string; body: UpdateUserRequestDTO },
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    async ({ userId, body }: { userId: string; body: UpdateUserRequestDTO }) =>
      updateUser(userId, body),
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: ['user'] });
        client.invalidateQueries({ queryKey: ['userAccounts'] });
      },
    },
  );
};

export const useCreateUser = (): UseMutationResult<
  CreateUserResponseDTO | null,
  unknown,
  CreateUserRequestDTO,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(async (body) => createUser(body), {
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useCreateRadUser = (): UseMutationResult<
  CreateRadUserResponseDTO | null,
  unknown,
  CreateRadUserRequestDTO,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(async (body) => createRadUser(body), {
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useUpdateRadUser = (): UseMutationResult<
  CreateRadUserResponseDTO | null,
  unknown,
  { userId: number; body: UpdateRadUserRequestDTO },
  unknown
> => {
  const client = useQueryClient();
  return useMutation(async ({ userId, body }) => updateRadUser(userId, body), {
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useBulkCreateUser = (): UseMutationResult<
  BulkCreateUserResponseDTO | null,
  unknown,
  CreateUserRequestDTO[],
  unknown
> => {
  const client = useQueryClient();
  return useMutation(async (body) => bulkCreateUser(body), {
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['users'] });
    },
  });
};
