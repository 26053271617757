import React, { ReactElement, useMemo } from 'react';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import { isAdminOrMTU, isMTUOrProgramAdmin } from '../../../utils/authUtils';
import { ReactComponent as ChevronDownIcon } from '../../../assets/chevron-down-white.svg';
import { ReactComponent as BusinessPlusIcon } from '../../../assets/shop-add-blue.svg';
import { ReactComponent as DollarSignIcon } from '../../../assets/dollar_sign.svg';
import { ReactComponent as ActionMenuIcon } from '../../../assets/action-menu-white.svg';

export function BusinessListingActions({
  modalFn,
  disabled = false,
}: {
  modalFn: (object: ModalTrigger) => void;
  disabled: boolean;
}): ReactElement | null {
  const hasMtuOrProgramAdminRole = isMTUOrProgramAdmin();
  const hasAdminAccess = isAdminOrMTU();
  const navigate = useNavigate();
  const items: MenuProps['items'] = useMemo(
    () => [
      {
        type: 'group',
        label: 'Users',
        key: 0,
        children: [
          {
            label: (
              <span className="flex items-center">
                <BusinessPlusIcon className="mr-4 w-6" />
                Create New Business
              </span>
            ),
            disabled: !hasMtuOrProgramAdminRole,
            key: 1,
            onClick: () => navigate('/dashboard/businesses/new'),
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        type: 'group',
        label: 'Funds',
        key: 2,
        children: [
          {
            label: (
              <span className="flex items-center">
                <DollarSignIcon className="mr-4 w-6" />
                Bulk Add Funds
              </span>
            ),
            key: 7,
            onClick: () => modalFn({ type: 'bulk-funds', val: true }),
          },
        ],
      },
    ],
    [modalFn, navigate, hasMtuOrProgramAdminRole],
  );

  return hasAdminAccess ? (
    <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
      <button
        type="button"
        disabled={disabled}
        className="flex min-w-[200px] items-center rounded-md bg-surface-primary-light px-4 py-2 font-semibold text-white"
      >
        <span className="mr-auto inline-flex items-center">
          <ActionMenuIcon className="mr-1 h-5 w-5" />
          Options
        </span>
        <ChevronDownIcon />
      </button>
    </Dropdown>
  ) : null;
}

export default BusinessListingActions;
