import React, { ReactElement } from 'react';
import { ReactComponent as CardIcon } from '../../assets/card.svg';
import { maskAccountNumber } from '../../utils/accountUtils';
import { UserAccountDTO } from '../../types/user/userAccountDTO';
import { BusinessAccountDTO } from '../../types/business/businessAccountDTO';
import { AvailableBalance } from '../AvailableBalance';

type AccountSelectItemProps = {
  account: UserAccountDTO | BusinessAccountDTO;
};
export function AccountSelectItem({
  account,
}: AccountSelectItemProps): ReactElement {
  return (
    <div className="flex items-center">
      <CardIcon />
      &nbsp;
      {maskAccountNumber(account.account_number)}
      &nbsp;&mdash;&nbsp;
      <AvailableBalance availableBalance={account.available_balance} />
    </div>
  );
}
