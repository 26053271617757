function batchWithIndex<T>(items: T[], size: number): [T[], number][] {
  const batches: [T[], number][] = [];
  let j = 0;
  for (let i = 0; i < items.length; i += size) {
    batches.push([items.slice(i, i + size), j]);
    j += 1;
  }
  return batches;
}

async function worker<T>(
  queue: T[],
  cb: (item: T) => Promise<void>,
): Promise<void> {
  if (queue.length === 0) return;
  const job = queue.pop();
  if (job === undefined) return;
  await cb(job);
  await worker(queue, cb);
}

/**
 * A function to split a large amount of work into more managable batches
 * then process each batch, potentially in parallel (see `concurrency`).
 *
 * **Note** Some servers may limit concurrency to ~3, and browsers to ~6.
 *
 * @param items An array of items to batch process
 * @param batchSize The number of items to process per batch
 * @param concurrency Batches to allow to process concurrently
 * @param mapper The function that processes a batch
 * @returns The results in order of batching, which is the same order as items
 */
export default async function batchPromises<T, U>(
  items: T[],
  batchSize: number,
  concurrency: number,
  mapper: (batch: T[]) => Promise<U>,
): Promise<U[]> {
  const batches: [T[], number][] = batchWithIndex(items, batchSize);
  const result: U[] = [];
  const workers = [];
  for (let i = 0; i < concurrency; i += 1) {
    workers.push(
      worker(batches, async ([batch, index]) => {
        result[index] = await mapper(batch);
      }),
    );
  }
  await Promise.all(workers);
  return result;
}
