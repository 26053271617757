import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select, Skeleton, notification } from 'antd';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { AvailableBalanceCard } from '../../../components/AvailableBalanceCard';
import { StatusCard } from '../../../components/StatusCard';
import { UserAccountTransactionDTO } from '../../../types/transactions/userAccountTransactionDTO';
import {
  getBalance,
  getDollarAmount,
  maskAccountNumber,
} from '../../../utils/accountUtils';
import { UserTransactionFiltersModal } from '../../../components/Modals/UserTransactionFiltersModal';
import { Transactions } from '../../../components/Table/Transactions';
import { AddFundsModal } from '../../../components/Modals/AddFundsModal';
import { AddFundsFormValues } from '../../../types/user/addFundsFormValues';
import { TransferFundsRequestDTO } from '../../../types/transfers/transferFundsRequestDTO';
import { useGetBusinesses } from '../../../api/rq/queries/businessQueries';
import {
  useGetUserAccountTransactions,
  useGetUserAccounts,
  useGetUserWithFullName,
} from '../../../api/rq/queries/userQueries';
import { useAddFundsToUserAccount } from '../../../api/rq/mutations/userMutations';
import { AccountSelectItem } from '../../../components/AccountSelectItem';
import { TransactionSearchQueryParams } from '../../../types/transactions/transactionSearchQueryParams';
import { isBusinessAdmin } from '../../../utils/authUtils';
import { ExportModal } from '../../../components/Modals/ExportModal';
import { makeUserFullNameForFileName } from '../../../utils/userUtils';

export function UserDeposits(): ReactElement {
  /**
   * Local state
   */
  const lastYear = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
  const hasBusinessAdminRole = isBusinessAdmin();
  /**
   * CSV ref
   */
  const csvRef = React.useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  /**
   * Filters.
   *
   * Only account_transfers for this page!
   */
  const [filters, setFilters] = useState<TransactionSearchQueryParams>({
    createdAt: lastYear,
    transactionType: 'account_transfer',
    transactionStatus: null,
    amount: {
      min: '',
      max: '',
    },
  });
  const [transactions, setTransactions] =
    useState<UserAccountTransactionDTO[]>();

  /**
   * Modal State
   */
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAddFundsModalOpen, setIsAddFundsModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  /**
   * Query parameters
   */

  const { accountId: accountIdQueryParam, userId: userIdQueryParam } =
    useParams();

  /**
   * Router navigation
   */

  const navigate = useNavigate();

  /**
   * Noficiations
   */

  const [notify, notificationContext] = notification.useNotification();

  /**
   * react-query queries/mutations that will execute in parallel on render
   */

  /**
   * RQ Mutations for API updates
   */

  const { mutateAsync: mutateAddFunds } = useAddFundsToUserAccount();

  /**
   * RQ Queries for fetching data
   */

  const {
    data: userAccountsData,
    isLoading: userAccountsLoading,
    error: userAccountsError,
  } = useGetUserAccounts(parseInt(userIdQueryParam || '0'));
  const { data: userData, error: userError } = useGetUserWithFullName(
    parseInt(userIdQueryParam || '0'),
  );

  const userBusinessIds = userData?.business_ids || [];
  const { data: businessesData, error: businessesError } = useGetBusinesses(
    '?page=1&pageSize=500',
    userBusinessIds,
  );

  const {
    data: transactionsData,
    isLoading: isLoadingTransactions,
    error: transactionsError,
    refetch: refetchTransactions,
  } = useGetUserAccountTransactions(
    parseInt(accountIdQueryParam || '0'),
    filters,
    !!accountIdQueryParam && accountIdQueryParam !== 'undefined',
    hasBusinessAdminRole,
  );

  /**
   * More local state/logic
   */
  const originalTransactionsData = [...(transactionsData || [])];

  const selectedAccount = userAccountsData?.find(
    (acc) => acc.id === parseInt(accountIdQueryParam || '0'),
  );

  const hasError =
    !!businessesError ||
    !!userAccountsError ||
    !!userError ||
    !!transactionsError;

  /**
   * Reroute user to transactions if they are a not a biz admin
   */
  useEffect(() => {
    if (!hasBusinessAdminRole) {
      navigate(
        `/dashboard/users/${userIdQueryParam}/accounts/${accountIdQueryParam}`,
      );
    }
  }, [accountIdQueryParam, hasBusinessAdminRole, navigate, userIdQueryParam]);

  /**
   * Component functions
   */
  useEffect(() => {
    setTransactions(transactionsData || []);
  }, [transactionsData]);

  const selectAccount = async (acctId: number) => {
    navigate(
      `/dashboard/users/${userIdQueryParam}/accounts/${acctId}/deposits`,
      {
        replace: false,
      },
    );
  };

  const makeDescriptionCell = useCallback(
    (info: CellContext<UserAccountTransactionDTO, string>) => {
      const {
        transaction_status: status,
        description,
        card_last_four: lastFour,
      } = info.row.original;
      return (
        <div className="flex items-center">
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              {status ? description : selectedAccount?.name}
            </div>
            {lastFour && (
              <div className="text-xs text-text-gray">{`Card #${lastFour}`}</div>
            )}
            {!status && (
              <div className="text-xs text-text-gray">{`Acct. #${selectedAccount?.account_number.slice(
                (selectedAccount?.account_number.length || 9) - 4,
                selectedAccount?.account_number.length,
              )}`}</div>
            )}
          </div>
        </div>
      );
    },
    [selectedAccount],
  );

  const columnHelper = createColumnHelper<UserAccountTransactionDTO>();

  const formatAvailableBalance = (
    availableBalance: number | undefined,
    centsSize = 'text-lg',
    showPlusSign = false,
    isCents = true,
  ): ReactElement => {
    const balance = getBalance(availableBalance || 0, showPlusSign, isCents);
    const [dollars, cents] = balance.split('.');
    return (
      <div className="flex items-baseline">
        <span className="font-semibold">{dollars}</span>
        <span className={`${centsSize} text-text-gray`}>
          {cents ? `.${cents}` : ''}
        </span>
      </div>
    );
  };

  /**
   * This is over complex....@TODO Refactor this!
   */
  const transactionColumns = useMemo(() => {
    const makeAmountCell = (
      info: CellContext<UserAccountTransactionDTO, number>,
      showPlusSign = false,
      isCents = true,
    ) => {
      const balance = formatAvailableBalance(
        info.getValue(),
        'text-xs',
        showPlusSign,
        isCents,
      );
      const isPending = info.row.original.transaction_status === 'PENDING';
      if (isPending) {
        return (
          <div className="flex flex-col">
            <div className="text-xs text-text-gray">Pending</div>
            <div className="text-sm font-semibold">{balance}</div>
          </div>
        );
      }
      return balance;
    };
    return [
      columnHelper.accessor('card_transaction_date', {
        header: 'DATE',
        cell: (info) =>
          dayjs(
            info.row.original.card_transaction_date ||
              info.row.original.balance_changed_date,
          ).format('MMM DD, YYYY'),
      }),
      columnHelper.accessor('description', {
        cell: (info) => makeDescriptionCell(info),
        header: 'DESCRIPTION',
      }),
      columnHelper.accessor('transaction_type', {
        cell: (info) => info.getValue(),
        header: 'TYPE',
      }),
      columnHelper.accessor('transaction_status', {
        cell: (info) => info.getValue() || 'APPROVED',
        header: 'STATUS',
      }),
      columnHelper.accessor('amount', {
        header: 'AMOUNT',
        cell: (info) => makeAmountCell(info, true, false),
      }),
      columnHelper.accessor('current_amount', {
        header: 'BALANCE',
        cell: (info) => {
          const isPending = info.row.original.transaction_status === 'PENDING';
          if (isPending) return '';
          return makeAmountCell(info, false, false);
        },
      }),
    ];
  }, [columnHelper, makeDescriptionCell]);

  const getPendingAmount = (trxs: UserAccountTransactionDTO[]) => {
    return trxs.reduce((acc, curr) => {
      if (curr.transaction_status === 'PENDING') {
        return acc + curr.amount;
      }
      return acc;
    }, 0);
  };

  const addFunds = async (data: AddFundsFormValues): Promise<void> => {
    if (data.business_account_id && selectedAccount) {
      const addFundsRequest: TransferFundsRequestDTO = {
        amount: parseInt(data.amount) * 100,
        from_account_id: data.business_account_id,
        to_account_id: selectedAccount?.id,
        transfer_type: 'business_to_user',
      };
      try {
        await mutateAddFunds(addFundsRequest);
        setIsAddFundsModalOpen(false);
        notify.success({
          type: 'success',
          message: 'Success!',
          description: `Successfully added $${parseInt(data.amount).toFixed(
            2,
          )} to account ${maskAccountNumber(selectedAccount.account_number)}`,
          placement: 'topLeft',
        });
        await refetchTransactions();
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  };

  /**
   * This has some duplicate code similar to other PDF export functions
   * in other Transaction/Deposits files. This is because the functionality
   * is similar, but has a lot of unique data. This could be abstracted,
   * however it would need to take a lot of input parameters, and the added
   * complexity doesn't seem worth it at this time.
   *
   * @TODO consider refactoring this to a shared function somewhere
   */
  const exportToPdf = () => {
    const pdfCompatibleData: string[][] =
      transactions
        ?.map((trx) => ({
          date: dayjs(trx.balance_changed_date).format('MMM DD, YYYY'),
          description: trx.description,
          type: trx.transaction_type,
          status: trx.transaction_status,
          amount: getDollarAmount(trx.amount / 100),
          balance: getDollarAmount(trx.current_amount),
        }))
        .map((trx) => Object.values(trx).map((val) => val?.toString() ?? '')) ??
      [];
    // eslint-disable-next-line new-cap
    const pdfDoc = new jsPDF();
    /**
     * Title
     */
    pdfDoc.setFontSize(18);
    pdfDoc.setFont('Inter');

    pdfDoc.text('USER', 200, 10, {
      align: 'right',
    });
    pdfDoc.text('TRANSACTIONS', 200, 17, {
      align: 'right',
    });
    /**
     * Sub-title
     */
    pdfDoc.setFontSize(10);
    pdfDoc.text(dayjs().format('MMMM DD, YYYY'), 200, 22, {
      align: 'right',
    });
    /**
     * Summary start
     */
    pdfDoc.setFontSize(10);
    pdfDoc.text('SUMMARY', 20, 40);
    /**
     * User's Name
     */
    pdfDoc.setFontSize(9);
    pdfDoc.setFont('Inter', 'normal');
    pdfDoc.text('User Name', 20, 50);
    pdfDoc.setFont('Inter', 'bold');
    pdfDoc.text(
      `${userData?.first_name ?? ''}, ${userData?.last_name ?? ''}`,
      50,
      50,
    );
    pdfDoc.setFont('Inter', 'normal');
    /**
     * Balance as of date
     */
    pdfDoc.text(`Balance as of`, 20, 56);

    pdfDoc.text(dayjs().format('MMM, DD, YYYY'), 20, 60);
    pdfDoc.setFont('Inter', 'bold');

    const accountBalance = selectedAccount?.available_balance || 0;
    pdfDoc.text(
      `$${
        accountBalance
          ? (accountBalance / 100).toLocaleString('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
            })
          : '$0'
      }`,
      50,
      56,
    );
    /**
     * Filter criteria
     */
    pdfDoc.setFont('Inter', 'normal');

    pdfDoc.text('Filter Criteria', 20, 65);
    pdfDoc.setFont('Inter', 'bold');
    pdfDoc.text(
      `Amount: ${filters.amount?.min || '-$100,000'} (Min), ${
        filters.amount?.max || '$100,000'
      } (Max)`,
      50,
      65,
    );
    pdfDoc.text(
      `Status: ${
        filters.transactionStatus ?? 'All except VOIDED, DECLINED, and EXPIRED'
      }`,
      50,
      70,
    );

    pdfDoc.text(
      `Type: ${
        filters.transactionType ?? hasBusinessAdminRole
          ? 'Account Transfers'
          : 'All'
      }`,
      50,
      75,
    );
    pdfDoc.text(`From Date: ${filters.createdAt}`, 50, 80);

    autoTable(pdfDoc, {
      theme: 'plain',
      columnStyles: {
        0: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        1: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        2: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        3: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        4: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        5: {
          cellWidth: 'auto',
          fontSize: 8,
        },
      },
      head: [['DATE', 'DESCRIPTION', 'TYPE', 'STATUS', 'AMOUNT', 'BALANCE']],
      body: pdfCompatibleData,
      margin: { top: 90 }, // Seting top margin for First Page.
      didDrawPage: (data) => {
        // Resetting top margin.
        // The change will be reflected only after print the first page.
        // eslint-disable-next-line no-param-reassign
        data.settings.margin.top = 20;
      },
    });
    pdfDoc.save(
      `${makeUserFullNameForFileName(
        userData,
      )}_account-${accountIdQueryParam}_transactions.pdf`,
    );
  };

  const exportTransactions = (fileType: string) => {
    if (fileType.toLowerCase() === 'pdf') {
      exportToPdf();
    }
    if (fileType.toLowerCase() === 'csv') {
      csvRef.current?.link.click();
    }
    setIsExportModalOpen(false);
  };

  return (
    <>
      {notificationContext}
      <div className="pb-4">
        <div className="text-base font-semibold">User Account</div>
        <Select
          placeholder="Select an Account"
          size="large"
          className="my-1 w-full md:w-1/3"
          options={userAccountsData?.map((account) => ({
            value: account.id,
            label: <AccountSelectItem account={account} />,
          }))}
          onChange={selectAccount}
          value={selectedAccount?.id}
        />
      </div>
      <div className="grid gap-5">
        <div className="row-span-1 flex h-auto flex-col items-center justify-between gap-6 md:flex-row">
          {!userAccountsLoading ? (
            <AvailableBalanceCard
              balance={selectedAccount?.available_balance || 0}
              pendingAmount={getPendingAmount(originalTransactionsData || [])}
              openAddFundsModal={() => setIsAddFundsModalOpen(true)}
            />
          ) : (
            <Skeleton active />
          )}
          {!userAccountsLoading ? (
            <StatusCard
              statusIdOrName={selectedAccount?.user_account_status_id || 0}
              title="Account Status"
            />
          ) : (
            <Skeleton active />
          )}
        </div>
        <Transactions<UserAccountTransactionDTO>
          setIsFilterModalOpen={setIsFilterModalOpen}
          setIsExportModalOpen={setIsExportModalOpen}
          transactionList={transactions || []}
          transactionColumns={transactionColumns}
          isLoadingTransactions={isLoadingTransactions}
          hasError={hasError}
        />
      </div>
      <UserTransactionFiltersModal
        isFilterModalOpen={isFilterModalOpen}
        setIsFilterModalOpen={setIsFilterModalOpen}
        setFilters={setFilters}
      />
      <ExportModal
        isModalOpen={isExportModalOpen}
        setIsModalOpen={setIsExportModalOpen}
        onConfirm={exportTransactions}
        isLoading={false}
        title="User Deposits Download"
      />
      <AddFundsModal
        selectedUser={userData || null}
        selectedUserAccount={selectedAccount || null}
        businesses={businessesData || []}
        onConfirm={addFunds}
        isModalOpen={isAddFundsModalOpen}
        setIsModalOpen={setIsAddFundsModalOpen}
      />
    </>
  );
}
