import React, { ReactElement, Suspense, useEffect } from 'react';
import {
  Await,
  Outlet,
  defer,
  matchPath,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';
import { Result, Skeleton } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import DashboardHeader from '../../components/DashboardHeader';
import { getBusinesses } from '../../api/DashboardApi';
import { BusinessDTO } from '../../types/business/businessDTO';
import Button from '../../components/Button';
import { useAppStore } from '../../zustand/store';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import { getBusiness } from '../../api/UsersApi';
import { ExpandedBusinessDTO } from '../../types/business/expandedBusinessDTO';

export const businessesLoader = () => {
  const isMtu = localStorage.getItem('mtu') || 'false';
  const programId = localStorage.getItem('programId') || '0';
  const page = 1;
  const pageSize = 1000;
  const queryString = `?${
    isMtu === 'true' ? '' : `programId=${programId}&`
  }page=${page}&pageSize=${pageSize}`;
  const businesses = getBusinesses(queryString);
  return defer({ businesses });
};

export function Businesses(): ReactElement {
  const businessesLoaderData = useRouteLoaderData('businesses') as {
    businesses: BusinessDTO[];
  };

  const [selectedBusiness, setSelectedBusiness] =
    React.useState<ExpandedBusinessDTO | null>(null);

  const setSelectedBusinessInAppState = useAppStore(
    (state) => state.setSelectedBusiness,
  );

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { accountId, businessId } = useParams();

  const businessListRoute = useMatch('dashboard/businesses');
  const newBusinessRoute = useMatch('dashboard/businesses/new');
  const businessInfoRoute = useMatch(
    `dashboard/businesses/${selectedBusiness?.id}/info`,
  );
  const businessTransactionsRoute = matchPath(
    `/dashboard/businesses/${businessId}/accounts/${accountId}`,
    pathname,
  );

  useEffect(() => {
    (async () => {
      if (businessId) {
        const business = await getBusiness(businessId?.toString() || '0');
        setSelectedBusiness(business);
        setSelectedBusinessInAppState(business);
      }
    })();
  }, [setSelectedBusinessInAppState, businessId]);

  const determineBreadcrumbText = () => {
    if (businessInfoRoute || businessTransactionsRoute) {
      return selectedBusiness?.legal_business_name || '';
    }
    if (newBusinessRoute) return 'Create New Business';
    return '';
  };

  const renderBusinessHeaderSubtext = () => {
    const breadcrumbText = determineBreadcrumbText();
    return (
      <div className="flex flex-wrap items-center gap-1 sm:gap-3">
        <div className="flex items-center gap-2">
          <Button
            onClick={() => {
              navigate('/dashboard/businesses');
            }}
            className="text-xl font-semibold md:text-2xl"
          >
            My Businesses
          </Button>
          {!businessListRoute && (
            <span className="mt-1 font-semibold">
              <ChevronRight />
            </span>
          )}
        </div>
        <span className="text-xl text-text-gray md:text-2xl">
          {breadcrumbText}
        </span>
      </div>
    );
  };

  return (
    <>
      <DashboardHeader subtext={renderBusinessHeaderSubtext()} />
      <ErrorBoundary
        fallback={
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong! Please refresh and try again"
          />
        }
      >
        <Suspense fallback={<Skeleton active />}>
          <Await resolve={businessesLoaderData.businesses}>
            {(businesses) => <Outlet context={{ businesses }} />}
          </Await>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
