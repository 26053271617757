import React, { useState, useEffect, ReactElement } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuthApi } from '../../context/authContext';
import PageFooter from '../../components/PageFooter';
import EmailFormInput from '../../components/Forms/EmailFormInput';
import { SignInFormValues } from '../../types/forms/signInFormValues';
import PasswordFormInput from '../../components/Forms/PasswordFormInput';
import LoadingSpinner from '../../components/LoadingSpinner';

export default function SignIn(): ReactElement {
  const [initTheme, setInitTheme] = useState('default');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [state, setState] = useState<{
    isSuccessful: boolean;
    isErrored: boolean;
    isLoading: boolean;
  }>({ isErrored: false, isSuccessful: false, isLoading: false });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignInFormValues>();

  const context = useAuthApi();
  const navigate = useNavigate();

  const { state: routerState } = useLocation();
  useEffect(() => {
    if (isAuthenticated) {
      /**
       * If coming from a previous route, navigate to that route
       * after authenticated. This allows signing in and continuing
       * to a shared link.
       */
      if (routerState?.previousRoute) {
        navigate(routerState.previousRoute);
      } else {
        navigate('/dashboard');
      }
    }
  }, [isAuthenticated, navigate, routerState]);

  const signInUser = async (data: SignInFormValues) => {
    setState({
      isErrored: false,
      isSuccessful: true,
      isLoading: true,
    });
    const response = await context?.login(data.email, data.password);
    if (response && response.success) {
      setIsAuthenticated(true);
      setState({
        isErrored: false,
        isSuccessful: true,
        isLoading: false,
      });
    } else {
      setState({
        isErrored: true,
        isSuccessful: false,
        isLoading: false,
      });
    }
  };

  /**
   * Set the theme based on the `window.location.origin`
   */
  useEffect(() => {
    const { origin } = window.location;

    switch (origin) {
      case 'https://payroll.intermexonline.com':
        setInitTheme('intermex');
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url("./themes/${initTheme}/desktop_bg.svg")`,
        }}
        className="absolute bottom-0 right-0 -z-10 h-full w-full bg-cover bg-center bg-no-repeat"
      />
      <div className={`${initTheme} flex h-screen w-full flex-col`}>
        <div className="flex w-full flex-1 flex-col items-center justify-center p-10">
          <div className="col-span-full row-span-1">
            <h1 className="mb-10 text-center text-4xl font-bold">
              Card Management Platform
            </h1>
          </div>
          <div className="flex h-auto w-full flex-col content-center items-center">
            <h2 className="mb-2 text-left text-2xl font-semibold">
              Let&apos;s get started, sign in below.
            </h2>
            <form
              className="grid min-w-[300px] auto-rows-max gap-y-5 sm:min-w-[375px]"
              onSubmit={handleSubmit((data) => signInUser(data))}
            >
              <EmailFormInput register={register} errors={errors} />
              <PasswordFormInput register={register} errors={errors} />
              {state.isErrored && (
                <div className="text-text-error-red">
                  There was a problem signing you in. Please try again.
                </div>
              )}
              {/* <div className="justify-self-center lg:justify-self-end">
              <FormLink href="/forgot-password">Forget Password?</FormLink>
            </div> */}
              <button
                className="flex items-center justify-center rounded-full bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
                type="submit"
                data-testid="submit-button"
              >
                {state.isLoading ? <LoadingSpinner /> : 'SIGN IN'}
              </button>
              <p className="text-center">
                <Link
                  to="/forgot-password"
                  className="text-center font-thin text-blue-500"
                >
                  <strong>Forgot Password?</strong>
                </Link>
              </p>
            </form>
          </div>
        </div>
        <PageFooter />
      </div>
    </>
  );
}
