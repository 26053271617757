import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import Button from '../../../components/Button';

export function RadUserDetail(): ReactElement {
  return (
    <>
      <div className="mb-8 flex items-center gap-4">
        <Button
          id="user-info"
          className="rounded-full border border-blue-300 bg-blue-200 px-4 py-2 text-sm font-semibold transition-all duration-300"
        >
          User <span className="hidden sm:inline">&nbsp;Info</span>
        </Button>
      </div>
      <Outlet />
    </>
  );
}
