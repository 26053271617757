import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { Select, Skeleton } from 'antd';
import dayjs from 'dayjs';
import xlsx from 'xlsx';
import { UserDTO } from '../../../types/user/userDTO';
import Table from '../../../components/Table';
import { tableStatusCell } from '../../../components/TableStatusCell';
import { ListPageActionItems } from '../../../components/ListPageActionItems';
import { UserFiltersModal } from '../../../components/Modals/UserFiltersModal';
import { formatAsPhoneNumber } from '../../../utils/stringUtils';
import { isAdminOrMTU, Roles } from '../../../utils/authUtils';
import { getFriendlyRoleName } from '../../../utils/userUtils';
import { BulkUserCreationModal } from '../../../components/Modals/BulkUserCreationModal';
import { useSearchUsers } from '../../../api/rq/queries/userQueries';
import { UserSearchQueryParams } from '../../../types/user/userSearchQueryParams';
import { useGetBusinesses } from '../../../api/rq/queries/businessQueries';
import { useDebounce } from '../../../utils/hookUtils';
import { getUsersAndACHDetails } from '../../../api/DashboardApi';
import { BulkAddCardsModal } from '../../../components/Modals/BulkAddCardsModal';
import { BulkAddFundsModal } from '../../../components/Modals/BulkAddFundsModal';
import { BulkAssignCardsModal } from '../../../components/Modals/BulkAssignCardsModal';
import { SelectRoleModal } from '../../../components/Modals/SelectRoleModal';

export function UsersList() {
  /**
   * Local State
   */
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 750);
  const anyTime = dayjs().subtract(10, 'year').format('YYYY-MM-DD');
  const [filters, setFilters] = useState<UserSearchQueryParams>({
    searchTerm: debouncedSearch,
    createdAt: anyTime,
    userStatus: null,
    kycStatus: null,
    businessId: null,
  });
  const [userList, setUserList] = useState<UserDTO[]>([]);
  const [, setSelectedUserId] = useState<number | null>(null);
  const hasAdminAccess = isAdminOrMTU();

  const navigate = useNavigate();
  /**
   * Modal State
   */
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isBulkUserImportModalOpen, setIsBulkUserImportModalOpen] =
    useState(false);
  const [isBulkAddCardsModalOpen, setIsBulkAddCardsModalOpen] = useState(false);
  const [isBulkAssignCardsModalOpen, setIsBulkAssignCardsModalOpen] =
    useState(false);
  const [isBulkAddFundsModalOpen, setIsBulkAddFundsModalOpen] = useState(false);
  const [isSelectUserRoleModalOpen, setIsSelectUserRoleModalOpen] =
    useState(false);
  /**
   * RQ Queries
   */
  const { isLoading, data: usersData } = useSearchUsers(filters);

  const { data: businessesData } = useGetBusinesses();

  /**
   * Only set the userList when the users API call is done loading
   */
  useEffect(() => {
    if (!isLoading) {
      setUserList(usersData || []);
    }
  }, [isLoading, usersData]);

  /**
   * Update search filters if the search term has changed
   */
  const handleSearchChange = useCallback(
    (val: string) => {
      if (filters?.searchTerm !== val) {
        setFilters({ ...filters, searchTerm: val });
      }
    },
    [filters],
  );

  const makeContactInfoCell = (user: UserDTO): ReactElement => {
    return (
      <>
        <p className="font-semibold">{user.email}</p>
        <p className="font-normal text-text-gray">
          {formatAsPhoneNumber(user.mobile_phone)}
        </p>
      </>
    );
  };

  const parseBirthYear = (dob: string): string => {
    const date = new Date(dob);
    return date.getFullYear() ? date.getFullYear().toString() : 'N/A';
  };

  /**
   * Navigate to the user details page based on the role of the user
   * being selected. If the user is a cardholder, navigate to the
   * full user details page. If the user is a RAD user, navigate to
   * the condensed/minimal user details page.
   */
  const navigateToUserDetails = useCallback(
    (user: UserDTO) => {
      /**
       * Cardholders should be redirected to the full user details page
       */
      if (user.role_id === Roles.RegularUser) {
        navigate(`/dashboard/users/${user.id}/info`);
      } else {
        /**
         * RAD users should be redirected to the condensed user details page
         */
        navigate(`/dashboard/users/${user.id}/rad/info`);
      }
    },
    [navigate],
  );

  const makeFullNameCell = useCallback(
    (user: UserDTO): ReactElement => {
      let birthYear = parseBirthYear(user.dob);
      if (Number.isNaN(birthYear)) {
        birthYear = 'N/A';
      }
      return (
        <div className="flex items-center font-semibold">
          {user.active ? (
            <div className="mr-2 h-2 w-2 rounded-full bg-text-success-green" />
          ) : (
            <div className="mr-2 h-2 w-2 rounded-full bg-surface-light-gray" />
          )}
          <button
            type="button"
            className="text-left text-text-primary hover:underline"
            onClick={() => navigateToUserDetails(user)}
          >
            {`${user.first_name || ''} ${user.last_name || ''}`}
          </button>
        </div>
      );
    },
    [navigateToUserDetails],
  );

  const handleUserAction = useCallback(
    (value: string, info: CellContext<UserDTO, unknown>) => {
      setSelectedUserId(info.row.original.id);
      const userRoleId = info.row.original.role_id;
      switch (value) {
        // case 'delete':
        //   setIsDeleteConfirmationModalOpen(true);
        //   break;
        case 'view_edit':
          /**
           * Cardholders should be redirected to the full user details page
           */
          if (userRoleId === Roles.RegularUser) {
            navigate(`/dashboard/users/${info.row.original.id}/info`);
          } else {
            /**
             * RAD users should be redirected to the condensed user details page
             */
            navigate(`/dashboard/users/${info.row.original.id}/rad/info`);
          }
          break;
        default:
          break;
      }
    },
    [navigate, setSelectedUserId],
  );

  const rowActions = useMemo(
    () => [
      {
        value: 'view_edit',
        label: hasAdminAccess ? 'View / Edit' : 'View',
        disabled: false,
      },
    ],
    [hasAdminAccess],
  );

  /**
   * If the user is an MTU give them the delete option
   */
  useEffect(() => {
    if (hasAdminAccess) {
      rowActions.push(
        ...[{ value: 'delete', label: 'Delete', disabled: false }],
      );
    }
  }, [hasAdminAccess, rowActions]);

  const selectDropdownCell = useCallback(
    (info: CellContext<UserDTO, unknown>): ReactElement => {
      return (
        <Select
          defaultValue="Select"
          style={{ width: 150 }}
          onClick={(e) => e.stopPropagation()}
          onChange={(val) => handleUserAction(val, info)}
          options={rowActions}
        />
      );
    },
    [handleUserAction, rowActions],
  );

  const columnHelper = createColumnHelper<UserDTO & { select?: string }>();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('full_name_and_dob', {
        header: 'FULL NAME',
        cell: (info) => makeFullNameCell(info.row.original),
      }),
      columnHelper.accessor('contact_information', {
        header: 'CONTACT INFO',
        cell: (info) => makeContactInfoCell(info.row.original),
      }),
      columnHelper.accessor('full_address', {
        header: 'STREET ADDRESS',
        cell: (info) => info.row.original.full_address || 'N/A',
      }),
      columnHelper.accessor('kyc_status', {
        header: 'KYC STATUS',
        cell: (info) => tableStatusCell<UserDTO>(info),
      }),
      columnHelper.accessor('role_name', {
        header: 'ROLE',
        cell: (info) => getFriendlyRoleName(info.getValue()),
      }),
      columnHelper.accessor('select', {
        header: '',
        cell: (info) => selectDropdownCell(info),
      }),
      columnHelper.accessor('active', {
        header: '',
        cell: (info) => info.getValue() === 1,
      }),
    ],
    [columnHelper, makeFullNameCell, selectDropdownCell],
  );

  const downloadUsersAndACHDetails = async () => {
    const businessId = localStorage.getItem('businessId');
    if (businessId) {
      const usersWithDetails = await getUsersAndACHDetails(businessId);
      const book = xlsx.utils.book_new();
      const sheet = xlsx.utils.json_to_sheet(usersWithDetails);
      xlsx.utils.book_append_sheet(book, sheet, 'Sheet1');
      const el = document.createElement('a');
      const data = xlsx.writeXLSX(book, { bookType: 'xlsx', type: 'base64' });
      el.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
      el.download = `users_ach_export_${
        new Date().toISOString().split('T')[0]
      }.xlsx`;
      el.click();
    }
  };

  /**
   * Navigates to one of two pages depending on the selected role.
   *
   * If the selected role is a regular user (Cardholder), navigate to
   * the full regular user / cardholder creation page.
   *
   * If the selected role is a RAD user type (biz/program admin, mtu,
   * platform agent, api user), navigate to the minimal/condensed
   * RAD user creation page.
   *
   * @param selectedRoleId ID of the selected role
   */
  const navigateToCreateUser = (selectedRoleId: number) => {
    setIsSelectUserRoleModalOpen(false);
    if (selectedRoleId === Roles.RegularUser) {
      navigate(`/dashboard/users/new?roleId=${selectedRoleId}`);
    } else {
      navigate(`/dashboard/users/rad/new?roleId=${selectedRoleId}`);
    }
  };

  return (
    <>
      <ListPageActionItems
        setIsModalOpen={(modal) => {
          switch (modal.type) {
            case 'bulk-users':
              setIsBulkUserImportModalOpen(modal.val);
              break;
            case 'bulk-cards':
              setIsBulkAddCardsModalOpen(modal.val);
              break;
            case 'bulk-assign':
              setIsBulkAssignCardsModalOpen(modal.val);
              break;
            case 'bulk-funds':
              setIsBulkAddFundsModalOpen(modal.val);
              break;
            case 'create-user':
              setIsSelectUserRoleModalOpen(modal.val);
              break;
            case 'filter':
            default:
              setIsFilterModalOpen(modal.val);
              break;
          }
        }}
        searchText={search}
        setGlobalFilter={handleSearchChange}
        downloadUsersAndAchDetails={downloadUsersAndACHDetails}
        entity="User"
        disabled={isLoading}
      />
      <UserFiltersModal
        businesses={businessesData || []}
        isFilterModalOpen={isFilterModalOpen}
        setIsFilterModalOpen={setIsFilterModalOpen}
        setFilters={setFilters}
      />
      <BulkUserCreationModal
        isFilterModalOpen={isBulkUserImportModalOpen}
        setIsFilterModalOpen={setIsBulkUserImportModalOpen}
      />
      <BulkAddCardsModal
        isFilterModalOpen={isBulkAddCardsModalOpen}
        setIsFilterModalOpen={setIsBulkAddCardsModalOpen}
      />
      <BulkAddFundsModal
        isFilterModalOpen={isBulkAddFundsModalOpen}
        setIsFilterModalOpen={setIsBulkAddFundsModalOpen}
      />
      <BulkAssignCardsModal
        isFilterModalOpen={isBulkAssignCardsModalOpen}
        setIsFilterModalOpen={setIsBulkAssignCardsModalOpen}
      />
      <SelectRoleModal
        isModalOpen={isSelectUserRoleModalOpen}
        onConfirm={navigateToCreateUser}
        setIsModalOpen={setIsSelectUserRoleModalOpen}
      />
      {usersData && !isLoading && (
        <Table<UserDTO>
          data={userList || []}
          columns={columns}
          globalFilter={search}
          setGlobalFilter={setSearch}
          totalItems={usersData?.length}
          isLoading={isLoading}
        />
      )}
      {isLoading && <Skeleton active />}
    </>
  );
}
