import React, { ReactElement } from 'react';
import { Tooltip } from 'antd';
import Button from '../Button';
import DebouncedInput from '../Table/DebouncedInput';
import { ReactComponent as FilterIcon } from '../../assets/filters.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import { UserListingActions } from '../ListingActions/UserActions';
import { BusinessListingActions } from '../ListingActions/BusinessActions';
import { isBusinessAdmin } from '../../utils/authUtils';

/**
 * Container for Debounced Input, Filter Button, Download Button,
 * and Create Business Button.
 */
export function ListPageActionItems({
  searchText,
  entity,
  downloadUsersAndAchDetails,
  setGlobalFilter,
  setIsModalOpen,
  disabled = false,
}: {
  searchText: string;
  entity: string;
  downloadUsersAndAchDetails?: () => void;
  setGlobalFilter: (val: string) => void;
  setIsModalOpen: ({ type, val }: ModalTrigger) => void;
  disabled?: boolean;
}): ReactElement {
  const isBusinessAdminRole = isBusinessAdmin();
  const pluralizeEntity = (entityName: string) => {
    switch (entityName.toLowerCase()) {
      case 'business':
        return 'Businesses';
      case 'user':
        return 'Users';
      case 'deposit':
        return 'Deposits';
      default:
        break;
    }
    return '';
  };

  /**
   * getEntityActions function takes the passed in entity for this
   * component and returns the respective actions list. This allow the component
   * to accept more actions and display the correct UX in that action button
   * @param type string | null
   * @returns ReactElement
   */
  const getEntityActions = (type?: string | null) => {
    switch ((type || '').toLowerCase()) {
      case 'business':
        return (
          <BusinessListingActions
            modalFn={setIsModalOpen}
            disabled={disabled}
          />
        );
      case 'user':
        return (
          <UserListingActions modalFn={setIsModalOpen} disabled={disabled} />
        );
      case null:
      case 'deposit':
      default:
        return null;
    }
  };

  return (
    <div className="mb-4 flex flex-col items-center gap-4 md:flex-row">
      <div className="flex flex-col justify-between gap-3 sm:flex-row">
        <DebouncedInput
          value={searchText ?? ''}
          onChange={(value: string) => setGlobalFilter(String(value))}
          className="border border-border-light-gray p-2 text-sm"
          placeholder={`Search for ${pluralizeEntity(entity).toLowerCase()}...`}
        />
        <button
          type="button"
          className="flex items-center rounded-md border border-border-light-gray p-2 text-sm font-semibold"
          onClick={() => setIsModalOpen({ type: 'filter', val: true })}
        >
          <FilterIcon />
          <span className="ml-2">Filters</span>
        </button>
      </div>

      <div className="ml-auto flex items-center space-x-4">
        {isBusinessAdminRole && entity === 'User' && (
          <Tooltip
            color="#06102B"
            title="Download all users with ACH details for your business"
          >
            <Button
              className="w-auto items-center rounded-md bg-surface-primary-light p-2 font-semibold text-white"
              // eslint-disable-next-line no-console
              onClick={downloadUsersAndAchDetails}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        )}
        {getEntityActions(entity)}
      </div>
    </div>
  );
}
