import React, { ReactElement } from 'react';
import { ReactComponent as DollarSign } from '../../assets/dollar_sign.svg';
import { ReactComponent as Plus } from '../../assets/plus.svg';
import Button from '../Button';
import { isAdminOrMTU } from '../../utils/authUtils';

export function AvailableBalanceCard({
  balance,
  pendingAmount,
  openAddFundsModal,
}: {
  openAddFundsModal?: () => void;
  balance: number;
  pendingAmount?: number;
}): ReactElement {
  const hasAdminAccess = isAdminOrMTU();
  const formatAvailableBalance = (
    availableBalance: number | undefined,
    centsSize = 'text-lg',
  ): ReactElement => {
    let isNegative = false;
    if (availableBalance && availableBalance < 0) {
      isNegative = true;
    }
    const formattedBalance = availableBalance
      ? (Math.abs(availableBalance) / 100).toFixed(2).toLocaleString()
      : '0';
    const [dollars, cents] = formattedBalance.split('.');
    return (
      <div className="flex items-baseline">
        <span className="font-semibold">
          {isNegative ? '-' : ''}${dollars}
        </span>
        <span className={`${centsSize} text-text-gray`}>
          {cents ? `.${cents}` : ''}
        </span>
      </div>
    );
  };

  return (
    <div className="flex h-full w-full gap-3 rounded-lg border border-border-light-gray bg-white p-6 py-8">
      <div className="flex flex-col items-center gap-2 sm:flex-row">
        <div className="flex w-max flex-col items-center">
          <div className="flex flex-row">
            <div className="mr-2 flex h-7 w-7 items-center justify-center rounded-full bg-surface-icon-1">
              <div className="flex h-5 w-5 items-center justify-center rounded-full bg-surface-icon-bg-light">
                <DollarSign />
              </div>
            </div>
            <span className="w-max pb-2 font-semibold opacity-50">
              Available Balance
            </span>
          </div>
          <span className="text-3xl">{formatAvailableBalance(balance)}</span>
        </div>
        <div
          className={`flex flex-col items-center lg:flex-row ${
            pendingAmount ? 'gap-4' : ''
          }`}
        >
          {hasAdminAccess && openAddFundsModal && (
            <Button
              onClick={openAddFundsModal}
              className="h-[35px] w-[147px] gap-2 rounded-full bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
              type="button"
              data-testid="submit-button"
            >
              <div className="flex h-5 w-5 items-center justify-center rounded-full bg-white">
                <Plus />
              </div>
              <span className="w-max">Add Funds</span>
            </Button>
          )}
          {!!pendingAmount && (
            <div className="flex flex-col flex-wrap items-center gap-2 rounded-lg border border-border-light-gray px-3 py-2 lg:flex-row lg:justify-around">
              <div className="h-8 w-auto min-w-[108px] max-w-[108px] rounded-lg bg-surface-action-orange p-3 py-1 text-center font-semibold text-text-action-orange">
                • PENDING
              </div>
              <span className="text-xl">
                {formatAvailableBalance(pendingAmount, 'text-sm')}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
