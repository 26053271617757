import React from 'react';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';

type CsvLinkContainerProps = {
  fileNameWithoutDate: string;
  dataToExport: object[];
  csvLinkRef: React.RefObject<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >;
};
export function CsvLinkContainer({
  fileNameWithoutDate,
  dataToExport,
  csvLinkRef,
}: CsvLinkContainerProps) {
  const downloadDate = dayjs().format('MM-DD-YYYY');
  return (
    <div>
      {dataToExport.length > 0 && (
        <CSVLink
          data={dataToExport}
          asyncOnClick
          headers={Object.keys(dataToExport[0])}
          filename={`${fileNameWithoutDate}_${downloadDate}.csv`}
          className="hidden"
          ref={csvLinkRef}
        />
      )}
    </div>
  );
}
