/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ReactElement,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { datadogRum } from '@datadog/browser-rum';
import {
  login,
  logout,
  getUser,
  sendForgottenPasswordEmail,
  resetPassword,
} from '../api/AuthApi';
import { User } from '../types/user/user';
import { ForgotPasswordEmail } from '../types/user/forgotPassword';

export type AuthContext = {
  login: (
    email: string,
    password: string,
  ) => Promise<{ success: boolean; message?: string }>;
  logout: () => void;
  isAuthenticated: () => boolean;
  sendForgotMyPasswordEmail: () => Promise<ForgotPasswordEmail>;
  sendForgottenPasswordEmail: (email: string) => Promise<ForgotPasswordEmail>;
  resetPassword: (data: {
    email: string;
    password: string;
    confirmPassword: string;
  }) => Promise<any>;
  user: User | null;
};

const AuthAPIContext = createContext<AuthContext | null>(null);

export const useAuthApi = () => useContext(AuthAPIContext);

export function AuthAPIProvider({ children }: { children: ReactElement }) {
  const { user: u } = getUser();
  const [user, setUser] = useState<null | User>(u);
  const value = useMemo(
    () => ({
      login: async (
        email: string,
        password: string,
      ): Promise<{ success: boolean; message?: string }> => {
        try {
          const loginResponse = await login(email, password);
          setUser(loginResponse);

          /**
           * If user is authenticated and in production
           * set the DataDog user for the session
           */
          if (process.env.NODE_ENV === 'production') {
            datadogRum.setUser({
              id: loginResponse?.id?.toString(),
              name: `${loginResponse?.firstname} ${loginResponse?.lastname}`,
              email: loginResponse?.email || '',
              isMTU: loginResponse?.mtu,
              roleId: loginResponse?.roleId,
              userTypeId: loginResponse?.userTypeId,
              businessId: loginResponse?.businessId,
              programId: loginResponse?.programId,
            });
          }

          return { success: true };
        } catch (error) {
          return { success: false, message: error };
        }
      },
      logout: (): void => {
        logout();
        setUser(null);
        // eslint-disable-next-line no-restricted-globals
        location.replace(location.origin);
      },
      isAuthenticated: (): boolean => {
        return !!user?.id;
      },
      sendForgotMyPasswordEmail: (): Promise<ForgotPasswordEmail> => {
        return sendForgottenPasswordEmail(user?.email || '');
      },
      sendForgottenPasswordEmail,
      resetPassword,
      user,
    }),
    [user],
  );

  return (
    <AuthAPIContext.Provider value={value}>{children}</AuthAPIContext.Provider>
  );
}
