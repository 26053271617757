import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { DatePicker, Select, Skeleton, notification } from 'antd';
import dayjs from 'dayjs';

import LoadingSpinner from '../../../components/LoadingSpinner';
import {
  Roles,
  createRoleDropdownList,
  getRoleId,
  isAdminOrMTU,
} from '../../../utils/authUtils';
import { CreateUserRequestDTO } from '../../../types/user/createUserRequestDTO';
import {
  dateNotInFuture,
  prepareCreateRequestBody,
} from '../../../utils/userUtils';
import {
  useGetCountries,
  useGetProvinces,
} from '../../../api/rq/queries/userQueries';
import { useGetBusinesses } from '../../../api/rq/queries/businessQueries';
import { useCreateUser } from '../../../api/rq/mutations/userMutations';

export function CreateNewUser(): ReactElement {
  /**
   * Local component state
   */

  const [selectedBusinessIds, setSelectedBusinessIds] = useState<number[]>([]);
  const [businessRequiredError, setBusinessRequiredError] =
    useState<string>('');
  const [selectedResidentialCountryId, setSelectedResidentialCountryId] =
    useState<string | null>(null);
  const [selectedShippingCountryId, setSelectedShippingCountryId] = useState<
    string | null
  >(null);

  /**
   * Populate role dropdown list based on what role
   * the currently logged in RAD user has.
   */
  const loggedInUserRoleId = getRoleId();
  const roleDropdownItems = createRoleDropdownList(loggedInUserRoleId);

  const hasAdminAccess = isAdminOrMTU();

  const navigate = useNavigate();
  /**
   * Query string parameters.
   *
   * The role_id in the params is the role that the user
   * wants to assign to the new user they are creating.
   */
  const [searchParams] = useSearchParams();
  const roleIdQueryParam = searchParams.get('roleId');

  /**
   * Notification state
   */
  const [notify, notificationContext] = notification.useNotification();

  /**
   * RQ Mutations for API updates
   */
  const { mutateAsync: mutateCreateUser, isLoading: createUserLoading } =
    useCreateUser();
  /**
   * RQ queries for API data
   */
  const { data: countriesData, isLoading: countriesLoading } =
    useGetCountries();

  const { data: residentialProvincesData, refetch: fetchResidentialProvinces } =
    useGetProvinces(
      selectedResidentialCountryId || '0',
      !!selectedResidentialCountryId && selectedResidentialCountryId !== '0',
    );

  const { data: shippingProvincesData, refetch: fetchShippingProvinces } =
    useGetProvinces(
      selectedShippingCountryId || '0',
      !!selectedShippingCountryId && selectedShippingCountryId !== '0',
    );

  const { data: allBusinessesData, isLoading: businessesLoading } =
    useGetBusinesses('?page=1&pageSize=1000');

  /**
   * Related Businesses select is handled by local component
   * state and not react-hook-form.
   */
  const allBusinessIds = allBusinessesData?.map((biz) => biz.id);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateUserRequestDTO>({});
  /**
   * Navigate back to user list if the user does not have
   * admin access.
   */
  useEffect(() => {
    if (!hasAdminAccess) navigate('/dashboard/users');
  }, [hasAdminAccess, navigate]);

  /**
   * Set the role_id value to the query string parameter
   * that was previously set when the user was asked to
   * select a role before coming to this page.
   */
  useEffect(() => {
    setValue('role_id', parseInt(roleIdQueryParam ?? '0'));
  }, [roleIdQueryParam, setValue]);

  const selectResidentialCountry = async (countryId: string): Promise<void> => {
    setSelectedResidentialCountryId(countryId);
    fetchResidentialProvinces();
  };
  const selectShipCountry = async (countryId: string): Promise<void> => {
    setSelectedShippingCountryId(countryId);
    fetchShippingProvinces();
  };

  /**
   * Transforms form data into the correct format
   * and then calls the User API to create a new user.
   * @param data Form data
   */
  const createNewUser = async (data: CreateUserRequestDTO): Promise<void> => {
    if (!selectedBusinessIds || selectedBusinessIds.length === 0) {
      setBusinessRequiredError('At least one business is required');
      document.documentElement.scrollTo(0, 0);
      return;
    }
    setBusinessRequiredError('');

    const createRequestBody = prepareCreateRequestBody({
      data,
      selectedBusinesses: selectedBusinessIds,
      residentialCountries: countriesData || [],
      residentialProvinces: residentialProvincesData || [],
      shipCountries: countriesData || [],
      shipProvinces: shippingProvincesData || [],
    });
    try {
      const createUserResp = await mutateCreateUser(createRequestBody);
      document.documentElement.scrollTo(0, 0);
      if (createUserResp?.user_id) {
        navigate(
          `/dashboard/users/${createUserResp?.user_id}/info?status=success`,
        );
      }
    } catch (err) {
      notify.error({
        message: 'Error!',
        description: `There was an error creating the user: ${err.message}. Please try again.`,
        placement: 'topLeft',
      });
      document.documentElement.scrollTo(0, 0);
    }
  };

  const setSelectedBusinesses = (businessIds: number[]) => {
    /**
     * filter for non deleted IDs since deleted ones
     * are also returned with the user's list of business IDs
     */
    setSelectedBusinessIds(
      [...new Set(businessIds || [])].filter((id) =>
        allBusinessIds?.includes(id),
      ) || [],
    );
  };

  /**
   * If the logged in user chooses a role other than
   * Regular User / Cardholder, then redirect them
   * to the minimal field version of the create user page.
   *
   * @param roleId ID of the role selected
   */
  const selectRole = (roleId: number) => {
    if (roleId !== Roles.RegularUser) {
      navigate(`/dashboard/users/rad/new?roleId=${roleId}`);
    }
  };

  return (
    <>
      {notificationContext}
      <div className="rounded-lg border border-border-light-gray bg-white p-3 lg:p-6">
        <div className="grid grid-cols-1 gap-4">
          <form onSubmit={handleSubmit((data) => createNewUser(data))}>
            <div className="gap-4 lg:grid lg:grid-cols-2">
              <div className="border-r-2 border-r-gray-100 pr-2 lg:pr-5">
                {/* User Info Section */}
                <section className="col-start-1">
                  <div className="mb-4 flex gap-3">
                    <span className="text-xl font-semibold">User Info</span>
                  </div>
                  <hr className="mb-4 border border-r-gray-100" />

                  {/* User Role and Related Businesses */}
                  <div className="mb-4 flex w-full flex-col">
                    {/*  User Role */}
                    <div className="mb-4 flex w-full flex-col">
                      <div className="mb-4 text-base font-semibold">
                        User Role{' '}
                        <span className="text-text-error-red">
                          *{' '}
                          <span className="text-xs font-normal">
                            {errors.role_id?.message || ''}
                          </span>
                        </span>
                      </div>
                      <Controller
                        name="role_id"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Select
                            id="role-id"
                            {...register('role_id', {
                              required: 'Role is Required',
                            })}
                            allowClear
                            placeholder="Select a role for this user"
                            size="large"
                            className="my-1 w-full"
                            options={roleDropdownItems.map((role) => ({
                              label: role.name,
                              value: role.id,
                              disabled: role.disabled,
                            }))}
                            onChange={(id: number) => {
                              selectRole(id);
                              onChange(id);
                            }}
                            value={value}
                          />
                        )}
                      />
                    </div>
                    {/* Not part of react hook form, but handled in state */}
                    {!businessesLoading ? (
                      <>
                        <div className="mb-4 text-base font-semibold">
                          Business Relationships{' '}
                          <span className="text-text-error-red">
                            *{' '}
                            <span className="text-xs font-normal">
                              {businessRequiredError || ''}
                            </span>
                          </span>
                        </div>
                        <Select
                          id="business-ids"
                          mode="multiple"
                          showSearch
                          placeholder="Select businesses"
                          size="large"
                          className="my-1 w-full"
                          disabled={!hasAdminAccess}
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={
                            allBusinessesData?.map((business) => ({
                              value: business.id,
                              label: business.legal_business_name,
                            })) || []
                          }
                          onChange={(ids) => {
                            setSelectedBusinesses(ids);
                          }}
                          value={selectedBusinessIds}
                        />
                      </>
                    ) : (
                      <Skeleton active />
                    )}
                  </div>

                  {/* First, Middle, Last Name, Email, Mobile Phone, Ext ID */}
                  <fieldset
                    disabled={!hasAdminAccess}
                    className="flex flex-col flex-wrap gap-3 lg:flex-nowrap"
                  >
                    <h4 className="text-base font-semibold">
                      Personal Details
                    </h4>
                    {/* First and Middle Name */}
                    <div className="flex flex-col gap-3 md:flex-row">
                      {/* First Name */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="first-name">
                          <div className="text-xs font-semibold">
                            First Name{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.first_name?.message || ''}
                              </span>
                            </span>
                          </div>
                          <input
                            id="first-name"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="First Name"
                            type="text"
                            {...register('first_name', {
                              required: 'First Name is Required',
                            })}
                          />
                        </label>
                      </div>
                      {/* Middle Name */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="middle-name">
                          <div className="text-xs font-semibold">
                            Middle Name
                          </div>
                          <input
                            id="middle-name"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="Middle Name"
                            type="text"
                            {...register('middle_name')}
                          />
                        </label>
                      </div>
                    </div>
                    {/* Last Name and DOB */}
                    <div className="flex flex-col gap-3 md:flex-row">
                      {/* Last Name */}
                      <div className="flex w-full flex-col">
                        <label htmlFor="last-name">
                          <div className="text-xs font-semibold">
                            Last Name{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.last_name?.message || ''}
                              </span>
                            </span>
                          </div>
                          <input
                            id="last-name"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="Last Name"
                            type="text"
                            {...register('last_name', {
                              required: 'Last Name is Required',
                            })}
                          />
                        </label>
                      </div>
                      {/* Date of Birth */}
                      <div className="flex w-full flex-col">
                        <div className="text-xs font-semibold">
                          Date of Birth{' '}
                          <span className="text-text-error-red">
                            *{' '}
                            <span className="font-normal">
                              {errors?.dob?.message || ''}
                            </span>
                          </span>
                        </div>
                        <Controller
                          control={control}
                          name="dob"
                          rules={{
                            required: 'Date of Birth is Required',
                          }}
                          render={({ field, fieldState }) => {
                            return (
                              <DatePicker
                                size="large"
                                placeholder="YYYY-MM-DD"
                                {...register('dob', {
                                  required: 'Date of Birth is Required',
                                  validate: (value) =>
                                    dateNotInFuture(value)
                                      ? undefined
                                      : 'Date of birth must be not be in the future',
                                })}
                                status={fieldState.error ? 'error' : undefined}
                                ref={field.ref}
                                name={field.name}
                                className="my-1"
                                onBlur={field.onBlur}
                                disabled={!hasAdminAccess}
                                value={field.value ? dayjs(field.value) : null}
                                onChange={(date) => {
                                  field.onChange(
                                    date ? dayjs(date).toISOString() : '',
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    {/* Email and Mobile Phone */}
                    <div className="flex flex-col gap-3 md:flex-row">
                      {/* Email */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="email">
                          <div className="text-xs font-semibold">
                            Email{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.email?.message || ''}
                              </span>
                            </span>
                          </div>
                          <input
                            id="email"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="user@domain.com"
                            type="text"
                            {...register('email', {
                              required: 'Email is Required',
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: 'Invalid Email Address',
                              },
                            })}
                          />
                        </label>
                      </div>
                      {/* Mobile Phone */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="mobile_phone">
                          <div className="text-xs font-semibold">
                            Mobile Phone{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.mobile_phone?.message || ''}
                              </span>
                            </span>
                          </div>
                          <input
                            id="mobile-phone"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="XXX-XXX-XXXX"
                            type="text"
                            {...register('mobile_phone', {
                              required: 'Mobile Phone is Required',
                              pattern: {
                                value:
                                  /^(?:\+\d{1,3}\s?)?(?:\(\d{3}\)|\d{3})[-\s]?\d{3}[-\s]?\d{4}$/,
                                message: 'Invalid Phone Number',
                              },
                            })}
                          />
                        </label>
                      </div>
                    </div>
                    {/* External ID */}
                    <div className="flex w-full flex-col">
                      <label htmlFor="external-id">
                        <div className="text-xs font-semibold">External ID</div>
                        <input
                          id="external-id"
                          className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                            hasAdminAccess ? '' : 'cursor-not-allowed'
                          }`}
                          placeholder="External ID"
                          type="text"
                          {...register('external_id')}
                        />
                      </label>
                    </div>
                  </fieldset>
                </section>

                {/* Residential Address Section */}
                <section className="col-start-1 mt-4">
                  {/* Country, Address 1 and 2, City, State, and Postal Code */}

                  {!countriesLoading ? (
                    <fieldset
                      disabled={!hasAdminAccess}
                      className="flex flex-col flex-wrap gap-3 lg:flex-nowrap"
                    >
                      <h4 className="text-base font-semibold">
                        Residential Address
                      </h4>

                      {/* Country */}
                      <div className="flex w-full flex-col">
                        {/* Below pattern prevents PO Boxes */}

                        <div className="text-xs font-semibold">
                          Country{' '}
                          <span className="text-text-error-red">
                            *{' '}
                            <span className="font-normal">
                              {errors?.country?.message || ''}
                            </span>
                          </span>
                        </div>
                        <Controller
                          name="country"
                          control={control}
                          rules={{
                            required: 'Country is Required',
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              id="country"
                              showSearch
                              placeholder="Select a country"
                              size="large"
                              className="my-1 w-full"
                              disabled={!hasAdminAccess}
                              filterOption={(input, option) =>
                                (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              {...register('country', {
                                required: 'Country is Required',
                              })}
                              options={
                                countriesData?.map((country) => ({
                                  value: country.id,
                                  label: country.name,
                                })) || []
                              }
                              onChange={(e) => {
                                selectResidentialCountry(e);
                                onChange(e);
                              }}
                              value={value}
                            />
                          )}
                        />
                      </div>

                      {/* Address 1 and Address 2 */}
                      <div className="flex flex-col gap-3 md:flex-row">
                        {/* Address 1 */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <label htmlFor="address-1">
                            <div className="text-xs font-semibold">
                              Address 1{' '}
                              <span className="text-text-error-red">
                                *{' '}
                                <span className="font-normal">
                                  {errors?.address_1?.message || ''}
                                </span>
                              </span>
                            </div>
                            <input
                              id="address-1"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess ? '' : 'cursor-not-allowed'
                              }`}
                              placeholder="Address"
                              type="text"
                              {...register('address_1', {
                                required: 'Address 1 is Required',
                                pattern: {
                                  value:
                                    /^(?!.*(?:\bP\.?\s?O\.?\s?Box\b|\bPost\s?Office\s?Box\b|\bPO\s?Box\b|\bPO\b|\bPost\b|\bOffice\b|\bPostal\s?(?:Box|Service)\b)).*$/i,
                                  message: 'PO Boxes are not allowed',
                                },
                              })}
                            />
                          </label>
                        </div>
                        {/* Address 2 */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <label htmlFor="address-2">
                            <div className="text-xs font-semibold">
                              Address 2{' '}
                              <span className="font-normal text-text-error-red">
                                {errors?.address_2?.message || ''}
                              </span>
                            </div>
                            <input
                              id="address-2"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess ? '' : 'cursor-not-allowed'
                              }`}
                              placeholder="Address 2"
                              type="text"
                              {...register('address_2', {
                                pattern: {
                                  value:
                                    /^(?!.*(?:\bP\.?\s?O\.?\s?Box\b|\bPost\s?Office\s?Box\b|\bPO\s?Box\b|\bPO\b|\bPost\b|\bOffice\b|\bPostal\s?(?:Box|Service)\b)).*$/i,
                                  message: 'PO Boxes are not allowed',
                                },
                              })}
                            />
                          </label>
                        </div>
                      </div>

                      {/* City and State */}
                      <div className="flex flex-col gap-3 md:flex-row">
                        {/* City */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <label htmlFor="city">
                            <div className="text-xs font-semibold">
                              City{' '}
                              <span className="text-text-error-red">
                                *{' '}
                                <span className="font-normal">
                                  {errors?.city?.message || ''}
                                </span>
                              </span>
                            </div>
                            <input
                              id="city"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess ? '' : 'cursor-not-allowed'
                              }`}
                              placeholder="City"
                              type="text"
                              {...register('city', {
                                required: 'City is Required',
                              })}
                            />
                          </label>
                        </div>
                        {/* State */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <div className="text-xs font-semibold">
                            State{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.province?.message || ''}
                              </span>
                            </span>
                          </div>
                          <Controller
                            name="province"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                showSearch
                                placeholder="Select a state"
                                size="large"
                                className="my-1 w-full"
                                disabled={!hasAdminAccess}
                                {...register('province', {
                                  required: 'State is Required',
                                })}
                                filterOption={(input, option) =>
                                  (option?.label ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={
                                  residentialProvincesData?.map((province) => ({
                                    value: province.id,
                                    label: province.alpha_code || province.name,
                                  })) || []
                                }
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                        </div>
                      </div>

                      {/* Postal Code */}
                      <div className="flex w-full flex-col">
                        <label htmlFor="postal-code">
                          <div className="text-xs font-semibold">
                            Postal Code{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.postal_code?.message || ''}
                              </span>
                            </span>
                          </div>
                          <input
                            id="postal-code"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="Postal Code"
                            type="text"
                            {...register('postal_code', {
                              required: 'Postal Code is Required',
                            })}
                          />
                        </label>
                      </div>
                    </fieldset>
                  ) : (
                    <Skeleton active />
                  )}
                </section>
              </div>

              {/* Shipping Address Section */}
              <section className="col-start-2 row-start-1 mt-4 lg:mt-0">
                <div className="mb-4 flex">
                  <span className="text-xl font-semibold">Optional Info</span>
                </div>
                <hr className="mb-4 border border-r-gray-100" />
                {/* Ship Country, Address 1 and 2, City, State, Postal Code */}
                {!countriesLoading ? (
                  <fieldset
                    disabled={!hasAdminAccess}
                    className="flex flex-col flex-wrap gap-3 lg:flex-nowrap"
                  >
                    <h4 className="text-base font-semibold">
                      Shipping Address
                    </h4>
                    {/* Shipping Country */}
                    <div className="flex w-full flex-col">
                      {/* Below pattern prevents PO Boxes */}

                      <div className="text-xs font-semibold">
                        Shipping Country
                      </div>
                      <Controller
                        name="ship_country"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            id="ship-country"
                            showSearch
                            placeholder="Select a shipping country"
                            size="large"
                            className="my-1 w-full"
                            disabled={!hasAdminAccess}
                            filterOption={(input, option) =>
                              (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            {...register('ship_country')}
                            options={
                              countriesData?.map((country) => ({
                                value: country.id,
                                label: country.name,
                              })) || []
                            }
                            onChange={(e) => {
                              selectShipCountry(e);
                              onChange(e);
                            }}
                            value={value}
                          />
                        )}
                      />
                    </div>

                    {/* Ship Address 1 and Address 2 */}
                    <div className="flex flex-col gap-3 md:flex-row">
                      {/* Ship Address 1 */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="ship-address-1">
                          <div className="text-xs font-semibold">
                            Shipping Address 1
                          </div>
                          <input
                            id="ship-address-1"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="Shipping Address"
                            type="text"
                            {...register('ship_address_1', {
                              pattern: {
                                value:
                                  /^(?!.*(?:\bP\.?\s?O\.?\s?Box\b|\bPost\s?Office\s?Box\b|\bPO\s?Box\b|\bPO\b|\bPost\b|\bOffice\b|\bPostal\s?(?:Box|Service)\b)).*$/i,
                                message: 'PO Boxes are not allowed',
                              },
                            })}
                          />
                        </label>
                        <div className="text-xs text-text-error-red">
                          {errors?.ship_address_1?.message || ''}
                        </div>
                      </div>
                      {/* Ship Address 2 */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="ship-address-2">
                          <div className="text-xs font-semibold">
                            Shipping Address 2
                          </div>
                          <input
                            id="ship-address-2"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="Shipping Address 2"
                            type="text"
                            {...register('ship_address_2', {
                              pattern: {
                                value:
                                  /^(?!.*(?:\bP\.?\s?O\.?\s?Box\b|\bPost\s?Office\s?Box\b|\bPO\s?Box\b|\bPO\b|\bPost\b|\bOffice\b|\bPostal\s?(?:Box|Service)\b)).*$/i,
                                message: 'PO Boxes are not allowed',
                              },
                            })}
                          />
                        </label>
                        <div className="text-xs text-text-error-red">
                          {errors?.ship_address_2?.message || ''}
                        </div>
                      </div>
                    </div>

                    {/* Ship City and State */}
                    <div className="flex flex-col gap-3 md:flex-row">
                      {/* Ship City */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="ship-city">
                          <div className="text-xs font-semibold">
                            Shipping City
                          </div>
                          <input
                            id="ship-city"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="Shipping City"
                            type="text"
                            {...register('ship_city')}
                          />
                        </label>
                      </div>
                      {/* Ship State */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <div className="text-xs font-semibold">
                          Shipping State
                        </div>

                        <Controller
                          name="ship_province"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              showSearch
                              placeholder="Select a shipping state"
                              size="large"
                              className="my-1 w-full"
                              disabled={!hasAdminAccess}
                              {...register('ship_province')}
                              filterOption={(input, option) =>
                                (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={
                                shippingProvincesData?.map((province) => ({
                                  value: province.id,
                                  label: province.alpha_code || province.name,
                                })) || []
                              }
                              onChange={onChange}
                              value={value}
                            />
                          )}
                        />
                      </div>
                    </div>

                    {/* Ship Postal Code */}
                    <div className="flex w-full flex-col">
                      <label htmlFor="ship-postal-code">
                        <div className="text-xs font-semibold">
                          Shipping Postal Code
                        </div>
                        <input
                          id="ship-postal-code"
                          className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                            hasAdminAccess ? '' : 'cursor-not-allowed'
                          }`}
                          placeholder="Shipping Postal Code"
                          type="text"
                          {...register('ship_postal_code')}
                        />
                      </label>
                    </div>
                  </fieldset>
                ) : (
                  <Skeleton active />
                )}
              </section>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                className={`h-10 w-[25%] items-center rounded-lg bg-surface-primary-dark text-white ${
                  hasAdminAccess ? '' : 'cursor-not-allowed'
                }`}
                type="submit"
                disabled={!hasAdminAccess || createUserLoading}
              >
                {createUserLoading ? <LoadingSpinner /> : <p>Save</p>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
