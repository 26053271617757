import React, { ReactElement } from 'react';
import { CellContext } from '@tanstack/react-table';
import {
  getCardStatusFromStatusId,
  getStatusFromStatusIdOrName,
  getStatusTypeFromIdOrName,
} from '../../utils/businessUtils';

export function tableStatusCell<T>(
  info: CellContext<T, string | number> | string,
  cardStatus = false,
): ReactElement | null {
  let status;
  let statusType;

  if (typeof info === 'string') {
    statusType = getStatusTypeFromIdOrName(info);
    status = getStatusFromStatusIdOrName(info || 0);
  } else {
    if (!info.getValue()) return null;
    /**
     * If this is for a card, we need to get the status
     * from the correct helper function
     */
    if (cardStatus) {
      /**
       * Card status function is different than the
       * other more general status function
       */
      status = getCardStatusFromStatusId(info.getValue());
      statusType = getStatusTypeFromIdOrName(status);
    } else {
      statusType = getStatusTypeFromIdOrName(info.getValue());
      status = getStatusFromStatusIdOrName(info.getValue() || 0);
    }
  }

  return (
    <div
      className={`w-[125px] rounded-lg py-1 text-center ${
        statusType === 'success' ? 'bg-surface-success-green' : ''
      } ${statusType === 'warning' ? 'bg-surface-action-orange' : ''} 
      ${statusType === 'error' ? 'bg-red-200' : ''} ${
        statusType === 'unknown' ? 'bg-gray-200' : ''
      } ${statusType === 'success' ? 'text-text-success-green' : ''} ${
        statusType === 'warning' ? 'text-text-action-orange' : ''
      } 
      ${statusType === 'error' ? 'text-text-error-red' : ''}  ${
        statusType === 'unknown' ? 'text-text-gray' : ''
      }`}
    >
      {status ? status.replaceAll('_', ' ').toUpperCase() : null}
    </div>
  );
}
