import React, { ReactElement, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { ReactComponent as UserGray } from '../../assets/user-gray.svg';
import { ReactComponent as CalendarGray } from '../../assets/calendar-gray.svg';
import { ReactComponent as DollarSignGray } from '../../assets/dollarsign-gray.svg';
import Table from '../Table';
import LoadingSpinner from '../LoadingSpinner';
import { BulkUserDTO } from '../../types/user/bulkUserDTO';
import { createUserInitials } from '../../utils/userUtils';
import { BusinessAccountDTO } from '../../types/business/businessAccountDTO';
import { AvailableBalance } from '../AvailableBalance';

type BulkAddFundSummaryProps = {
  users: BulkUserDTO[];
  totalAmount: number;
  userCount: number;
  businessAccount: BusinessAccountDTO | null;
  isLoading: boolean;
  error?: string;
  handleConfirm: () => void;
  goToStep: (step: number) => void;
};
export function BulkAddFundSummary({
  users,
  isLoading,
  totalAmount,
  userCount,
  businessAccount,
  error,
  handleConfirm,
  goToStep,
}: BulkAddFundSummaryProps): ReactElement {
  const createNameAndEmailCell = useCallback(
    (info: CellContext<BulkUserDTO, unknown>): ReactElement => {
      return (
        <div className="flex items-center">
          <div className="mr-2 flex h-7 w-7 items-center justify-center rounded-full bg-surface-icon-1">
            {createUserInitials(info.row.original.full_name)}
          </div>
          <div className="flex flex-col">
            <p className="font-semibold">{info.row.original.full_name}</p>
            <p className="font-normal text-text-gray">
              {info.row.original.email}
            </p>
          </div>
        </div>
      );
    },
    [],
  );

  const userColumnHelper = createColumnHelper<BulkUserDTO>();

  const userColumns = useMemo(
    () => [
      userColumnHelper.accessor('full_name', {
        cell: (info) => createNameAndEmailCell(info),
        header: 'USER',
      }),
      userColumnHelper.accessor('masked_account_number', {
        header: 'ACCOUNT',
        cell: (info) => info.getValue(),
      }),
      userColumnHelper.accessor('full_address', {
        header: 'STREET ADDRESS',
        cell: (info) => info.row.original.full_address || 'N/A',
      }),
      userColumnHelper.accessor('amount', {
        header: 'DEPOSIT AMOUNT',
        cell: (info) => `$${info.getValue().toLocaleString()} USD` || 'N/A',
      }),
    ],
    [userColumnHelper, createNameAndEmailCell],
  );

  return (
    <>
      <h3 className="pb-2 text-xl font-semibold">Add Funds</h3>
      <div className="mb-2 text-text-error-red">{error}</div>
      <div className="flex flex-col rounded-lg border border-border-light-gray p-4">
        <Table<BulkUserDTO> data={users} columns={userColumns} />
      </div>
      <div className="mx-auto my-8 flex w-full items-center justify-between lg:w-[95%]">
        <div className="flex w-fit">
          <div className="mr-2 mt-1">
            <UserGray />
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-text-light-gray">
              From Business Account
            </p>
            <div>
              <p className="font-semibold">
                {businessAccount?.name} --{' '}
                <AvailableBalance
                  availableBalance={businessAccount?.available_balance || 0}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="mr-2 mt-1">
            <UserGray />
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-text-light-gray">
              Total Users to be Paid
            </p>
            <p className="font-semibold">{userCount}</p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-2 mt-1">
            <DollarSignGray />
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-text-light-gray">
              Total Amount to be Paid
            </p>
            <p className="font-semibold">${totalAmount.toLocaleString()} USD</p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-2 mt-1">
            <CalendarGray />
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-text-light-gray">Payment Date</p>
            <p className="font-semibold"> {dayjs().format('L LT z')}</p>
          </div>
        </div>
      </div>
      <footer className="flex flex-col gap-4 pb-2">
        <button
          className="flex h-10 items-center justify-center rounded-full border border-border-primary bg-white p-3 text-text-primary transition duration-300 ease-in-out hover:bg-surface-primary hover:text-white"
          type="button"
          onClick={() => goToStep(1)}
        >
          Back to Editing
        </button>
        <button
          className="flex h-10 w-full items-center justify-center rounded-full border-border-primary bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark "
          type="button"
          onClick={handleConfirm}
        >
          {isLoading ? <LoadingSpinner /> : 'Confirm and Add Funds'}
        </button>
      </footer>
    </>
  );
}
