import React, { ReactElement, useMemo } from 'react';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { isAdminOrMTU } from '../../../utils/authUtils';
import { ReactComponent as ChevronDownIcon } from '../../../assets/chevron-down-white.svg';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg';
import { ReactComponent as BulkUserPlusIcon } from '../../../assets/bulk-user-add-blue.svg';
import { ReactComponent as CardsIcon } from '../../../assets/card.svg';
import { ReactComponent as AssignCardIcon } from '../../../assets/assign-card.svg';
import { ReactComponent as DollarSignIcon } from '../../../assets/dollar_sign.svg';
import { ReactComponent as ActionMenuIcon } from '../../../assets/action-menu-white.svg';

export function UserListingActions({
  modalFn,
  disabled = false,
}: {
  modalFn: (object: ModalTrigger) => void;
  disabled: boolean;
}): ReactElement | null {
  const hasAdminAccess = isAdminOrMTU();
  const items: MenuProps['items'] = useMemo(
    () => [
      {
        type: 'group',
        label: 'Users',
        key: 0,
        children: [
          {
            label: (
              <span className="flex items-center">
                <PlusIcon className="mr-4 w-6" />
                Create Single User
              </span>
            ),
            key: 1,
            onClick: () => modalFn({ type: 'create-user', val: true }),
          },
          {
            label: (
              <span className="flex items-center">
                <BulkUserPlusIcon className="mr-4 w-6" />
                Create Multiple Users
              </span>
            ),
            key: 2,
            onClick: () => modalFn({ type: 'bulk-users', val: true }),
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        type: 'group',
        label: 'Cards',
        key: 3,
        children: [
          {
            label: (
              <span className="flex items-center">
                <CardsIcon className="mr-4" />
                Order Multiple Cards
              </span>
            ),
            key: 4,
            onClick: () => modalFn({ type: 'bulk-cards', val: true }),
          },
          {
            label: (
              <span className="flex items-center">
                <AssignCardIcon className="mr-4 h-6 w-6" />
                Assign Cards
              </span>
            ),
            key: 5,
            onClick: () => modalFn({ type: 'bulk-assign', val: true }),
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        type: 'group',
        label: 'Funds',
        key: 6,
        children: [
          {
            label: (
              <span className="flex items-center">
                <DollarSignIcon className="mr-4 w-6" />
                Bulk Add Funds
              </span>
            ),
            key: 7,
            onClick: () => modalFn({ type: 'bulk-funds', val: true }),
          },
        ],
      },
    ],
    [modalFn],
  );

  return hasAdminAccess ? (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      placement="bottomRight"
      disabled={disabled}
    >
      <button
        type="button"
        className="flex min-w-[200px] items-center rounded-md bg-surface-primary-light px-4 py-2 font-semibold text-white"
      >
        <span className="mr-auto inline-flex items-center">
          <ActionMenuIcon className="mr-1 h-5 w-5" />
          Options
        </span>
        <ChevronDownIcon />
      </button>
    </Dropdown>
  ) : null;
}

export default UserListingActions;
