import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ExpandedBusinessDTO } from '../types/business/expandedBusinessDTO';
import { UserDTO } from '../types/user/userDTO';

interface AppState {
  selectedBusiness: ExpandedBusinessDTO | null;
  setSelectedBusiness: (business: ExpandedBusinessDTO | null) => void;
  selectedUser: UserDTO | null;
  setSelectedUser: (user: UserDTO | null) => void;
  notification: {
    message: string;
    type: string;
  } | null;
  setNotification: (
    notification: {
      type: string;
      message: string;
    } | null,
  ) => void;
}
export const useAppStore = create<AppState>()(
  devtools((set) => ({
    selectedBusiness: null,
    setSelectedBusiness: (business: ExpandedBusinessDTO | null) =>
      set(() => ({ selectedBusiness: business })),
    selectedUser: null,
    setSelectedUser: (user: UserDTO | null) =>
      set(() => ({ selectedUser: user })),
    notification: null,
    setNotification: (notification: { message: string; type: string }) =>
      set(() => ({ notification })),
  })),
);
