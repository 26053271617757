import { Modal } from 'antd';
import React, { BaseSyntheticEvent, ReactElement, useState } from 'react';
import LoadingSpinner from '../../LoadingSpinner';

type CardCTAModalProps = {
  isModalOpen: boolean;
  primaryText: string;
  submitButtonText: string;
  setIsModalOpen: (val: boolean) => void;
  onCancel: (e: BaseSyntheticEvent) => void;
  onConfirm: (e: BaseSyntheticEvent) => void;
  forDelete?: boolean;
};

export function CardCTAModal({
  isModalOpen,
  primaryText,
  submitButtonText,
  setIsModalOpen,
  onCancel,
  onConfirm,
  forDelete = false,
}: CardCTAModalProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async (e: BaseSyntheticEvent) => {
    setIsLoading(true);
    try {
      await onConfirm(e);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer="">
      <div className="mb-6 flex flex-col gap-6">
        <h3 className="text-xl font-semibold">{submitButtonText}</h3>
        <p className="text-base font-semibold">{primaryText}</p>
      </div>
      <footer className="flex flex-col gap-6 py-2">
        <button
          onClick={onCancel}
          disabled={isLoading}
          type="button"
          className="flex h-10 items-center justify-center rounded-full border border-border-primary bg-white p-3 text-text-primary transition duration-300 ease-in-out hover:bg-surface-primary hover:text-white"
        >
          Cancel
        </button>
        <button
          className={`h-10 w-full justify-center text-white hover:border-border-error-red hover:bg-surface-error-red hover:text-white ${
            isLoading
              ? 'border-border-error-red bg-surface-error-red'
              : 'border-border-primary bg-surface-primary'
          } flex items-center justify-center rounded-full ${
            forDelete ? 'bg-surface-error-red' : 'bg-surface-primary'
          } p-3 transition duration-300 ease-in-out`}
          onClick={handleConfirm}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <LoadingSpinner /> : submitButtonText}
        </button>
      </footer>
    </Modal>
  );
}
