import { UserDTO } from '../types/user/userDTO';

export enum Roles {
  PlatformAdministrator = 1,
  APIAccount = 2,
  PlatformAgent = 3,
  RegularUser = 4,
  ProgramAdministrator = 5,
  ReadOnly = 6,
  BusinessAdministrator = 7,
}

export const isMTU = () => localStorage.getItem('mtu') === 'true';

export const getRoleId = () => parseInt(localStorage.getItem('roleId') ?? '0');

/**
 * Determines whether the role dropdown item should be disabled based on the
 * logged in user's role.
 */
const shouldDisable = (roleId: number, requesterRoleId: number) => {
  const isPlatformAdmin = requesterRoleId === Roles.PlatformAdministrator;
  const isProgramAdmin = requesterRoleId === Roles.ProgramAdministrator;
  const isBusinessAdmin = requesterRoleId === Roles.BusinessAdministrator;

  if (isPlatformAdmin) {
    return false;
  }

  if (isProgramAdmin) {
    const isAllowedRole =
      roleId === Roles.BusinessAdministrator ||
      roleId === Roles.PlatformAgent ||
      roleId === Roles.APIAccount ||
      roleId === Roles.RegularUser;
    return !isAllowedRole;
  }

  if (isBusinessAdmin) {
    const isAllowedRole =
      roleId === Roles.PlatformAgent || roleId === Roles.RegularUser;
    return !isAllowedRole;
  }
  return true;
};

/**
 * Creates a dropdown list of Roles and disabled items
 * based on the logged in user's role
 *
 * @param requesterRoleId Role of the logged in user
 * @returns The dropdown list populated
 */
export const createRoleDropdownList = (
  requesterRoleId: number,
  includeCardholder = true,
) => {
  const roleDropdownList = [
    {
      id: Roles.PlatformAdministrator,
      name: 'Platform Admin (MTU)',
      disabled: shouldDisable(Roles.PlatformAdministrator, requesterRoleId),
    },
    {
      id: Roles.ProgramAdministrator,
      name: 'Program Admin',
      disabled: shouldDisable(Roles.ProgramAdministrator, requesterRoleId),
    },
    {
      id: Roles.BusinessAdministrator,
      name: 'Business Admin',
      disabled: shouldDisable(Roles.BusinessAdministrator, requesterRoleId),
    },
    {
      id: Roles.PlatformAgent,
      name: 'Platform Agent',
      disabled: shouldDisable(Roles.PlatformAgent, requesterRoleId),
    },
    {
      id: Roles.APIAccount,
      name: 'API User',
      disabled: shouldDisable(Roles.APIAccount, requesterRoleId),
    },
  ];
  if (includeCardholder) {
    roleDropdownList.push({
      id: Roles.RegularUser,
      name: 'Cardholder',
      disabled: shouldDisable(Roles.RegularUser, requesterRoleId),
    });
  }
  return roleDropdownList;
};

export const canViewUser = (user: UserDTO) => {
  const requesterRoleId = getRoleId();
  /**
   * Platform Admins can view all users
   */
  if (requesterRoleId === Roles.PlatformAdministrator) {
    return true;
  }
  /**
   * Program Admins can view all users except Platform Admins
   */
  if (requesterRoleId === Roles.ProgramAdministrator) {
    if (user.role_id === Roles.PlatformAdministrator) {
      return false;
    }
    return true;
  }
  /**
   * Business Admins can only view Cardholders and other Business Admins
   */
  if (requesterRoleId === Roles.BusinessAdministrator) {
    if (
      user.role_id === Roles.PlatformAdministrator ||
      user.role_id === Roles.ProgramAdministrator ||
      user.role_id === Roles.PlatformAgent ||
      user.role_id === Roles.APIAccount
    ) {
      return false;
    }
    return true;
  }
  /**
   * Platform Agents can view all users except
   * Platform Admins, Program Admins, API Accounts
   * and Business Admins
   */
  if (requesterRoleId === Roles.PlatformAgent) {
    if (
      user.role_id === Roles.PlatformAdministrator ||
      user.role_id === Roles.ProgramAdministrator ||
      user.role_id === Roles.BusinessAdministrator ||
      user.role_id === Roles.APIAccount
    ) {
      return false;
    }
    return true;
  }
  return false;
};

/**
 * Checks if a user is an admin or MTU
 * - RoleId 5 is Program Admin
 * - RoleId 7 is Business Admin
 * @returns true if user is an admin or MTU
 */
export const isAdminOrMTU = () => {
  const roleId = localStorage.getItem('roleId');
  const isMtu = localStorage.getItem('mtu');
  return isMtu === 'true' || roleId === '5' || roleId === '7';
};

/**
 * Checks if a user is a program admin
 * - RoleId 5 is Program Admin
 * @returns true if user is a program admin
 */
export const isProgramAdmin = () => {
  const roleId = localStorage.getItem('roleId');
  return roleId === '5';
};

export const isMTUOrProgramAdmin = () => {
  const roleId = localStorage.getItem('roleId');
  const isMtu = localStorage.getItem('mtu');
  return isMtu === 'true' || roleId === '5';
};

/**
 * Checks if a user is a business admin
 * - RoleId 7 is Business Admin
 * @returns true if user is a business admin
 */
export const isBusinessAdmin = () => {
  const roleId = localStorage.getItem('roleId');
  return roleId === '7';
};
