import React, { ReactElement, isValidElement } from 'react';

import { Dropdown } from 'antd';

import { ReactComponent as ChevronDown } from '../../assets/chevron-down.svg';
import { ReactComponent as Lock } from '../../assets/lock.svg';

import { AuthContext, useAuthApi } from '../../context/authContext';

function DashboardHeader({
  subtext,
}: {
  subtext: string | ReactElement;
}): ReactElement {
  const authState: AuthContext | null = useAuthApi();

  const renderSubtext = () => {
    if (typeof subtext === 'string') {
      // Handle string case
      return (
        <div className="w-full">
          <span className="font-light text-gray-500">{subtext}</span>
        </div>
      );
    }
    if (isValidElement(subtext)) {
      // Handle node case
      return subtext;
    }
    // Handle other cases
    return null;
  };

  const settingsDropdownItems = [
    {
      label: (
        <div className="flex items-center">
          <Lock />
          <div className="ml-3 font-semibold" id="sign-out">
            Sign Out
          </div>
        </div>
      ),
      key: 2,
      onClick: () => authState?.logout(),
    },
  ];

  return (
    <div className="mb-6 w-full">
      <div className="flex h-10 flex-col justify-between md:flex-row md:items-center">
        {subtext && renderSubtext()}
        <div className="mt-5 hidden justify-center gap-5 md:mt-0 md:flex md:justify-end">
          <Dropdown
            className="cursor-pointer"
            menu={{ items: settingsDropdownItems }}
            trigger={['click']}
          >
            <div className="flex h-10 items-center rounded-full border-border-light-gray bg-blue-200 p-3">
              <div className="mr-3 flex h-8 w-8 items-center justify-center rounded-full bg-white">
                <div className="h-7 w-7 rounded-full bg-surface-primary-dark" />
              </div>
              <p className="ml-3 mr-1 min-w-[100px]">
                {authState?.user?.firstname}{' '}
                {authState?.user?.lastname?.slice(0, 1)}
              </p>
              <ChevronDown />{' '}
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
