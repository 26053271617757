import React from 'react';
import {
  FieldErrors,
  RegisterOptions,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { ResetPasswordFormValues } from '../../../types/forms/resetPasswordFormValues';

type ConfirmPasswordFormInputProps = {
  errors: FieldErrors<ResetPasswordFormValues>;
  register: (
    name: 'confirmPassword',
    options?:
      | RegisterOptions<ResetPasswordFormValues, 'confirmPassword'>
      | undefined,
  ) => UseFormRegisterReturn<'confirmPassword'>;
};

export default function ConfirmPasswordFormInput({
  register,
  errors,
}: ConfirmPasswordFormInputProps) {
  return (
    <div className="flex flex-col">
      <div
        className="m-1 text-xs text-text-error-red"
        data-testid="confirmPassword-errors"
      >
        {errors?.confirmPassword?.message || ''}
      </div>
      <input
        id="confirmPassword"
        className="rounded-lg border border-zinc-200 p-2"
        placeholder="Confirm Password"
        type="password"
        {...register('confirmPassword', {
          required: 'Password Confirmation is Required',
        })}
      />
    </div>
  );
}
