import React, { ReactElement } from 'react';
import LoadingSpinner from '../../LoadingSpinner';

export function BulkFileProcessing({
  percent,
  status,
}: {
  percent: number;
  status: string;
}): ReactElement {
  return (
    <div className="flex flex-col justify-center">
      <h2 className="mb-6 text-xl font-medium">Processing your file</h2>
      <div className="flex h-12 w-full items-center justify-between rounded-md bg-surface-icon-bg-light">
        <p className="w-full px-6 py-12 font-bold tracking-wide text-text-primary">
          {status}
        </p>
        <div className="ml-auto flex h-full items-center justify-center rounded-r-md bg-surface-primary px-6">
          <LoadingSpinner />
        </div>
      </div>
      <div className="mt-4 flex w-full items-center rounded-lg transition-all">
        <div className="flex h-4 w-full rounded-lg bg-surface-icon-bg-light transition-all">
          <div
            className="flex h-4 rounded-lg bg-surface-primary-light transition-all"
            style={{ width: `${percent}%` }}
          />
        </div>
      </div>
    </div>
  );
}
