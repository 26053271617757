import { ColumnDef } from '@tanstack/react-table';
import { Result, Skeleton, Tooltip } from 'antd';
import React from 'react';
import Table from '.';
import { ReactComponent as FilterIcon } from '../../assets/filters.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import { isAdminOrMTU } from '../../utils/authUtils';
import { ReactComponent as Plus } from '../../assets/plus.svg';

type WiresProps<T> = {
  setIsFilterModalOpen: (isOpen: boolean) => void;
  setIsExportModalOpen: (isOpen: boolean) => void;
  setIsCreateWireModalOpen: (isOpen: boolean) => void;
  setPageIndex: (index: number) => void;
  setPageSize: (size: number) => void;
  wireList: T[];
  wireColumns: ColumnDef<T>[];
  isLoadingWires: boolean;
  hasError: boolean;
  totalWire: number;
  currentPageIndex: number;
  currentPageSize: number;
};

export function Wires<T>({
  setIsFilterModalOpen,
  setIsExportModalOpen,
  setIsCreateWireModalOpen,
  wireList,
  wireColumns,
  isLoadingWires,
  hasError,
  totalWire,
  currentPageIndex,
  currentPageSize,
  setPageSize,
  setPageIndex,
}: WiresProps<T>) {
  const hasAdminAccess = isAdminOrMTU();

  return (
    <div className="grid gap-3 rounded-lg border border-border-light-gray bg-white px-2 py-3 md:px-6">
      <div className="row-span-3 flex h-auto w-full flex-col">
        <div className="flex w-full flex-row items-center justify-between gap-3">
          <div className="text-xl">Wires</div>
          <div className="flex">
            {hasAdminAccess && (
              <button
                type="button"
                className={`flex w-auto items-center rounded-md bg-surface-primary-light p-2 font-semibold text-white ${
                  isLoadingWires ? 'cursor-not-allowed' : ''
                }`}
                data-testid="submit-button"
                onClick={() => setIsCreateWireModalOpen(true)}
              >
                <div className="flex h-5 w-5 items-center justify-center rounded-full bg-white sm:mr-2">
                  <Plus />
                </div>
                <span className="w-max">Add Wire</span>
              </button>
            )}
            {hasAdminAccess && (
              <Tooltip
                color="#06102B"
                title="Click to export transactions with filters applied if provided"
              >
                <button
                  type="button"
                  disabled={isLoadingWires}
                  className={`flex w-auto items-center rounded-md bg-surface-primary-light p-2 font-semibold text-white sm:ml-5 ${
                    isLoadingWires ? 'cursor-not-allowed' : ''
                  }`}
                  onClick={() => setIsExportModalOpen(true)}
                >
                  <DownloadIcon />
                </button>
              </Tooltip>
            )}

            <button
              type="button"
              className="flex items-center rounded-md border border-border-light-gray p-2 text-sm font-semibold sm:ml-5"
              onClick={() => setIsFilterModalOpen(true)}
            >
              <FilterIcon />
              <span className="ml-2">Filters</span>
            </button>
          </div>
        </div>
      </div>
      {!isLoadingWires && wireList && wireList.length > 0 && (
        <Table<T>
          data={wireList}
          columns={wireColumns}
          showPagination
          totalItems={totalWire}
          pageIndex={currentPageIndex}
          pageSize={currentPageSize}
          setPageIndex={(page) => setPageIndex(page)}
          setPageSize={(pageSize) => setPageSize(pageSize)}
        />
      )}
      {isLoadingWires && <Skeleton active />}
      {!isLoadingWires && wireList && wireList.length === 0 && (
        <div className="mb-2 flex items-center justify-center">
          <p className="font-semibold">No Wires Found</p>
        </div>
      )}
      {hasError && (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong loading the transactions! Please refresh and try again"
        />
      )}
    </div>
  );
}
