import { Modal } from 'antd';
import React, { BaseSyntheticEvent, ReactElement, useState } from 'react';
import LoadingSpinner from '../../LoadingSpinner';

export function DeleteConfirmationModal({
  nameField,
  nameLabel,
  isModalOpen,
  setIsModalOpen,
  onCancel,
  onConfirm,
}: {
  nameField: string;
  nameLabel: string;
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  onCancel: (e: BaseSyntheticEvent) => void;
  onConfirm: (e: BaseSyntheticEvent) => void;
}): ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async (e: BaseSyntheticEvent) => {
    setIsLoading(true);
    try {
      await onConfirm(e);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer="">
      <div className="my-10 flex flex-col items-center gap-6">
        <div className="flex flex-col items-center gap-1">
          <p className="text-2xl font-bold">Are You Sure You Want to Delete?</p>
          <p className="text-lg text-text-gray">
            Once deleted, you cannot undo this action.
          </p>
        </div>
        <div className="flex flex-col items-center">
          <p className="font-semibold text-text-gray">
            {nameLabel.toUpperCase()}
          </p>
          <p className="text-xl font-bold">{nameField}</p>
        </div>
      </div>
      <footer className="flex flex-col gap-6 py-2">
        <button
          onClick={onCancel}
          disabled={isLoading}
          type="button"
          className="w-full rounded-md bg-surface-primary p-3 font-semibold text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
        >
          Cancel
        </button>
        <button
          className={`w-full justify-center bg-white hover:border-red-500 hover:bg-red-500 hover:text-white ${
            isLoading
              ? 'border-red-500 bg-red-500'
              : 'border-border-gray bg-surface-light-gray'
          } rounded-md border p-3 font-semibold text-white transition duration-300 ease-in-out`}
          onClick={handleConfirm}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <LoadingSpinner /> : 'Delete'}
        </button>
      </footer>
    </Modal>
  );
}
