import TernFetchAPI from './TernFetchAPI';
import { UserDTO } from '../types/user/userDTO';
import { UserModel } from '../types/user/userModel';
import { ExpandedBusinessDTO } from '../types/business/expandedBusinessDTO';
import { BusinessAccountDTO } from '../types/business/businessAccountDTO';
import { CreateBusinessResponseDTO } from '../types/business/createBusinessResponseDTO';
import { BusinessAccountTransactionDTO } from '../types/transactions/businessAccountTransactionDTO';
import { UserAccountTransactionDTO } from '../types/transactions/userAccountTransactionDTO';
import { UserAccountDTO } from '../types/user/userAccountDTO';
import { UserAccountTransactionModel } from '../types/transactions/userAccountTransactionModel';
import { CreateUserResponseDTO } from '../types/user/createUserResponseDTO';
import { UpdateUserRequestDTO } from '../types/user/updateUserRequestDTO';
import { CreateUserRequestDTO } from '../types/user/createUserRequestDTO';
import { TransferFundsRequestDTO } from '../types/transfers/transferFundsRequestDTO';
import { TransferFundsResponseDTO } from '../types/transfers/tranferFundsResponseDTO';
import { AccountHistoryDTO } from '../types/user/acountHistoryDTO';
import { BulkCreateUserResponseDTO } from '../types/user/bulkCreateUserResponseDTO';
import { BulkTransferFundsResponseDTO } from '../types/transfers/bulkTransferFundsResponseDTO';
import { TERN_USER_API_URL } from './Endpoints';
import batchPromises from '../utils/batchPromises';
import { BusinessAccountWireDTO } from '../types/transactions/businessAccountWireDTO';
import { CreateBusinessWireRequestDTO } from '../types/transactions/createBusinessWireRequestDTO';
import { UpdateBusinessWireRequestDTO } from '../types/transactions/updateBusinessWireRequestDTO';

export const getUserWithFullName = async (
  userId: number,
): Promise<UserDTO | null> => {
  const userResponse = await TernFetchAPI.get<UserModel[]>(
    TERN_USER_API_URL,
    `${userId}?includeRole=true`,
  );
  const user = userResponse.data[0] || null;
  if (!user) {
    return null;
  }
  return {
    ...user,
    full_name_and_dob: `${user.first_name} ${user.last_name} ${user.dob}`,
    contact_information: `${user.email} ${user.mobile_phone}`,
    full_address: `${user.address_1 ? `${user.address_1},` : ''} ${
      user.address_2 ? `${user.address_2},` : ''
    } ${user.city ? `${user.city},` : ''} ${
      user.province ? `${user.province},` : ''
    } ${user.postal_code ? `${user.postal_code},` : ''} ${user.country ?? ''}`,
    full_ship_address: `${
      user.ship_address_1 ? `${user.ship_address_1},` : ''
    } ${user.ship_address_2 ? `${user.ship_address_2},` : ''} ${
      user.ship_city ? `${user.ship_city},` : ''
    } ${user.ship_province ? `${user.ship_province},` : ''} ${
      user.ship_postal_code ? `${user.ship_postal_code},` : ''
    } ${user.ship_country ? user.ship_country : ''}`,
  };
};

export const getUsers = async (queryString?: string): Promise<UserDTO[]> => {
  const usersResponse = await TernFetchAPI.get<UserModel[]>(
    TERN_USER_API_URL,
    `${
      queryString ||
      '?page=1&pageSize=2000&include_roles=true&order_by=created_at&order_direction=DESC'
    }`,
  );
  const usersWithFullName: UserDTO[] = usersResponse.data?.map((user) => {
    return {
      ...user,
      full_name_and_dob: `${user.first_name} ${user.last_name} ${user.dob}`,
      contact_information: `${user.email} ${user.mobile_phone}`,
      full_address: `${user.address_1 ? `${user.address_1},` : ''} ${
        user.address_2 ? `${user.address_2},` : ''
      } ${user.city ? `${user.city},` : ''} ${
        user.province ? `${user.province},` : ''
      } ${user.postal_code ? `${user.postal_code},` : ''} ${
        user.country ?? ''
      }`,
    };
  });
  return usersWithFullName || [];
};

export const getUser = async (userId: number): Promise<UserModel | null> => {
  const userResponse = await TernFetchAPI.get<UserDTO[]>(
    TERN_USER_API_URL,
    `${userId}`,
  );
  return userResponse.data ? userResponse.data[0] : null;
};

export const updateUser = async (
  userId: string,
  body: UpdateUserRequestDTO,
): Promise<UserDTO | null> => {
  const businessResponse = await TernFetchAPI.put<UserDTO[]>(
    TERN_USER_API_URL,
    `${userId}`,
    body,
  );
  return businessResponse.data ? businessResponse.data[0] : null;
};

export const createUser = async (
  body: CreateUserRequestDTO,
): Promise<CreateUserResponseDTO | null> => {
  const userResponse = await TernFetchAPI.post<CreateUserResponseDTO[]>(
    TERN_USER_API_URL,
    '/',
    body,
  );
  return userResponse.data ? userResponse.data[0] : null;
};

export const bulkCreateUser = async (
  body: CreateUserRequestDTO[],
): Promise<BulkCreateUserResponseDTO> => {
  return (
    await batchPromises(body, 10, 3, (batch) =>
      TernFetchAPI.post<BulkCreateUserResponseDTO>(
        TERN_USER_API_URL,
        `bulk`,
        batch,
      ),
    )
  ).flatMap((v) => v.data);
};

export const getUserAccounts = async (
  userId: number,
): Promise<UserAccountDTO[]> => {
  const userAccounts = await TernFetchAPI.get<UserAccountDTO[]>(
    TERN_USER_API_URL,
    `accounts?user_id=${userId}`,
    true,
  );
  return userAccounts.data;
};

export const getUserAccountHistory = async (
  accountId: number,
  queryString = '?page=1&pageSize=20',
): Promise<AccountHistoryDTO[]> => {
  const accountHistory = await TernFetchAPI.get<AccountHistoryDTO[]>(
    TERN_USER_API_URL,
    `accounts/${accountId}/history${queryString}`,
    true,
  );
  return accountHistory.data;
};

export const getUserTransactions = async (
  userAccountId: number,
  queryString: string,
): Promise<UserAccountTransactionDTO[]> => {
  const accountTransactionsResponse = await TernFetchAPI.get<
    UserAccountTransactionModel[]
  >(
    TERN_USER_API_URL,
    `accounts/${userAccountId}/v2/transactions${queryString}`,
  );
  return (
    accountTransactionsResponse.data.map((transaction) => {
      return {
        id: transaction.id,
        description: transaction.description || '',
        card_last_four: transaction.card_last_four || '',
        amount: transaction.amount,
        current_amount: transaction.new_balance,
        transaction_status: transaction.status || '',
        transaction_type: transaction.transaction_type || '',
        transfer_type: transaction.transfer_type || '',
        card_transaction_date: transaction.card_transaction_date,
        balance_changed_date: transaction.balance_changed_date,
        icon_asset_url: transaction.icon_asset_url,
      };
    }) || []
  );
};

export const addFundsToUserAccount = async (
  body: TransferFundsRequestDTO,
): Promise<TransferFundsResponseDTO | null> => {
  const transferFundsResponse = await TernFetchAPI.patch<
    TransferFundsResponseDTO[]
  >(TERN_USER_API_URL, `accounts/transfer_balances`, body);
  return transferFundsResponse.data ? transferFundsResponse.data[0] : null;
};

export const bulkAddFundsToUserAccount = async (
  body: TransferFundsRequestDTO[],
): Promise<BulkTransferFundsResponseDTO | null> => {
  const transferFundsResponse =
    await TernFetchAPI.post<BulkTransferFundsResponseDTO>(
      TERN_USER_API_URL,
      `accounts/transfer_balances/bulk`,
      body,
    );
  return transferFundsResponse.data || [];
};

export const getBusiness = async (
  businessId: string,
): Promise<ExpandedBusinessDTO | null> => {
  const businessResponse = await TernFetchAPI.get<ExpandedBusinessDTO[]>(
    TERN_USER_API_URL,
    `business/${businessId}`,
  );
  return businessResponse.data ? businessResponse.data[0] : null;
};

export const getBusinessAccounts = async (
  businessId: string,
): Promise<BusinessAccountDTO[]> => {
  const accountResponse = await TernFetchAPI.get<BusinessAccountDTO[]>(
    TERN_USER_API_URL,
    `business/accounts?business_id=${businessId}`,
  );
  return accountResponse.data || [];
};

export const updateBusiness = async (
  businessId: string,
  body: ExpandedBusinessDTO,
): Promise<ExpandedBusinessDTO | null> => {
  const businessResponse = await TernFetchAPI.put<ExpandedBusinessDTO[]>(
    TERN_USER_API_URL,
    `business/${businessId}`,
    body,
  );
  return businessResponse.data ? businessResponse.data[0] : null;
};

export const createBusiness = async (
  body: ExpandedBusinessDTO,
): Promise<CreateBusinessResponseDTO | null> => {
  const businessResponse = await TernFetchAPI.post<CreateBusinessResponseDTO[]>(
    TERN_USER_API_URL,
    `business`,
    body,
  );
  return businessResponse.data ? businessResponse.data[0] : null;
};

export const getBusinessAccountTransactions = async (
  businessAccountId: number,
  queryString = '',
): Promise<BusinessAccountTransactionDTO[]> => {
  const accountTransactionsResponse = await TernFetchAPI.get<
    BusinessAccountTransactionDTO[]
  >(
    TERN_USER_API_URL,
    `business/accounts/${businessAccountId}/transactions${queryString}`,
  );
  const data = accountTransactionsResponse.data || [];
  return data.map((transaction) => {
    if (transaction.transfer_type.includes('to_business')) {
      const adjustedTrx = {
        ...transaction,
        amount: Math.abs(transaction.amount),
      };
      return adjustedTrx;
    }
    if (transaction.transfer_type.includes('business_to')) {
      const adjustedTrx: BusinessAccountTransactionDTO = {
        ...transaction,
        amount: transaction.amount * -1,
      };
      return adjustedTrx;
    }
    return transaction;
  });
};

export const getBusinessAccountWires = async (
  businessAccountId: number,
  queryString = '',
): Promise<{ data: BusinessAccountWireDTO[]; total: number }> => {
  const accountTransactionsResponse = await TernFetchAPI.get<
    BusinessAccountWireDTO[]
  >(
    TERN_USER_API_URL,
    `business/accounts/${businessAccountId}/wires${queryString}`,
  );
  const data = accountTransactionsResponse.data || [];
  return {
    data: data.map((transaction) => {
      const adjustedTrx = {
        ...transaction,
        amount: Math.abs(transaction.amount),
      };
      return adjustedTrx;
    }),
    total: accountTransactionsResponse?.pagination?.total ?? 0,
  };
};

export const createBusinessWire = async (
  body: CreateBusinessWireRequestDTO,
): Promise<CreateBusinessWireRequestDTO | null> => {
  const businessWireResponse = await TernFetchAPI.post<
    CreateBusinessWireRequestDTO[]
  >(TERN_USER_API_URL, `business-wire`, body);
  return businessWireResponse.data ? businessWireResponse.data[0] : null;
};

export const updateBusinessWire = async (
  businessWireId: number,
  body: UpdateBusinessWireRequestDTO,
) => {
  const businessWireResponse = await TernFetchAPI.put<{ message: string }[]>(
    TERN_USER_API_URL,
    `business-wire/${businessWireId}`,
    body,
  );
  return businessWireResponse.data
    ? businessWireResponse.data[0].message
    : null;
};

export const deleteBusinessAndChildren = async (businessId: number) => {
  const deletionResponse = await TernFetchAPI.delete<{ message: string }[]>(
    TERN_USER_API_URL,
    `business/${businessId}`,
  );
  return deletionResponse.data ? deletionResponse.data[0].message : null;
};
