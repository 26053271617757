export const maskAccountNumber = (accountNumber?: string): string => {
  if (!accountNumber) return '';
  const last4 = accountNumber.slice(-4);
  return `****${last4}`;
};

export const getDollarAmount = (amount: number) => {
  return `${amount.toString().includes('-') ? '-$' : ' $'}${Math.abs(
    amount,
  ).toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
  })}`;
};

export const getBalance = (
  transaction: number,
  showPlusSign = false,
  isCents = true,
) => {
  if (transaction < 0) {
    const amt = isCents ? transaction / 100 : transaction;
    return `-$${Math.abs(amt).toFixed(2).toLocaleString()}`;
  }
  const amt = isCents ? transaction / 100 : transaction;
  return `${showPlusSign ? '+' : ''}$${amt.toFixed(2).toLocaleString()}`;
};

export const getCardType = (type?: string) => {
  if (!type) return '';
  switch (type) {
    case 'VIRTUAL':
      return 'Virtual Card';
    case 'PHYSICAL':
      return 'Physical Card';
    default:
      return '';
  }
};

export const getCardStatusFromId = (statusId?: number) => {
  if (!statusId) return 'Unknown';
  switch (statusId) {
    case 1:
      return 'Active';
    case 2:
      return 'Paused';
    case 3:
      return 'Open';
    case 4:
      return 'Closed';
    case 5:
      return 'Pending Fulfillment';
    case 6:
      return 'Pending Activation';
    case 8:
      return 'Inactive';
    default:
      return 'Unknown';
  }
};
