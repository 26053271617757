import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { Modal, Radio, RadioChangeEvent, Select } from 'antd';
import dayjs from 'dayjs';

import { BusinessDTO } from '../../../types/business/businessDTO';

/**
 * Modal for showing filters a user can apply
 * to the list of businesses.
 */
export function BusinessFiltersModal({
  businesses,
  originalBusinessList,
  setBusinessList,
  isFilterModalOpen,
  setIsFilterModalOpen,
}: {
  businesses: BusinessDTO[];
  originalBusinessList: BusinessDTO[];
  setBusinessList: (businesses: BusinessDTO[]) => void;
  isFilterModalOpen: boolean;
  setIsFilterModalOpen: Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
  /**
   * Initialize list of provinces and cities
   */
  const [provinces, setProvinces] = useState<{ alphaCode: string }[]>([]);
  const [cities, setCities] = useState<{ name: string }[]>([]);
  /**
   * Initialize filters for the list of businesses.
   */
  const [cityFilter, setCityFilter] = useState<string | null>(null);
  const [provinceFilter, setProvinceFilter] = useState<string | null>(null);
  const [kybStatusFilter, setKybStatusFilter] = useState<string | null>(null);
  const [activeCardNumberFilter, setActiveCardNumberFilter] = useState<{
    min?: number | string;
    max?: number | string;
  }>({
    min: '',
    max: '',
  });
  const [activeUserNumberFilter, setActiveUserNumberFilter] = useState<{
    min: number | string;
    max: number | string;
  }>({
    min: '',
    max: '',
  });
  /**
   * Initialize and prepare data for the date created filter.
   */
  const todaysDate = dayjs();
  const last7Days = todaysDate.subtract(7, 'day').format('YYYY-MM-DD');
  const last30Days = todaysDate.subtract(30, 'day').format('YYYY-MM-DD');
  const lastYear = todaysDate.subtract(1, 'year').format('YYYY-MM-DD');
  const anyTime = todaysDate.subtract(10, 'year').format('YYYY-MM-DD');
  const [createdAtFilter, setCreatedAtFilter] = useState<string>(lastYear);
  const createdAtSelectOptions = [
    {
      label: 'Today',
      value: todaysDate.format('YYYY-MM-DD'),
    },
    {
      label: 'Last 7 Days',
      value: last7Days,
    },
    {
      label: 'Last 30 Days',
      value: last30Days,
    },
    {
      label: 'Last 12 Months',
      value: lastYear,
    },
    {
      label: 'Any Time',
      value: anyTime,
    },
  ];

  /**
   * Prepare the list of provinces and cities
   * and set them in local state to be used
   * by the select dropdowns.
   */
  useEffect(() => {
    const businessProvinces = Array.from(
      new Set(businesses.map((business) => business.province)),
    )
      .filter((prov) => !!prov)
      .sort((a, b) => a.localeCompare(b))
      .map((prov) => ({ alphaCode: prov }));

    const businessCities = Array.from(
      new Set(businesses.map((business) => business.city)),
    )
      .filter((city) => !!city)
      .sort((a, b) => a.localeCompare(b))
      .map((city) => ({ name: city }));
    setProvinces(businessProvinces);
    setCities(businessCities);
  }, [businesses]);

  /**
   * Filters the list of businesses by provided filters.
   *
   * If the filter was *not* set, the current iterated business
   * automatically passes the filter test.
   *
   * If the filter is set, the filter value is compared to
   * the current iterated business value. If the values match,
   * the business passes the filter test. If they do not, the
   * business does not get returned in the filtered list.
   *
   */
  const applyFilters = () => {
    if (
      createdAtFilter === lastYear &&
      !kybStatusFilter &&
      !provinceFilter &&
      !cityFilter &&
      !activeUserNumberFilter.min &&
      !activeUserNumberFilter.max &&
      !activeCardNumberFilter.min &&
      !activeCardNumberFilter.max
    ) {
      setBusinessList(originalBusinessList);
      setIsFilterModalOpen(false);
      return;
    }
    const filteredList = businesses.filter((business) => {
      const businessKybStatus = business.kyb_status || 'N/A';
      const passesKybFilter = kybStatusFilter
        ? businessKybStatus.toLowerCase() === kybStatusFilter.toLowerCase()
        : true;
      const passesProvinceFilter = provinceFilter
        ? business.province?.toLowerCase() === provinceFilter.toLowerCase()
        : true;
      const passesCityFilter = cityFilter
        ? business.city?.toLowerCase() === cityFilter.toLowerCase()
        : true;
      const passesCreatedAtFilter = createdAtFilter
        ? business.created_at >= createdAtFilter
        : true;
      const passesActiveUserNumberFilter = activeUserNumberFilter
        ? business.user_count >=
            parseInt(activeUserNumberFilter.min?.toString() || '0') &&
          business.user_count <=
            parseInt(activeUserNumberFilter.max?.toString() || '100000')
        : true;
      const passesActiveCardNumberFilter = activeCardNumberFilter
        ? business.card_count >=
            parseInt(activeCardNumberFilter.min?.toString() || '0') &&
          business.card_count <=
            parseInt(activeCardNumberFilter.max?.toString() || '100000')
        : true;
      /**
       * all filters below must be true in order
       * to be returned in this array filter and
       * included in the filtered list
       */
      return (
        passesKybFilter &&
        passesProvinceFilter &&
        passesCityFilter &&
        passesCreatedAtFilter &&
        passesActiveUserNumberFilter &&
        passesActiveCardNumberFilter
      );
    });
    setBusinessList(filteredList);
    setIsFilterModalOpen(false);
  };

  /**
   * Sets all local state filter variables back
   * to their defaults/placeholders
   */
  const clearFilters = () => {
    setCreatedAtFilter(lastYear);
    setProvinceFilter(null);
    setCityFilter(null);
    setKybStatusFilter(null);
    setActiveCardNumberFilter({ min: '', max: '' });
    setActiveUserNumberFilter({ min: '', max: '' });
  };

  return (
    <Modal
      open={isFilterModalOpen}
      onCancel={() => setIsFilterModalOpen(false)}
      footer=""
    >
      <h2 className="text-xl">Select Business Filters</h2>
      <div className="py-2">
        <div className="text-xs font-semibold">Date Created</div>
        <Radio.Group
          value={createdAtFilter}
          onChange={(e: RadioChangeEvent) => {
            setCreatedAtFilter(e.target.value);
          }}
        >
          <div className="flex flex-col">
            {createdAtSelectOptions.map((option) => (
              <Radio value={option.value} key={option.value.toString()}>
                {option.label}
              </Radio>
            ))}
          </div>
        </Radio.Group>
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold">Province</div>
        <Select
          id="province"
          allowClear
          placeholder="Select a province"
          size="large"
          className="my-1 w-full"
          options={provinces.map((province) => ({
            value: province.alphaCode,
            label: province.alphaCode,
          }))}
          value={provinceFilter}
          onChange={(provinceName: string) => setProvinceFilter(provinceName)}
        />
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold">City</div>
        <Select
          id="city"
          allowClear
          placeholder="Select a city"
          size="large"
          className="my-1 w-full"
          options={cities.map((city) => ({
            value: city.name,
            label: city.name,
          }))}
          value={cityFilter}
          onChange={(cityName: string) => setCityFilter(cityName)}
        />
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold">KYB Status</div>
        <Select
          id="kyb_status"
          allowClear
          placeholder="Select a KYB status"
          size="large"
          className="my-1 w-full"
          options={[{ label: 'Approved', value: 'Approved' }]}
          value={kybStatusFilter}
          onChange={(kybStatus: string) => setKybStatusFilter(kybStatus)}
        />
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold">Active Users</div>
        <div className="flex items-center gap-6">
          <input
            className="my-1 w-full rounded-lg border border-zinc-200 p-2"
            placeholder="Enter a Minimum Count"
            step={100}
            type="number"
            value={activeUserNumberFilter.min}
            onChange={(e) =>
              setActiveUserNumberFilter((prevState) => ({
                max: prevState.max,
                min: parseInt(e.target.value || '0'),
              }))
            }
          />{' '}
          -
          <input
            className="my-1 w-full rounded-lg border border-zinc-200 p-2"
            placeholder="Enter a Maximum Count"
            step={100}
            type="number"
            value={activeUserNumberFilter.max}
            onChange={(e) =>
              setActiveUserNumberFilter((prevState) => ({
                min: prevState.min,
                max: parseInt(e.target.value || '0'),
              }))
            }
          />
        </div>
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold">Active Cards</div>
        <div className="flex items-center gap-6">
          <input
            className="my-1 w-full rounded-lg border border-zinc-200 p-2"
            placeholder="Enter a Minimum Count"
            step={100}
            type="number"
            value={activeCardNumberFilter.min}
            onChange={(e) =>
              setActiveCardNumberFilter((prevState) => ({
                max: prevState.max,
                min: parseInt(e.target.value || '0'),
              }))
            }
          />
          -
          <input
            className="my-1 w-full rounded-lg border border-zinc-200 p-2"
            placeholder="Enter a Maximum Count"
            step={100}
            type="number"
            value={activeCardNumberFilter.max}
            onChange={(e) =>
              setActiveCardNumberFilter((prevState) => ({
                min: prevState.min,
                max: parseInt(e.target.value || '0'),
              }))
            }
          />
        </div>
      </div>
      <div className="flex justify-between gap-2 py-2">
        <button
          type="button"
          className="w-full rounded-full border border-border-primary bg-white p-3 text-text-primary transition duration-300 ease-in-out hover:bg-surface-primary hover:text-white"
          onClick={clearFilters}
        >
          Clear Filters
        </button>
        <button
          type="submit"
          onClick={applyFilters}
          className="w-full rounded-full bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
        >
          Search
        </button>
      </div>
    </Modal>
  );
}
