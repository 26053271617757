import { Modal, Select, Skeleton } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import LoadingSpinner from '../../LoadingSpinner';
import { getUser } from '../../../api/UsersApi';
import { UserAccountDTO } from '../../../types/user/userAccountDTO';
import { maskAccountNumber } from '../../../utils/accountUtils';
import { UserModel } from '../../../types/user/userModel';
import { AssignCardFormValues } from '../../../types/cards/assignCardFormValues';

type AssignCardModalProps = {
  isModalOpen: boolean;
  onConfirm: (data: AssignCardFormValues) => Promise<void>;
  setIsModalOpen: (val: boolean) => void;
  selectedUserAccount: UserAccountDTO | null;
};

export function AssignCardModal({
  onConfirm,
  isModalOpen,
  selectedUserAccount,
  setIsModalOpen,
}: AssignCardModalProps): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [users, setUsers] = useState<UserModel[]>([]);
  const [userAccounts, setUserAccounts] = useState<UserAccountDTO[]>([]);

  const { userId: userIdQueryParam, accountId: userAccountIdQueryParam } =
    useParams();

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<AssignCardFormValues>({
    defaultValues: {
      user_id: parseInt(userIdQueryParam || '0'),
      user_account_id: parseInt(userAccountIdQueryParam || '0'),
      proxy_value: '',
    },
  });

  useEffect(() => {
    (async () => {
      if (userIdQueryParam && users.length === 0) {
        const id = parseInt(userIdQueryParam);
        const user = await getUser(id);
        setUsers(user ? [user] : []);
      }
      setUserAccounts(selectedUserAccount ? [selectedUserAccount] : []);
    })();
  }, [selectedUserAccount, userIdQueryParam, users]);

  const clearModal = () => {
    setError('');
    setValue('proxy_value', '');
    setValue('user_account_id', null);
  };

  const handleConfirm = async (data: AssignCardFormValues): Promise<void> => {
    setIsLoading(true);
    try {
      await onConfirm(data);
      clearModal();
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer="">
      <form
        className="grid auto-rows-max gap-y-5"
        onSubmit={handleSubmit((data) => handleConfirm(data))}
      >
        <div className="mb-4 flex flex-col">
          <h3 className="pb-2 text-xl font-semibold">Assign Card</h3>
          <span className="text-text-error-red">{error || ''}</span>
          {userAccounts && userAccounts.length > 0 ? (
            <>
              <div className="py-2">
                <div className="text-xs font-semibold">User</div>
                <Controller
                  name="user_id"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      id="user"
                      {...register('user_id')}
                      placeholder="Select a User"
                      size="large"
                      className="my-1 w-full"
                      options={users.map((user) => ({
                        label: `${user.first_name} ${user.last_name}`,
                        value: user.id,
                      }))}
                      onChange={onChange}
                      disabled
                      value={value}
                    />
                  )}
                />
              </div>
              <div className="py-2">
                <div className="text-xs font-semibold">
                  User Account
                  <span className="text-text-error-red">
                    *{' '}
                    <span className="font-normal">
                      {errors?.user_account_id?.message || ''}
                    </span>
                  </span>
                </div>

                <Controller
                  name="user_account_id"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      allowClear
                      {...register('user_account_id', {
                        required: 'User Account is Required',
                      })}
                      placeholder="Select an Account"
                      size="large"
                      className="my-1 w-full"
                      options={userAccounts.map((account) => ({
                        label: `${maskAccountNumber(
                          account.account_number,
                        )} -- $${account.available_balance / 100}`,
                        value: account.id,
                      }))}
                      disabled
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </>
          ) : (
            <Skeleton active />
          )}
          <div className="w-full py-2">
            <label htmlFor="proxy-value">
              <div className="text-xs font-semibold">
                Proxy Value
                <span className="text-text-error-red">
                  *{' '}
                  <span className="font-normal">
                    {errors?.proxy_value?.message || ''}
                  </span>
                </span>
              </div>
              <input
                id="proxy-value"
                className="my-1 w-full rounded-lg border border-zinc-200 p-2"
                placeholder="ABCD-EFGH-IJKL"
                type="text"
                {...register('proxy_value', {
                  required: 'Proxy Value is Required',
                  pattern: {
                    value:
                      /^(?=.{12}$|.{14}$)(?:[A-Za-z\d]*[A-Za-z][A-Za-z\d]*|.{4}-.{4}-.{4}|[A-Za-z\d]*[A-Za-z\d]{3}-[A-Za-z\d]{4}-[A-Za-z\d]{4})$/i,
                    message:
                      'Proxy value must be 12 characters without dashes or 14 with dashes',
                  },
                })}
              />
            </label>
          </div>
        </div>
        <footer className="flex py-2">
          <button
            className="flex h-10 w-full items-center justify-center rounded-full border-border-primary bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark "
            type="submit"
          >
            {isLoading ? <LoadingSpinner /> : 'Assign Card'}
          </button>
        </footer>
      </form>
    </Modal>
  );
}
