import React from 'react';
import { CardModel } from '../../../types/cards/cardModel';
import { ReactComponent as ChevronRightSmall } from '../../../assets/chevron-right-small.svg';
import { ReactComponent as LockCard } from '../../../assets/lock-card.svg';
import { ReactComponent as XCircle } from '../../../assets/x-circle.svg';
import { ReactComponent as AssignCard } from '../../../assets/assign-card.svg';
import { ReactComponent as AddCard } from '../../../assets/add-order-card.svg';
import { ReactComponent as BullsEye } from '../../../assets/bullseye.svg';

type CardCTAsProps = {
  disabled: boolean;
  selectedCard: CardModel | null;
  setIsUnlockCardModalOpen: (val: boolean) => void;
  setIsLockCardModalOpen: (val: boolean) => void;
  setIsDeactivateCardModalOpen: (val: boolean) => void;
  setIsAssignCardModalOpen: (val: boolean) => void;
  setIsAddCardModalOpen: (val: boolean) => void;
  setIsReplaceCardModalOpen: (val: boolean) => void;
};
export function CardCTAs({
  disabled,
  selectedCard,
  setIsUnlockCardModalOpen,
  setIsLockCardModalOpen,
  setIsDeactivateCardModalOpen,
  setIsAssignCardModalOpen,
  setIsAddCardModalOpen,
  setIsReplaceCardModalOpen,
}: CardCTAsProps) {
  return (
    <div className="flex h-fit w-full flex-col gap-6 rounded-lg border border-border-light-gray bg-white p-4 lg:w-[480px]">
      <h2 className="text-lg font-semibold">Card Actions</h2>

      {/*  Add/Order Card */}
      <button
        type="button"
        disabled={disabled}
        className="flex items-center justify-between"
        onClick={() => setIsAddCardModalOpen(true)}
      >
        <div className="flex items-center justify-center">
          <AddCard />
          <p className="ml-4 font-semibold">Add Card</p>
        </div>
        <ChevronRightSmall />
      </button>

      {/* Replace Card */}
      <button
        type="button"
        disabled={disabled}
        className="flex items-center justify-between"
        onClick={() => setIsReplaceCardModalOpen(true)}
      >
        <div className="flex items-center justify-center">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-surface-icon-bg-light">
            <BullsEye />
          </div>{' '}
          <p className="ml-4 font-semibold">Replace Card</p>
        </div>
        <ChevronRightSmall />
      </button>

      {/* Lock/Unlock (Pause/Unpause) Show depending on status */}
      {selectedCard?.card_status === 'Paused' ? (
        // Unlock Card
        <button
          disabled={disabled}
          type="button"
          className="flex items-center justify-between"
          onClick={() => setIsUnlockCardModalOpen(true)}
        >
          <div className="flex items-center justify-center">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-surface-icon-1">
              <LockCard />
            </div>
            <p className="ml-4 font-semibold">Unlock Card</p>
          </div>
          <ChevronRightSmall />
        </button>
      ) : (
        //  Lock Card
        <button
          disabled={disabled}
          type="button"
          className="flex items-center justify-between"
          onClick={() => setIsLockCardModalOpen(true)}
        >
          <div className="flex items-center justify-center">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-surface-icon-1">
              <LockCard />
            </div>
            <p className="ml-4 font-semibold">Lock Card</p>
          </div>
          <ChevronRightSmall />
        </button>
      )}

      {/* Assign Card */}
      <button
        type="button"
        disabled={disabled}
        className="flex items-center justify-between"
        onClick={() => setIsAssignCardModalOpen(true)}
      >
        <div className="flex items-center justify-center">
          <AssignCard />
          <p className="ml-4 font-semibold">Assign Card</p>
        </div>
        <ChevronRightSmall />
      </button>

      {/* Dectivate Card */}
      {selectedCard?.card_status !== 'Inactive' && (
        <button
          type="button"
          disabled={disabled}
          className="flex items-center justify-between"
          onClick={() => setIsDeactivateCardModalOpen(true)}
        >
          <div className="flex items-center justify-center">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-surface-icon-1">
              <XCircle />
            </div>
            <p className="ml-4 font-semibold">Deactivate Card</p>
          </div>
          <ChevronRightSmall />
        </button>
      )}
    </div>
  );
}
