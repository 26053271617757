import React, { ReactElement, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuthApi } from '../context/authContext';

/**
 * Protects internal routes, and auto logs out after 15 min
 * for compliance reasons.
 */
function ProtectedRoute({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  const context = useAuthApi();
  const location = useLocation();
  const navigate = useNavigate();

  const [inactiveTimer, setInactiveTimer] = useState(0);

  useEffect(() => {
    /**
     * Event handlers for user actions
     */
    const events = ['click', 'load', 'keydown', 'mousemove', 'scroll'];

    /**
     * Reset timer on user action
     */
    const resetTimer = async () => {
      setInactiveTimer(0);
    };
    /**
     * Add event listeners for user actions
     */
    events.forEach((event) => window.addEventListener(event, resetTimer));
    /**
     * Check every minute if 15 minutes have passed
     */
    const intervalId = setInterval(() => {
      setInactiveTimer((timer) => timer + 1);
    }, 60000);
    /**
     * Clean up setInterval and event listeners
     */
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      clearInterval(intervalId);
    };
  }, []);

  /**
   * Log out user after 15 min
   */
  useEffect(() => {
    if (inactiveTimer >= 15) {
      sessionStorage.clear();
      navigate('/');
    }
  }, [inactiveTimer, navigate]);

  if (context && !context.isAuthenticated()) {
    /**
     * if user is not authenticated, pass route for redirect post-login
     */
    return <Navigate to="/" state={{ previousRoute: location.pathname }} />;
  }

  return children;
}

export default ProtectedRoute;
