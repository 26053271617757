import React from 'react';
import { ReactComponent as ChevronLeftSmall } from '../../assets/chevron-left-small.svg';

type LeftArrowProps = {
  slideCount: number;
  currentSlide: number;
};

export function LeftArrow({
  slideCount,
  currentSlide,
  ...restArrowProps
}: LeftArrowProps) {
  return (
    <ChevronLeftSmall
      {...restArrowProps}
      className="absolute left-0 top-0 z-10 mt-[130px] block cursor-pointer"
    />
  );
}
