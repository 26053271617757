import dayjs from 'dayjs';
import { CountryDTO } from '../types/business/countryDTO';
import { ProvinceDTO } from '../types/business/provinceDTO';
import { CreateUserRequestDTO } from '../types/user/createUserRequestDTO';
import { UpdateUserRequestDTO } from '../types/user/updateUserRequestDTO';
import { UserDTO } from '../types/user/userDTO';
import { Roles } from './authUtils';

export const makeUserFullNameForFileName = (
  user: UserDTO | null | undefined,
) => {
  return `${user?.first_name}${user?.last_name ? `-${user.last_name}` : ''}`;
};

export const dateNotInFuture = (date: string | null): boolean => {
  const today = dayjs().format('YYYY-MM-DD');
  const selectedDate = dayjs(date).format('YYYY-MM-DD');
  return selectedDate <= today;
};

export const getFriendlyRoleName = (roleName?: string): string => {
  if (!roleName) return 'Unknown Role';
  switch (roleName) {
    case 'Platform Administrator':
      return 'Platform Admin';
    case 'Regular Users':
      return 'Cardholder';
    case 'Program Administrator':
      return 'Program Admin';
    case 'API Account':
      return 'API User';
    case 'Read Only':
      return 'Read Only User';
    default:
      return roleName;
  }
};

/**
 * Create user initials for the user icon.
 * Handles `First Last`, `First`, `Last`,
 * `First Last1 Last2`, etc cases.
 * @param fullName First + Last name of user
 * @returns User's initials for their avatar icon
 */
export const createUserInitials = (fullName: string): string => {
  const splitName = fullName.split(' ');
  if (splitName.length === 2) {
    return `${splitName[0].slice(0, 1)}${splitName[1].slice(0, 1)}`;
  }

  if (splitName.length === 1) {
    return `${splitName[0].slice(0, 1)}`;
  }

  if (splitName.length > 2) {
    return `${splitName[0].slice(0, 1)}${splitName[splitName.length - 1].slice(
      0,
      1,
    )}`;
  }
  return '';
};

export const prepareUpdateRequestBody = ({
  data,
  selectedBusinesses,
  residentialCountries,
  residentialProvinces,
  shipCountries,
  shipProvinces,
}: {
  data: UpdateUserRequestDTO;
  selectedBusinesses: number[];
  residentialCountries: CountryDTO[];
  residentialProvinces: ProvinceDTO[];
  shipCountries: CountryDTO[];
  shipProvinces: ProvinceDTO[];
}) => {
  const residentialCountryAlphaCode =
    residentialCountries.find(
      (country) =>
        country.name === data.country ||
        parseInt(data.country ?? '0') === country.id,
    )?.alpha_code || data.country;

  // data.province can either be the id, or
  // alpha code of the province
  const selectedResidentialProvince = residentialProvinces.find(
    (province) =>
      province.alpha_code === data.province ||
      parseInt(data.province ?? '0') === province.id,
  );

  const selectedResidentialProvinceValue =
    selectedResidentialProvince?.alpha_code ||
    selectedResidentialProvince?.name;

  // data.country can either be the id, name, or
  // alpha code of the country
  const shipCountryAlphaCode =
    shipCountries.find(
      (country) =>
        country.name === data.ship_country ||
        parseInt(data.ship_country ?? '0') === country.id,
    )?.alpha_code || data.ship_country;

  // data.province can either be the id, or
  // alpha code of the province
  const selectedShipProvince = shipProvinces.find(
    (province) =>
      province.alpha_code === data.ship_province ||
      parseInt(data.ship_province ?? '0') === province.id,
  );

  const selectedShipProvinceValue =
    selectedShipProvince?.alpha_code || selectedShipProvince?.name;
  const updateRequestBody: UpdateUserRequestDTO = {
    first_name: data.first_name,
    middle_name: data.middle_name || null,
    last_name: data.last_name,
    email: data.email,
    mobile_phone: data.mobile_phone,
    dob: dayjs(data.dob).format('YYYY-MM-DD'),
    address_1: data.address_1,
    address_2: data.address_2 || null,
    city: data.city,
    country: residentialCountryAlphaCode || null,
    province: selectedResidentialProvinceValue || null,
    postal_code: data.postal_code,
    ship_address_1: data.ship_address_1 || null,
    ship_address_2: data.ship_address_2 || null,
    ship_city: data.ship_city || null,
    ship_country: shipCountryAlphaCode || null,
    ship_province: selectedShipProvinceValue || null,
    ship_postal_code: data.ship_postal_code || null,
    business_id: selectedBusinesses?.join(',') || null,
    external_id: data.external_id || null,
  };
  return updateRequestBody;
};

export const prepareCreateRequestBody = ({
  data,
  selectedBusinesses,
  residentialCountries,
  residentialProvinces,
  shipCountries,
  shipProvinces,
}: {
  data: CreateUserRequestDTO;
  selectedBusinesses: number[];
  residentialCountries: CountryDTO[];
  residentialProvinces: ProvinceDTO[];
  shipCountries: CountryDTO[];
  shipProvinces: ProvinceDTO[];
}): CreateUserRequestDTO => {
  const residentialCountryAlphaCode =
    residentialCountries.find(
      (country) =>
        country.name === data.country ||
        parseInt(data.country ?? '0') === country.id,
    )?.alpha_code || data.country;

  // data.province can either be the id, or
  // alpha code of the province
  const selectedResidentialProvince = residentialProvinces.find(
    (province) =>
      province.alpha_code === data.province ||
      parseInt(data.province ?? '0') === province.id,
  );

  const selectedResidentialProvinceValue =
    selectedResidentialProvince?.alpha_code ||
    selectedResidentialProvince?.name;

  // data.country can either be the id, name, or
  // alpha code of the country
  const shipCountryAlphaCode =
    shipCountries.find(
      (country) =>
        country.name === data.ship_country ||
        parseInt(data.ship_country ?? '0') === country.id,
    )?.alpha_code || data.ship_country;

  // data.province can either be the id, or
  // alpha code of the province
  const selectedShipProvince = shipProvinces.find(
    (province) =>
      province.alpha_code === data.ship_province ||
      parseInt(data.ship_province ?? '0') === province.id,
  );

  const selectedShipProvinceValue =
    selectedShipProvince?.alpha_code || selectedShipProvince?.name;

  const createRequestBody: CreateUserRequestDTO = {
    first_name: data.first_name,
    middle_name: data.middle_name || null,
    last_name: data.last_name,
    email: data.email,
    mobile_phone: data.mobile_phone,
    dob: dayjs(data.dob).format('YYYY-MM-DD'),
    address_1: data.address_1,
    address_2: data.address_2 || '',
    city: data.city,
    country: residentialCountryAlphaCode || null,
    province: selectedResidentialProvinceValue || null,
    postal_code: data.postal_code,
    ship_address_1: data.ship_address_1 || null,
    ship_address_2: data.ship_address_2 || null,
    ship_city: data.ship_city || null,
    ship_country: shipCountryAlphaCode || null,
    ship_province: selectedShipProvinceValue || null,
    ship_postal_code: data.ship_postal_code || null,
    business_id: selectedBusinesses?.join(',') || null,
    external_id: data.external_id || '',
    user_type_id: 1,
    active: 1,
    role_id: Roles.RegularUser, // only allow creating cardholders with this endpoint
  };
  return createRequestBody;
};
