import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import dayjs from 'dayjs';
import { Select, Skeleton } from 'antd';

import { BusinessDTO } from '../../../types/business/businessDTO';
import Table from '../../../components/Table';
import { ListPageActionItems } from '../../../components/ListPageActionItems';
import { BusinessFiltersModal } from '../../../components/Modals/BusinessFiltersModal';
import { DeleteConfirmationModal } from '../../../components/Modals/DeleteConfirmationModal';
import { BulkAddFundsModal } from '../../../components/Modals/BulkAddFundsModal';
import { useAppStore } from '../../../zustand/store';
import {} from '../../../utils/businessUtils';
import { tableStatusCell } from '../../../components/TableStatusCell';
import { deleteBusinessAndChildren } from '../../../api/UsersApi';
import { SuccessNotification } from '../../../components/Notifications/SuccessNotification';
import { ErrorNotification } from '../../../components/Notifications/ErrorNotification';
import { isAdminOrMTU, isMTU } from '../../../utils/authUtils';

export function BusinessList(): ReactElement {
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [businessList, setBusinessList] = useState<BusinessDTO[]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isBulkAddFundsModalOpen, setIsBulkAddFundsModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState<number | null>(
    null,
  );

  const [originalBusinessList, setOriginalBusinessList] = useState<
    BusinessDTO[] | null
  >(null);

  const [totalItems, setTotalItems] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { businesses } = useOutletContext<{
    businesses: BusinessDTO[];
  }>();

  const hasMTUAccess = isMTU();
  const hasAdminAccess = isAdminOrMTU();

  const setSelectedBusiness = useAppStore((state) => state.setSelectedBusiness);
  const setNotification = useAppStore((state) => state.setNotification);
  const notification = useAppStore((state) => state.notification);
  const navigate = useNavigate();
  const [queryStringParams, setQueryStringParams] = useSearchParams();
  const businessStatus = queryStringParams.get('status');

  useEffect(() => {
    setIsLoading(true);
    setBusinessList(businesses);
    if (originalBusinessList === null) {
      setOriginalBusinessList(businesses);
    }
    setSelectedBusiness(null);
    setTotalItems(businesses.length);
    setIsLoading(false);
    return () => {
      if (businessStatus) setNotification(null);
    };
  }, [
    businessStatus,
    businesses,
    setNotification,
    setSelectedBusiness,
    originalBusinessList,
  ]);

  const deleteBusiness = async () => {
    // clear query string params so the notifications show properly
    setQueryStringParams({});
    try {
      if (selectedBusinessId) {
        const resultMessage = await deleteBusinessAndChildren(
          selectedBusinessId,
        );
        setIsDeleteConfirmationModalOpen(false);
        setNotification({
          message: resultMessage || 'Business Successfully Deleted',
          type: 'success',
        });
        setQueryStringParams({ status: 'success' });
      }
    } catch (err) {
      setIsDeleteConfirmationModalOpen(false);
      setNotification({
        type: 'error',
        message: `There was an error deleting the business: ${err.message}. Please try again.`,
      });
      setQueryStringParams({ status: 'failure' });
    }
  };

  const handleBusinessAction = (
    value: string,
    info: CellContext<BusinessDTO, unknown>,
  ) => {
    setSelectedBusinessId(info.row.original.id);
    switch (value) {
      case 'delete':
        setIsDeleteConfirmationModalOpen(true);
        break;
      case 'view_edit':
        navigate(`/dashboard/businesses/${info.row.original.id}/info`);
        break;
      default:
        break;
    }
  };

  const rowActions = [
    {
      value: 'view_edit',
      label: hasAdminAccess ? 'View / Edit' : 'View',
      disabled: false,
    },
  ];

  if (hasMTUAccess)
    rowActions.push(...[{ value: 'delete', label: 'Delete', disabled: false }]);

  const selectDropdownCell = (
    info: CellContext<BusinessDTO, unknown>,
  ): ReactElement => {
    return (
      <Select
        defaultValue="Select"
        style={{ width: 150 }}
        onClick={(e) => e.stopPropagation()}
        onChange={(val) => handleBusinessAction(val, info)}
        options={rowActions}
      />
    );
  };

  const makeBusinessNameCell = (info: CellContext<BusinessDTO, unknown>) => {
    return (
      <button
        type="button"
        className="text-left text-text-primary hover:underline"
        onClick={() =>
          navigate(`/dashboard/businesses/${info.row.original.id}/info`)
        }
      >
        {info.row.original.legal_business_name}
      </button>
    );
  };

  const columnHelper = createColumnHelper<BusinessDTO & { select?: string }>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('legal_business_name', {
        cell: (info) => makeBusinessNameCell(info),
        header: 'LEGAL BUSINESS NAME',
      }),
      columnHelper.accessor('city', {
        header: 'CITY',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('province', {
        header: 'PROVINCE',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('user_count', {
        header: 'ACTIVE USERS',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('card_count', {
        header: 'ACTIVE CARDS',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('created_at', {
        header: 'DATE CREATED',
        cell: (info) => dayjs(info.getValue()).format('MMM DD, YYYY'),
      }),
      columnHelper.accessor('kyb_status', {
        header: 'KYB STATUS',
        cell: (info) => tableStatusCell<BusinessDTO>(info),
      }),
      columnHelper.accessor('select', {
        header: '',
        cell: (info) => selectDropdownCell(info),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderNotification = (
    newNotification: {
      type: string;
      message: string;
    } | null,
  ) => {
    if (!newNotification) return null;
    if (newNotification.type === 'success')
      return <SuccessNotification message={newNotification.message} />;
    if (newNotification.type === 'error')
      return <ErrorNotification message={newNotification.message} />;
    return null;
  };

  return (
    <>
      {renderNotification(notification)}
      <ListPageActionItems
        setIsModalOpen={(modal) => {
          switch (modal.type) {
            case 'bulk-funds':
              setIsBulkAddFundsModalOpen(modal.val);
              break;
            case 'filter':
            default:
              setIsFilterModalOpen(modal.val);
              break;
          }
        }}
        searchText={globalFilter}
        setGlobalFilter={setGlobalFilter}
        entity="Business"
      />
      <BusinessFiltersModal
        businesses={businessList}
        originalBusinessList={originalBusinessList || []}
        setBusinessList={setBusinessList}
        isFilterModalOpen={isFilterModalOpen}
        setIsFilterModalOpen={setIsFilterModalOpen}
      />
      <DeleteConfirmationModal
        nameField={
          businessList.find((business) => business.id === selectedBusinessId)
            ?.legal_business_name || ''
        }
        nameLabel="Legal Business Name"
        isModalOpen={isDeleteConfirmationModalOpen}
        setIsModalOpen={setIsDeleteConfirmationModalOpen}
        onCancel={() => setIsDeleteConfirmationModalOpen(false)}
        onConfirm={deleteBusiness}
      />
      <BulkAddFundsModal
        isFilterModalOpen={isBulkAddFundsModalOpen}
        setIsFilterModalOpen={setIsBulkAddFundsModalOpen}
      />
      {businessList && !isLoading && (
        <Table<BusinessDTO>
          data={businessList}
          columns={columns}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          totalItems={totalItems}
        />
      )}
      {businessList.length <= 0 && isLoading && <Skeleton active />}
    </>
  );
}
