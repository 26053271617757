import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import DashboardHeader from '../../components/DashboardHeader';
import Button from '../../components/Button';

export function PayrollDeposits() {
  const navigate = useNavigate();

  const renderUserHeaderSubtext = () => {
    return (
      <div className="flex items-baseline space-x-2">
        <Button
          onClick={() => {
            navigate('/dashboard/deposits');
          }}
          id="deposits"
          className="text-xl font-semibold md:text-2xl xl:text-3xl"
        >
          Deposits
        </Button>
      </div>
    );
  };

  return (
    <>
      <DashboardHeader subtext={renderUserHeaderSubtext()} />
      <Outlet />
    </>
  );
}
