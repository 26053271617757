import React, { ReactElement, memo } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
  getPaginationRowModel,
  PaginationState,
} from '@tanstack/react-table';
import { Pagination } from 'antd';
import TableHeader from './TableHeader';

function exactMatchFilter<T>(
  row: Row<T>,
  columnId: string,
  searchTerm: string,
): boolean {
  const rowValue = (row.original as Record<string, unknown>)[columnId];
  let searchTermLower = searchTerm
    .toLowerCase()
    .replaceAll('-', '')
    .replaceAll('+', '');

  if (searchTerm === '"') {
    // ignore first ' " ' handle it as no input
    return true;
  }
  if (searchTerm.startsWith('"') && searchTerm.endsWith('"')) {
    const exactSearchTerm = searchTerm
      .slice(1, -1)
      .replaceAll('-', '')
      .replaceAll('+', '');
    return (
      String(rowValue) === exactSearchTerm ||
      String(rowValue).includes(exactSearchTerm)
    );
  }
  if (searchTerm.startsWith('"')) {
    // ignore until ending ' " ' has been entered
    searchTermLower = searchTerm.slice(1);
  }
  return rowValue !== undefined
    ? String(rowValue).toLowerCase().includes(searchTermLower) ||
        String(rowValue).toLowerCase() === searchTermLower
    : false;
}

function Table<T>({
  data,
  columns,
  globalFilter,
  setGlobalFilter,
  handleRowClick,
  pageSize = 10,
  pageIndex = 0,
  totalItems,
  setPageIndex,
  isLoading = false,
  pageCount,
  showPagination = false,
  setPageSize,
}: {
  data: T[];
  columns: ColumnDef<T>[];
  pageSize?: number;
  pageIndex?: number;
  pageCount?: number;
  totalItems?: number;
  isLoading?: boolean;
  showPagination?: boolean;
  setPageSize?: (size: number) => void;
  setPageIndex?: (index: number) => void;
  globalFilter?: string;
  setGlobalFilter?: (val: string) => void;
  handleRowClick?: (row: Row<T>) => void;
}): ReactElement {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex,
    pageSize,
  });
  const paginationInfo = React.useMemo(
    () => ({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
    }),
    [pagination.pageIndex, pagination.pageSize],
  );
  const table = useReactTable<T>({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
      pagination: paginationInfo,
    },
    pageCount: pageCount || Math.ceil(data.length / pageSize),
    initialState: {
      columnVisibility: {
        is_active: false,
        // transaction_type: false,
        // transaction_status: false,
      },
    },
    globalFilterFn: exactMatchFilter<T>,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,

    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: !!showPagination,
    debugTable: true,
  });

  const filteredRowsLength = Object.keys(table.getRowModel().rowsById).length;

  return (
    <div className="overflow-x-auto overflow-y-hidden rounded-lg pb-4 md:overflow-x-hidden md:py-4">
      {data.length > 0 && !isLoading ? (
        <table className="w-full overflow-auto rounded-lg border border-solid border-border-light-gray bg-white font-medium">
          <thead className="bg-neutral-gray-50">
            <TableHeader table={table} />
          </thead>
          <tbody className="bg-white">
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  onClick={() => handleRowClick && handleRowClick(row)}
                  className="rounded-lg"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="break-words p-2 text-sm text-neutral-gray-900"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="flex w-full items-center justify-center text-sm font-semibold">
          No Data Found
        </div>
      )}
      {showPagination ? (
        <div className="my-auto ml-0 mt-5 flex w-[650px] items-center justify-start text-text-primary md:justify-center xl:ml-[25%]">
          <div className="flex rounded-md border border-border-light-gray">
            <Pagination
              current={paginationInfo.pageIndex + 1}
              showSizeChanger
              onChange={(page: number) => {
                if (setPageIndex) {
                  setPageIndex(page - 1);
                }
                setPagination({
                  ...paginationInfo,
                  pageIndex: page - 1,
                });
              }}
              onShowSizeChange={(_current, size) => {
                if (setPageSize) {
                  setPageSize(size);
                }
                setPagination({
                  ...paginationInfo,
                  pageSize: size,
                });
              }}
              pageSize={table.getState().pagination.pageSize}
              total={totalItems}
              responsive={false}
            />
            <div className="flex items-center justify-center bg-white px-5">
              {totalItems} Items
            </div>
          </div>
        </div>
      ) : (
        // Show client-side pagination control
        <div
          className={`my-auto ${
            filteredRowsLength < table.getState().pagination.pageSize
              ? 'hidden'
              : ''
          } ml-0 mt-5 flex w-auto items-center justify-start text-text-primary md:justify-center`}
        >
          <div className="flex rounded-md border border-border-light-gray">
            <Pagination
              current={table.getState().pagination.pageIndex + 1}
              onChange={(page) => {
                table.setPageIndex(page - 1);
              }}
              onShowSizeChange={(_current, size) => {
                table.setPageSize(size);
              }}
              total={filteredRowsLength}
              pageSize={table.getState().pagination.pageSize}
              hideOnSinglePage
              responsive={false}
            />
            {filteredRowsLength > 10 ? (
              <div className="flex items-center justify-center bg-white px-5 ">
                {filteredRowsLength} Items
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(Table) as typeof Table;
