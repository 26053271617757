/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';

// import { ReactComponent as Bird } from '../../assets/tern_logo.svg';

function Circle() {
  const [pos, setPos] = useState({
    x: Math.random() * window.innerWidth,
    y: Math.random() * window.innerHeight,
  });

  useEffect(() => {
    function handleMouseMove(event) {
      const { clientX, clientY } = event;
      const svg = document.getElementById('svg');
      const circle = document.getElementById('circle');

      const svgRect = svg.getBoundingClientRect();
      const circleRect = circle.getBoundingClientRect();

      const mouseX = clientX - svgRect.left;
      const mouseY = clientY - svgRect.top;

      const distanceX = pos.x - mouseX;
      const distanceY = pos.y - mouseY;

      const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

      if (distance < 10 + circleRect.width / 2) {
        const newPosX = Math.min(
          Math.max(circleRect.width / 2, mouseX),
          svgRect.width - circleRect.width / 2,
        );
        const newPosY = Math.min(
          Math.max(circleRect.height / 2, mouseY),
          svgRect.height - circleRect.height / 2,
        );
        setPos({ x: newPosX, y: newPosY });
      }
    }

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [pos]);

  return (
    <svg id="svg" width="100vw" height="100vh">
      {/* <Bird id="circle"  r="10" fill="purple" /> */}
      <foreignObject width="100%" height="100%">
        <div className="h-screen w-full text-center">
          <h1 className="my-10 text-6xl">UH OH</h1>
          <p className="mb-6 text-xl">This is awkward...</p>
          <p className="text-base">
            Our birds dont recognize this url. Could you have misspelled the
            URL?
          </p>
        </div>
      </foreignObject>

      <g
        transform={`translate(${pos.x}, ${pos.y})`}
        width="62"
        height="40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="circle"
      >
        <path
          d="M9.16149 29.4212L23.6335 11.1472H0C0 18.5843 3.58696 25.1866 9.16149 29.4212Z"
          fill="#10172A"
        />
        <path
          d="M40.559 11.1472C40.559 8.36971 37.9348 6.12341 35.0932 6.12341C32.2516 6.12341 30.2485 8.36971 30.2485 11.1472H23.6335C23.6335 17.4156 28.7578 22.5002 35.0621 22.5002C41.3665 22.5002 46.4907 17.4156 46.4907 11.1472H40.559ZM35.4037 13.4998C34.0839 13.4998 32.9969 12.4373 32.9969 11.1472C32.9969 9.85713 33.9286 8.80987 35.2485 8.80987C36.5683 8.80987 37.795 9.85713 37.795 11.1472C37.8106 12.4525 36.7391 13.4998 35.4037 13.4998Z"
          fill="white"
        />
        <path
          d="M35.0932 0.00674565C35.0777 -0.00843206 16.4131 0.00674565 16.4131 0.00674565C16.4131 4.72701 19.3634 9.53835 23.6336 11.1472H30.2485C30.2485 8.36966 32.2516 6.12336 35.0932 6.12336C37.9348 6.12336 40.559 8.36966 40.559 11.1472H46.5063C46.5063 4.98503 41.3976 0.00674565 35.0932 0.00674565Z"
          fill="#6780E0"
        />
        <path
          d="M38.975 21.8323C38.8353 21.8778 38.7111 21.9233 38.5713 21.9689C38.5402 21.9841 38.4937 21.9992 38.4626 22.0144C38.2918 22.0599 38.1365 22.1055 37.9657 22.151C37.9502 22.151 37.9502 22.151 37.9347 22.1662C37.2204 22.3483 36.475 22.4697 35.7142 22.5001C35.6986 22.5001 35.6831 22.5001 35.6676 22.5001C35.4657 22.5153 35.2794 22.5153 35.0775 22.5153C28.7576 22.5153 23.6489 17.4307 23.6489 11.1624L0.760742 40L39.4409 21.6501C39.3632 21.6805 39.2701 21.7109 39.1924 21.7412C39.1148 21.7716 39.0527 21.8019 38.975 21.8323Z"
          fill="#6780E0"
        />
        <path
          d="M46.5061 11.1472C46.5061 11.3293 46.5061 11.4963 46.4906 11.6633C46.4906 11.7088 46.4906 11.7543 46.4906 11.7847C46.475 11.9516 46.475 12.1186 46.4595 12.2855V12.3007C46.444 12.4829 46.4129 12.665 46.3974 12.8471C46.3974 12.8623 46.3974 12.8775 46.3819 12.9078C45.8539 16.2773 43.8508 19.1459 41.0402 20.8458C41.0247 20.8609 41.0092 20.8609 40.9937 20.8761C40.8539 20.952 40.7142 21.0431 40.5899 21.119H40.5744C40.2017 21.3163 39.8291 21.4984 39.4409 21.6502L0.760742 40H23.6334C36.0402 40 46.1334 30.347 46.4906 18.2959L61.5527 11.1472H46.5061Z"
          fill="#10172A"
        />
        <path
          d="M37.8105 11.1472C37.8105 12.4373 36.7391 13.4997 35.4037 13.4997C34.0683 13.4997 32.9968 12.4373 32.9968 11.1472C32.9968 9.85708 33.9285 8.80981 35.2484 8.80981C36.5683 8.80981 37.8105 9.85708 37.8105 11.1472Z"
          fill="#10172A"
        />
      </g>
    </svg>
  );
}

export default Circle;
