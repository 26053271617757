import React from 'react';
import { ReactComponent as ChevronRightSmall } from '../../assets/chevron-right-small.svg';

type RightArrowProps = {
  slideCount: number;
  currentSlide: number;
};

export function RightArrow({
  slideCount,
  currentSlide,
  ...restArrowProps
}: RightArrowProps) {
  return (
    <ChevronRightSmall
      {...restArrowProps}
      className="absolute right-0 top-0 z-10 mt-[130px] block cursor-pointer"
    />
  );
}
