import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Dashboard from './containers/Dashboard';
import Users from './containers/Users';
import NotFound from './containers/NotFound';
import { UserDetail } from './containers/Users/UserDetail';
import { UserTransactions } from './containers/Users/UserTransactions';
import { UsersList } from './containers/Users/UsersList';
import DashboardHome from './containers/DashboardHome';
import { UserDetailInfo } from './containers/Users/UserDetailInfo';
import ProtectedRoute from './routes/ProtectedRoute';
import SignIn from './containers/SignIn';
import ForgotPassword from './containers/SignIn/forgot-password';
import ResetPassword from './containers/SignIn/reset-password';
import { Businesses, businessesLoader } from './containers/Businesses';
import { BusinessList } from './containers/Businesses/BusinessList';
import {
  businessDetailLoader,
  BusinessDetail,
} from './containers/Businesses/BusinessDetail';
import { BusinessDetailInfo } from './containers/Businesses/BusinessDetailInfo';
import { BusinessTransactions } from './containers/Businesses/BusinessTransactions';
import { UserCards } from './containers/Users/UserCards';
import { CreateNewUser } from './containers/Users/CreateNewUser';
import { PayrollDeposits } from './containers/PayrollDeposits';
import { DepositsList } from './containers/PayrollDeposits/DepositsList';
import { UserDeposits } from './containers/Users/UserDeposits';
import { CreateNewRadUser } from './containers/Users/CreateNewRadUser';
import { RadUserDetailInfo } from './containers/Users/RadUserDetailInfo';
import { RadUserDetail } from './containers/Users/RadUserDetail';
import { BusinessWires } from './containers/Businesses/BusinessWires';

const router = createBrowserRouter([
  {
    path: '/',
    element: <SignIn />,
    errorElement: <NotFound />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    errorElement: <NotFound />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    errorElement: <NotFound />,
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/dashboard',
        index: true,
        element: (
          <ProtectedRoute>
            <DashboardHome />
          </ProtectedRoute>
        ),
      },
      {
        element: (
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        ),
        id: 'users',
        children: [
          {
            path: '/dashboard/users',
            index: true,
            element: <UsersList />,
            errorElement: <NotFound />,
          },
          {
            path: '/dashboard/users/new',
            id: 'newUser',
            element: <CreateNewUser />,
            errorElement: <NotFound />,
          },
          {
            path: '/dashboard/users/rad/new',
            element: <CreateNewRadUser />,
            errorElement: <NotFound />,
          },
          {
            /**
             * This route is for users that have the user type id
             * of 3 (RAD user) since they need minimal details.
             * This includes biz/program admins, MTUs, platform agents,
             * and API accounts.
             */
            path: '/dashboard/users/:userId/rad',
            element: <RadUserDetail />,
            errorElement: <NotFound />,
            children: [
              {
                path: '/dashboard/users/:userId/rad/info',
                index: true,
                element: <RadUserDetailInfo />,
              },
            ],
          },
          {
            /**
             * This route is for users that have the user type of
             * regular user (cardholder)
             */
            path: '/dashboard/users/:userId',
            element: <UserDetail />,
            id: 'userDetail',
            errorElement: <NotFound />,
            children: [
              {
                path: '/dashboard/users/:userId/info',
                index: true,
                element: <UserDetailInfo />,
              },
              {
                path: '/dashboard/users/:userId/accounts/:accountId',
                element: <UserTransactions />,
              },
              {
                path: '/dashboard/users/:userId/accounts/:accountId/deposits',
                element: <UserDeposits />,
              },
              {
                path: '/dashboard/users/:userId/accounts/:accountId/cards',
                element: <UserCards />,
              },
            ],
          },
        ],
      },
      {
        element: (
          <ProtectedRoute>
            <Businesses />
          </ProtectedRoute>
        ),
        loader: businessesLoader,
        id: 'businesses',
        children: [
          {
            path: '/dashboard/businesses',
            index: true,
            element: <BusinessList />,
          },
          {
            path: '/dashboard/businesses/new',
            id: 'newBusiness',
            element: <BusinessDetailInfo />,
            errorElement: <NotFound />,
          },
          {
            path: '/dashboard/businesses/:businessId',
            loader: businessDetailLoader,
            id: 'businessDetail',
            element: <BusinessDetail />,
            errorElement: <NotFound />,
            children: [
              {
                path: '/dashboard/businesses/:businessId/info',
                element: <BusinessDetailInfo />,
              },
              {
                path: '/dashboard/businesses/:businessId/accounts/:accountId',
                element: <BusinessTransactions />,
              },
              {
                path: '/dashboard/businesses/:businessId/wire-history/:accountId',
                element: <BusinessWires />,
              },
            ],
          },
        ],
      },
      {
        path: '/dashboard/*',
        element: <NotFound />,
      },
      {
        element: (
          <ProtectedRoute>
            <PayrollDeposits />
          </ProtectedRoute>
        ),
        errorElement: <NotFound />,
        children: [
          {
            path: '/dashboard/deposits',
            index: true,
            element: <DepositsList />,
            errorElement: <NotFound />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default router;
