import { Modal, Select, Skeleton } from 'antd';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { UserAccountDTO } from '../../../types/user/userAccountDTO';
import { maskAccountNumber } from '../../../utils/accountUtils';
import { UserDTO } from '../../../types/user/userDTO';
import { AddFundsFormValues } from '../../../types/user/addFundsFormValues';
import { BulkAddFundSummary } from '../../BulkAddFundSummary';
import { BulkUserDTO } from '../../../types/user/bulkUserDTO';
import { BusinessDTO } from '../../../types/business/businessDTO';
import { getBusinessAccounts } from '../../../api/UsersApi';
import { BusinessAccountDTO } from '../../../types/business/businessAccountDTO';
import { ReactComponent as ClipboardGray } from '../../../assets/clipboard-gray.svg';
import { ReactComponent as DollarSignGray } from '../../../assets/dollarsign-gray.svg';

type AddFundsModalProps = {
  isModalOpen: boolean;
  onConfirm: (data: AddFundsFormValues) => Promise<void>;
  setIsModalOpen: (val: boolean) => void;
  selectedUserAccount: UserAccountDTO | null;
  selectedUser: UserDTO | null;
  businesses: BusinessDTO[];
};

export function AddFundsModal({
  onConfirm,
  isModalOpen,
  businesses,
  selectedUserAccount,
  selectedUser,
  setIsModalOpen,
}: AddFundsModalProps): ReactElement {
  /**
   * Local component state
   */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [users, setUsers] = useState<BulkUserDTO[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [formData, setFormData] = useState<AddFundsFormValues | null>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0.0);
  const [businessAccounts, setBusinessAccounts] = useState<
    BusinessAccountDTO[]
  >([]);
  const [selectedBusinessAccount, setSelectedBusinessAccount] =
    useState<BusinessAccountDTO | null>(null);
  /**
   * Query string parameters
   */
  const { userId: userIdQueryParam, accountId: userAccountIdQueryParam } =
    useParams();
  /**
   * react-hook-form instantiation
   */
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm<AddFundsFormValues>({
    defaultValues: {
      business_id: null,
      business_account_id: null,
      user_id: parseInt(userIdQueryParam || '0'),
      user_account_id: parseInt(userAccountIdQueryParam || '0'),
      amount: '',
    },
  });

  const selectBusiness = useCallback(
    async (businessId: number): Promise<void> => {
      setIsLoadingAccounts(true);
      setBusinessAccounts([]);
      setSelectedBusinessAccount(null);
      const accts = await getBusinessAccounts(businessId.toString());
      if (accts.length === 1) {
        setBusinessAccounts(accts);
        setValue('business_account_id', accts[0].id);
        setSelectedBusinessAccount(accts[0]);
      } else {
        setBusinessAccounts(accts);
        setValue('business_account_id', null);
      }
      setIsLoadingAccounts(false);
    },
    [setValue],
  );

  useEffect(() => {
    if (businesses.length === 1) {
      setValue('business_id', businesses[0].id);
      selectBusiness(businesses[0].id);
    }
  }, [businesses, selectBusiness, setValue]);

  /**
   * Set users, accounts, and total amount when user or account changes
   */
  useEffect(() => {
    if (selectedUser) {
      /**
       * Format user for display in table
       */
      const usr: BulkUserDTO = {
        id: selectedUser.id,
        full_name: `${selectedUser.first_name} ${selectedUser?.last_name}`,
        full_address: selectedUser.full_address,
        email: selectedUser.email,
        amount: parseFloat(formData?.amount || '0.00'),
        masked_account_number: maskAccountNumber(
          selectedUserAccount?.account_number || '',
        ),
      };
      /**
       * Make the user an array since the bulk summary component
       * takes an array of users. This allows it to be reused for
       * bulk load as well.
       */
      const usrs = usr ? [usr] : [];
      setUsers(usrs);
      /**
       * Calculate total amount from all users
       */
      const total = usrs.reduce((acc, curr) => acc + curr.amount, 0);
      setTotalAmount(total);
    }
  }, [selectedUserAccount, selectedUser, formData?.amount, businesses]);

  const clearModal = (): void => {
    setError('');
    setSelectedBusinessAccount(null);
    setBusinessAccounts([]);
    reset();
  };

  const handleConfirm = async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (formData) {
        await onConfirm(formData);
      }
      clearModal();
      setCurrentStep(1);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const goToStep = (step: number): void => {
    setError('');
    setCurrentStep(step);
  };

  const openConfirmStep = (data: AddFundsFormValues): void => {
    setFormData(data);
    setCurrentStep(2);
  };

  const selectBusinessAccount = (businessAccountId: number): void => {
    const selectedAcct = businessAccounts.find(
      (acct) => acct.id === businessAccountId,
    );
    setSelectedBusinessAccount(selectedAcct || null);
  };

  return (
    <Modal
      afterClose={clearModal}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={currentStep === 1 ? 400 : 900}
      footer=""
      style={{ transition: 'width 0.3s ease-in' }}
    >
      {currentStep === 1 && (
        <form
          className="grid auto-rows-max gap-y-5"
          onSubmit={handleSubmit((data) => openConfirmStep(data))}
        >
          <div className="mb-4 flex flex-col">
            <h3 className="pb-2 text-xl font-semibold">Add Funds</h3>
            <span className="mb-1 text-text-gray">
              Account {maskAccountNumber(selectedUserAccount?.account_number)}
            </span>
            <span className="text-text-error-red">{error || ''}</span>
            <div className="flex">
              <span className="mt-[9px]">
                <ClipboardGray />
              </span>
              <h4 className="ml-1 flex pt-3 font-semibold">Send From</h4>
            </div>
            <div className="pt-2">
              <div className="text-xs font-semibold">
                Business{' '}
                <span className="text-text-error-red">
                  *{' '}
                  <span className="font-normal">
                    {errors.business_id?.message || ''}
                  </span>
                </span>
              </div>
              <Controller
                name="business_id"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    id="business-id"
                    {...register('business_id', {
                      required: 'Business is Required',
                    })}
                    allowClear
                    placeholder="Select a Business to Transfer Money From"
                    size="large"
                    className="my-1 w-full"
                    options={businesses.map((biz) => ({
                      label: `${biz.legal_business_name}`,
                      value: biz.id,
                    }))}
                    onChange={(businessId: number) => {
                      selectBusiness(businessId);
                      onChange(businessId);
                    }}
                    value={value}
                  />
                )}
              />
            </div>
            <div className="pb-2 pt-1">
              {!isLoadingAccounts ? (
                <>
                  <div className="text-xs font-semibold">
                    Business Account{' '}
                    <span className="text-text-error-red">
                      *{' '}
                      <span className="font-normal">
                        {errors.business_account_id?.message || ''}
                      </span>
                    </span>
                  </div>
                  <Controller
                    name="business_account_id"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        id="business-account-id"
                        {...register('business_account_id', {
                          required: 'Business Account is Required',
                        })}
                        allowClear
                        placeholder="Select an Account to Transfer Money From"
                        size="large"
                        className="my-1 w-full"
                        options={businessAccounts.map((acct) => ({
                          label: `${maskAccountNumber(
                            acct.account_number,
                          )} -- $${acct.available_balance / 100}`,
                          value: acct.id,
                        }))}
                        disabled={getValues('business_id') === null}
                        onChange={(businessAccountId: number) => {
                          selectBusinessAccount(businessAccountId);
                          onChange(businessAccountId);
                        }}
                        value={value}
                      />
                    )}
                  />
                </>
              ) : (
                <Skeleton.Input size="large" active block />
              )}
            </div>
            <div className="flex">
              <span className="mt-[9px]">
                <DollarSignGray />
              </span>
              <h4 className="ml-1 flex pt-2 font-semibold">Transfer Amount</h4>
            </div>{' '}
            <div className="w-full pt-2">
              <label htmlFor="amount">
                <div className="pb-1 text-xs font-semibold">
                  Amount{' '}
                  <span className="text-text-error-red">
                    *{' '}
                    <span className="font-normal">
                      {errors.amount?.message || ''}
                    </span>
                  </span>
                </div>
                <div className="flex items-center justify-between rounded-lg border border-zinc-200 px-2">
                  <span className="text-text-light-gray">$</span>
                  <input
                    id="amount"
                    className="my-1 w-full border-none focus:outline-none focus:ring-0"
                    placeholder="10000"
                    type="number"
                    pattern="^\d*(\.\d{0,2})?$"
                    step=".01"
                    {...register('amount', {
                      required: 'Amount is Required',
                      min: {
                        value: 1,
                        message: 'Minimum amount is $1',
                      },
                      max: {
                        value: 1000000,
                        message: 'Maximum amount is $10,000',
                      },
                    })}
                  />
                  <span className="text-text-light-gray">USD</span>
                </div>
              </label>
            </div>
          </div>
          <footer className="flex py-2">
            <button
              className="flex h-10 w-full items-center justify-center rounded-full border-border-primary bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark "
              type="submit"
            >
              Continue
            </button>
          </footer>
        </form>
      )}
      {currentStep === 2 && (
        <BulkAddFundSummary
          totalAmount={totalAmount}
          userCount={users.length || 0}
          users={users}
          businessAccount={selectedBusinessAccount}
          isLoading={isLoading}
          error={error}
          goToStep={goToStep}
          handleConfirm={handleConfirm}
        />
      )}
    </Modal>
  );
}
