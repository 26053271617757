export const formatAsPhoneNumber = (phoneNumber: string): string => {
  const number = phoneNumber.replaceAll(/-/g, '');
  if (number.length === 10) {
    return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6)}`;
  }
  return phoneNumber;
};

/**
 * This function takes a comma-delimited string containing
 * values with double-quotes around
 * @param dirtyValues string[]
 * @returns Object
 */
const cleanedValues = (dirtyValues: string[]) =>
  dirtyValues.map((val: string) => val.replace(/["]/g, '').trim());

/**
 * This function takes a CSV file and converts it to JSON
 * supports comma-delimited values
 * @param csvFile File
 */
export const csvToJson = (
  csvFile: File,
  callback: (error: Error | null, content: object[] | null) => object,
) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    /**
     * Split the CSV data into rows
     */
    const rows =
      e.target?.result
        ?.toString()
        .replace(/(\r\n|\n|\r)+$/, '') // removes end of file new lines or carriage returns
        .split('\n') || [];

    /**
     * Grab the headers of the CSV and then delete them from rows
     */
    const headers = cleanedValues(rows[0].split(','));
    delete rows[0];

    return callback(
      null,
      rows.reduce((acc: Array<object>, row: string) => {
        const splitRows = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
        const rowData = cleanedValues(splitRows);
        const json: { [key: string]: string | string[] } = {};

        headers.forEach((field: string, idx: number) => {
          if (field.includes(',')) {
            json[field] = rowData[idx]
              .split(',')
              .map((item: string) => item?.trim());
          }
          if (field !== 'error') {
            json[field] = rowData[idx]?.trim() || '';
          }
        });

        return [...acc, json];
      }, []),
    );
  };

  reader.onerror = () => callback(new Error('Error reading the file'), null);

  reader.readAsText(csvFile);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function jsonToCsv(jsonData: any): string {
  if (!jsonData || jsonData.length === 0) return '';

  const keys = Object.keys(jsonData[0]);

  // Create header row with the keys
  const header = `${keys.join(',')}\n`;

  // Create rows of data
  const rows = jsonData
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((row: any) => {
      return keys
        .map((key) => {
          let cell = row[key];
          if (typeof cell === 'string') {
            cell = `"${cell.replace(/"/g, '""')}"`;
          }
          return cell;
        })
        .join(',');
    })
    .join('\n');

  // Concatenate header and rows
  return `${header}${rows}`;
}
