import { Carousel } from 'antd';
import React from 'react';
import { CarouselRef } from 'antd/es/carousel';
import { CardModel } from '../../types/cards/cardModel';
import { LeftArrow } from '../LeftArrow';
import { RightArrow } from '../RightArrow';
import { ReactComponent as EmvChip } from '../../assets/emv-chip.svg';
import { ReactComponent as Contactless } from '../../assets/contactless.svg';

type CardCarouselProps = {
  handleChange: (currentSlide: number) => void;
  cards: CardModel[];
  carouselRef: React.RefObject<CarouselRef>;
};
export function CardCarousel({
  handleChange,
  cards,
  carouselRef,
}: CardCarouselProps) {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [smallSide, largeSide] = ['151px', '241px'];
  return (
    <Carousel
      ref={carouselRef}
      dots
      dotPosition="bottom"
      arrows
      prevArrow={
        <LeftArrow slideCount={cards.length} currentSlide={currentSlide} />
      }
      nextArrow={
        <RightArrow slideCount={cards.length} currentSlide={currentSlide} />
      }
      afterChange={(e) => {
        setCurrentSlide(e);
        handleChange(e);
      }}
    >
      {cards.map((card) => {
        return (
          <div key={`${card.id}-${card.card_identifier}`} className="flex p-8">
            <div className="flex items-center justify-center">
              {card?.card_img_url ? (
                <img
                  className="rounded-lg"
                  src={card.card_img_url || ''}
                  width={
                    card.card_img_url?.toLowerCase().includes('vertical')
                      ? smallSide
                      : largeSide
                  }
                  height={
                    card.card_img_url?.toLowerCase().includes('vertical')
                      ? largeSide
                      : smallSide
                  }
                  alt="card"
                />
              ) : (
                <div className="m-auto flex h-[240px] w-[160px] flex-col justify-between rounded-lg bg-white p-2 shadow-md">
                  <div className="grid h-full grid-cols-3">
                    <span className="col-start-2 flex justify-center">
                      <EmvChip />
                    </span>
                    <span className="col-start-3 m-2 flex justify-end">
                      <Contactless />
                    </span>
                  </div>
                  <p>**** **** {card.last_four}</p>
                  <div className="text-xs font-thin">
                    {card?.expiration_month}
                    {' / '}
                    {card?.expiration_year}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </Carousel>
  );
}
