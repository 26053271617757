import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select, Skeleton, notification } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import dayjs from 'dayjs';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { UserAccountDTO } from '../../../types/user/userAccountDTO';
import { getCardStatusFromId, getCardType } from '../../../utils/accountUtils';
import {
  addCardToUserAccount,
  assignCardToUserAccount,
  getCard,
  getCardsForAccount,
  replaceCardByIdentifier,
  updateCardStatus,
} from '../../../api/CardApi';
import { CardModel } from '../../../types/cards/cardModel';
import { CardCarousel } from '../../../components/CardCarousel';

import { CardCTAModal } from '../../../components/Modals/CardCTAModal';
import { AccountSelectItem } from '../../../components/AccountSelectItem';
import { CardDetails } from '../../../components/CardDetails';
import { CardCTAs } from '../../../components/Cards/CardCTAs';
import { AssignCardModal } from '../../../components/Modals/AssignCardModal';
import { AssignCardRequest } from '../../../types/cards/assignCardRequest';
import { AssignCardFormValues } from '../../../types/cards/assignCardFormValues';
import { UserDTO } from '../../../types/user/userDTO';
import { AddOrReplaceCardModal } from '../../../components/Modals/AddOrReplaceCardModal';
import { AddCardRequest } from '../../../types/cards/addCardRequest';
import { ReplaceCardRequest } from '../../../types/cards/replaceCardRequest';
import { AddCardFormValues } from '../../../types/cards/addCardFormValues';
import {
  useGetAccountHistory,
  useGetUserAccounts,
} from '../../../api/rq/queries/userQueries';
import { isAdminOrMTU } from '../../../utils/authUtils';
import { tableStatusCell } from '../../../components/TableStatusCell';
import Table from '../../../components/Table';
import { AccountHistoryDTO } from '../../../types/user/acountHistoryDTO';

export function UserCards(): ReactElement {
  /**
   * Local Component State
   */
  const hasAdminAccess = isAdminOrMTU();
  const [selectedAccount, setSelectedAccount] = useState<UserAccountDTO | null>(
    null,
  );
  const [selectedUser, setSelectedUser] = useState<UserDTO | null>(null);
  const [cards, setCards] = useState<CardModel[] | null>(null);
  const [selectedCard, setSelectedCard] = useState<CardModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  /**
   * Ref for AntD Carousel
   */
  const carouselRef = useRef<CarouselRef>(null);
  /**
   * Card Modal State
   */
  const [isLockCardModalOpen, setIsLockCardModalOpen] =
    useState<boolean>(false);
  const [isUnlockCardModalOpen, setIsUnlockCardModalOpen] =
    useState<boolean>(false);
  const [isDeactivateCardModalOpen, setIsDeactivateCardModalOpen] =
    useState<boolean>(false);
  const [isAssignCardModalOpen, setIsAssignCardModalOpen] =
    useState<boolean>(false);
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState<boolean>(false);
  const [isReplaceCardModalOpen, setIsReplaceCardModalOpen] =
    useState<boolean>(false);

  /**
   * Notification state
   */
  const [notify, notificationContext] = notification.useNotification();

  /**
   * Query Params
   */
  const { accountId: accountIdQueryStringParam, userId: userIdQueryParam } =
    useParams();
  const [accounts, setAccounts] = useState<UserAccountDTO[]>([]);

  /**
   * React query hooks
   */
  const { data: userAccountsData } = useGetUserAccounts(
    parseInt(userIdQueryParam || '0'),
    !!userIdQueryParam,
  );

  const {
    data: accountHistoryData,
    isLoading: isLoadingAccountHistory,
    refetch: refetchAccountHistoryData,
  } = useGetAccountHistory(
    selectedAccount?.id || 0,
    !!selectedAccount?.id,
    '?page=1&pageSize=20',
  );

  /**
   * Router Hooks
   */
  const navigate = useNavigate();

  useEffect(() => {
    if (userAccountsData) setAccounts(userAccountsData);
  }, [userAccountsData]);

  useEffect(() => {
    (async () => {
      if (
        accounts?.length >= 1 &&
        accountIdQueryStringParam &&
        accountIdQueryStringParam !== 'null'
      ) {
        setIsLoading(true);

        const selectedAcc =
          accounts.find(
            (acc) => acc.id === parseInt(accountIdQueryStringParam || '0'),
          ) || accounts[0];
        setSelectedAccount(selectedAcc);

        const accountCards = await getCardsForAccount(selectedAcc?.id);
        setCards(accountCards || []);
        setSelectedCard(accountCards ? accountCards[0] : null);

        setIsLoading(false);
      }
    })();
  }, [accountIdQueryStringParam, accounts]);

  const selectAccount = async (acctId: number) => {
    navigate(`/dashboard/users/${userIdQueryParam}/accounts/${acctId}/cards`, {
      replace: false,
    });
  };

  const handleCarouselChange = async (currentSlide: number): Promise<void> => {
    const card = cards ? cards[currentSlide] : null;
    if (!card) return;
    setSelectedCard(card);
  };

  const makeTransactionDateCell = (
    info: CellContext<AccountHistoryDTO, unknown>,
  ): ReactElement => {
    return (
      <div className="flex flex-col">
        <p className="font-semibold">
          {dayjs(info.row.original.created_at).format('MMM DD, YYYY')}
        </p>
        <p
          className="text-sm font-normal
  text-text-light-gray"
        >
          {info.row.original.type}
        </p>
      </div>
    );
  };

  const makeCardInfoCell = useCallback(
    (info: CellContext<AccountHistoryDTO, unknown>): ReactElement => {
      return (
        <p className="font-semibold">
          **** ****
          {info.row.original?.last_four || '****'}
        </p>
      );
    },
    [],
  );

  const columnHelper = createColumnHelper<AccountHistoryDTO>();

  const cardHistoryColumns = useMemo(
    () => [
      columnHelper.accessor('created_at', {
        cell: (info) => makeTransactionDateCell(info),
        header: 'DATE',
      }),
      columnHelper.accessor('proxy_value', {
        cell: (info) => makeCardInfoCell(info),
        header: 'CARD',
      }),
      columnHelper.accessor('action', {
        cell: (info) => {
          switch (info.getValue()) {
            case 'INSERT':
              return 'Created';
            case 'UPDATE':
              return 'Updated';
            case 'DELETE':
              return 'Deleted';
            default:
              return '';
          }
        },
        header: 'EVENT',
      }),
      columnHelper.accessor('card_status_id', {
        header: 'STATUS',
        cell: (info) => tableStatusCell<AccountHistoryDTO>(info, true),
      }),
    ],
    [columnHelper, makeCardInfoCell],
  );

  const lockCard = async () => {
    if (selectedCard?.card_identifier || selectedCard?.card_token) {
      try {
        const response = await updateCardStatus(
          selectedCard.card_identifier || selectedCard.card_token,
          'Paused',
        );
        setSelectedCard({
          ...selectedCard,
          card_status: getCardStatusFromId(response?.card_status_id),
        });
        refetchAccountHistoryData();
        notify.success({
          message: 'Success!',
          description: `Card has been successfully locked.`,
          placement: 'topLeft',
        });
      } catch (err) {
        notify.error({
          message: 'Error!',
          description: `There was an error locking the card: ${err.message}. Please try again.`,
          placement: 'topLeft',
        });
      } finally {
        setIsLoading(false);
        setIsLockCardModalOpen(false);
        document.documentElement.scrollTo(0, 0);
      }
    }
  };

  const unlockCard = async () => {
    if (selectedCard?.card_identifier || selectedCard?.card_token) {
      try {
        const response = await updateCardStatus(
          selectedCard.card_identifier || selectedCard.card_token,
          'Active',
        );
        setSelectedCard({
          ...selectedCard,
          card_status: getCardStatusFromId(response?.card_status_id),
        });
        refetchAccountHistoryData();
        notify.success({
          message: 'Success!',
          description: `Card has been successfully unlocked.`,
          placement: 'topLeft',
        });
      } catch (err) {
        notify.error({
          message: 'Error!',
          description: `There was an error unlocking the card: ${err.message}. Please try again.`,
          placement: 'topLeft',
        });
      } finally {
        setIsLoading(false);
        setIsUnlockCardModalOpen(false);
        document.documentElement.scrollTo(0, 0);
      }
    }
  };

  const deactivateCard = async () => {
    if (selectedCard?.card_identifier || selectedCard?.card_token) {
      try {
        await updateCardStatus(
          selectedCard.card_identifier || selectedCard.card_token,
          'Inactive',
        );
        if (cards) {
          const filteredCards = cards.filter(
            (card) =>
              card.id !== selectedCard.id ||
              card.card_identifier !== selectedCard.card_identifier,
          );
          setCards(filteredCards);
          setSelectedCard(filteredCards.length > 0 ? filteredCards[0] : null);
          refetchAccountHistoryData();
        }
        notify.success({
          message: 'Success!',
          description: `Card has been successfully deactivated.`,
          placement: 'topLeft',
        });
      } catch (err) {
        notify.error({
          message: 'Error!',
          description: `There was an error deactivating the card: ${err.message}. Please try again.`,
          placement: 'topLeft',
        });
      } finally {
        setIsLoading(false);
        setIsDeactivateCardModalOpen(false);
        document.documentElement.scrollTo(0, 0);
      }
    }
  };

  /**
   * Assigns a card to a user account, adds it to the beginning
   * of the cards list so it's shown in the carousel, and fetches
   * the card history for the new card.
   *
   * @param formData User ID, User Acct ID, Proxy Value
   */
  const assignCard = async (formData: AssignCardFormValues): Promise<void> => {
    if (formData.user_account_id && formData.proxy_value) {
      const body: AssignCardRequest = {
        user_account_id: formData.user_account_id,
        proxy_value: formData.proxy_value,
      };
      try {
        const cardResp = await assignCardToUserAccount(body);
        if (cardResp?.card_identifier) {
          const card = await getCard(cardResp.card_identifier);
          if (card && cards) {
            setCards([card, ...cards]);
            setSelectedCard(card);
            refetchAccountHistoryData();
            notify.success({
              message: 'Success!',
              description: `Card has been successfully assigned.`,
              placement: 'topLeft',
            });
          }
        }
      } catch (err) {
        notify.error({
          message: 'Error!',
          description: `There was an error assigning the card: ${err.message}. Please try again.`,
          placement: 'topLeft',
        });
      } finally {
        setIsLoading(false);
        setIsAssignCardModalOpen(false);
        document.documentElement.scrollTo(0, 0);
      }
    }
  };

  const addCard = async (formData: AddCardFormValues): Promise<void> => {
    let addCardBody: AddCardRequest = {
      user_account_id: formData.user_account_id,
      type: formData.card_type || 'VIRTUAL',
      shipping_address: {
        first_name: selectedUser?.first_name ?? '',
        last_name: selectedUser?.last_name ?? '',
      },
    };
    if (formData.card_type === 'PHYSICAL') {
      addCardBody = {
        ...addCardBody,
        shipping_address: {
          first_name: selectedUser?.first_name ?? '',
          last_name: selectedUser?.last_name ?? '',
          address1: selectedUser?.ship_address_1 ?? '',
          address2: selectedUser?.ship_address_2 ?? ' ',
          city: selectedUser?.ship_city ?? '',
          state: selectedUser?.ship_province ?? '',
          postal_code: selectedUser?.ship_postal_code ?? '',
          country:
            selectedUser?.ship_country === 'US'
              ? 'USA'
              : selectedUser?.ship_country ?? '',
        },
      };
    }
    try {
      const addCardResponse = await addCardToUserAccount(addCardBody);
      if (addCardResponse) {
        const newCard = await getCard(addCardResponse.token);
        if (newCard && cards) {
          setCards([newCard, ...cards]);
          setSelectedCard(newCard);
          refetchAccountHistoryData();
          notify.success({
            message: 'Success!',
            description: `Card has been successfully added.`,
            placement: 'topLeft',
          });
        }
      }
    } catch (err) {
      notify.error({
        message: 'Error!',
        description: `There was an error adding the card: ${err.message}. Please try again.`,
        placement: 'topLeft',
      });
    } finally {
      setIsLoading(false);
      setIsAddCardModalOpen(false);
      document.documentElement.scrollTo(0, 0);
    }
  };

  const replaceCard = async (): Promise<void> => {
    const replaceCardBody: ReplaceCardRequest = {
      shipping_address: {
        first_name: selectedUser?.first_name ?? '',
        last_name: selectedUser?.last_name ?? '',
        address1: selectedUser?.ship_address_1 ?? '',
        address2: selectedUser?.ship_address_2 ?? ' ',
        city: selectedUser?.ship_city ?? '',
        state: selectedUser?.ship_province ?? '',
        postal_code: selectedUser?.ship_postal_code ?? '',
        country:
          selectedUser?.ship_country === 'US'
            ? 'USA'
            : selectedUser?.ship_country ?? '',
      },
    };
    try {
      const id = selectedCard?.card_identifier ?? selectedCard?.card_token;
      if (id) {
        const replaceCardResponse = await replaceCardByIdentifier(
          id,
          replaceCardBody,
        );

        if (replaceCardResponse) {
          const newCard = await getCard(replaceCardResponse.token);
          if (newCard && cards) {
            const filteredCards = cards.filter(
              (card) => card.card_identifier !== id && card.card_token !== id,
            );
            const newCards = [newCard, ...filteredCards];
            setCards(newCards);
            carouselRef.current?.goTo(0);
            setSelectedCard(newCards[0]);
            refetchAccountHistoryData();
            notify.success({
              message: 'Success!',
              description: `Card has been successfully replaced.`,
              placement: 'topLeft',
            });
          }
        }
      }
    } catch (err) {
      notify.error({
        message: 'Error!',
        description: `There was an error replacing the card: ${err.message}. Please try again.`,
        placement: 'topLeft',
      });
    } finally {
      setIsLoading(false);
      setIsReplaceCardModalOpen(false);
      document.documentElement.scrollTo(0, 0);
    }
  };

  return (
    <>
      {notificationContext}
      {isAssignCardModalOpen && (
        <AssignCardModal
          isModalOpen={isAssignCardModalOpen}
          setIsModalOpen={setIsAssignCardModalOpen}
          selectedUserAccount={selectedAccount}
          onConfirm={assignCard}
        />
      )}
      {isAddCardModalOpen && (
        <AddOrReplaceCardModal
          title="Add Card"
          selectedCard={null}
          isModalOpen={isAddCardModalOpen}
          setIsModalOpen={setIsAddCardModalOpen}
          onConfirm={addCard}
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
        />
      )}
      {isReplaceCardModalOpen && (
        <AddOrReplaceCardModal
          title="Replace Card"
          selectedCard={selectedCard}
          isModalOpen={isReplaceCardModalOpen}
          setIsModalOpen={setIsReplaceCardModalOpen}
          onConfirm={replaceCard}
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
        />
      )}
      <CardCTAModal
        primaryText="Locking a card prevents anyone from making new purchases on the card. Other types of transactions, like direct deposits, and ACH transfers, are not affected."
        submitButtonText="Lock Card"
        isModalOpen={isLockCardModalOpen}
        setIsModalOpen={setIsLockCardModalOpen}
        onCancel={() => setIsLockCardModalOpen(false)}
        onConfirm={lockCard}
      />
      <CardCTAModal
        primaryText="This card is currently locked. Unlocking will allow anyone to make new purchases on the card."
        submitButtonText="Unlock Card"
        isModalOpen={isUnlockCardModalOpen}
        setIsModalOpen={setIsUnlockCardModalOpen}
        onCancel={() => setIsUnlockCardModalOpen(false)}
        onConfirm={unlockCard}
      />
      <CardCTAModal
        primaryText="This action cannot be undone. Are you sure you want to proceed?"
        submitButtonText="Deactivate Card"
        isModalOpen={isDeactivateCardModalOpen}
        setIsModalOpen={setIsDeactivateCardModalOpen}
        onCancel={() => setIsDeactivateCardModalOpen(false)}
        onConfirm={deactivateCard}
        forDelete
      />
      <div className="pb-4">
        <div className="text-base font-semibold">User Account</div>
        <Select
          placeholder="Select an Account"
          size="large"
          className="my-1 w-full md:w-[50%]"
          options={accounts.map((account) => ({
            value: account.id,
            label: <AccountSelectItem account={account} />,
          }))}
          onChange={selectAccount}
          value={selectedAccount?.id}
        />
      </div>
      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="flex w-full flex-col rounded-lg border border-border-light-gray bg-white p-4 lg:w-[50%]">
          <h2 className="mb-1 text-lg font-semibold">Active Cards</h2>
          <p className="text-sm text-text-gray">
            {getCardType(selectedCard?.type)}
          </p>
          {cards && cards.length > 0 && !isLoading && (
            <CardCarousel
              cards={cards}
              handleChange={handleCarouselChange}
              carouselRef={carouselRef}
            />
          )}
          {isLoading && (
            <span className="mb-2">
              <Skeleton active />
            </span>
          )}
          {cards?.length === 0 && !isLoading && (
            <div className="mt-4 flex w-full items-center justify-center text-sm font-semibold">
              No Cards Found
            </div>
          )}
          {cards && cards.length > 0 && !isLoading && (
            <CardDetails selectedCard={selectedCard} />
          )}

          <div className="my-8">
            <h2 className="text-lg font-semibold">Card History</h2>
            {(isLoadingAccountHistory || isLoading) && (
              <Skeleton className="mt-2" active />
            )}

            {accountHistoryData && (
              <Table<AccountHistoryDTO>
                data={accountHistoryData || []}
                pageSize={5}
                columns={cardHistoryColumns}
              />
            )}
          </div>
        </div>

        {/* Card CTAs */}
        {hasAdminAccess && (
          <CardCTAs
            disabled={isLoading}
            selectedCard={selectedCard}
            setIsAssignCardModalOpen={setIsAssignCardModalOpen}
            setIsUnlockCardModalOpen={setIsUnlockCardModalOpen}
            setIsLockCardModalOpen={setIsLockCardModalOpen}
            setIsDeactivateCardModalOpen={setIsDeactivateCardModalOpen}
            setIsAddCardModalOpen={setIsAddCardModalOpen}
            setIsReplaceCardModalOpen={setIsReplaceCardModalOpen}
          />
        )}
      </div>
    </>
  );
}
