import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuthApi } from '../../context/authContext';
import PageFooter from '../../components/PageFooter';
import EmailFormInput from '../../components/Forms/EmailFormInput';
import { SignInFormValues } from '../../types/forms/signInFormValues';
import LoadingSpinner from '../../components/LoadingSpinner';

export default function ForgotPassword(): ReactElement {
  const [initTheme, setInitTheme] = useState('default');
  const [state, setState] = useState<{
    isSuccessful: boolean;
    isErrored: boolean;
    isLoading: boolean;
  }>({ isErrored: false, isSuccessful: false, isLoading: false });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignInFormValues>();

  const context = useAuthApi();
  const navigate = useNavigate();

  const sendPasswordResetEmail = async (email: string) => {
    setState({
      isErrored: false,
      isSuccessful: true,
      isLoading: true,
    });
    const response = await context?.sendForgottenPasswordEmail(email);
    console.log(response);
    if (response && response.emailSent) {
      navigate(`/reset-password?email=${encodeURIComponent(email)}`);
      setState({
        isErrored: false,
        isSuccessful: true,
        isLoading: false,
      });
    } else {
      setState({
        isErrored: true,
        isSuccessful: false,
        isLoading: false,
      });
    }
  };

  /**
   * Set the theme based on the `window.location.origin`
   */
  useEffect(() => {
    const { origin } = window.location;

    switch (origin) {
      case 'payroll.intermexonline.com':
        setInitTheme('intermex');
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url("./themes/${initTheme}/desktop_bg.svg")`,
        }}
        className="absolute bottom-0 right-0 -z-10 h-full w-full bg-cover bg-center bg-no-repeat"
      />
      <div className={`${initTheme} flex h-screen w-full flex-col`}>
        <div className="flex w-full flex-1 flex-col items-center justify-center p-10">
          <div className="col-span-full row-span-1 transition-all ease-in-out">
            <h1 className="mb-10 text-center text-4xl font-bold">
              Card Management Platform
            </h1>
            <div className="flex h-auto w-full flex-col content-center items-center">
              <h2 className="mb-2 text-left text-2xl font-semibold">
                Forgot your password?
              </h2>
              <p className="text-center">
                No problem, let&apos;s reset that for you.
                <br />
                Send a reset email below;
              </p>
              <form
                className="grid min-w-[300px] auto-rows-max gap-y-5 sm:min-w-[375px]"
                onSubmit={handleSubmit((data) =>
                  sendPasswordResetEmail(data?.email),
                )}
              >
                <EmailFormInput register={register} errors={errors} />
                <button
                  className="flex items-center justify-center rounded-full bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
                  type="submit"
                  data-testid="submit-button"
                >
                  {state.isLoading ? <LoadingSpinner /> : 'RESET MY PASSWORD'}
                </button>
                <p className="text-center">
                  <Link to="/" className="text-center font-thin text-blue-500">
                    <strong>Back to Login</strong>
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
        <PageFooter />
      </div>
    </>
  );
}
