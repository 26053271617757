import React, { useState, useEffect, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuthApi } from '../../context/authContext';
import PageFooter from '../../components/PageFooter';
import { ResetPasswordFormValues } from '../../types/forms/resetPasswordFormValues';
import OTPFormInput from '../../components/Forms/OTPFormInput';
import PasswordFormInput from '../../components/Forms/PasswordFormInput';
import ConfirmPasswordFormInput from '../../components/Forms/ConfirmPasswordFormInput';
import LoadingSpinner from '../../components/LoadingSpinner';

export default function ResetPassword(): ReactElement {
  const [initTheme, setInitTheme] = useState('default');
  const [state, setState] = useState<{
    isSuccessful: boolean;
    isErrored: boolean;
    isLoading: boolean;
  }>({ isErrored: false, isSuccessful: false, isLoading: false });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>();

  const context = useAuthApi();

  const resetPassword = async (data: ResetPasswordFormValues) => {
    setState({
      isErrored: false,
      isSuccessful: true,
      isLoading: true,
    });
    const email = window.location.search.split('=')[1];
    if (email) {
      const response = await context?.resetPassword({ email, ...data });
      if (response && Object.keys(response[0]).length === 0) {
        setState({
          isErrored: false,
          isSuccessful: true,
          isLoading: false,
        });
      } else {
        setState({
          isErrored: true,
          isSuccessful: false,
          isLoading: false,
        });
      }
    }
  };

  /**
   * Set the theme based on the `window.location.origin`
   */
  useEffect(() => {
    const { origin } = window.location;

    switch (origin) {
      case 'payroll.intermexonline.com':
        setInitTheme('intermex');
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url("./themes/${initTheme}/desktop_bg.svg")`,
        }}
        className="absolute bottom-0 right-0 -z-10 h-full w-full bg-cover bg-center bg-no-repeat"
      />
      <div className={`${initTheme} flex h-screen w-full flex-col`}>
        <div className="flex w-full flex-1 flex-col items-center justify-center p-10">
          <div className="col-span-full row-span-1 transition-all ease-in-out">
            <h1 className="mb-10 text-center text-4xl font-bold">
              Card Management Platform
            </h1>
          </div>

          {(!state?.isErrored || !state?.isSuccessful) && (
            <div className="flex h-auto w-full flex-col content-center items-center">
              <h2 className="mb-2 text-left text-2xl font-semibold">
                Password Reset
              </h2>
              <div className="flex w-5/6 items-center rounded-md bg-green-500 md:w-1/3">
                <div className="px-5 py-3 text-center text-sm text-white">
                  <strong>Success!</strong> We&apos;ve sent you an email with a
                  one-time code to change your password.
                </div>
              </div>

              <form
                className="grid min-w-[300px] auto-rows-max sm:min-w-[375px]"
                onSubmit={handleSubmit((data) => resetPassword(data))}
              >
                <div className="mb-6 w-full">
                  <p className="mb-0 mt-6 text-left">
                    Verify the OTP code sent to your email address.
                  </p>
                  <OTPFormInput register={register} errors={errors} />
                </div>
                <div className="mb-3 gap-y-3">
                  <PasswordFormInput register={register} errors={errors} />
                  <ConfirmPasswordFormInput
                    register={register}
                    errors={errors}
                  />
                </div>

                <button
                  className="flex items-center justify-center rounded-full bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
                  type="submit"
                  data-testid="submit-button"
                >
                  {state.isLoading ? <LoadingSpinner /> : 'Change Password'}
                </button>
                <p className="mt-6 text-center">
                  <Link to="/" className="text-center font-thin text-blue-500">
                    <strong>Back to Login</strong>
                  </Link>
                </p>
              </form>
            </div>
          )}
        </div>
        <PageFooter />
      </div>
    </>
  );
}
