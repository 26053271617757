import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Select } from 'antd';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { BusinessAccountDTO } from '../../../types/business/businessAccountDTO';
import { AvailableBalanceCard } from '../../../components/AvailableBalanceCard';
import { StatusCard } from '../../../components/StatusCard';
import { BusinessTransactionFiltersModal } from '../../../components/Modals/BusinessTransactionFiltersModal';
import { tableStatusCell } from '../../../components/TableStatusCell';
import { useAppStore } from '../../../zustand/store';
import {
  getBusinessAccountTransactions,
  getBusinessAccounts,
} from '../../../api/UsersApi';
import { BusinessAccountTransactionDTO } from '../../../types/transactions/businessAccountTransactionDTO';
import { Transactions } from '../../../components/Table/Transactions';
import { AccountSelectItem } from '../../../components/AccountSelectItem';
import { isAdminOrMTU, isBusinessAdmin } from '../../../utils/authUtils';
import { CsvLinkContainer } from '../../../components/CsvLinkContainer';
import { useGetBusiness } from '../../../api/rq/queries/businessQueries';
import { ExportModal } from '../../../components/Modals/ExportModal';
import { getDollarAmount } from '../../../utils/accountUtils';
import { TransactionSearchQueryParams } from '../../../types/transactions/transactionSearchQueryParams';

export function BusinessTransactions(): ReactElement {
  /**
   * Local State
   */
  const [selectedAccount, setSelectedAccount] =
    useState<BusinessAccountDTO | null>(null);
  const [allTransactionList, setAllTransactionList] = useState<
    BusinessAccountTransactionDTO[]
  >([]);
  const [transactionList, setTransactionList] = useState<
    BusinessAccountTransactionDTO[]
  >([]);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const lastYear = dayjs().subtract(1, 'year').format('YYYY-MM-DD');

  /**
   * Need to keep track of filters set/handled in the \
   * BusinessTransactionsFiltersModal so they can be applied to PDF generation
   */
  const [filters, setFilters] = useState<TransactionSearchQueryParams>({
    createdAt: lastYear,
    transactionStatus: null,
    transactionType: null,
    amount: {
      min: '',
      max: '',
    },
  });

  const hasBusinessAdminRole = isBusinessAdmin();

  const setNotification = useAppStore((state) => state.setNotification);

  /**
   * CSV ref
   */
  const csvRef = React.useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const hasAdminRole = isAdminOrMTU();

  const { accounts } = useOutletContext<{
    accounts: BusinessAccountDTO[];
  }>();

  const { accountId, businessId } = useParams();

  const navigate = useNavigate();

  /**
   * RQ Queries for fetching data
   */

  const { data: businessData } = useGetBusiness(parseInt(businessId || '0'));

  useEffect(() => {
    (async () => {
      const queryString = `?pageSize=${1000}&pageNumber=${1}&orderBy=created_at&order=desc`;
      setIsLoadingTransactions(true);
      try {
        /**
         * acountId can be 'null' string due to it coming from the query params
         */
        let updateBlance = false;
        try {
          if (businessId) {
            getBusinessAccounts(businessId).then((res) => {
              const selectedAcc =
                res.find((acc) => acc.id === parseInt(accountId || '0')) ||
                res[0];
              setSelectedAccount(selectedAcc);
            });
          }
        } catch (err) {
          updateBlance = true;
          console.error(err);
        }
        if (accountId && accountId !== 'null') {
          if (updateBlance) {
            const selectedAcc =
              accounts.find((acc) => acc.id === parseInt(accountId || '0')) ||
              accounts[0];
            setSelectedAccount(selectedAcc);
          }
          const trxs = await getBusinessAccountTransactions(
            parseInt(accountId || '0') || accounts[0].id,
            queryString,
          );
          const trxNoBadStatuses = trxs.filter((trx) => {
            return !['PAYOUT_CANCELLED'].includes(trx.status);
          });
          setAllTransactionList(trxs);
          setTransactionList(trxNoBadStatuses);
        } else {
          setTransactionList([]);
          setAllTransactionList([]);
        }
      } catch (err) {
        console.error(err);
        setHasError(true);
      }

      setIsLoadingTransactions(false);
    })();
    setNotification(null);
  }, [accountId, accounts, businessId, setAllTransactionList, setNotification]);

  const selectAccount = async (acctId: number) => {
    navigate(`/dashboard/businesses/${businessId}/accounts/${acctId}`, {
      replace: false,
    });
  };

  const getBalance = (
    info: CellContext<BusinessAccountTransactionDTO, number>,
  ) => {
    const row = info.row.original;
    const { amount } = row;
    if (row.transfer_type.includes('to_business')) {
      return `+$${amount.toFixed(2).toLocaleString()}`;
    }
    if (row.transfer_type.includes('business_to')) {
      return `-$${Math.abs(amount).toFixed(2).toLocaleString()}`;
    }
    return '';
  };

  const formatAvailableBalance = useCallback(
    (
      info: CellContext<BusinessAccountTransactionDTO, number>,
    ): ReactElement => {
      const balance = getBalance(info);
      const [dollars, cents] = balance.split('.');
      return (
        <div className="flex items-baseline">
          <span className="font-semibold">{dollars}</span>
          <span className="text-xs text-text-gray">
            {cents ? `.${cents}` : ''}
          </span>
        </div>
      );
    },
    [],
  );

  const columnHelper = createColumnHelper<BusinessAccountTransactionDTO>();

  const transactionColumns = useMemo(
    () => [
      columnHelper.accessor('created_at', {
        header: 'DATE',
        cell: (info) => dayjs(info.getValue()).format('MMM DD, YYYY'),
      }),
      columnHelper.accessor('other_entity', {
        cell: (info) => info.getValue(),
        header: 'DESCRIPTION',
      }),
      columnHelper.accessor('transfer_type', {
        cell: (info) => info.getValue(),
        header: 'TYPE',
      }),
      columnHelper.accessor('amount', {
        header: 'AMOUNT',
        cell: (info) => formatAvailableBalance(info),
      }),
      columnHelper.accessor('status', {
        header: 'STATUS',
        cell: (info) => tableStatusCell<BusinessAccountTransactionDTO>(info),
      }),
    ],
    [columnHelper, formatAvailableBalance],
  );

  /**
   * This has some duplicate code similar to other PDF export functions
   * in other Transaction/Deposits files. This is because the functionality
   * is similar, but has a lot of unique data. This could be abstracted,
   * however it would need to take a lot of input parameters, and the added
   * complexity doesn't seem worth it at this time.
   *
   * @TODO consider refactoring this to a shared function somewhere
   */
  const exportToPdf = () => {
    /**
     * Gernate JSON data that is compatible with the jsPDF
     * autotable library being used. This is a array of string arrays, with
     * each individual string array representing a row in the table.
     */
    const pdfCompatibleData: string[][] =
      transactionList
        ?.map((trx) => ({
          date: dayjs(trx.created_at).format('MMM DD, YYYY'),
          description: trx.other_entity,
          type: trx.transfer_type,
          status: trx.status,
          amount: getDollarAmount(trx.amount),
        }))
        .map((trx) => Object.values(trx).map((val) => val?.toString() ?? '')) ??
      [];
    // eslint-disable-next-line new-cap
    const pdfDoc = new jsPDF();
    /**
     * Title
     */
    pdfDoc.setFontSize(18);
    pdfDoc.setFont('Inter');
    pdfDoc.text('BUSINESS', 200, 10, {
      align: 'right',
    });
    pdfDoc.text('TRANSACTIONS', 200, 17, {
      align: 'right',
    });
    /**
     * Sub-title
     */
    pdfDoc.setFontSize(10);
    pdfDoc.text(dayjs().format('MMMM DD, YYYY'), 200, 22, {
      align: 'right',
    });
    /**
     * Summary start
     */
    pdfDoc.setFontSize(10);
    pdfDoc.text('SUMMARY', 20, 40);
    /**
     * User's Name
     */
    pdfDoc.setFontSize(9);
    pdfDoc.setFont('Inter', 'normal');
    pdfDoc.text('Business Name', 20, 50);
    pdfDoc.setFont('Inter', 'bold');
    pdfDoc.text(businessData?.legal_business_name ?? '', 50, 50);
    pdfDoc.setFont('Inter', 'normal');
    /**
     * Balance as of date
     */
    pdfDoc.text(`Balance as of`, 20, 56);

    pdfDoc.text(dayjs().format('MMM, DD, YYYY'), 20, 60);
    pdfDoc.setFont('Inter', 'bold');
    const accountBalance = selectedAccount?.available_balance || 0;
    pdfDoc.text(
      `$${
        accountBalance
          ? (accountBalance / 100).toLocaleString('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
            })
          : '$0'
      }`,
      50,
      56,
    );
    /**
     * Filter criteria
     */
    pdfDoc.setFont('Inter', 'normal');

    pdfDoc.text('Filter Criteria', 20, 65);
    pdfDoc.setFont('Inter', 'bold');
    pdfDoc.text(
      `Amount: ${filters.amount?.min || '-$100,000'} (Min), ${
        filters.amount?.max || '$100,000'
      } (Max)`,
      50,
      65,
    );
    pdfDoc.text(
      `Status: ${
        filters.transactionStatus ?? 'All except VOIDED, DECLINED, and EXPIRED'
      }`,
      50,
      70,
    );

    pdfDoc.text(
      `Type: ${
        filters.transactionType ?? hasBusinessAdminRole
          ? 'Account Transfers'
          : 'All'
      }`,
      50,
      75,
    );
    pdfDoc.text(`From Date: ${filters.createdAt}`, 50, 80);

    autoTable(pdfDoc, {
      theme: 'plain',
      columnStyles: {
        0: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        1: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        2: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        3: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        4: {
          cellWidth: 'auto',
          fontSize: 8,
        },
      },
      head: [['DATE', 'DESCRIPTION', 'TYPE', 'STATUS', 'AMOUNT']],
      body: pdfCompatibleData,
      margin: { top: 90 }, // Seting top margin for First Page.
      didDrawPage: (data) => {
        // Resetting top margin.
        // The change will be reflected only after print the first page.
        // eslint-disable-next-line no-param-reassign
        data.settings.margin.top = 20;
      },
    });
    pdfDoc.save(
      `${
        businessData?.legal_business_name ?? businessData?.dba_business_name
      }_account-${selectedAccount?.id}_transactions.pdf`,
    );
  };

  const exportTransactions = (fileType: string) => {
    if (fileType.toLowerCase() === 'pdf') {
      exportToPdf();
    }
    if (fileType.toLowerCase() === 'csv') {
      csvRef.current?.link.click();
    }
    setIsExportModalOpen(false);
  };

  return (
    <>
      {hasAdminRole && (
        <CsvLinkContainer
          fileNameWithoutDate={`${businessData?.legal_business_name}_account-${accountId}_transactions`}
          dataToExport={transactionList || []}
          csvLinkRef={csvRef}
        />
      )}
      <div className="pb-4">
        <div className="text-xs font-semibold">Business Account</div>
        <Select
          showSearch
          placeholder="Select an Account"
          size="large"
          className="my-1 w-full md:w-1/3"
          options={accounts.map((account) => ({
            value: account.id,
            label: <AccountSelectItem account={account} />,
          }))}
          onChange={selectAccount}
          value={selectedAccount?.id}
        />
      </div>
      <div className="grid gap-5">
        <div className="row-span-1 flex h-auto flex-col items-center justify-between gap-6 md:flex-row">
          <AvailableBalanceCard
            balance={selectedAccount?.available_balance || 0}
          />
          <StatusCard
            statusIdOrName={selectedAccount?.business_account_status_id || 0}
            title="Account Status"
          />
        </div>
        <Transactions<BusinessAccountTransactionDTO>
          setIsFilterModalOpen={setIsFilterModalOpen}
          setIsExportModalOpen={setIsExportModalOpen}
          transactionList={transactionList}
          transactionColumns={transactionColumns}
          isLoadingTransactions={isLoadingTransactions}
          hasError={hasError}
        />
      </div>
      <ExportModal
        isModalOpen={isExportModalOpen}
        setIsModalOpen={setIsExportModalOpen}
        onConfirm={exportTransactions}
        isLoading={false}
        title="Business Details Transactions Download"
      />
      <BusinessTransactionFiltersModal
        allTransactions={allTransactionList}
        setTransactionList={setTransactionList}
        isFilterModalOpen={isFilterModalOpen}
        setIsFilterModalOpen={setIsFilterModalOpen}
        setFilters={setFilters}
      />
    </>
  );
}
