import React from 'react';
import {
  FieldErrors,
  RegisterOptions,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { ResetPasswordFormValues } from '../../../types/forms/resetPasswordFormValues';

type OTPFormInputProps = {
  errors: FieldErrors<ResetPasswordFormValues>;
  register: (
    name: 'otp',
    options?: RegisterOptions<ResetPasswordFormValues, 'otp'> | undefined,
  ) => UseFormRegisterReturn<'otp'>;
};

export default function OTPFormInput({ register, errors }: OTPFormInputProps) {
  return (
    <div className="flex flex-col">
      <div className="m-1 text-xs text-text-error-red" data-testid="otp-errors">
        {errors?.otp?.message || ''}
      </div>
      <input
        id="otp"
        className="rounded-lg border border-zinc-200 p-2"
        placeholder="OTP Code"
        type="text"
        {...register('otp', {
          required: 'OTP is Required',
        })}
      />
    </div>
  );
}
