import { Modal, Select } from 'antd';
import React, { useState } from 'react';
import { createRoleDropdownList, getRoleId } from '../../../utils/authUtils';

type ExportModalProps = {
  isModalOpen: boolean;
  onConfirm: (selectedRoleId: number) => void;
  setIsModalOpen: (val: boolean) => void;
};

export function SelectRoleModal({
  isModalOpen,
  onConfirm,
  setIsModalOpen,
}: ExportModalProps) {
  const [selectedRoleId, setSelectedRoleId] = useState(0);
  const userRole = getRoleId();
  const roleDropdownList = createRoleDropdownList(userRole);

  return (
    <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer="">
      <div className="mb-4 flex flex-col gap-4">
        <h3 className="pb-2 text-xl font-semibold">Select a User Role</h3>
        <Select
          id="role"
          allowClear
          placeholder="Select a user role"
          size="large"
          className="my-1 w-full"
          options={roleDropdownList.map((role) => ({
            value: role.id,
            label: role.name,
            disabled: role.disabled,
          }))}
          onChange={(role: string) => setSelectedRoleId(parseInt(role))}
        />
      </div>
      <footer className="flex flex-col gap-6 py-2">
        <button
          className={`${
            selectedRoleId ? '' : 'cursor-not-allowed'
          } flex h-10 w-full items-center justify-center rounded-full border-border-primary bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark`}
          onClick={() => onConfirm(selectedRoleId)}
          type="submit"
          disabled={!selectedRoleId}
        >
          Continue to User Creation
        </button>
      </footer>
    </Modal>
  );
}
