/* eslint-disable class-methods-use-this */
import { datadogLogs } from '@datadog/browser-logs';
import { ApiResponseDTO } from '../types/apiResponseDTO';
import { RefreshToken } from '../types/auth/refreshToken';
import { TERN_USER_API_URL } from './Endpoints';

class TernFetchAPI {
  getHeaders() {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
  }

  supplementUpdateData(data: object | []): string {
    const id = localStorage.getItem('id') || null;

    /**
     * Until I get a chance to discuss with the team
     * lets not send this field when the request body
     * is an array
     */
    if (Array.isArray(data)) {
      return JSON.stringify(data);
    }

    return JSON.stringify({
      ...data,
      updated_by: id,
    });
  }

  async get<T>(
    baseApi: string,
    endpoint: string,
    ignoreRetry = false,
  ): Promise<ApiResponseDTO<T>> {
    const requestPromise = fetch(`${baseApi}/${endpoint}`, {
      headers: this.getHeaders(),
    });
    const response = await requestPromise;
    return this.handleKnownErrors<T>(
      response,
      baseApi,
      endpoint,
      'GET',
      ignoreRetry,
    );
  }

  async post<T>(
    baseApi: string,
    endpoint: string,
    body: object,
    ignoreRetry = false,
  ): Promise<{ data: T }> {
    const requestPromise = fetch(`${baseApi}/${endpoint}`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: this.supplementUpdateData(body),
    });
    const response = await requestPromise;
    return this.handleKnownErrors<T>(
      response,
      baseApi,
      endpoint,
      'POST',
      ignoreRetry,
      body,
    );
  }

  async put<T>(
    baseApi: string,
    endpoint: string,
    body: object,
    ignoreRetry = false,
  ): Promise<{ data: T }> {
    const requestPromise = fetch(`${baseApi}/${endpoint}`, {
      method: 'PUT',
      headers: this.getHeaders(),
      body: this.supplementUpdateData(body),
    });
    const response = await requestPromise;
    return this.handleKnownErrors<T>(
      response,
      baseApi,
      endpoint,
      'PUT',
      ignoreRetry,
      body,
    );
  }

  async patch<T>(
    baseApi: string,
    endpoint: string,
    body: object,
    ignoreRetry = false,
  ): Promise<{ data: T }> {
    const requestPromise = fetch(`${baseApi}/${endpoint}`, {
      method: 'PATCH',
      headers: this.getHeaders(),
      body: this.supplementUpdateData(body),
    });
    const response = await requestPromise;
    return this.handleKnownErrors<T>(
      response,
      baseApi,
      endpoint,
      'PATCH',
      ignoreRetry,
      body,
    );
  }

  async delete<T>(
    baseApi: string,
    endpoint: string,
    ignoreRetry = false,
  ): Promise<{ data: T }> {
    const requestPromise = fetch(`${baseApi}/${endpoint}`, {
      method: 'DELETE',
      headers: this.getHeaders(),
    });
    const response = await requestPromise;
    return this.handleKnownErrors<T>(
      response,
      baseApi,
      endpoint,
      'DELETE',
      ignoreRetry,
    );
  }

  async handleKnownErrors<T>(
    response: Response,
    baseApi: string,
    endpoint: string,
    method: string,
    ignoreRetry = false,
    body?: object,
  ): Promise<{ data: T }> {
    if (response.status === 401 && ignoreRetry === false) {
      const token = localStorage.getItem('refreshToken');
      const refreshResponse = await this.post<RefreshToken[]>(
        TERN_USER_API_URL,
        'refresh_token',
        {
          token,
        },
      );

      if (
        refreshResponse.data[0].token &&
        refreshResponse.data[0].refresh_token
      ) {
        const {
          data: [{ token: newToken, refresh_token: newRefreshToken }],
        } = refreshResponse;
        localStorage.setItem('token', newToken);
        localStorage.setItem('refreshToken', newRefreshToken);

        let originalRequest;
        // Retry the original request
        switch (method) {
          case 'POST':
            originalRequest = await this.post<T>(
              baseApi,
              endpoint,
              body || {},
              ignoreRetry,
            );
            break;
          case 'PUT':
            originalRequest = await this.put<T>(
              baseApi,
              endpoint,
              body || {},
              ignoreRetry,
            );
            break;
          case 'PATCH':
            originalRequest = await this.patch<T>(
              baseApi,
              endpoint,
              body || {},
              ignoreRetry,
            );
            break;
          case 'DELETE':
            originalRequest = await this.delete<T>(
              baseApi,
              endpoint,
              ignoreRetry,
            );
            break;
          case 'GET':
          default:
            originalRequest = await this.get<T>(baseApi, endpoint, ignoreRetry);
            break;
        }
        return originalRequest;
      }
    }
    if (![200, 201, 202, 203, 204].includes(response.status)) {
      const errResp = await response.json();
      datadogLogs.logger.error('TernFetchAPI.handleKnownErrors', {
        body: errResp.error?.message || errResp,
      });
      throw new Error(errResp.error?.message || errResp);
    }
    return response.json();
  }
}

export default new TernFetchAPI();
