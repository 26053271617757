/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

export default function Button(props) {
  const {
    type,
    id,
    className,
    ariaLabel,
    children,
    onClick,
    disabled,
    ...otherProps
  } = props;
  return (
    <button
      className={`flex items-center justify-center ${className}`}
      type={type}
      id={id}
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  id: '',
  className: '',
  ariaLabel: '',
  children: [],
  onClick: null,
  disabled: false,
};
