import React, { ReactElement } from 'react';
import { useNavigate, useParams, useMatch, Outlet } from 'react-router-dom';
import Button from '../../../components/Button';
import { useGetUserAccounts } from '../../../api/rq/queries/userQueries';
import { isBusinessAdmin } from '../../../utils/authUtils';

export function UserDetail(): ReactElement {
  const navigate = useNavigate();
  const { userId: userIdQueryParam, accountId: accountIdQueryParam } =
    useParams();
  const hasBusinessAdminRole = isBusinessAdmin();

  /**
   * Queries for fetching data
   */

  const { data: userAccountsData, isLoading: userAccountsLoading } =
    useGetUserAccounts(
      parseInt(userIdQueryParam || '0'),
      !!accountIdQueryParam,
    );
  const defaultAccount = userAccountsData ? userAccountsData[0] : null;

  const infoMatch = useMatch(`dashboard/users/${userIdQueryParam}/info`);

  const cardsMatch = useMatch(
    `dashboard/users/${userIdQueryParam}/accounts/${
      accountIdQueryParam || defaultAccount?.id
    }/cards`,
  );

  const transactionsMatch = useMatch(
    `dashboard/users/${userIdQueryParam}/accounts/${
      accountIdQueryParam || defaultAccount?.id
    }`,
  );

  const userBusinessDepositsMatch = useMatch(
    `dashboard/users/${userIdQueryParam}/accounts/${
      accountIdQueryParam || defaultAccount?.id
    }/deposits`,
  );

  return (
    <>
      <div className="mb-8 flex items-center gap-4">
        <Button
          id="user-info"
          disabled={userAccountsLoading}
          onClick={() => {
            navigate(`/dashboard/users/${userIdQueryParam}/info`);
          }}
          className={`rounded-full border px-4 py-2 transition-all duration-300 ${
            infoMatch
              ? 'border-blue-300 bg-blue-200 text-sm font-semibold'
              : 'border-transparent text-sm text-surface-primary-dark hover:bg-blue-100 hover:text-surface-primary-light'
          }`}
        >
          User <span className="hidden sm:inline">&nbsp;Info</span>
        </Button>
        <Button
          id="user-card-details"
          disabled={userAccountsLoading}
          onClick={() =>
            navigate(
              `/dashboard/users/${userIdQueryParam}/accounts/${
                accountIdQueryParam || defaultAccount?.id
              }/cards`,
            )
          }
          className={`rounded-full border px-4 py-2 transition-all duration-300 ${
            cardsMatch
              ? 'border-blue-300 bg-blue-200 text-sm font-semibold'
              : 'border-transparent text-sm text-surface-primary-dark hover:bg-blue-100 hover:text-surface-primary-light'
          }`}
        >
          Card<span className="sm:hidden">s</span>
          <span className="hidden sm:inline">&nbsp;Details</span>
        </Button>
        {!hasBusinessAdminRole && (
          <Button
            id="transactions"
            disabled={userAccountsLoading}
            onClick={() =>
              navigate(
                `/dashboard/users/${userIdQueryParam}/accounts/${
                  accountIdQueryParam || defaultAccount?.id
                }`,
              )
            }
            className={`rounded-full border px-4 py-2 transition-all duration-300 ${
              transactionsMatch
                ? 'border-blue-300 bg-blue-200 text-sm font-semibold'
                : 'border-transparent text-sm text-surface-primary-dark hover:bg-blue-100 hover:text-surface-primary-light'
            }`}
          >
            <span className="inline sm:hidden">Activity</span>
            <span className="hidden sm:inline">Transactions</span>
          </Button>
        )}
        {hasBusinessAdminRole && (
          <Button
            id="deposits"
            disabled={userAccountsLoading}
            onClick={() =>
              navigate(
                `/dashboard/users/${userIdQueryParam}/accounts/${
                  accountIdQueryParam || defaultAccount?.id
                }/deposits`,
              )
            }
            className={`rounded-full border px-4 py-2 transition-all duration-300 ${
              userBusinessDepositsMatch
                ? 'border-blue-300 bg-blue-200 text-sm font-semibold'
                : 'border-transparent text-sm text-surface-primary-dark hover:bg-blue-100 hover:text-surface-primary-light'
            }`}
          >
            Deposits
          </Button>
        )}
      </div>
      <Outlet />
    </>
  );
}
