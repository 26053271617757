import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { Modal, Upload } from 'antd';
import type { UploadProps } from 'antd';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { Data } from 'react-csv/lib/core';
import { csvToJson, jsonToCsv } from '../../../utils/stringUtils';
import { ReactComponent as UploadIcon } from '../../../assets/document-upload.svg';
import { BulkFileProcessing } from '../../BulkActionHelpers/BulkFileProcessing';
import { BulkAllRecordsSuccess } from '../../BulkActionHelpers/BulkAllRecordsSuccess';
import { BulkAllRecordsFailed } from '../../BulkActionHelpers/BulkAllRecordsFailed';
import { BulkRecordsAttempted } from '../../BulkActionHelpers/BulkRecordsAttempted';
import { useBulkLoadFundsToUserAccount } from '../../../api/rq/mutations/cardMutations';
import { LoadFundsRequest } from '../../../types/cards/loadFundsRequest';
import { BulkLoadFundsReponse } from '../../../types/cards/bulkLoadFundsResponse';
import { useGetBulkTemplateData } from '../../../api/rq/queries/cardQueries';
import LoadingSpinner from '../../LoadingSpinner';

const { Dragger } = Upload;

/**
 * AntD uploader props and action definitions
 */
const props: UploadProps = {
  name: 'file',
  multiple: false,
  accept: '.csv',
  style: { border: 'none' },
  className:
    'flex items-center justify-center rounded-lg border border-2 border-dashed border-surface-primary bg-blue-50 h-56',
};

/**
 * Modal for uploading a file for bulk funds transfers
 */
export function BulkAddFundsModal({
  isFilterModalOpen,
  setIsFilterModalOpen,
}: {
  isFilterModalOpen: boolean;
  setIsFilterModalOpen: Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [progress, setProgress] = useState<{ percent: number; status: string }>(
    {
      percent: 10,
      status: 'Validating your uploaded file.',
    },
  );
  const {
    data: updatedBalances,
    mutateAsync: mutateBulkLoadFunds,
    isLoading: isBulkLoadFundsLoading,
    isSuccess: isBulkLoadFundsSuccess,
    isError: isBulkLoadFundsError,
  } = useBulkLoadFundsToUserAccount();

  const {
    data: initTemplateData,
    isLoading: isInitTemplateDataLoading,
    isSuccess: isInitTemplateDataSuccess,
  } = useGetBulkTemplateData('loadFunds', isFilterModalOpen);

  const downloadReport = (records: BulkLoadFundsReponse) => {
    const afterActionReport =
      records
        ?.filter((crd: BulkLoadFundsReponse[0]) => crd.status === 'failed')
        .map((crd: BulkLoadFundsReponse[0]) => {
          /**
           * Create CSV file to download
           * remove any fields
           * we dont want the user to set
           */
          return {
            error: crd.message,
            ...crd.data,
          };
        }) || [];

    const file = new File(
      [jsonToCsv(afterActionReport)],
      'failed_load_funds_request_report.csv',
      {
        type: 'text/csv',
      },
    );
    const csvFileUrl = URL.createObjectURL(file);

    window.location.assign(csvFileUrl);
    URL.revokeObjectURL(csvFileUrl);
  };

  useEffect(() => {
    /**
     * Request has not started but file is uploaded
     */
    if (!isBulkLoadFundsLoading && !isBulkLoadFundsSuccess)
      setProgress({
        percent: 27,
        status: 'Creating bulk loading funds request.',
      });

    /**
     * API request is started and running
     */
    if (isBulkLoadFundsLoading)
      setTimeout(
        () =>
          setProgress({
            percent: 52,
            status: 'Importing transfers from your file into the system.',
          }),
        2000,
      );

    /**
     * API request has succeeded or failed
     */
    if (isBulkLoadFundsSuccess || isBulkLoadFundsError) {
      setProgress({
        percent: 92,
        status: 'Loading funds is complete. Generating summary.',
      });
    }
  }, [
    isBulkLoadFundsLoading,
    isBulkLoadFundsSuccess,
    isBulkLoadFundsError,
    setProgress,
  ]);

  return (
    <Modal
      open={isFilterModalOpen}
      onCancel={() => {
        setCurrentStep(1);
        setProgress({ percent: 0, status: '' });
        setIsFilterModalOpen(false);
      }}
      footer=""
      width={406}
    >
      {currentStep === 1 && (
        <>
          <h2 className="mb-6 text-xl font-medium">Add Funds</h2>
          <div className="p-6 pb-0">
            <Dragger
              fileList={[]}
              {...props}
              beforeUpload={(file) => {
                // run some validation checks?
                if (file) {
                  csvToJson(file, async (err, content) => {
                    setProgress({
                      percent: 15,
                      status: 'Upload file validated.',
                    });
                    /**
                     * Add user specific metadata before request
                     */
                    const normalizedFunds: LoadFundsRequest[] =
                      content?.map(
                        (item: LoadFundsRequest): LoadFundsRequest => ({
                          type: item.type,
                          business_account_id: item.business_account_id,
                          card_token: item.card_token,
                          /**
                           * API takes cents, not dollars
                           */
                          amount: item.amount * 100,
                        }),
                      ) || [];

                    /**
                     * Set user at step 2 - file processing loading screen
                     */
                    setCurrentStep(2);

                    /**
                     * Try the bulk action and send user to correct step
                     * based on response
                     */
                    try {
                      const addedCards = await mutateBulkLoadFunds(
                        normalizedFunds,
                      );

                      const allPasses = addedCards?.filter(
                        (crd) => crd.status === 'success',
                      );
                      const allFailed = addedCards?.filter(
                        (crd) => crd.status === 'failed',
                      );

                      /**
                       * Some failed, some passed => records attemped screen
                       */
                      if (allPasses?.length && allFailed?.length)
                        setTimeout(() => setCurrentStep(3), 2000);

                      /**
                       * No fails and no partial fails => records success screen
                       */
                      if (allPasses?.length && !allFailed?.length)
                        setTimeout(() => setCurrentStep(4), 2000);

                      /**
                       * No users (api call timeout) or all users failed =>
                       * records failed screen
                       */
                      if (
                        addedCards !== undefined &&
                        allFailed?.length &&
                        !allPasses?.length
                      )
                        setTimeout(() => setCurrentStep(5), 2000);
                    } catch (error) {
                      setCurrentStep(5);
                    }
                  });
                }

                return false;
              }}
            >
              <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-surface-icon-bg-light">
                <UploadIcon />
              </div>
              <p className="mt-6 text-center text-base font-semibold text-surface-primary hover:text-surface-primary-light">
                Click here or drag file to upload
              </p>
            </Dragger>
            <p className="mb-6 mt-4 text-sm text-text-gray">
              Supported formats: .csv
            </p>
            <div className="flex justify-center">
              {isInitTemplateDataSuccess && (
                <CSVLink
                  data={initTemplateData as Data}
                  filename={`tern_bulk_load_funds_template_${dayjs().format(
                    'MM-DD-YYYY',
                  )}`}
                  className="mb-6 flex flex-col justify-center text-center text-base font-semibold tracking-wide text-surface-primary transition duration-300 ease-in-out hover:text-surface-primary-light"
                  target="_blank"
                >
                  Download Add Funds Template{' '}
                  <small className="w-full text-xs text-text-light-gray">
                    template is pre-populated with your active users
                  </small>
                </CSVLink>
              )}
              {isInitTemplateDataLoading && (
                <div className="flex w-full items-center rounded-lg bg-surface-icon-bg-light px-3 py-2">
                  <p className="mr-auto text-surface-primary-dark">
                    Gathering your template data...
                  </p>
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/**
       * Process bulk cards file
       */}
      {currentStep === 2 && (
        <div className="pb-6">
          <BulkFileProcessing
            percent={progress.percent}
            status={progress.status}
          />
        </div>
      )}

      {/**
       * Some card requests have failed and some card requests have succeeded
       */}
      {currentStep === 3 && (
        <BulkRecordsAttempted
          records={updatedBalances || []}
          isLoading={isBulkLoadFundsLoading}
          downloadAction={downloadReport}
          onCancel={() => {
            setCurrentStep(1);
            setIsFilterModalOpen(false);
          }}
        />
      )}

      {/**
       * All card requests have succeeded
       */}
      {currentStep === 4 && (
        <BulkAllRecordsSuccess
          records={updatedBalances || []}
          isLoading={isBulkLoadFundsLoading}
          onCancel={() => {
            setCurrentStep(1);
            setIsFilterModalOpen(false);
          }}
        />
      )}

      {/**
       * All card requests have failed
       */}
      {currentStep === 5 && (
        <BulkAllRecordsFailed
          records={updatedBalances || []}
          isLoading={isBulkLoadFundsLoading}
          downloadAction={downloadReport}
          onCancel={() => {
            setCurrentStep(1);
            setIsFilterModalOpen(false);
          }}
        />
      )}
    </Modal>
  );
}

export default BulkAddFundsModal;
