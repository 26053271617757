import React, { ReactElement } from 'react';
import { ReactComponent as X } from '../../assets/x.svg';
import { useAppStore } from '../../zustand/store';

export function ErrorNotification({
  message,
}: {
  message: string;
}): ReactElement {
  const setNotification = useAppStore((state) => state.setNotification);
  return (
    <div className="mb-4 flex items-center justify-between rounded-lg bg-red-200 p-2 text-text-error-red">
      <div className="flex items-center">
        <div className="m-2 flex h-6 w-6 items-center justify-center rounded-full bg-surface-error-red font-semibold text-white">
          X
        </div>
        <p>{message}</p>
      </div>
      <X className="m-2 cursor-pointer" onClick={() => setNotification(null)} />
    </div>
  );
}
