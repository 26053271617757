import { Modal, DatePicker } from 'antd';
import React, { ReactElement, useState } from 'react';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as DollarSignGray } from '../../../assets/dollarsign-gray.svg';
import { BusinessAccountWireFormValues } from '../../../types/transactions/businessAccountWireFormValues';
import LoadingSpinner from '../../LoadingSpinner';
import { isMTU } from '../../../utils/authUtils';

type BusinessWireCreationModalProps = {
  isModalOpen: boolean;
  onConfirm: (data: BusinessAccountWireFormValues) => Promise<void>;
  setIsModalOpen: (val: boolean) => void;
};

export function BusinessWireCreationModal({
  onConfirm,
  isModalOpen,
  setIsModalOpen,
}: BusinessWireCreationModalProps): ReactElement {
  /**
   * Local component state
   */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const isMtuUser = isMTU();
  /**
   * react-hook-form instantiation
   */
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<BusinessAccountWireFormValues>({
    defaultValues: {
      business_account_id: 0,
      track_id: '',
      post_at: '',
      comment: '',
      amount: 0,
      is_load_directly: false,
    },
  });

  const clearModal = (): void => {
    setError('');
    reset();
  };

  const handleConfirm = async (
    data: BusinessAccountWireFormValues,
  ): Promise<void> => {
    setIsLoading(true);
    try {
      if (data) {
        console.log(data);
        await onConfirm(data);
        clearModal();
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      afterClose={clearModal}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={400}
      footer=""
      style={{ transition: 'width 0.3s ease-in' }}
    >
      <form
        className="grid auto-rows-max gap-y-5"
        onSubmit={handleSubmit((data) => handleConfirm(data))}
      >
        <div className="mb-4 flex flex-col">
          <h3 className="pb-2 text-xl font-semibold">Add Wire Record</h3>
          <span className="text-text-error-red">{error || ''}</span>
          <div className="flex">
            <span className="mt-[9px]">
              <DollarSignGray />
            </span>
            <h4 className="ml-1 flex pt-2 font-semibold">Wire Amount</h4>
          </div>{' '}
          <div className="w-full pt-2">
            <label htmlFor="amount">
              <div className="pb-1 text-xs font-semibold">
                Amount{' '}
                <span className="text-text-error-red">
                  *{' '}
                  <span className="font-normal">
                    {errors.amount?.message || ''}
                  </span>
                </span>
              </div>
              <div className="flex items-center justify-between rounded-lg border border-zinc-200 px-2">
                <span className="text-text-light-gray">$</span>
                <input
                  id="amount"
                  className="my-1 w-full border-none focus:outline-none focus:ring-0"
                  placeholder="10000"
                  type="number"
                  pattern="^\d*(\.\d{0,2})?$"
                  step=".01"
                  {...register('amount', {
                    required: 'Amount is Required',
                    min: {
                      value: 1,
                      message: 'Minimum amount is $1',
                    },
                  })}
                />
                <span className="text-text-light-gray">USD</span>
              </div>
            </label>
          </div>
          <div className="w-full pt-2">
            <label htmlFor="track_id">
              <div className="pb-1 text-xs font-semibold">
                Track ID{' '}
                <span className="text-text-error-red">
                  *{' '}
                  <span className="font-normal">
                    {errors.track_id?.message || ''}
                  </span>
                </span>
              </div>
              <div className="flex items-center justify-between rounded-lg border border-zinc-200 px-2">
                <input
                  id="track_id"
                  className="my-1 w-full border-none focus:outline-none focus:ring-0"
                  placeholder=""
                  type="text"
                  {...register('track_id', {
                    required: 'Track ID is Required',
                  })}
                />
              </div>
            </label>
          </div>
          <div className="pt-2">
            <div className="text-xs font-semibold">
              Post Time{' '}
              <span className="text-text-error-red">
                *{' '}
                <span className="font-normal">
                  {errors.post_at?.message || ''}
                </span>
              </span>
            </div>
            <Controller
              name="post_at"
              control={control}
              rules={{
                required: 'Post Time is Required',
              }}
              render={({ field, fieldState }) => {
                return (
                  <DatePicker
                    id="post-date-incorporation"
                    size="large"
                    placeholder="Select a date"
                    {...register('post_at', {
                      required: 'Post Time is Required',
                    })}
                    status={fieldState.error ? 'error' : undefined}
                    ref={field.ref}
                    name={field.name}
                    // eslint-disable-next-line
                    className="wire-post-date"
                    onBlur={field.onBlur}
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      field.onChange(date ? dayjs(date).toISOString() : '');
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="w-full pt-2">
            <label htmlFor="comment">
              <div className="pb-1 text-xs font-semibold">Comment</div>
              <div className="flex items-center justify-between rounded-lg border border-zinc-200 px-2">
                <textarea
                  id="comment"
                  {...register('comment')}
                  className="my-1 w-full border-none focus:outline-none focus:ring-0"
                  placeholder=""
                />
              </div>
            </label>
          </div>
          {isMtuUser && (
            <div className="w-full pt-2">
              <div className="flex items-center rounded-lg border-none">
                <input
                  id="is_load_directly"
                  {...register('is_load_directly')}
                  value="true"
                  type="checkbox"
                  className="my-1 mr-2 border border-zinc-200 focus:outline-none focus:ring-0"
                />
                Load Wire Directly
              </div>
              <p className="text-red-600">
                By selecting this option, you certify this wire amount and
                tracing number are accurate.
              </p>
            </div>
          )}
        </div>
        <footer className="flex py-2">
          <button
            className="flex h-10 w-full items-center justify-center rounded-full border-border-primary bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark "
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <LoadingSpinner /> : 'Save'}
          </button>
        </footer>
      </form>
    </Modal>
  );
}
