import React, { ReactElement, useEffect } from 'react';
import {
  Await,
  defer,
  useRouteLoaderData,
  useNavigate,
  useParams,
  useMatch,
  Outlet,
} from 'react-router-dom';
import { Skeleton } from 'antd';
import { ExpandedBusinessDTO } from '../../../types/business/expandedBusinessDTO';
import Button from '../../../components/Button';
import { getCountries } from '../../../api/DashboardApi';
import { CountryDTO } from '../../../types/business/countryDTO';
import { BusinessAccountDTO } from '../../../types/business/businessAccountDTO';
import { getBusiness, getBusinessAccounts } from '../../../api/UsersApi';

/**
 * @TODO  replace the split URL code with something better...
 */
export const businessDetailLoader = async ({
  request,
}: {
  request: { url: string };
}) => {
  const url = new URL(request.url);
  const splitUrl = url.pathname.split('/');
  let businessId = '';
  if (splitUrl.length === 6) {
    businessId = splitUrl[splitUrl.length - 3];
  } else if (splitUrl.length === 5) {
    businessId = splitUrl[splitUrl.length - 2];
  }
  const business = getBusiness(businessId);
  const accounts = getBusinessAccounts(businessId);
  const countries = getCountries();
  return defer({ business, countries, accounts });
};
export function BusinessDetail(): ReactElement {
  const navigate = useNavigate();
  const { businessId } = useParams();

  const infoMatch = useMatch(`dashboard/businesses/${businessId}/info`);
  const transactionsMatch = useMatch(
    `dashboard/businesses/${businessId}/accounts/*`,
  );
  const wiresMatch = useMatch(
    `dashboard/businesses/${businessId}/wire-history/*`,
  );

  const businessLoaderData = useRouteLoaderData('businessDetail') as {
    business: ExpandedBusinessDTO;
    countries: CountryDTO[];
    accounts: BusinessAccountDTO[];
  };

  const refValue = React.useRef<number | null>(null);

  useEffect(() => {
    (async () => {
      const accts = await getBusinessAccounts(businessId?.toString() || '0');
      /**
       * Update state without re-rendering component, which
       * would flash a loading screen for a split second
       * and is not good UX.
       */
      if (accts?.length > 0) {
        refValue.current = accts[0].id;
      }
    })();
  }, [businessId]);

  return (
    <>
      <div className="mb-8 flex items-center gap-4">
        <Button
          onClick={() => {
            navigate(`/dashboard/businesses/${businessId}/info`);
          }}
          className={`cursor-pointer rounded-full border px-4 py-2 transition-all duration-300 ${
            infoMatch
              ? 'border-blue-300 bg-blue-200 text-sm font-semibold'
              : 'border-transparent text-sm text-surface-primary-dark hover:bg-blue-100 hover:text-surface-primary-light'
          }`}
        >
          Business Info
        </Button>
        <Button
          onClick={() => {
            navigate(
              `/dashboard/businesses/${businessId}/accounts/${refValue.current}`,
            );
          }}
          className={`cursor-pointer rounded-full border px-4 py-2 transition-all duration-300 ${
            transactionsMatch
              ? 'border-blue-300 bg-blue-200 text-sm font-semibold'
              : 'border-transparent text-sm text-surface-primary-dark hover:bg-blue-100 hover:text-surface-primary-light'
          }`}
        >
          Transactions
        </Button>
        <Button
          onClick={() => {
            navigate(
              `/dashboard/businesses/${businessId}/wire-history/${refValue.current}`,
            );
          }}
          className={`cursor-pointer rounded-full border px-4 py-2 transition-all duration-300 ${
            wiresMatch
              ? 'border-blue-300 bg-blue-200 text-sm font-semibold'
              : 'border-transparent text-sm text-surface-primary-dark hover:bg-blue-100 hover:text-surface-primary-light'
          }`}
        >
          Wires
        </Button>
      </div>
      <React.Suspense fallback={<Skeleton active />}>
        <Await
          resolve={Promise.all([
            businessLoaderData.business,
            businessLoaderData.countries,
            businessLoaderData.accounts,
          ])}
        >
          {([business, countries, accounts]) => (
            <Outlet context={{ business, countries, accounts }} />
          )}
        </Await>
      </React.Suspense>
    </>
  );
}
