import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  addCardToUserAccount,
  assignCardToUserAccount,
  bulkAddCardsToUserAccounts,
  bulkAssignCardsToUserAccounts,
  bulkLoadFundsToUserAccount,
  replaceCardByIdentifier,
  updateCardStatus,
} from '../../CardApi';
import { CardModel } from '../../../types/cards/cardModel';
import { AddCardRequest } from '../../../types/cards/addCardRequest';
import { AddCardResponse } from '../../../types/cards/addCardResponse';
import { AssignCardResponse } from '../../../types/cards/assignCardResponse';
import { AssignCardRequest } from '../../../types/cards/assignCardRequest';
import { ReplaceCardRequest } from '../../../types/cards/replaceCardRequest';
import { ReplaceCardResponse } from '../../../types/cards/replaceCardResponse';
import { BulkAssignCardResponse } from '../../../types/cards/bulkAssignCardResponse';
import { BulkAddCardResponse } from '../../../types/cards/bulkAddCardResponse';
import { BulkLoadFundsReponse } from '../../../types/cards/bulkLoadFundsResponse';
import { LoadFundsRequest } from '../../../types/cards/loadFundsRequest';

/**
 * React Query Mutations are used to update data on the server,
 * and then update the cache with the new data.
 *
 * This allows invalidating certain queries that become
 * stale after the create/update requests succeed.
 *
 * @docs https://tanstack.com/query/v4/docs/react/guides/mutations
 *
 */

export const useUpdateCardStatus = (): UseMutationResult<
  CardModel | null,
  unknown,
  {
    cardIdentifier: string;
    status: string;
  },
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    async ({
      cardIdentifier,
      status,
    }: {
      cardIdentifier: string;
      status: string;
    }) => updateCardStatus(cardIdentifier, status),
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: ['userAccounts'] });
        client.invalidateQueries({ queryKey: ['userAccountCards'] });
        client.invalidateQueries({ queryKey: ['userAccountCardTransactions'] });
        client.invalidateQueries({
          queryKey: ['cardHistory'],
        });
      },
    },
  );
};

export const useAssignCardToUserAccount = (): UseMutationResult<
  AssignCardResponse | null,
  unknown,
  AssignCardRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    async (body: AssignCardRequest) => assignCardToUserAccount(body),
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: ['userAccounts'] });
        client.invalidateQueries({ queryKey: ['userAccountCards'] });
        client.invalidateQueries({ queryKey: ['userAccountCardTransactions'] });
        client.invalidateQueries({
          queryKey: ['cardHistory'],
        });
      },
    },
  );
};

export const useBulkAssignCardsToUserAccounts = (): UseMutationResult<
  BulkAssignCardResponse | null,
  unknown,
  AssignCardRequest[],
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    async (body: AssignCardRequest[]) => bulkAssignCardsToUserAccounts(body),
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: ['userAccounts'] });
        client.invalidateQueries({ queryKey: ['userAccountCards'] });
        client.invalidateQueries({ queryKey: ['userAccountCardTransactions'] });
        client.invalidateQueries({
          queryKey: ['cardHistory'],
        });
      },
    },
  );
};

export const useAddCardToUserAccount = (): UseMutationResult<
  AddCardResponse | null,
  unknown,
  AddCardRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    async (body: AddCardRequest) => addCardToUserAccount(body),
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: ['userAccounts'] });
        client.invalidateQueries({ queryKey: ['userAccountCards'] });
        client.invalidateQueries({ queryKey: ['userAccountCardTransactions'] });
        client.invalidateQueries({
          queryKey: ['cardHistory'],
        });
      },
    },
  );
};

export const useBulkAddCardToUserAccount = (): UseMutationResult<
  BulkAddCardResponse | null,
  unknown,
  AddCardRequest[],
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    async (body: AddCardRequest[]) => bulkAddCardsToUserAccounts(body),
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: ['userAccounts'] });
        client.invalidateQueries({ queryKey: ['userAccountCards'] });
        client.invalidateQueries({ queryKey: ['userAccountCardTransactions'] });
        client.invalidateQueries({
          queryKey: ['cardHistory'],
        });
      },
    },
  );
};

export const useReplaceCardByIdentifier = (): UseMutationResult<
  ReplaceCardResponse | null,
  unknown,
  {
    cardIdentifier: string;
    body: ReplaceCardRequest;
  },
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    async ({
      cardIdentifier,
      body,
    }: {
      cardIdentifier: string;
      body: ReplaceCardRequest;
    }) => replaceCardByIdentifier(cardIdentifier, body),
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: ['userAccounts'] });
        client.invalidateQueries({ queryKey: ['userAccountCards'] });
        client.invalidateQueries({ queryKey: ['userAccountCardTransactions'] });
        client.invalidateQueries({
          queryKey: ['cardHistory'],
        });
      },
    },
  );
};

export const useBulkLoadFundsToUserAccount = (): UseMutationResult<
  BulkLoadFundsReponse | null,
  unknown,
  LoadFundsRequest[],
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    async (body: LoadFundsRequest[]) => bulkLoadFundsToUserAccount(body),
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: ['userAccountTransactions'] });
        client.invalidateQueries({ queryKey: ['userAccounts'] });
      },
    },
  );
};
