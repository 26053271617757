import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Select, notification } from 'antd';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { BusinessAccountDTO } from '../../../types/business/businessAccountDTO';
import { AvailableBalanceCard } from '../../../components/AvailableBalanceCard';
import { StatusCard } from '../../../components/StatusCard';
import { tableStatusCell } from '../../../components/TableStatusCell';
import { useAppStore } from '../../../zustand/store';
import {
  getBusinessAccountWires,
  createBusinessWire,
  updateBusinessWire,
  getBusinessAccounts,
} from '../../../api/UsersApi';
import { BusinessAccountWireDTO } from '../../../types/transactions/businessAccountWireDTO';
import { Wires } from '../../../components/Table/Wires';
import { AccountSelectItem } from '../../../components/AccountSelectItem';
import { isAdminOrMTU, isMTU } from '../../../utils/authUtils';
import { CsvLinkContainer } from '../../../components/CsvLinkContainer';
import { useGetBusiness } from '../../../api/rq/queries/businessQueries';
import { ExportModal } from '../../../components/Modals/ExportModal';
import { getDollarAmount } from '../../../utils/accountUtils';
import { WireSearchQueryParams } from '../../../types/transactions/wireSearchQueryParams';
import { BusinessWireCreationModal } from '../../../components/Modals/BusinessWireCreationModal';
import { BusinessAccountWireFormValues } from '../../../types/transactions/businessAccountWireFormValues';
import { CreateBusinessWireRequestDTO } from '../../../types/transactions/createBusinessWireRequestDTO';
import { UpdateBusinessWireModal } from '../../../components/Modals/UpdateBusinessWireModal';
import { UpdateBusinessWireRequestDTO } from '../../../types/transactions/updateBusinessWireRequestDTO';
import { BusinessWireFiltersModal } from '../../../components/Modals/BusinessWireFiltersModal';

export function BusinessWires(): ReactElement {
  /**
   * Local State
   */
  const [selectedAccount, setSelectedAccount] =
    useState<BusinessAccountDTO | null>(null);
  const [wireList, setWireList] = useState<BusinessAccountWireDTO[]>([]);
  const [wireTotal, setWireTotal] = useState<number>(0);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [isLoadingWires, setIsLoadingWires] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isCreateWireModalOpen, setIsCreateWireModalOpen] = useState(false);
  const [isActionConfirmationModalOpen, setIsActionConfirmationModalOpen] =
    useState(false);
  const [selectedBusinessWire, setSelectedBusinessWire] =
    useState<BusinessAccountWireDTO | null>(null);
  const [selectedActionType, setSelectedActionType] = useState<string | null>(
    null,
  );
  /**
   * Need to keep track of filters set/handled in the \
   * BusinessTransactionsFiltersModal so they can be applied to PDF generation
   */
  const [filters, setFilters] = useState<WireSearchQueryParams>({
    status: null,
    trackId: null,
    amount: {
      min: '',
      max: '',
    },
  });

  const setNotification = useAppStore((state) => state.setNotification);
  /**
   * Noficiations
   */
  const [notify, notificationContext] = notification.useNotification();

  const rowActions = [
    {
      value: 'cancel',
      label: 'Cancel',
      disabled: false,
    },
  ];
  const rowRefundActions = [
    {
      value: 'refund',
      label: 'Refund',
      disabled: false,
    },
  ];
  if (isMTU())
    rowActions.push(...[{ value: 'load', label: 'Load', disabled: false }]);

  const handleBusinessWireAction = (
    value: string,
    info: CellContext<BusinessAccountWireDTO, unknown>,
  ) => {
    setSelectedActionType(value);
    setIsActionConfirmationModalOpen(true);
    setSelectedBusinessWire(info.row.original);
  };

  /**
   * CSV ref
   */
  const csvRef = React.useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const hasAdminRole = isAdminOrMTU();

  const { accounts } = useOutletContext<{
    accounts: BusinessAccountDTO[];
  }>();

  const { accountId, businessId } = useParams();

  const navigate = useNavigate();

  /**
   * RQ Queries for fetching data
   */

  const { data: businessData } = useGetBusiness(parseInt(businessId || '0'));
  const updateBalance = () => {
    if (businessId) {
      getBusinessAccounts(businessId).then((res) => {
        const selectedAcc =
          res.find((acc) => acc.id === parseInt(accountId || '0')) || res[0];
        setSelectedAccount(selectedAcc);
      });
    }
  };
  const getBusinessWireList = async (pageIndex: number, pageSize: number) => {
    const index = pageIndex + 1;
    let queryString = `?pageSize=${pageSize}&page=${index}&orderBy=created_at&order=desc`;
    if (filters.amount && filters.amount?.min) {
      queryString += `&amountMin=${filters.amount.min}`;
    }
    if (filters.amount && filters.amount?.max) {
      queryString += `&amountMax=${filters.amount.max}`;
    }
    if (filters.trackId) {
      queryString += `&trackId=${filters.trackId}`;
    }
    if (filters.status) {
      queryString += `&status=${filters.status}`;
    }

    setIsLoadingWires(true);
    try {
      /**
       * acountId can be 'null' string due to it coming from the query params
       */
      if (accountId && accountId !== 'null') {
        // const selectedAcc =
        //   accounts.find((acc) => acc.id === parseInt(accountId || '0')) ||
        //   accounts[0];
        // setSelectedAccount(selectedAcc);
        const trxs = await getBusinessAccountWires(
          parseInt(accountId || '0') || accounts[0].id,
          queryString,
        );
        setWireTotal(trxs.total);
        const trxNoBadStatuses = trxs.data.filter((trx) => {
          return !['PAYOUT_CANCELLED'].includes(trx.status);
        });
        setWireList(trxNoBadStatuses);
      } else {
        setWireList([]);
      }
    } catch (err) {
      setHasError(true);
    }
    setIsLoadingWires(false);
  };
  useEffect(
    () => {
      getBusinessWireList(currentPageIndex, currentPageSize);
      setNotification(null);
      updateBalance();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNotification, filters],
  );

  const selectAccount = async (acctId: number) => {
    navigate(`/dashboard/businesses/${businessId}/accounts/${acctId}`, {
      replace: false,
    });
  };

  const getBalance = (info: CellContext<BusinessAccountWireDTO, number>) => {
    const row = info.row.original;
    const { amount } = row;
    return `$${amount.toFixed(2).toLocaleString()}`;
  };

  const formatAvailableBalance = useCallback(
    (info: CellContext<BusinessAccountWireDTO, number>): ReactElement => {
      const balance = getBalance(info);
      const [dollars, cents] = balance.split('.');
      return (
        <div className="flex items-baseline">
          <span className="font-semibold">{dollars}</span>
          <span className="text-xs text-text-gray">
            {cents ? `.${cents}` : ''}
          </span>
        </div>
      );
    },
    [],
  );

  const selectDropdownCell = (
    info: CellContext<BusinessAccountWireDTO, unknown>,
  ): ReactElement => {
    if (info.row.original.status === 'Approved' && isMTU()) {
      return (
        <Select
          defaultValue="Select"
          style={{ width: 150 }}
          onClick={(e) => e.stopPropagation()}
          onChange={(val) => handleBusinessWireAction(val, info)}
          options={rowRefundActions}
        />
      );
    }
    if (info.row.original.status !== 'Pending') {
      return <p> </p>;
    }
    return (
      <Select
        defaultValue="Select"
        style={{ width: 150 }}
        onClick={(e) => e.stopPropagation()}
        onChange={(val) => handleBusinessWireAction(val, info)}
        options={rowActions}
      />
    );
  };
  const columnHelper = createColumnHelper<
    BusinessAccountWireDTO & { select?: string }
  >();

  const transactionColumns = useMemo(
    () => [
      columnHelper.accessor('created_at', {
        header: 'Create DATE',
        cell: (info) => dayjs(info.getValue()).format('MMM DD, YYYY'),
      }),
      columnHelper.accessor('post_at', {
        cell: (info) => dayjs(info.getValue()).format('MMM DD, YYYY'),
        header: 'Post At',
      }),
      columnHelper.accessor('load_at', {
        cell: (info) =>
          info.getValue() ? dayjs(info.getValue()).format('MMM DD, YYYY') : '',
        header: 'Load At',
      }),
      columnHelper.accessor('amount', {
        header: 'AMOUNT',
        cell: (info) => formatAvailableBalance(info),
      }),
      columnHelper.accessor('track_id', {
        header: 'Track ID',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('status', {
        header: 'STATUS',
        cell: (info) => tableStatusCell<BusinessAccountWireDTO>(info),
      }),
      columnHelper.accessor('select', {
        header: 'Actions',
        cell: (info) => selectDropdownCell(info),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnHelper, formatAvailableBalance],
  );

  const updateBusinessWireAction = async () => {
    try {
      if (selectedBusinessWire) {
        const body: UpdateBusinessWireRequestDTO = {
          type: selectedActionType ?? '',
        };
        const resultMessage = await updateBusinessWire(
          selectedBusinessWire.id,
          body,
        );
        setIsActionConfirmationModalOpen(false);
        setNotification({
          message: resultMessage || `Business Wire ${selectedActionType}`,
          type: 'success',
        });
        getBusinessWireList(currentPageIndex, currentPageSize);
        updateBalance();
      }
    } catch (err) {
      setIsActionConfirmationModalOpen(false);
      setNotification({
        type: 'error',
        message: `There was an error when ${selectedActionType} the business wire: ${err.message}. Please try again.`,
      });
    }
  };

  /**
   * This has some duplicate code similar to other PDF export functions
   * in other Transaction/Deposits files. This is because the functionality
   * is similar, but has a lot of unique data. This could be abstracted,
   * however it would need to take a lot of input parameters, and the added
   * complexity doesn't seem worth it at this time.
   *
   * @TODO consider refactoring this to a shared function somewhere
   */
  const exportToPdf = () => {
    /**
     * Gernate JSON data that is compatible with the jsPDF
     * autotable library being used. This is a array of string arrays, with
     * each individual string array representing a row in the table.
     */
    const pdfCompatibleData: string[][] =
      wireList
        ?.map((trx) => ({
          date: dayjs(trx.created_at).format('MMM DD, YYYY'),
          postAt: dayjs(trx.post_at).format('MMM DD, YYYY'),
          loadAt: trx.load_at ? dayjs(trx.load_at).format('MMM DD, YYYY') : '',
          trackId: trx.track_id,
          status: trx.status,
          amount: getDollarAmount(trx.amount),
        }))
        .map((trx) => Object.values(trx).map((val) => val?.toString() ?? '')) ??
      [];
    // eslint-disable-next-line new-cap
    const pdfDoc = new jsPDF();
    /**
     * Title
     */
    pdfDoc.setFontSize(18);
    pdfDoc.setFont('Inter');
    pdfDoc.text('BUSINESS', 200, 10, {
      align: 'right',
    });
    pdfDoc.text('Wires', 200, 17, {
      align: 'right',
    });
    /**
     * Sub-title
     */
    pdfDoc.setFontSize(10);
    pdfDoc.text(dayjs().format('MMMM DD, YYYY'), 200, 22, {
      align: 'right',
    });
    /**
     * Summary start
     */
    pdfDoc.setFontSize(10);
    pdfDoc.text('SUMMARY', 20, 40);
    /**
     * User's Name
     */
    pdfDoc.setFontSize(9);
    pdfDoc.setFont('Inter', 'normal');
    pdfDoc.text('Business Name', 20, 50);
    pdfDoc.setFont('Inter', 'bold');
    pdfDoc.text(businessData?.legal_business_name ?? '', 50, 50);
    pdfDoc.setFont('Inter', 'normal');
    /**
     * Balance as of date
     */
    pdfDoc.text(`Balance as of`, 20, 56);

    pdfDoc.text(dayjs().format('MMM, DD, YYYY'), 20, 60);
    pdfDoc.setFont('Inter', 'bold');
    const accountBalance = selectedAccount?.available_balance || 0;
    pdfDoc.text(
      `$${
        accountBalance
          ? (accountBalance / 100).toLocaleString('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
            })
          : '$0'
      }`,
      50,
      56,
    );
    /**
     * Filter criteria
     */
    pdfDoc.setFont('Inter', 'normal');

    pdfDoc.text('Filter Criteria', 20, 65);
    pdfDoc.setFont('Inter', 'bold');
    pdfDoc.text(
      `Amount: ${filters.amount?.min || ''} (Min), ${
        filters.amount?.max || ''
      } (Max)`,
      50,
      65,
    );
    pdfDoc.text(`Status: ${filters.status ?? 'All'}`, 50, 70);
    pdfDoc.text(`Track Id: ${filters.trackId ?? ''}`, 50, 70);

    autoTable(pdfDoc, {
      theme: 'plain',
      columnStyles: {
        0: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        1: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        2: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        3: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        4: {
          cellWidth: 'auto',
          fontSize: 8,
        },
      },
      head: [['DATE', 'POST AT', 'LOAD AT', 'TRACK ID', 'STATUS', 'AMOUNT']],
      body: pdfCompatibleData,
      margin: { top: 90 }, // Seting top margin for First Page.
      didDrawPage: (data) => {
        // Resetting top margin.
        // The change will be reflected only after print the first page.
        // eslint-disable-next-line no-param-reassign
        data.settings.margin.top = 20;
      },
    });
    pdfDoc.save(
      `${
        businessData?.legal_business_name ?? businessData?.dba_business_name
      }_account-${selectedAccount?.id}_wires.pdf`,
    );
  };

  const changePageSize = (size: number) => {
    if (size !== currentPageSize) {
      console.log('change page index: ', size);
      setCurrentPageSize(size);
      getBusinessWireList(currentPageIndex, size);
    }
  };

  const changePageIndex = (page: number) => {
    if (page !== currentPageIndex) {
      console.log('change page index: ', page);
      setCurrentPageIndex(page);
      getBusinessWireList(page, currentPageSize);
    }
  };

  const exportWires = (fileType: string) => {
    if (fileType.toLowerCase() === 'pdf') {
      exportToPdf();
    }
    if (fileType.toLowerCase() === 'csv') {
      csvRef.current?.link.click();
    }
    setIsExportModalOpen(false);
  };

  const createWire = async (
    data: BusinessAccountWireFormValues,
  ): Promise<void> => {
    if (data.amount && selectedAccount && selectedAccount.id) {
      const addWiresRequest: CreateBusinessWireRequestDTO = {
        amount: data.amount * 1,
        comment: data?.comment,
        track_id: data.track_id,
        business_account_id: selectedAccount?.id ?? 0,
        post_at: dayjs(data.post_at).format('YYYY-MM-DD HH:mm:ss'),
        isLoadDirectly: data.is_load_directly,
      };
      try {
        await createBusinessWire(addWiresRequest);
        setIsCreateWireModalOpen(false);
        notify.success({
          type: 'success',
          message: 'Success!',
          description: `Successfully added a wire record.`,
          placement: 'topLeft',
        });
        getBusinessWireList(0, currentPageSize);
        updateBalance();
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  };

  return (
    <>
      {hasAdminRole && (
        <CsvLinkContainer
          fileNameWithoutDate={`${businessData?.legal_business_name}_account-${accountId}_wires`}
          dataToExport={wireList || []}
          csvLinkRef={csvRef}
        />
      )}
      {notificationContext}
      <div className="pb-4">
        <div className="text-xs font-semibold">Business Account</div>
        <Select
          showSearch
          placeholder="Select an Account"
          size="large"
          className="my-1 w-full md:w-1/3"
          options={accounts.map((account) => ({
            value: account.id,
            label: <AccountSelectItem account={account} />,
          }))}
          onChange={selectAccount}
          value={selectedAccount?.id}
        />
      </div>
      <div className="grid gap-5">
        <div className="row-span-1 flex h-auto flex-col items-center justify-between gap-6 md:flex-row">
          <AvailableBalanceCard
            balance={selectedAccount?.available_balance || 0}
          />
          <StatusCard
            statusIdOrName={selectedAccount?.business_account_status_id || 0}
            title="Account Status"
          />
        </div>
        <Wires<BusinessAccountWireDTO>
          setIsFilterModalOpen={setIsFilterModalOpen}
          setIsExportModalOpen={setIsExportModalOpen}
          setIsCreateWireModalOpen={setIsCreateWireModalOpen}
          setPageIndex={changePageIndex}
          setPageSize={changePageSize}
          currentPageIndex={currentPageIndex}
          currentPageSize={currentPageSize}
          wireList={wireList}
          wireColumns={transactionColumns}
          isLoadingWires={isLoadingWires}
          hasError={hasError}
          totalWire={wireTotal}
        />
      </div>
      <UpdateBusinessWireModal
        type={selectedActionType ?? ''}
        businessWire={selectedBusinessWire ?? null}
        isModalOpen={isActionConfirmationModalOpen}
        setIsModalOpen={setIsActionConfirmationModalOpen}
        onCancel={() => setIsActionConfirmationModalOpen(false)}
        onConfirm={updateBusinessWireAction}
      />
      <ExportModal
        isModalOpen={isExportModalOpen}
        setIsModalOpen={setIsExportModalOpen}
        onConfirm={exportWires}
        isLoading={false}
        title="Business Details Wires Download"
      />
      <BusinessWireCreationModal
        isModalOpen={isCreateWireModalOpen}
        setIsModalOpen={setIsCreateWireModalOpen}
        onConfirm={createWire}
      />
      <BusinessWireFiltersModal
        isFilterModalOpen={isFilterModalOpen}
        setIsFilterModalOpen={setIsFilterModalOpen}
        setFilters={setFilters}
      />
    </>
  );
}
