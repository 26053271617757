import React, { ReactElement } from 'react';
import { ReactComponent as GreenCheckWithCircle } from '../../assets/check_with_circle.svg';
import { ReactComponent as X } from '../../assets/x.svg';
import { useAppStore } from '../../zustand/store';

export function SuccessNotification({
  message,
}: {
  message: string;
}): ReactElement {
  const setNotification = useAppStore((state) => state.setNotification);
  return (
    <div className="mb-4 flex items-center justify-between rounded-lg bg-surface-success-green p-2 text-text-success-green">
      <div className="flex items-center">
        <GreenCheckWithCircle className="m-2" />
        <p>{message}</p>
      </div>
      <X className="m-2 cursor-pointer" onClick={() => setNotification(null)} />
    </div>
  );
}
