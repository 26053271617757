import React, { ReactElement } from 'react';
import LoadingSpinner from '../../LoadingSpinner';
import { ReactComponent as CheckIcon } from '../../../assets/hollow-check-circle.svg';

export function BulkAllRecordsSuccess({
  records,
  isLoading,
  onCancel,
}: {
  records: unknown[] | [];
  isLoading: boolean;
  onCancel: () => void;
}): ReactElement {
  const total = Array.isArray(records) ? records?.length || 0 : records;

  return (
    <div className="flex flex-col justify-center">
      <h2 className="mb-6 text-xl font-medium">Records Succeeded</h2>
      <div className="flex w-full items-center">
        <p className="mb-6 flex w-full items-baseline">
          <span className="text-3xl">{total}</span>
          <span className="text-2xl leading-9 text-text-light-gray">
            /{total} Added
          </span>
        </p>
      </div>
      <hr />
      <div className="mt-6 flex w-full items-center rounded-md bg-surface-success-green px-6 py-4">
        <CheckIcon className="mr-3" />
        <p className="w-full text-sm font-bold text-text-success-green">
          All of your uploads were successful.
        </p>
      </div>
      <footer className="mt-6 flex flex-col gap-4 pb-2">
        <button
          className="flex h-10 w-full items-center justify-center rounded-full border-border-primary bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-light"
          type="button"
          onClick={() => onCancel()}
        >
          {isLoading ? <LoadingSpinner /> : 'Done'}
        </button>
      </footer>
    </div>
  );
}
