import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { Skeleton, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { PayrollDepositDTO } from '../../../types/transactions/payrollDepositDTO';
import {
  useGetBusiness,
  useGetPayrollDeposits,
} from '../../../api/rq/queries/businessQueries';
import { tableStatusCell } from '../../../components/TableStatusCell';
import Table from '../../../components/Table';
import { isBusinessAdmin } from '../../../utils/authUtils';
import { createUserInitials } from '../../../utils/userUtils';
import { DepositsFiltersModal } from '../../../components/Modals/DepositsFiltersModal';
import { ListPageActionItems } from '../../../components/ListPageActionItems';
import { CsvLinkContainer } from '../../../components/CsvLinkContainer';
import { ReactComponent as DownloadIcon } from '../../../assets/download.svg';
import { ExportModal } from '../../../components/Modals/ExportModal';
import { getDollarAmount } from '../../../utils/accountUtils';
import { TransactionSearchQueryParams } from '../../../types/transactions/transactionSearchQueryParams';

export function DepositsList() {
  const isBusinessAdminRole = isBusinessAdmin();
  const navigate = useNavigate();
  const userBusinessId = parseInt(localStorage.getItem('businessId') ?? '0');

  const [deposits, setDeposits] = useState<PayrollDepositDTO[]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  /**
   * Need to keep track of filters set/handled in the \
   * DepositsFiltersModal so they can be applied to PDF generation
   */
  const lastYear = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
  const [filters, setFilters] = useState<TransactionSearchQueryParams>({
    createdAt: lastYear,
    transactionStatus: null,
    transactionType: null,
    amount: {
      min: '',
      max: '',
    },
  });

  /**
   * CSV ref
   */
  const csvRef = React.useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  /**
   * RQ Queries
   */
  const { data: depositsData, isLoading: isLoadingDepositsData } =
    useGetPayrollDeposits(userBusinessId, !!userBusinessId);

  const { data: businessData } = useGetBusiness(userBusinessId);

  /**
   * Reroute user if not business admin
   */
  useEffect(() => {
    if (!isBusinessAdminRole) {
      navigate('/dashboard');
    }
  }, [isBusinessAdminRole, navigate]);

  /**
   * Set deposits data
   */
  useEffect(() => {
    setDeposits(depositsData || []);
  }, [depositsData]);

  const getBalance = useCallback(
    (info: CellContext<PayrollDepositDTO, string>) => {
      const row = info.row.original;
      const { amount } = row;
      return `+$${amount}`;
    },
    [],
  );

  const formatAvailableBalance = useCallback(
    (info: CellContext<PayrollDepositDTO, string>): ReactElement => {
      const balance = getBalance(info);
      const [dollars, cents] = balance.split('.');
      return (
        <div className="flex items-baseline">
          <span className="font-semibold">{dollars}</span>
          <span className="text-xs text-text-gray">
            {cents ? `.${cents}` : ''}
          </span>
        </div>
      );
    },
    [getBalance],
  );

  const createNameAndEmailCell = useCallback(
    (info: CellContext<PayrollDepositDTO, string>): ReactElement => {
      return (
        <div className="flex items-center">
          <div className="mr-2 flex h-7 w-7 items-center justify-center rounded-full bg-surface-icon-1">
            {createUserInitials(info.row.original.transfer_to_user)}
          </div>
          <div className="flex flex-col">
            <p className="font-semibold">
              {info.row.original.transfer_to_user}
            </p>
            <p className="font-normal text-text-gray">
              {info.row.original.email}
            </p>
          </div>
        </div>
      );
    },
    [],
  );

  const columnHelper = createColumnHelper<PayrollDepositDTO>();
  const payrollDepositColumns = useMemo(
    () => [
      columnHelper.accessor('transfer_to_user', {
        header: 'TO USER',
        cell: (info) => createNameAndEmailCell(info),
      }),
      columnHelper.accessor('amount', {
        header: 'AMOUNT',
        cell: (info) => formatAvailableBalance(info),
      }),
      columnHelper.accessor('status', {
        header: 'STATUS',
        cell: (info) => tableStatusCell<PayrollDepositDTO>(info),
      }),
      columnHelper.accessor('created_at', {
        header: 'DATE',
        cell: (info) => dayjs(info.getValue()).format('MM/DD/YYYY'),
      }),
    ],
    [columnHelper, formatAvailableBalance, createNameAndEmailCell],
  );

  /**
   * This has some duplicate code similar to other PDF export functions
   * in other Transaction/Deposits files. This is because the functionality
   * is similar, but has a lot of unique data. This could be abstracted,
   * however it would need to take a lot of input parameters, and the added
   * complexity doesn't seem worth it at this time.
   *
   * @TODO consider refactoring this to a shared function somewhere
   */
  const exportToPdf = () => {
    /**
     * Gernate JSON data that is compatible with the jsPDF
     * autotable library being used. This is a array of string arrays, with
     * each individual string array representing a row in the table.
     */
    const pdfCompatibleData: string[][] =
      deposits
        ?.map((deposit) => ({
          date: dayjs(deposit.created_at).format('MMM DD, YYYY'),
          to_user: deposit.transfer_to_user,
          status: deposit.status,
          amount: getDollarAmount(parseInt(deposit.amount)),
        }))
        .map((trx) => Object.values(trx).map((val) => val?.toString() ?? '')) ??
      [];
    // eslint-disable-next-line new-cap
    const pdfDoc = new jsPDF();
    /**
     * Title
     */
    pdfDoc.setFontSize(18);
    pdfDoc.setFont('Inter');
    pdfDoc.text('BUSINESS', 200, 10, {
      align: 'right',
    });
    pdfDoc.text('DEPOSITS', 200, 17, {
      align: 'right',
    });
    /**
     * Sub-title
     */
    pdfDoc.setFontSize(10);
    pdfDoc.text(dayjs().format('MMMM DD, YYYY'), 200, 22, {
      align: 'right',
    });
    /**
     * Summary start
     */
    pdfDoc.setFontSize(10);
    pdfDoc.text('SUMMARY', 20, 40);
    /**
     * User's Name
     */
    pdfDoc.setFontSize(9);
    pdfDoc.setFont('Inter', 'normal');
    pdfDoc.text('Business Name', 20, 50);
    pdfDoc.setFont('Inter', 'bold');
    pdfDoc.text(businessData?.legal_business_name ?? '', 50, 50);
    pdfDoc.setFont('Inter', 'normal');

    /**
     * Filter criteria
     */
    pdfDoc.setFont('Inter', 'normal');

    pdfDoc.text('Filter Criteria', 20, 65);
    pdfDoc.setFont('Inter', 'bold');
    pdfDoc.text(
      `Amount: ${filters.amount?.min || '-$100,000'} (Min), ${
        filters.amount?.max || '$100,000'
      } (Max)`,
      50,
      65,
    );
    pdfDoc.text(`Status: ${filters.transactionStatus ?? 'All'}`, 50, 70);

    pdfDoc.text(`Type: Account Transfers`, 50, 75);
    pdfDoc.text(`From Date: ${filters.createdAt}`, 50, 80);

    autoTable(pdfDoc, {
      theme: 'plain',
      columnStyles: {
        0: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        1: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        2: {
          cellWidth: 'auto',
          fontSize: 8,
        },
        3: {
          cellWidth: 'auto',
          fontSize: 8,
        },
      },
      head: [['DATE', 'TO USER', 'STATUS', 'AMOUNT']],
      body: pdfCompatibleData,
      margin: { top: 90 }, // Seting top margin for First Page.
      didDrawPage: (data) => {
        // Resetting top margin.
        // The change will be reflected only after print the first page.
        // eslint-disable-next-line no-param-reassign
        data.settings.margin.top = 20;
      },
    });
    pdfDoc.save(
      `${
        businessData?.legal_business_name ?? businessData?.dba_business_name
      }_deposits.pdf`,
    );
  };

  const exportTransactions = (fileType: string) => {
    if (fileType.toLowerCase() === 'pdf') {
      exportToPdf();
    }
    if (fileType.toLowerCase() === 'csv') {
      csvRef.current?.link.click();
    }
    setIsExportModalOpen(false);
  };

  return (
    <>
      <div className="flex">
        <div className="flex w-full justify-between">
          <ListPageActionItems
            setIsModalOpen={(modal) => setIsFilterModalOpen(modal.val)}
            searchText={globalFilter}
            setGlobalFilter={setGlobalFilter}
            entity="Deposit"
          />
          <Tooltip
            color="#06102B"
            title="Click to export deposits with filters applied if provided"
          >
            <button
              type="button"
              disabled={isLoadingDepositsData}
              className={`flex h-10 w-auto items-center rounded-md bg-surface-primary-light p-2 font-semibold text-white ${
                isLoadingDepositsData ? 'cursor-not-allowed' : ''
              }`}
              onClick={() => setIsExportModalOpen(true)}
            >
              <DownloadIcon />
            </button>
          </Tooltip>
        </div>
      </div>
      {isBusinessAdminRole && (
        <CsvLinkContainer
          fileNameWithoutDate="deposits"
          dataToExport={deposits || []}
          csvLinkRef={csvRef}
        />
      )}
      <ExportModal
        isModalOpen={isExportModalOpen}
        setIsModalOpen={setIsExportModalOpen}
        onConfirm={exportTransactions}
        isLoading={false}
        title="Business Deposits Download"
      />
      <DepositsFiltersModal
        deposits={depositsData || []}
        setDeposits={setDeposits}
        isFilterModalOpen={isFilterModalOpen}
        setIsFilterModalOpen={setIsFilterModalOpen}
        setFilters={setFilters}
      />
      {depositsData && !isLoadingDepositsData && (
        <Table<PayrollDepositDTO>
          data={deposits || []}
          columns={payrollDepositColumns}
          totalItems={depositsData?.length}
          isLoading={isLoadingDepositsData}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      {isLoadingDepositsData && <Skeleton active />}
    </>
  );
}
