import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { Modal, Radio, RadioChangeEvent, Select } from 'antd';
import dayjs from 'dayjs';
import { TransactionSearchQueryParams } from '../../../types/transactions/transactionSearchQueryParams';
import { isBusinessAdmin } from '../../../utils/authUtils';

/**
 * Modal for showing filters a user can apply
 * to the list of user account transactions.
 */
export function UserTransactionFiltersModal({
  isFilterModalOpen,
  setIsFilterModalOpen,
  setFilters,
}: {
  isFilterModalOpen: boolean;
  setIsFilterModalOpen: Dispatch<React.SetStateAction<boolean>>;
  setFilters: Dispatch<SetStateAction<TransactionSearchQueryParams>>;
}): ReactElement {
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [typeFilter, setTypeFilter] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [amountFilter, setAmountFilter] = useState<{
    min: string;
    max: string;
  }>({
    min: '',
    max: '',
  });

  const hasBusinessAdminRole = isBusinessAdmin();

  /**
   * Initialize and prepare data for the date created filter
   * with the default value being one month ago.
   */
  const todaysDate = dayjs();
  const last7Days = todaysDate.subtract(7, 'day').format('YYYY-MM-DD');
  const last30Days = todaysDate.subtract(30, 'day').format('YYYY-MM-DD');
  const lastYear = todaysDate.subtract(1, 'year').format('YYYY-MM-DD');
  const anyTime = todaysDate.subtract(10, 'year').format('YYYY-MM-DD');
  const [createdAtFilter, setCreatedAtFilter] = useState<string>(lastYear);
  const createdAtSelectOptions = [
    {
      label: 'Today',
      value: todaysDate.format('YYYY-MM-DD'),
    },
    {
      label: 'Last 7 Days',
      value: last7Days,
    },
    {
      label: 'Last 30 Days',
      value: last30Days,
    },
    {
      label: 'Last 12 Months',
      value: lastYear,
    },
    {
      label: 'Any Time',
      value: anyTime,
    },
  ];

  /**
   * Filters the list of transactions by provided filters.
   *
   * If the filter was *not* set, the current iterated transaction
   * automatically passes the filter test.
   *
   * If the filter is set, the filter value is compared to
   * the current iterated transaction value. If the values match,
   * the transaction passes the filter test. If they do not, the
   * transaction does not get returned in the filtered list.
   *
   */
  const applyFilters = () => {
    if (parseInt(amountFilter.min) > parseInt(amountFilter.max)) {
      setError('Minimum amount must be less than maximum amount.');
      return;
    }
    setFilters({
      transactionType: typeFilter,
      transactionStatus: statusFilter,
      createdAt: createdAtFilter,
      amount: {
        min: amountFilter.min !== '' ? amountFilter.min : '',
        max: amountFilter.max !== '' ? amountFilter.max : '',
      },
    });
    setError(null);
    setIsFilterModalOpen(false);
  };

  /**
   * Sets all local state filter variables back
   * to their defaults/placeholders
   */
  const clearFilters = () => {
    setCreatedAtFilter(lastYear);
    setStatusFilter(null);
    setTypeFilter(null);
    setAmountFilter({ min: '', max: '' });
  };

  const handleMaxAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setAmountFilter((prevState) => ({
        max: '',
        min: prevState.min,
      }));
      return;
    }
    /**
     * Only allow positive and negative integers
     */
    const passed = /^-?([0-9]+)?$/.test(e.target.value);
    if (!passed) return;
    setAmountFilter((prevState) => ({
      min: prevState.min,
      max: e.target.value || '',
    }));
  };

  const handleMinAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setAmountFilter((prevState) => ({
        min: '',
        max: prevState.max,
      }));
      return;
    }
    /**
     * Only allow positive and negative integers
     */
    const passed = /^-?([0-9]+)?$/.test(e.target.value);
    if (!passed) return;
    setAmountFilter((prevState) => ({
      min: e.target.value || '',
      max: prevState.max,
    }));
  };

  return (
    <Modal
      open={isFilterModalOpen}
      onCancel={() => setIsFilterModalOpen(false)}
      footer=""
    >
      <h2 className="text-xl">Select Transaction Filters</h2>
      <p className="my-2 text-text-error-red">{error}</p>
      <div className="py-2">
        <div className="text-xs font-semibold">Date Created</div>
        <Radio.Group
          value={createdAtFilter}
          onChange={(e: RadioChangeEvent) => {
            setCreatedAtFilter(e.target.value);
          }}
        >
          <div className="flex flex-col">
            {createdAtSelectOptions.map((option) => (
              <Radio value={option.value} key={option.value.toString()}>
                {option.label}
              </Radio>
            ))}
          </div>
        </Radio.Group>
      </div>
      {!hasBusinessAdminRole && (
        <div className="py-2">
          <div className="text-xs font-semibold">Type</div>
          <Select
            id="transaction_type"
            allowClear
            placeholder="Select a Transaction Type"
            size="large"
            className="my-1 w-full"
            options={[
              { label: 'Card Transaction', value: 'card_transaction' },
              { label: 'Load', value: 'load' },
              { label: 'Withdraw', value: 'withdraw' },
              { label: 'Account Transfer', value: 'account_transfer' },
              { label: 'Card Fee Transaction', value: 'card_fee_transaction' },
              { label: 'ACH Transactions', value: 'ach_transactions' },
            ]}
            value={typeFilter}
            onChange={(type: string) => setTypeFilter(type)}
          />
        </div>
      )}
      <div className="py-2">
        <div className="text-xs font-semibold">Status</div>
        <Select
          id="transaction_status"
          allowClear
          placeholder="Select a Transaction Status"
          size="large"
          className="my-1 w-full"
          options={[
            { label: 'Approved', value: 'APPROVED' },
            { label: 'Authorization', value: 'AUTHORIZATION' },
            { label: 'Balance Inquiry', value: 'BALANCE_INQUIRY' },
            { label: 'Declined', value: 'DECLINED' },
            { label: 'Expired', value: 'EXPIRED' },
            {
              label: 'Financial Authorization',
              value: 'FINANCIAL_AUTHORIZATION',
            },
            { label: 'Pending', value: 'PENDING' },
            { label: 'Settled', value: 'SETTLED' },
            { label: 'Voided', value: 'VOIDED' },
          ]}
          value={statusFilter}
          onChange={(status: string) => setStatusFilter(status)}
        />
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold">Amount</div>
        <div className="flex items-center gap-6">
          <input
            className="my-1 w-full rounded-lg border border-zinc-200 p-2"
            placeholder="Enter a Minimum Amount"
            type="text"
            value={amountFilter.min}
            onChange={handleMinAmountChange}
          />{' '}
          -
          <input
            className="my-1 w-full rounded-lg border border-zinc-200 p-2"
            placeholder="Enter a Maximum Amount"
            value={amountFilter.max}
            type="text"
            onChange={handleMaxAmountChange}
          />
        </div>
      </div>
      <div className="flex justify-between gap-2 py-2">
        <button
          type="button"
          className="w-full rounded-full border border-border-primary bg-white p-3 text-text-primary transition duration-300 ease-in-out hover:bg-surface-primary hover:text-white"
          onClick={clearFilters}
        >
          Clear Filters
        </button>
        <button
          type="button"
          onClick={applyFilters}
          className="w-full rounded-full bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
        >
          Search
        </button>
      </div>
    </Modal>
  );
}
