import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { BusinessDTO } from '../../../types/business/businessDTO';
import { getBusinesses, getPayrollDeposits } from '../../DashboardApi';
import { PayrollDepositDTO } from '../../../types/transactions/payrollDepositDTO';
import { getBusiness } from '../../UsersApi';
import { ExpandedBusinessDTO } from '../../../types/business/expandedBusinessDTO';

/**
 * React Query queries are used to fetch data from the server.
 * @docs https://tanstack.com/query/v4/docs/react/query-functions/usequery
 *
 * The query key is an array of strings that uniquely identifies the query.
 * Usually it will either be a single string identifier, or the identifier
 * plus a specific ID. This allows caching of queries at a resource level.
 * @docs https://tanstack.com/query/v4/docs/vue/guides/query-keys#if-your-query-function-depends-on-a-variable-include-it-in-your-query-key
 *
 * @param enabled The enabled option can not only be used to permanently
 * disable a query, but also to enable / disable it at a later time.
 * A good example would be a filter form where you only want to fire off
 * the first request once the user has entered a filter value, or a search
 * that shouldn't be executed until the user has entered at least 3 characters.
 *
 * @param staletime is used to set the time in milliseconds that the data
 * should be considered fresh. If the data is older than the stale time,
 * then a new request will be made in the background, but the stale data
 * will still be visible immediately.
 *
 * @param select is used to transform the data returned from the server into the
 * format that the UI expects. This is useful for normalizing data, or
 * for selecting a specific part of a larger response. The argument of
 * the select should match the name of the query key used above it.
 */

export const useGetBusinesses = (
  queryString = '?page=1&pageSize=1000',
  userBusinessIds?: number[],
): UseQueryResult<BusinessDTO[], Error> => {
  return useQuery<BusinessDTO[], Error>(
    ['businesses', userBusinessIds],
    () => getBusinesses(queryString),
    {
      staleTime: 3600, // 1 hour
      select: (businesses) =>
        userBusinessIds
          ? businesses.filter((biz) => userBusinessIds.includes(biz.id))
          : businesses,
    },
  );
};

export const useGetBusiness = (
  businessId: number,
): UseQueryResult<ExpandedBusinessDTO | null, Error> => {
  return useQuery<ExpandedBusinessDTO | null, Error>(
    ['business', businessId],
    () => getBusiness(businessId.toString()),
    {
      enabled: !!businessId,
    },
  );
};

export const useGetPayrollDeposits = (
  businessId: number,
  enabled = false,
): UseQueryResult<PayrollDepositDTO[], Error> => {
  return useQuery<PayrollDepositDTO[], Error>(
    ['payrollDeposits', businessId],
    () => getPayrollDeposits(businessId?.toString()),
    {
      enabled,
      select: (payrollDeposits) => {
        return payrollDeposits.map((deposit) => {
          return {
            ...deposit,
            amount: Math.abs(parseInt(deposit.amount))
              .toFixed(2)
              .toLocaleString(),
          };
        });
      },
    },
  );
};
