import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Select, Skeleton, notification } from 'antd';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {
  Roles,
  createRoleDropdownList,
  getRoleId,
  isAdminOrMTU,
} from '../../../utils/authUtils';
import { useGetBusinesses } from '../../../api/rq/queries/businessQueries';
import { useUpdateRadUser } from '../../../api/rq/mutations/userMutations';
import { useGetRadUser } from '../../../api/rq/queries/userQueries';
import { UpdateRadUserRequestDTO } from '../../../types/user/updateRadUserRequestDTO';
import {
  resetPassword,
  sendForgottenPasswordEmail,
} from '../../../api/AuthApi';
import { ResetPasswordFormValues } from '../../../types/forms/resetPasswordFormValues';
import { useAuthApi } from '../../../context/authContext';

export function RadUserDetailInfo(): ReactElement {
  /**
   * Local component state
   */
  const [selectedBusinessIds, setSelectedBusinessIds] = useState<number[]>([]);
  const [businessRequiredError, setBusinessRequiredError] =
    useState<string>('');

  const hasAdminAccess = isAdminOrMTU();
  const authContext = useAuthApi();
  const loggedInUser = authContext?.user;

  const navigate = useNavigate();

  /**
   * Path parameters.
   */
  const { userId: userIdQueryParam } = useParams();

  /**
   * Query string parameters
   */
  const [searchParams] = useSearchParams();
  const userStatus = searchParams.get('status');

  /**
   * Notification state
   */
  const [notify, notificationContext] = notification.useNotification();

  /**
   * Show success notification if needed
   */
  useEffect(() => {
    if (userStatus === 'success') {
      notify.success({
        message: 'Success!',
        description: `User was created successfully!`,
        placement: 'topLeft',
      });
    }
  }, [userStatus, notify]);

  /**
   * RQ Mutations for API updates
   */
  const { mutateAsync: mutateUpdateRadUser, isLoading: updateRadUserLoading } =
    useUpdateRadUser();

  /**
   * RQ queries for API data
   */
  const { data: selectedUserData } = useGetRadUser(
    parseInt(userIdQueryParam || '0'),
    !!userIdQueryParam,
  );

  const { data: allBusinessesData, isLoading: businessesLoading } =
    useGetBusinesses('?page=1&pageSize=1000');

  /**
   * Related Businesses select is handled by local component
   * state and not react-hook-form.
   */
  const allBusinessIds = allBusinessesData?.map((biz) => biz.id);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<UpdateRadUserRequestDTO>();

  const { register: registerReset, handleSubmit: handleSubmitReset } =
    useForm<ResetPasswordFormValues>();

  const setSelectedBusinesses = useMemo(
    () => (businessIds: number[]) => {
      /**
       * filter for non deleted IDs since deleted ones
       * are also returned with the user's list of business IDs
       */
      setSelectedBusinessIds(
        [...new Set(businessIds || [])].filter((id) =>
          allBusinessIds?.includes(id),
        ) || [],
      );
    },
    [allBusinessIds],
  );

  /**
   * Populate the user's data in the form
   */
  useEffect(() => {
    if (selectedUserData) {
      reset({
        first_name: selectedUserData.first_name,
        last_name: selectedUserData.last_name,
        email: selectedUserData.email,
        mobile_phone: selectedUserData.mobile_phone,
        role_id: selectedUserData.role_id,
      });
      setSelectedBusinessIds(selectedUserData.business_ids);
    }
  }, [selectedUserData, reset]);

  /**
   * Populate role dropdown list based on what role
   * the currently logged in RAD user has.
   */
  const loggedInUserRoleId = getRoleId();
  const roleDropdownItems = createRoleDropdownList(loggedInUserRoleId, false);

  /**
   * Transforms form data into the correct format
   * and then calls the Dashboard API to update the user.
   * @param data Form data
   */
  const updateRadUser = async (
    data: UpdateRadUserRequestDTO,
  ): Promise<void> => {
    console.log({ dirtyFields });
    if (!selectedBusinessIds || selectedBusinessIds.length === 0) {
      setBusinessRequiredError('At least one business is required');
      return;
    }
    setBusinessRequiredError('');
    /**
     * Checks if the selectedBusinessIds param is different
     * than the original list of ids
     */
    let businessIdsChanged = false;
    console.log({ selectedBusinessIds, bis: selectedUserData?.business_ids });
    if (
      JSON.stringify(selectedUserData?.business_ids) !==
      JSON.stringify(selectedBusinessIds)
    ) {
      businessIdsChanged = true;
    }
    const updateRequestBody: UpdateRadUserRequestDTO = {
      ...data,
      role_id: dirtyFields.role_id ? data.role_id : undefined,
      first_name: dirtyFields.first_name ? data.first_name : undefined,
      last_name: dirtyFields.last_name ? data.last_name : undefined,
      email: dirtyFields.email ? data.email : undefined,
      mobile_phone: dirtyFields.mobile_phone ? data.mobile_phone : undefined,
      business_ids: businessIdsChanged ? selectedBusinessIds : undefined,
    };
    console.log({ updateRequestBody });
    try {
      const modifiedUser = await mutateUpdateRadUser({
        userId: parseInt(userIdQueryParam ?? '0'),
        body: updateRequestBody,
      });
      if (modifiedUser?.id) {
        notify.success({
          message: 'Success!',
          description: `Successfully updated user ${modifiedUser.email}`,
          placement: 'topLeft',
        });
      }
    } catch (err) {
      notify.error({
        message: 'Error!',
        description: `There was an error updating the user:
     ${err.message}. Please try again.`,
        placement: 'topLeft',
      });
    }
  };

  const selectRole = (roleId: number) => {
    if (roleId === Roles.RegularUser) {
      navigate(
        `/dashboard/users/${selectedUserData?.id}/info?roleId=${roleId}`,
      );
    }
  };

  const [resetPasswordStarted, setResetPassworStarted] = useState(false);

  const handleResetUserPassword = async (
    data: ResetPasswordFormValues,
  ): Promise<void> => {
    console.log(data, selectedUserData);
    try {
      const result = await resetPassword({
        email: selectedUserData?.email || '',
        ...data,
      });

      if (result && Object.keys(result[0]).length === 0) {
        notify.success({
          type: 'success',
          message: 'Success!',
          description: 'User Password reset successfully',
        });
      }

      setResetPassworStarted(false);
    } catch (error) {
      console.log(error.error);
      notify.error({
        type: 'error',
        message: 'Error!',
        description: error.error,
      });
    }
  };

  const handleSendForgottenPasswordEmail = async (): Promise<void> => {
    const result = await sendForgottenPasswordEmail(loggedInUser?.email || '');

    if (result && result.emailSent) {
      notify.success({
        type: 'success',
        message: 'Success!',
        description: 'Email sent successfully',
      });
    }
    setResetPassworStarted(true);
  };

  return (
    <>
      {notificationContext}
      <div className="rounded-lg border border-border-light-gray bg-white p-3 lg:p-6">
        <div className="grid grid-cols-1 gap-4">
          <form onSubmit={handleSubmit((data) => updateRadUser(data))}>
            <div className="gap-4 lg:grid lg:grid-cols-2">
              <div className="border-r-2 border-r-gray-100 pr-2 lg:pr-5">
                {/* User Info Section */}
                {selectedUserData ? (
                  <section className="col-start-1">
                    <div className="mb-4 flex gap-3">
                      <span className="text-xl font-semibold">User Info</span>
                    </div>
                    <hr className="mb-4 border border-r-gray-100" />

                    {/* Related Businesses and User Role */}
                    <div className="mb-4 flex w-full flex-col">
                      {/*  User Role */}
                      {roleDropdownItems && (
                        <div className="mb-4 flex w-full flex-col">
                          <div className="mb-4 text-base font-semibold">
                            User Role{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="text-xs font-normal">
                                {errors.role_id?.message || ''}
                              </span>
                            </span>
                          </div>
                          <Controller
                            name="role_id"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Select
                                id="role-id"
                                {...register('role_id', {
                                  required: 'Role is Required',
                                })}
                                allowClear
                                placeholder="Select a role for this user"
                                size="large"
                                className="my-1 w-full"
                                options={roleDropdownItems.map((role) => ({
                                  label: role.name,
                                  value: role.id,
                                  disabled: role.disabled,
                                }))}
                                onChange={(id: number) => {
                                  selectRole(id);
                                  onChange(id);
                                }}
                                value={value}
                              />
                            )}
                          />
                        </div>
                      )}
                      {/* Related Businesses - Not part of react hook form */}
                      {!businessesLoading ? (
                        <>
                          <div className="mb-4 text-base font-semibold">
                            Business Relationships{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="text-xs font-normal">
                                {businessRequiredError || ''}
                              </span>
                            </span>
                          </div>
                          <Select
                            id="business-ids"
                            mode="multiple"
                            showSearch
                            placeholder="Select businesses"
                            size="large"
                            className="my-1 w-full"
                            disabled={!hasAdminAccess}
                            filterOption={(input, option) =>
                              (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={
                              allBusinessesData?.map((business) => ({
                                value: business.id,
                                label: business.legal_business_name,
                              })) || []
                            }
                            onChange={(ids) => {
                              setSelectedBusinesses(ids);
                            }}
                            value={selectedBusinessIds}
                          />
                        </>
                      ) : (
                        <Skeleton active />
                      )}
                    </div>

                    {/* First, Last Name, Email, Mobile Phone */}
                    <fieldset
                      disabled={!hasAdminAccess}
                      className="flex flex-col flex-wrap gap-3 lg:flex-nowrap"
                    >
                      <h4 className="text-base font-semibold">
                        Personal Details
                      </h4>
                      {/* First and Last Name */}
                      <div className="flex flex-col gap-3 md:flex-row">
                        {/* First Name */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <label htmlFor="first-name">
                            <div className="text-xs font-semibold">
                              First Name{' '}
                              <span className="text-text-error-red">
                                *{' '}
                                <span className="font-normal">
                                  {errors?.first_name?.message || ''}
                                </span>
                              </span>
                            </div>
                            <input
                              id="first-name"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess ? '' : 'cursor-not-allowed'
                              }`}
                              placeholder="First Name"
                              type="text"
                              {...register('first_name', {
                                required: 'First Name is Required',
                              })}
                            />
                          </label>
                        </div>
                        {/* Last Name */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <label htmlFor="last-name">
                            <div className="text-xs font-semibold">
                              Last Name{' '}
                              <span className="text-text-error-red">
                                *{' '}
                                <span className="font-normal">
                                  {errors?.last_name?.message || ''}
                                </span>
                              </span>
                            </div>
                            <input
                              id="last-name"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess ? '' : 'cursor-not-allowed'
                              }`}
                              placeholder="Last Name"
                              type="text"
                              {...register('last_name', {
                                required: 'Last Name is Required',
                              })}
                            />
                          </label>
                        </div>
                      </div>
                      {/* Email and Mobile Phone */}
                      <div className="flex flex-col gap-3 md:flex-row">
                        {/* Email */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <label htmlFor="email">
                            <div className="text-xs font-semibold">
                              Email{' '}
                              <span className="text-text-error-red">
                                *{' '}
                                <span className="font-normal">
                                  {errors?.email?.message || ''}
                                </span>
                              </span>
                            </div>
                            <input
                              id="email"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess ? '' : 'cursor-not-allowed'
                              }`}
                              placeholder="user@domain.com"
                              type="text"
                              {...register('email', {
                                required: 'Email is Required',
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                  message: 'Invalid Email Address',
                                },
                              })}
                            />
                          </label>
                        </div>
                        {/* Mobile Phone */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <label htmlFor="mobile_phone">
                            <div className="text-xs font-semibold">
                              Mobile Phone{' '}
                              <span className="text-text-error-red">
                                *{' '}
                                <span className="font-normal">
                                  {errors?.mobile_phone?.message || ''}
                                </span>
                              </span>
                            </div>
                            <input
                              id="mobile-phone"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess ? '' : 'cursor-not-allowed'
                              }`}
                              placeholder="XXX-XXX-XXXX"
                              type="text"
                              {...register('mobile_phone', {
                                required: 'Mobile Phone is Required',
                                pattern: {
                                  value:
                                    /^(?:\+\d{1,3}\s?)?(?:\(\d{3}\)|\d{3})[-\s]?\d{3}[-\s]?\d{4}$/,
                                  message: 'Invalid Phone Number',
                                },
                              })}
                            />
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </section>
                ) : (
                  <Skeleton active />
                )}
              </div>
            </div>

            <div className="mt-8 flex justify-start">
              <button
                className={`h-10 w-[25%] items-center rounded-lg bg-surface-primary-dark text-white ${
                  hasAdminAccess ? '' : 'cursor-not-allowed'
                }`}
                type="submit"
                disabled={!hasAdminAccess || updateRadUserLoading}
              >
                {updateRadUserLoading ? <LoadingSpinner /> : <p>Save</p>}
              </button>
            </div>
          </form>
        </div>
      </div>

      {hasAdminAccess && (
        <div className="mt-10 rounded-lg border border-border-light-gray bg-white p-3 lg:p-6">
          <div className="flex flex-col items-center justify-center p-0">
            <div className="flex w-full flex-col items-start">
              <h3 className="w-full text-left text-xl font-bold">
                Reset Password
              </h3>
              {!resetPasswordStarted && (
                <button
                  type="button"
                  onClick={() => handleSendForgottenPasswordEmail()}
                >
                  <p>Click here to reset this user&apos;s password.</p>
                </button>
              )}
            </div>

            {resetPasswordStarted && (
              <form
                className="flex w-full flex-col justify-start"
                onSubmit={handleSubmitReset((data) =>
                  handleResetUserPassword(data),
                )}
              >
                <div className="flex w-1/3 auto-rows-max flex-col gap-y-5 sm:min-w-[375px]">
                  <div className="mb-6 flex flex-col items-start">
                    <p className="mb-0 mt-6 text-left">
                      Verify the OTP code sent to your email address.
                    </p>
                    <input
                      id="otp"
                      className="my-1 w-full rounded-lg border border-zinc-200 p-2"
                      placeholder="OTP"
                      type="text"
                      {...registerReset('otp', {
                        required: 'OTP is Required',
                      })}
                    />
                  </div>
                  <div className="mb-3 gap-y-3">
                    <input
                      id="password"
                      className="my-1 w-full rounded-lg border border-zinc-200 p-2"
                      placeholder="Password"
                      type="password"
                      {...registerReset('password', {
                        required: 'Password is Required',
                      })}
                    />
                    <input
                      id="confirm-password"
                      className="my-1 w-full rounded-lg border border-zinc-200 p-2"
                      placeholder="Confirm Password"
                      type="password"
                      {...registerReset('confirmPassword', {
                        required: 'Confirm Password is Required',
                      })}
                    />
                  </div>
                  <button
                    type="button"
                    className="text-red-600"
                    onClick={() => setResetPassworStarted(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="flex h-10 items-center justify-center rounded-lg bg-surface-primary-dark text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
                    type="submit"
                    data-testid="submit-button"
                  >
                    {updateRadUserLoading ? (
                      <LoadingSpinner />
                    ) : (
                      'Change Password'
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </>
  );
}
