import React, { ReactElement, useEffect, useState } from 'react';
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { DatePicker, Select, Skeleton } from 'antd';
import { createColumnHelper, CellContext } from '@tanstack/table-core';
import { ExpandedBusinessDTO } from '../../../types/business/expandedBusinessDTO';
import { CountryDTO } from '../../../types/business/countryDTO';
import { ProvinceDTO } from '../../../types/business/provinceDTO';
import {
  businessTypes,
  getCountries,
  getProvinces,
  naturesOfBusiness,
} from '../../../api/DashboardApi';
import {
  createBusiness,
  getBusiness,
  updateBusiness,
} from '../../../api/UsersApi';
import Table from '../../../components/Table';
import { BusinessAccountDTO } from '../../../types/business/businessAccountDTO';
import { useAppStore } from '../../../zustand/store';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { StatusCard } from '../../../components/StatusCard';
import { SuccessNotification } from '../../../components/Notifications/SuccessNotification';
import { ErrorNotification } from '../../../components/Notifications/ErrorNotification';
import { isAdminOrMTU } from '../../../utils/authUtils';
import { getBalance } from '../../../utils/accountUtils';
import { BusinessAccountHistoryDTO } from '../../../types/business/businessAccountHistoryDTO';
import { tableStatusCell } from '../../../components/TableStatusCell';
import { createUserInitials, dateNotInFuture } from '../../../utils/userUtils';
import BankDetails from '../../../components/BankDetails';

export function BusinessDetailInfo(): ReactElement {
  const [provinces, setProvinces] = useState<ProvinceDTO[]>([]);
  const [countries, setCountries] = useState<CountryDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const hasAdminAccess = isAdminOrMTU();

  const navigate = useNavigate();
  const [queryStringParams] = useSearchParams();
  const businessStatus = queryStringParams.get('status');

  const { businessId } = useParams();

  // if no businessId is passed with the route and
  // hasAdminAccess is false
  // the user should not be able to create a new business and
  // should be redirected accordingly
  useEffect(() => {
    if (!businessId && !hasAdminAccess) navigate('/dashboard/businesses');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    business: selectedBusiness,
    countries: countryList,
    accounts,
  } = useOutletContext<{
    business: ExpandedBusinessDTO;
    countries: CountryDTO[];
    accounts: BusinessAccountDTO[];
  }>();

  const setSelectedBusiness = useAppStore((state) => state.setSelectedBusiness);
  const setNotification = useAppStore((state) => state.setNotification);
  const notification = useAppStore((state) => state.notification);

  const determineSelectedCountry = (alphaCode: string): string => {
    const country = countries.find((c) => c.alpha_code === alphaCode);
    return country?.name ?? '';
  };

  const determineSelectedProvince = (
    provinceResp: ProvinceDTO[],
    alphaCode: string,
  ): string => {
    const province = provinceResp.find((p) => p.alpha_code === alphaCode);
    return province?.alpha_code ?? '';
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ExpandedBusinessDTO>({
    defaultValues: {
      legal_business_name: selectedBusiness?.legal_business_name ?? '',
      dba_business_name: selectedBusiness?.dba_business_name ?? '',
      parent_company: selectedBusiness?.parent_company ?? '',
      bus_ein: selectedBusiness?.bus_ein ?? '',
      address_1: selectedBusiness?.address_1 ?? '',
      address_2: selectedBusiness?.address_2 ?? '',
      city: selectedBusiness?.city ?? '',
      province: null, // this is set in useEffect
      country: selectedBusiness
        ? determineSelectedCountry(selectedBusiness.country ?? '')
        : null,
      postal_code: selectedBusiness?.postal_code ?? '',
      bus_state_incorporation: null, // this is set in useEffect
      bus_date_incorporation: selectedBusiness?.bus_date_incorporation ?? null,
      business_type: null, // this is set in useEffect
      nature_of_business: null, // this is set in useEffect
      toll_free_phone: selectedBusiness?.toll_free_phone ?? '',
      csr_phone: selectedBusiness?.csr_phone ?? '',
      other_phone: selectedBusiness?.other_phone ?? '',
      website_url: selectedBusiness?.website_url ?? '',
    },
  });

  useEffect(() => {
    (async () => {
      if (!countryList) {
        const cntrys = await getCountries();
        setCountries(cntrys?.filter((c) => c.alpha_code === 'US') || []);
        setCountries(cntrys?.filter((c) => c.alpha_code === 'US') || []);
      }
    })();
  }, [countryList]);

  useEffect(() => {
    if (businessStatus === 'created') {
      setNotification({
        type: 'success',
        message: 'Business Details have been saved and are now visible to edit',
      });
    }
    (async () => {
      /**
       * Set the selected business that was passed in
       */
      if (!selectedBusiness && businessId) {
        /**
         * If there is no selected business being passed in,
         * fetch the business and set it
         */
        const biz = await getBusiness(businessId);
        setSelectedBusiness(biz);
      }
      /**
       * US only for now. Undo this filter when wanting other countries
       * in the dropdown
       */
      setCountries(countryList?.filter((c) => c.alpha_code === 'US') || []);
      if (selectedBusiness?.country) {
        const country = countryList.find(
          (c) => c.alpha_code === selectedBusiness.country,
        );
        const provinceResp = await getProvinces(country?.id.toString() ?? '0');
        setProvinces(provinceResp);
        const provinceName = determineSelectedProvince(
          provinceResp,
          selectedBusiness.province || '',
        );
        const busIncStateName = determineSelectedProvince(
          provinceResp,
          selectedBusiness.bus_state_incorporation || '',
        );
        // set dropdown values
        setValue('bus_state_incorporation', busIncStateName);
        setValue('business_type', selectedBusiness.business_type);
        setValue('nature_of_business', selectedBusiness.nature_of_business);
        setValue('country', selectedBusiness.country);
        setValue('province', provinceName);
      } else {
        const countryResp = await getCountries();
        const country = countryResp?.filter((c) => c.alpha_code === 'US');
        setCountries(country || []);
        const provinceResp = await getProvinces(
          country[0].id.toString() ?? '0',
        );
        setProvinces(provinceResp);
      }
    })();
    return () => setNotification(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectCountry = async (countryId: string): Promise<void> => {
    setValue('province', null);
    const provinceResp = await getProvinces(countryId);
    setProvinces(provinceResp);
  };

  const formatAvailableBalance = (
    availableBalance: number | undefined,
    centsSize = 'text-lg',
  ): ReactElement => {
    const balance = getBalance(availableBalance || 0);
    const [dollars, cents] = balance.split('.');
    return (
      <div className="flex items-baseline">
        <span className="font-semibold">{dollars}</span>
        <span className={`${centsSize} text-text-gray`}>
          {cents ? `.${cents}` : ''}
        </span>
      </div>
    );
  };

  const makeBusinessAccountNameCell = (
    info: CellContext<BusinessAccountDTO, unknown>,
  ) => {
    return (
      <div className="flex items-center">
        <BankDetails info={info} />
        <button
          type="button"
          className="text-left text-text-primary hover:underline"
          onClick={() =>
            navigate(
              `/dashboard/businesses/${info.row.original.business_id}/accounts/${info.row.original.id}`,
            )
          }
        >
          {info.row.original.name}
        </button>
      </div>
    );
  };

  const historyColumnHelper = createColumnHelper<BusinessAccountHistoryDTO>();

  const accountHistoryColumns = [
    historyColumnHelper.accessor('event', {
      cell: (info) => info.getValue(),
      header: 'EVENT',
    }),
    historyColumnHelper.accessor('date', {
      header: 'DATE',
      cell: (info) => info.getValue(),
    }),
  ];

  const columnHelper = createColumnHelper<BusinessAccountDTO>();

  const accountColumns = [
    columnHelper.accessor('name', {
      cell: (info) => makeBusinessAccountNameCell(info),
      header: 'NAME',
    }),
    columnHelper.accessor('business_account_status_id', {
      header: 'STATUS',
      cell: (info) => tableStatusCell<BusinessAccountDTO>(info),
    }),
    columnHelper.accessor('available_balance', {
      header: 'BALANCE',
      cell: (info) => formatAvailableBalance(info.getValue(), 'text-xs'),
    }),
  ];

  const accountHistory: BusinessAccountHistoryDTO[] = [];

  if (selectedBusiness?.created_at) {
    accountHistory.push({
      event: 'Created',
      date: selectedBusiness?.created_at
        ? dayjs(selectedBusiness?.created_at).format('MMM DD, YYYY')
        : '',
    });
  }

  if (selectedBusiness?.updated_at) {
    accountHistory.push({
      event: 'Last Updated',
      date: selectedBusiness?.updated_at
        ? dayjs(selectedBusiness?.updated_at).format('MMM DD, YYYY')
        : '',
    });
  }

  if (selectedBusiness?.kyb_approval_date) {
    accountHistory.push({
      event: 'KYB Approved',
      date: selectedBusiness?.kyb_approval_date
        ? dayjs(selectedBusiness?.kyb_approval_date).format('MMM DD, YYYY')
        : '',
    });
  }

  /**
   * Transforms form data into the correct format
   * and then calls the User API to either create a
   * new business or update an existing business.
   *
   * @param data Form data
   */
  const createOrUpdateBusiness = async (
    data: ExpandedBusinessDTO,
  ): Promise<void> => {
    setNotification(null);
    // data.country can either be the id, name, or
    // alpha code of the country
    const countryAlphaCode =
      countries.find(
        (country) =>
          country.name === data.country ||
          parseInt(data.country ?? '0') === country.id,
      )?.alpha_code || data.country;

    // data.province can either be the id, or
    // alpha code of the province
    const selectedProvince = provinces.find(
      (province) =>
        province.alpha_code === data.province ||
        parseInt(data.province ?? '0') === province.id,
    );

    const selectedProvinceValue =
      selectedProvince?.alpha_code || selectedProvince?.name;

    // data.bus_state_incorporation can either be the id, or
    // alpha code of the province
    const selectedBusStateProvince = provinces.find(
      (province) =>
        province.alpha_code === data.bus_state_incorporation ||
        parseInt(data.bus_state_incorporation ?? '0') === province.id,
    );

    const selectedBusStateProvinceValue =
      selectedBusStateProvince?.alpha_code || selectedBusStateProvince?.name;

    const updateRequestBody = {
      ...data,
      bus_date_incorporation: dayjs(data.bus_date_incorporation).format(
        'YYYY-MM-DD HH:mm:ss',
      ),
      country: countryAlphaCode || null,
      province: selectedProvinceValue || null,
      bus_state_incorporation: selectedBusStateProvinceValue || null,
    };

    setIsLoading(true);

    const createOrUpdateSuccess =
      'Business Details have been saved and are now visible to edit';
    /**
     * If there is a businessId, then we are updating
     * the business.
     */
    if (businessId) {
      try {
        await updateBusiness(businessId, updateRequestBody);
        setNotification({ type: 'success', message: createOrUpdateSuccess });
        setIsLoading(false);
        document.documentElement.scrollTo(0, 0);
      } catch (err) {
        setNotification({
          type: 'error',
          message: `There was an error saving the business details: ${err.message}. Please try again.`,
        });
        setIsLoading(false);
      }
    } else {
      /**
       * If there is no businessId, then we are creating
       * a new business
       */
      try {
        const programId = parseInt(localStorage.getItem('programId') || '0');
        const createBusinessResp = await createBusiness({
          ...updateRequestBody,
          program_id: programId,
          tos_timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        });
        navigate(
          `/dashboard/businesses/${createBusinessResp?.business_id}/info?status=created`,
        );
        // setNotification({ type: 'success', message: createOrUpdateSuccess });
        // setSelectedBusiness(updateRequestBody);
      } catch (err) {
        setNotification({
          type: 'error',
          message: `There was an error saving the business details: ${err.message}. Please try again.`,
        });
        setIsLoading(false);
      }
    }
  };

  const renderNotification = (
    newNotification: {
      type: string;
      message: string;
    } | null,
  ) => {
    if (!newNotification) return null;
    if (newNotification.type === 'success')
      return <SuccessNotification message={newNotification.message} />;
    if (newNotification.type === 'error')
      return <ErrorNotification message={newNotification.message} />;
    return null;
  };

  return (
    <>
      {renderNotification(notification)}
      <div className="rounded-lg border border-border-light-gray bg-white p-3 lg:p-6">
        <div
          className={`grid grid-cols-1 ${
            selectedBusiness ? 'md:grid-cols-2' : ''
          } gap-4`}
        >
          {selectedBusiness && (
            <div className="col-span-1 flex flex-col gap-5 md:border-r-2 md:border-r-gray-100 md:pr-4 lg:pr-6">
              <span className="text-xl font-semibold">Business Details</span>
              <div className="flex w-full flex-col items-center gap-2 sm:flex-row">
                <div className="flex h-24 w-24 items-center justify-center rounded-full bg-surface-primary-dark text-4xl text-white sm:mr-8 sm:h-24">
                  {createUserInitials(
                    selectedBusiness?.legal_business_name || '',
                  )}
                </div>
                <StatusCard
                  title="KYB Status"
                  statusIdOrName={selectedBusiness.kyb_status}
                  large={false}
                />
              </div>
              <div className="flex flex-col gap-3">
                <span className="text-lg font-semibold">Accounts</span>
                {accounts && accounts.length > 0 ? (
                  <Table<BusinessAccountDTO>
                    data={accounts || []}
                    columns={accountColumns}
                  />
                ) : (
                  <p className="ml-4">No Accounts Found</p>
                )}
                <span className="text-lg font-semibold">Business History</span>
                <Table<BusinessAccountHistoryDTO>
                  data={accountHistory || []}
                  columns={accountHistoryColumns}
                />
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit((data) => createOrUpdateBusiness(data))}>
            <fieldset disabled={!hasAdminAccess}>
              <div
                className={`grid grid-cols-1 ${
                  selectedBusiness ? '' : 'md:grid-cols-2'
                } gap-4`}
              >
                <section
                  className={`${
                    selectedBusiness
                      ? ''
                      : 'md:border-r-2 md:border-r-gray-100 md:pr-4'
                  }`}
                >
                  <div className="mb-4 flex gap-3">
                    <span className="text-xl font-semibold">Business Info</span>
                  </div>
                  <hr className="mb-4 border border-r-gray-100" />

                  {/* Start Name, EIN, Inc Date, Inc State, Type, and Nature */}

                  <fieldset className="flex flex-col flex-wrap gap-3 lg:flex-nowrap">
                    <h4 className="text-base font-semibold">Main Details</h4>
                    <div className="flex flex-col gap-3 lg:flex-row">
                      {/* Legal Business Name */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="legal-business-name">
                          <div className="text-xs font-semibold">
                            Legal Business Name
                            <span className="text-text-error-red">*</span>
                          </div>
                          <input
                            id="legal-business-name"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess
                                ? ''
                                : 'cursor-not-allowed bg-neutral-100'
                            }`}
                            placeholder="Legal Business Name"
                            type="text"
                            {...register('legal_business_name', {
                              required: 'Legal Business Name is Required',
                            })}
                          />
                        </label>
                        <div className="m-1 text-xs text-text-error-red">
                          {errors?.legal_business_name?.message || ''}
                        </div>
                      </div>
                      {/* Business EIN */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="business-ein">
                          <div className="text-xs font-semibold">
                            Business EIN
                            <span className="text-text-error-red">*</span>
                          </div>
                          <input
                            id="business-ein"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess
                                ? ''
                                : 'cursor-not-allowed bg-neutral-100'
                            }`}
                            placeholder="Business EIN"
                            type="text"
                            {...register('bus_ein', {
                              required: 'Business EIN is Required',
                              pattern: {
                                value:
                                  /^(?!(?:\D*\d){10})(?!(?:-?\d){10})(?=.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d)[0-9-]{9,11}$/i,
                                message:
                                  'EIN must be 9 to 11 characters and contain only numbers and up to two hyphens (-)',
                              },
                            })}
                          />
                        </label>
                        <div className="m-1 text-xs text-text-error-red">
                          {errors?.bus_ein?.message || ''}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-3 lg:flex-row">
                      {/* Business Incorporation Date */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <div className="text-xs font-semibold">
                          Business Incorporation Date
                          <span className="text-text-error-red">*</span>
                        </div>
                        <Controller
                          control={control}
                          name="bus_date_incorporation"
                          rules={{
                            required: 'Business Incorporation Date is Required',
                          }}
                          render={({ field, fieldState }) => {
                            return (
                              <DatePicker
                                id="bus-date-incorporation"
                                size="large"
                                placeholder="Select a date"
                                {...register('bus_date_incorporation', {
                                  required:
                                    'Business Incorporation Date is Required',
                                  validate: (value) =>
                                    dateNotInFuture(value)
                                      ? undefined
                                      : 'Business Incorporation Date must be not be in the future',
                                })}
                                status={fieldState.error ? 'error' : undefined}
                                ref={field.ref}
                                name={field.name}
                                className="my-1"
                                onBlur={field.onBlur}
                                disabled={!hasAdminAccess}
                                value={field.value ? dayjs(field.value) : null}
                                onChange={(date) => {
                                  field.onChange(
                                    date ? dayjs(date).toISOString() : '',
                                  );
                                }}
                              />
                            );
                          }}
                        />
                        <div className="m-1 text-xs text-text-error-red">
                          {errors?.bus_date_incorporation?.message || ''}
                        </div>
                      </div>
                      {/* Business Incorporation State */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <div className="text-xs font-semibold">
                          Business Incorporation State
                          <span className="text-text-error-red">*</span>
                        </div>
                        <Controller
                          name="bus_state_incorporation"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              id="bus-state-incorporation"
                              showSearch
                              placeholder="Select a state"
                              size="large"
                              className="my-1 w-full"
                              disabled={!hasAdminAccess}
                              {...register('bus_state_incorporation', {
                                required: {
                                  message:
                                    'Business Incorporation State is Required',
                                  value: provinces.length > 0,
                                },
                              })}
                              filterOption={(input, option) =>
                                (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={
                                provinces?.map((province) => ({
                                  value: province.id,
                                  label: province.alpha_code || province.name,
                                })) || []
                              }
                              onChange={onChange}
                              value={value}
                            />
                          )}
                        />
                        <div className="m-1 text-xs text-text-error-red">
                          {errors?.bus_state_incorporation?.message || ''}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-3 lg:flex-row">
                      {/* Business Type */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <div className="text-xs font-semibold">
                          Business Type
                          <span className="text-text-error-red">*</span>
                        </div>
                        <Controller
                          name="business_type"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              id="business-type"
                              placeholder="Select a business type"
                              size="large"
                              className="my-1 w-full"
                              disabled={!hasAdminAccess}
                              {...register('business_type', {
                                required: 'Business Type is Required',
                              })}
                              options={businessTypes}
                              onChange={onChange}
                              value={value}
                            />
                          )}
                        />
                        <div className="m-1 text-xs text-text-error-red">
                          {errors?.business_type?.message || ''}
                        </div>
                      </div>
                      {/* Nature of Business */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <div className="text-xs font-semibold">
                          Nature of Business
                          <span className="text-text-error-red">*</span>
                        </div>
                        <Controller
                          name="nature_of_business"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              id="nature-of-business"
                              placeholder="Select a nature of business"
                              size="large"
                              className="my-1 w-full"
                              disabled={!hasAdminAccess}
                              {...register('nature_of_business', {
                                required: 'Nature of Business is Required',
                              })}
                              options={naturesOfBusiness}
                              onChange={onChange}
                              value={value}
                            />
                          )}
                        />
                        <div className="m-1 text-xs text-text-error-red">
                          {errors?.nature_of_business?.message || ''}
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  {/* End Name and EIN Inputs */}

                  {/* Start Address 1 Inputs */}
                  <section className="col-start-1 mt-4">
                    {countries && countries.length > 0 ? (
                      <fieldset className="flex flex-col flex-wrap gap-3 lg:flex-nowrap">
                        <h4 className="text-base font-semibold">
                          Residential Address
                        </h4>
                        {/* Country */}
                        <div className="flex w-full flex-col">
                          {/* Below pattern prevents PO Boxes */}

                          <div className="text-xs font-semibold">
                            Country{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.country?.message || ''}
                              </span>
                            </span>
                          </div>
                          <Controller
                            name="country"
                            control={control}
                            rules={{
                              required: 'Country is Required',
                            }}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                id="country"
                                showSearch
                                placeholder="Select a country"
                                size="large"
                                className="my-1 w-full"
                                disabled={!hasAdminAccess}
                                filterOption={(input, option) =>
                                  (option?.label ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                {...register('country', {
                                  required: 'Country is Required',
                                })}
                                options={
                                  countries?.map((country) => ({
                                    value: country.id,
                                    label: country.name,
                                  })) || []
                                }
                                onChange={(e) => {
                                  selectCountry(e);
                                  onChange(e);
                                }}
                                value={value}
                              />
                            )}
                          />
                        </div>
                        {/* Address 1 and Address 2 */}
                        <div className="flex flex-col gap-3 lg:flex-row">
                          {/* Address 1 */}
                          <div className="flex w-full flex-col lg:w-1/2">
                            <label htmlFor="address-1">
                              <div className="text-xs font-semibold">
                                Address 1{' '}
                                <span className="text-text-error-red">
                                  *{' '}
                                  <span className="font-normal">
                                    {errors?.address_1?.message || ''}
                                  </span>
                                </span>
                              </div>
                              <input
                                id="address-1"
                                className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                  hasAdminAccess
                                    ? ''
                                    : 'cursor-not-allowed bg-neutral-100'
                                }`}
                                placeholder="Address"
                                type="text"
                                {...register('address_1', {
                                  required: 'Address 1 is Required',
                                  pattern: {
                                    value:
                                      /^(?!.*(?:\bP\.?\s?O\.?\s?Box\b|\bPost\s?Office\s?Box\b|\bPO\s?Box\b|\bPO\b|\bPost\b|\bOffice\b|\bPostal\s?(?:Box|Service)\b)).*$/i,
                                    message: 'PO Boxes are not allowed',
                                  },
                                })}
                              />
                            </label>
                          </div>
                          {/* Address 2 */}
                          <div className="flex w-full flex-col lg:w-1/2">
                            <label htmlFor="address-2">
                              <div className="text-xs font-semibold">
                                Address 2{' '}
                                <span className="font-normal text-text-error-red">
                                  {errors?.address_2?.message || ''}
                                </span>
                              </div>
                              <input
                                id="address-2"
                                className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                  hasAdminAccess
                                    ? ''
                                    : 'cursor-not-allowed bg-neutral-100'
                                }`}
                                placeholder="Address 2"
                                type="text"
                                {...register('address_2', {
                                  pattern: {
                                    value:
                                      /^(?!.*(?:\bP\.?\s?O\.?\s?Box\b|\bPost\s?Office\s?Box\b|\bPO\s?Box\b|\bPO\b|\bPost\b|\bOffice\b|\bPostal\s?(?:Box|Service)\b)).*$/i,
                                    message: 'PO Boxes are not allowed',
                                  },
                                })}
                              />
                            </label>
                          </div>
                        </div>
                        {/* City and State */}
                        <div className="flex flex-col gap-3 lg:flex-row">
                          {/* City */}
                          <div className="flex w-full flex-col lg:w-1/2">
                            <label htmlFor="city">
                              <div className="text-xs font-semibold">
                                City{' '}
                                <span className="text-text-error-red">
                                  *{' '}
                                  <span className="font-normal">
                                    {errors?.city?.message || ''}
                                  </span>
                                </span>
                              </div>
                              <input
                                id="city"
                                className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                  hasAdminAccess
                                    ? ''
                                    : 'cursor-not-allowed bg-neutral-100'
                                }`}
                                placeholder="City"
                                type="text"
                                {...register('city', {
                                  required: 'City is Required',
                                })}
                              />
                            </label>
                          </div>
                          {/* State */}
                          <div className="flex w-full flex-col lg:w-1/2">
                            <div className="text-xs font-semibold">
                              State{' '}
                              <span className="text-text-error-red">
                                *{' '}
                                <span className="font-normal">
                                  {errors?.province?.message || ''}
                                </span>
                              </span>
                            </div>
                            <Controller
                              name="province"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  id="province"
                                  showSearch
                                  placeholder="Select a state"
                                  size="large"
                                  className="my-1 w-full"
                                  disabled={!hasAdminAccess}
                                  {...register('province', {
                                    required: 'State is Required',
                                  })}
                                  filterOption={(input, option) =>
                                    (option?.label ?? '')
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={
                                    provinces?.map((province) => ({
                                      value: province.id,
                                      label:
                                        province.alpha_code || province.name,
                                    })) || []
                                  }
                                  onChange={onChange}
                                  value={value}
                                />
                              )}
                            />
                          </div>
                        </div>
                        {/* Postal Code */}
                        <div className="flex w-full flex-col">
                          <label htmlFor="postal-code">
                            <div className="text-xs font-semibold">
                              Postal Code{' '}
                              <span className="text-text-error-red">
                                *{' '}
                                <span className="font-normal">
                                  {errors?.postal_code?.message || ''}
                                </span>
                              </span>
                            </div>
                            <input
                              id="postal-code"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess
                                  ? ''
                                  : 'cursor-not-allowed bg-neutral-100'
                              }`}
                              placeholder="Postal Code"
                              type="text"
                              {...register('postal_code', {
                                required: 'Postal Code is Required',
                              })}
                            />
                          </label>
                        </div>
                      </fieldset>
                    ) : (
                      <Skeleton active />
                    )}
                  </section>

                  {/* End Address 1 Inputs */}
                </section>
                {/* End Type and Nature Inputs */}

                <section id="optional-info">
                  <div className="mb-4">
                    <span className="text-xl font-semibold">Optional Info</span>
                  </div>
                  <hr className="mb-4 border border-r-gray-100" />

                  {/* Start DBA Name and Parent Company Inputs */}

                  <fieldset className="mb-6 flex flex-col flex-wrap gap-3 lg:flex-nowrap">
                    <h4 className="text-base font-semibold">
                      Additional Details
                    </h4>
                    <div className="flex flex-col gap-3 lg:flex-row">
                      <div className="flex w-full flex-col">
                        <label htmlFor="dba-business-name">
                          <div className="text-xs font-semibold">
                            DBA Business Name
                          </div>
                          <input
                            id="dba-business-name"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess
                                ? ''
                                : 'cursor-not-allowed bg-neutral-100'
                            }`}
                            placeholder="DBA Business Name"
                            type="text"
                            {...register('dba_business_name')}
                          />
                        </label>
                      </div>
                      <div className="flex w-full flex-col">
                        <label htmlFor="parent-company">
                          <div className="text-xs font-semibold">
                            Parent Company
                          </div>
                          <input
                            id="parent-company"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess
                                ? ''
                                : 'cursor-not-allowed bg-neutral-100'
                            }`}
                            placeholder="Parent Company"
                            type="text"
                            {...register('parent_company')}
                          />
                        </label>
                      </div>
                    </div>
                  </fieldset>

                  {/* End Name and Parent Company Inputs */}

                  {/* Start Phone Number and CSR Phone Number Inputs */}

                  <fieldset className="mb-6 flex flex-wrap gap-3 lg:flex-nowrap">
                    <div className="flex w-full flex-col">
                      <label htmlFor="toll_free_phone">
                        <div className="text-xs font-semibold">
                          Toll-free Phone Number
                        </div>
                        <input
                          id="toll_free_phone"
                          className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                            hasAdminAccess
                              ? ''
                              : 'cursor-not-allowed bg-neutral-100'
                          }`}
                          placeholder="XXX-XXX-XXXX"
                          type="text"
                          {...register('toll_free_phone', {
                            pattern: {
                              value:
                                /^(?:\+\d{1,3}\s?)?(?:\(\d{3}\)|\d{3})[-\s]?\d{3}[-\s]?\d{4}$/,
                              message: 'Invalid Phone Number',
                            },
                          })}
                        />
                      </label>
                    </div>
                    <div className="flex w-full flex-col">
                      <label htmlFor="csr-phone">
                        <div className="text-xs font-semibold">
                          Customer Service Phone Number
                        </div>
                        <input
                          id="csr-phone"
                          className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                            hasAdminAccess
                              ? ''
                              : 'cursor-not-allowed bg-neutral-100'
                          }`}
                          placeholder="XXX-XXX-XXXX"
                          type="text"
                          {...register('csr_phone', {
                            pattern: {
                              value:
                                /^(?:\+\d{1,3}\s?)?(?:\(\d{3}\)|\d{3})[-\s]?\d{3}[-\s]?\d{4}$/,
                              message: 'Invalid Phone Number',
                            },
                          })}
                        />
                      </label>
                    </div>
                  </fieldset>

                  {/* End Phone Number and CSR Phone Number Inputs */}

                  {/* Start Other Phone Number and Website Inputs */}

                  <fieldset className="mb-6 flex flex-wrap gap-3 lg:flex-nowrap">
                    <div className="flex w-full flex-col">
                      <label htmlFor="other-phone">
                        <div className="text-xs font-semibold">
                          Other Phone Number
                        </div>
                        <input
                          id="other-phone"
                          className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                            hasAdminAccess
                              ? ''
                              : 'cursor-not-allowed bg-neutral-100'
                          }`}
                          placeholder="XXX-XXX-XXXX"
                          type="text"
                          {...register('other_phone', {
                            pattern: {
                              value:
                                /^(?:\+\d{1,3}\s?)?(?:\(\d{3}\)|\d{3})[-\s]?\d{3}[-\s]?\d{4}$/,
                              message: 'Invalid Phone Number',
                            },
                          })}
                        />
                      </label>
                    </div>
                    <div className="flex w-full flex-col">
                      <label htmlFor="website-url">
                        <div className="text-xs font-semibold">Website URL</div>
                        <input
                          id="website-url"
                          className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                            hasAdminAccess
                              ? ''
                              : 'cursor-not-allowed bg-neutral-100'
                          }`}
                          placeholder="Website URL"
                          type="text"
                          {...register('website_url')}
                        />
                      </label>
                    </div>
                  </fieldset>
                </section>

                {/* End Other Phone Number and Website Inputs */}
              </div>

              <div className="flex justify-end">
                <button
                  className={`h-10 w-[25%] items-center rounded-lg bg-surface-primary-dark text-white ${
                    hasAdminAccess ? '' : 'cursor-not-allowed bg-neutral-400'
                  }`}
                  type="submit"
                  disabled={!hasAdminAccess || isLoading}
                >
                  {isLoading ? <LoadingSpinner /> : <p>Save</p>}
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
}
