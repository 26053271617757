import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { Modal, Radio, RadioChangeEvent, Select } from 'antd';
import dayjs from 'dayjs';
import { UserSearchQueryParams } from '../../../types/user/userSearchQueryParams';
import { BusinessDTO } from '../../../types/business/businessDTO';

/**
 * Modal for showing filters a user can apply
 * to the list of users.
 */
export function UserFiltersModal({
  businesses,
  isFilterModalOpen,
  setIsFilterModalOpen,
  setFilters,
}: {
  businesses: BusinessDTO[];
  isFilterModalOpen: boolean;
  setIsFilterModalOpen: Dispatch<SetStateAction<boolean>>;
  setFilters: Dispatch<SetStateAction<UserSearchQueryParams>>;
}): ReactElement {
  /**
   * Initialize filters for the list of users.
   */
  const [kycStatusFilter, setKycStatusFilter] = useState<string | null>(null);
  const [userStatusFilter, setUserStatusFilter] = useState<string | null>(null);
  const [businessIdFilter, setBusinessIdFilter] = useState<number | null>(null);
  /**
   * Initialize and prepare data for the date created filter.
   */
  const todaysDate = dayjs();
  const last7Days = todaysDate.subtract(7, 'day').format('YYYY-MM-DD');
  const last30Days = todaysDate.subtract(30, 'day').format('YYYY-MM-DD');
  const lastYear = todaysDate.subtract(1, 'year').format('YYYY-MM-DD');
  const anyTime = todaysDate.subtract(10, 'year').format('YYYY-MM-DD');
  const [createdAtFilter, setCreatedAtFilter] = useState<string>(anyTime);
  const createdAtSelectOptions = [
    {
      label: 'Today',
      value: todaysDate.format('YYYY-MM-DD'),
    },
    {
      label: 'Last 7 Days',
      value: last7Days,
    },
    {
      label: 'Last 30 Days',
      value: last30Days,
    },
    {
      label: 'Last 12 Months',
      value: lastYear,
    },
    {
      label: 'Any Time',
      value: anyTime,
    },
  ];

  /**
   * Filters the list of users by provided filters.
   *
   * If the filter was *not* set, the current iterated user
   * automatically passes the filter test.
   *
   * If the filter is set, the filter value is compared to
   * the current iterated user value. If the values match,
   * the user passes the filter test. If they do not, the
   * user does not get returned in the filtered list.
   *
   */
  const applyFilters = () => {
    setFilters((prevFilters) => ({
      searchTerm: prevFilters.searchTerm,
      kycStatus: kycStatusFilter,
      userStatus: userStatusFilter,
      createdAt: createdAtFilter,
      businessId: businessIdFilter,
    }));
    setIsFilterModalOpen(false);
  };

  /**
   * Sets all local state filter variables back
   * to their defaults/placeholders
   */
  const clearFilters = () => {
    setCreatedAtFilter(anyTime);
    setKycStatusFilter(null);
    setUserStatusFilter(null);
    setBusinessIdFilter(null);
  };

  return (
    <Modal
      open={isFilterModalOpen}
      onCancel={() => setIsFilterModalOpen(false)}
      footer=""
    >
      <h2 className="text-xl">Select User Filters</h2>
      <div className="py-2">
        <div className="text-xs font-semibold">Date Created</div>
        <Radio.Group
          value={createdAtFilter}
          onChange={(e: RadioChangeEvent) => {
            setCreatedAtFilter(e.target.value);
          }}
        >
          <div className="flex flex-col">
            {createdAtSelectOptions.map((option) => (
              <Radio value={option.value} key={option.value.toString()}>
                {option.label}
              </Radio>
            ))}
          </div>
        </Radio.Group>
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold">User Status</div>
        <Select
          id="user_status"
          allowClear
          placeholder="Select a user status"
          size="large"
          className="my-1 w-full"
          options={[
            { label: 'Active', value: 'true' },
            { label: 'Inactive', value: 'false' },
          ]}
          value={userStatusFilter}
          onChange={(userStatus: string) => setUserStatusFilter(userStatus)}
        />
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold">KYC Status</div>
        <Select
          id="kyc-status"
          allowClear
          placeholder="Select a KYC status"
          size="large"
          className="my-1 w-full"
          options={[
            { label: 'Accepted', value: 'ACCEPTED' },
            { label: 'Pending', value: 'PENDING' },
            { label: 'Rejected', value: 'REJECTED' },
            { label: 'Manual Approval', value: 'MANUAL_APPROVAL' },
          ]}
          value={kycStatusFilter}
          onChange={(kycStatus: string) => setKycStatusFilter(kycStatus)}
        />
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold">Business</div>
        <Select
          id="business-id"
          showSearch
          allowClear
          placeholder="Select a business"
          size="large"
          className="my-1 w-full"
          options={businesses.map((business) => ({
            label: business.legal_business_name,
            value: business.id,
          }))}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          value={businessIdFilter}
          onChange={(businessId: number) => setBusinessIdFilter(businessId)}
        />
      </div>
      <div className="flex justify-between gap-2 py-2">
        <button
          className="w-full rounded-full border border-border-primary bg-white p-3 text-text-primary transition duration-300 ease-in-out hover:bg-surface-primary hover:text-white"
          onClick={clearFilters}
          type="button"
        >
          Clear Filters
        </button>
        <button
          type="submit"
          onClick={applyFilters}
          className="w-full rounded-full bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
        >
          Search
        </button>
      </div>
    </Modal>
  );
}
