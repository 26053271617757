import React, { ReactElement } from 'react';

export function AvailableBalance({
  availableBalance,
}: {
  availableBalance: number;
}): ReactElement {
  if (!availableBalance) {
    return <span className="text-text-gray">$0.00</span>;
  }
  return (
    <span className="text-text-gray">
      {availableBalance < 0 ? '-' : ''}$
      {Math.abs(availableBalance / 100).toFixed(2)}
    </span>
  );
}
