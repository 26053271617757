import React from 'react';
import {
  FieldErrors,
  RegisterOptions,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { SignInFormValues } from '../../../types/forms/signInFormValues';

type EmailFormInputProps = {
  errors: FieldErrors<SignInFormValues>;
  register: (
    name: 'email',
    options?: RegisterOptions<SignInFormValues, 'email'> | undefined,
  ) => UseFormRegisterReturn<'email'>;
};

export default function EmailFormInput({
  register,
  errors,
}: EmailFormInputProps) {
  return (
    <div className="flex flex-col">
      <div
        className="m-1 text-xs text-text-error-red"
        data-testid="email-errors"
      >
        {errors?.email?.message || ''}
      </div>
      <input
        id="email"
        className="rounded-lg border border-zinc-200 p-2"
        placeholder="Email Address"
        type="text"
        data-testid="email"
        {...register('email', {
          pattern: {
            value:
              /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: 'Invalid Email Address',
          },
          required: 'Email is Required',
        })}
      />
    </div>
  );
}
