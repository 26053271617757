import { AddCardRequest } from '../types/cards/addCardRequest';
import { AddCardResponse } from '../types/cards/addCardResponse';
import { AssignCardRequest } from '../types/cards/assignCardRequest';
import { AssignCardResponse } from '../types/cards/assignCardResponse';
import { BulkAddCardResponse } from '../types/cards/bulkAddCardResponse';
import { BulkAssignCardResponse } from '../types/cards/bulkAssignCardResponse';
import { BulkLoadFundsReponse } from '../types/cards/bulkLoadFundsResponse';
import {
  BulkAssignCardsTemplateResponse,
  BulkCreateCardsTemplateResponse,
  BulkLoadFundsTemplateResponse,
} from '../types/cards/bulkTemplateResponses';
import { CardModel } from '../types/cards/cardModel';
import { CardTransactionModel } from '../types/cards/cardTransactionModel';
import { LoadFundsRequest } from '../types/cards/loadFundsRequest';
import { ReplaceCardRequest } from '../types/cards/replaceCardRequest';
import { ReplaceCardResponse } from '../types/cards/replaceCardResponse';

import TernFetchAPI from './TernFetchAPI';

export const getCardsForAccount = async (
  accountId: number,
  queryString = '?page=1&pageSize=100',
): Promise<CardModel[] | null> => {
  const cardsResponse = await TernFetchAPI.get<CardModel[]>(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/card${queryString}&user_account_id=${accountId}&excludeInactiveCards=true`,
  );
  return cardsResponse.data || null;
};

export const getCardForBusiness = async (
  busnessId: number,
  queryString = '?page=1&pageSize=100',
): Promise<CardModel | null> => {
  const cardsResponse = await TernFetchAPI.get<CardModel[]>(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/card${queryString}&business_id=${busnessId}&excludeInactiveCards=true`,
  );
  return cardsResponse.data ? cardsResponse.data[0] : null;
};

export const getCardTransactionsForAccount = async (
  cardId: number,
  queryString = '?page=1&pageSize=5',
): Promise<CardTransactionModel[] | null> => {
  const transactionsResponse = await TernFetchAPI.get<CardTransactionModel[]>(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/transaction${queryString}&card_id=${cardId}`,
  );
  return transactionsResponse.data || null;
};

export const getCard = async (
  cardIdentifier: string,
): Promise<CardModel | null> => {
  const cardResponse = await TernFetchAPI.get<CardModel[]>(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/card/${cardIdentifier}`,
  );
  return cardResponse.data ? cardResponse.data[0] : null;
};

export const updateCardStatus = async (
  cardIdentifier: string,
  status: string,
): Promise<CardModel | null> => {
  const cardStatusUpdateResponse = await TernFetchAPI.put<CardModel[]>(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/card/${cardIdentifier}`,
    {
      status,
    },
  );
  return cardStatusUpdateResponse.data
    ? cardStatusUpdateResponse.data[0]
    : null;
};

export const assignCardToUserAccount = async (body: AssignCardRequest) => {
  const assignCardResponse = await TernFetchAPI.patch<AssignCardResponse[]>(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/card/assign`,
    body,
  );
  return assignCardResponse.data ? assignCardResponse.data[0] : null;
};

export const bulkAssignCardsToUserAccounts = async (
  body: AssignCardRequest[],
): Promise<BulkAssignCardResponse | null> => {
  const assignCardResponse = await TernFetchAPI.post<BulkAssignCardResponse>(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/card/assign/bulk`,
    body,
  );
  return assignCardResponse.data || [];
};

export const addCardToUserAccount = async (
  body: AddCardRequest,
): Promise<AddCardResponse | null> => {
  const addCardResponse = await TernFetchAPI.post<AddCardResponse[]>(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/card`,
    body,
  );
  return addCardResponse.data ? addCardResponse.data[0] : null;
};

export const bulkAddCardsToUserAccounts = async (
  body: AddCardRequest[],
): Promise<BulkAddCardResponse | null> => {
  const assignCardResponse = await TernFetchAPI.post<BulkAddCardResponse>(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/card/bulk`,
    body,
  );
  return assignCardResponse.data || [];
};

export const replaceCardByIdentifier = async (
  cardIdentifier: string,
  body: ReplaceCardRequest,
): Promise<ReplaceCardResponse | null> => {
  const replaceCardResponse = await TernFetchAPI.post<ReplaceCardResponse[]>(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/card/${cardIdentifier}/replace`,
    body,
  );
  return replaceCardResponse.data ? replaceCardResponse.data[0] : null;
};

export const bulkLoadFundsToUserAccount = async (
  body: LoadFundsRequest[],
): Promise<BulkLoadFundsReponse | null> => {
  const transferFundsResponse = await TernFetchAPI.post<BulkLoadFundsReponse>(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/card/load/bulk`,
    body,
  );
  return transferFundsResponse.data || [];
};

export const getBulkTemplateData = async (query: {
  type: 'loadFunds' | 'createCards' | 'assignCards' | 'createUnassignedCards';
}): Promise<
  | BulkLoadFundsTemplateResponse[]
  | BulkCreateCardsTemplateResponse[]
  | BulkAssignCardsTemplateResponse[]
> => {
  const bulkTemplateData = await TernFetchAPI.get<
    | BulkLoadFundsTemplateResponse[]
    | BulkCreateCardsTemplateResponse[]
    | BulkAssignCardsTemplateResponse[]
  >(
    process.env.REACT_APP_LITHIC_API_URL || '',
    `cards/bulk-templates?type=${query.type}`,
  );
  return bulkTemplateData.data || null;
};
