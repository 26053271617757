import React, { ReactElement } from 'react';
import LoadingSpinner from '../../LoadingSpinner';
import { BulkResponseDTO } from '../../../types/bulkDTO';
import { ReactComponent as WarningIcon } from '../../../assets/warning-yellow.svg';

export function BulkRecordsAttempted({
  records,
  isLoading,
  downloadAction,
  onCancel,
}: {
  records: unknown[] | [];
  isLoading: boolean;
  downloadAction: (records: unknown[]) => void;
  onCancel: () => void;
}): ReactElement {
  if (!Array.isArray(records))
    Error(`prop 'records' requires an array. recieved '${typeof records}'`);

  const total = records?.length || 0;
  const successes = records.filter(
    (rec: BulkResponseDTO) => rec.status === 'success',
  );

  return (
    <div className="flex flex-col justify-center">
      <h2 className="mb-6 text-xl font-medium">Records Attempted</h2>
      <div className="flex w-full items-center">
        <p className="mb-6 flex w-full items-baseline">
          <span className="text-3xl">{successes?.length}</span>
          <span className="text-2xl leading-9 text-text-light-gray">
            /{total} Added
          </span>
        </p>
      </div>
      <hr />
      <div className="mt-6 flex w-full items-center rounded-md bg-surface-action-orange px-4 py-6">
        <WarningIcon className="mr-3" />
        <p className="mt-0 w-full text-sm text-text-action-orange">
          Not all of your uploads were successful. Please{' '}
          <b>download your report</b> to correct any issues and try again.
        </p>
      </div>
      <footer className="mt-6 flex flex-col gap-4 pb-2">
        <button
          className="flex h-10 w-full items-center justify-center rounded-full border-border-primary bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-light"
          type="button"
          onClick={() => downloadAction(records)}
        >
          Download Report
        </button>
        <button
          className="flex h-10 items-center justify-center rounded-full border border-border-light-gray bg-white p-3 transition duration-300 ease-in-out hover:bg-surface-gray hover:text-white"
          type="button"
          onClick={() => onCancel()}
        >
          {isLoading ? <LoadingSpinner /> : 'Close'}
        </button>
      </footer>
    </div>
  );
}
