import { Spin } from 'antd';
import React, { ReactElement } from 'react';

export default function LoadingSpinner(): ReactElement {
  return (
    <div className="text-center">
      <Spin />
    </div>
  );
}
