import React from 'react';
import {
  FieldErrors,
  RegisterOptions,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { SignInFormValues } from '../../../types/forms/signInFormValues';
import { ResetPasswordFormValues } from '../../../types/forms/resetPasswordFormValues';

type PasswordFormInputProps = {
  errors: FieldErrors<SignInFormValues | ResetPasswordFormValues>;
  register: (
    name: 'password',
    options?:
      | RegisterOptions<SignInFormValues | ResetPasswordFormValues, 'password'>
      | undefined,
  ) => UseFormRegisterReturn<'password'>;
};

export default function PasswordFormInput({
  register,
  errors,
}: PasswordFormInputProps) {
  return (
    <div className="flex flex-col">
      <div
        className="m-1 text-xs text-text-error-red"
        data-testid="password-errors"
      >
        {errors?.password?.message || ''}
      </div>
      <input
        id="password"
        className="rounded-lg border border-zinc-200 p-2"
        placeholder="Password"
        type="password"
        {...register('password', {
          required: 'Password is Required',
        })}
      />
    </div>
  );
}
