import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { DatePicker, Select, Skeleton, notification } from 'antd';
import { createColumnHelper, CellContext } from '@tanstack/table-core';
import dayjs from 'dayjs';
import { ReactComponent as Plus } from '../../../assets/plus.svg';
import Table from '../../../components/Table';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { StatusCard } from '../../../components/StatusCard';
import { Roles, isAdminOrMTU, isBusinessAdmin } from '../../../utils/authUtils';
import { UserAccountDTO } from '../../../types/user/userAccountDTO';
import { tableStatusCell } from '../../../components/TableStatusCell';
import { UserAccountHistoryDTO } from '../../../types/user/userAccountHistoryDTO';
import { getBalance, maskAccountNumber } from '../../../utils/accountUtils';
import { UpdateUserRequestDTO } from '../../../types/user/updateUserRequestDTO';
import {
  createUserInitials,
  dateNotInFuture,
  prepareUpdateRequestBody,
} from '../../../utils/userUtils';
import { AddFundsModal } from '../../../components/Modals/AddFundsModal';
import { TransferFundsRequestDTO } from '../../../types/transfers/transferFundsRequestDTO';
import { AddFundsFormValues } from '../../../types/user/addFundsFormValues';
import {
  useGetCountries,
  useGetProvinces,
  useGetUserAccounts,
  useGetUserWithFullName,
} from '../../../api/rq/queries/userQueries';
import { useGetBusinesses } from '../../../api/rq/queries/businessQueries';
import {
  useAddFundsToUserAccount,
  useUpdateUser,
} from '../../../api/rq/mutations/userMutations';
import BankDetails from '../../../components/BankDetails';
import { CountryDTO } from '../../../types/business/countryDTO';
import { UserDTO } from '../../../types/user/userDTO';
import {
  resetPassword,
  sendForgottenPasswordEmail,
} from '../../../api/AuthApi';
import { ResetPasswordFormValues } from '../../../types/forms/resetPasswordFormValues';
import { useAuthApi } from '../../../context/authContext';

export function UserDetailInfo(): ReactElement {
  /**
   * Local state
   */
  const [selectedBusinessIds, setSelectedBusinessIds] = useState<number[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<UserAccountDTO>();
  const [selectedResidentialCountryId, setSelectedResidentialCountryId] =
    useState<string | null>(null);
  const [selectedShippingCountryId, setSelectedShippingCountryId] = useState<
    string | null
  >(null);
  const [businessRequiredError, setBusinessRequiredError] =
    useState<string>('');

  const hasAdminAccess = isAdminOrMTU();
  const hasBusinessAdminRole = isBusinessAdmin();
  const authContext = useAuthApi();
  const loggedInUser = authContext?.user;

  const navigate = useNavigate();

  /**
   * Notification state
   */
  const [notify, notificationContext] = notification.useNotification();

  /**
   * Modal state
   */
  const [isAddFundsModalOpen, setIsAddFundsModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<UpdateUserRequestDTO>({});

  const { register: registerReset, handleSubmit: handleSubmitReset } =
    useForm<ResetPasswordFormValues>();

  const { userId: userIdQueryParam } = useParams();
  const [queryStringParams] = useSearchParams();
  const userStatus = queryStringParams.get('status');

  /**
   * Cannot select any other roles since the user
   * has already been created as Cardholder.
   */
  const roleDropdownItems = [
    {
      id: Roles.RegularUser,
      name: 'Cardholder',
    },
  ];

  /**
   * RQ Mutations for post/put/patch updates
   */
  const { mutateAsync: mutateAddFunds } = useAddFundsToUserAccount();
  const { mutateAsync: mutateUpdateUser, isLoading: updateUserLoading } =
    useUpdateUser();

  /**
   * RQ Queries for fetching data
   *
   * When a second argument is passed using !!, the query will only run
   * when that variable is truthy. This is useful for when you want to
   * fetch data based on a query param that may or may not be present.
   */
  const { data: selectedUserData, isLoading: isLoadingUserData } =
    useGetUserWithFullName(
      parseInt(userIdQueryParam || '0'),
      !!userIdQueryParam,
    );

  useEffect(() => {
    if (userStatus === 'success') {
      notify.success({
        message: 'Success!',
        description: `User was created successfully!`,
        placement: 'topLeft',
      });
    }
  }, [userStatus, notify]);

  const { data: userAccountsData, isLoading: isLoadingUserAccounts } =
    useGetUserAccounts(parseInt(userIdQueryParam || '0'), !!userIdQueryParam);

  const { data: countriesData, isLoading: countriesLoading } =
    useGetCountries();

  const { data: residentialProvincesData, refetch: fetchResidentialProvinces } =
    useGetProvinces(
      selectedResidentialCountryId || '0',
      !!selectedResidentialCountryId && selectedResidentialCountryId !== '0',
    );

  const { data: shippingProvincesData, refetch: fetchShippingProvinces } =
    useGetProvinces(
      selectedShippingCountryId || '0',
      !!selectedShippingCountryId && selectedShippingCountryId !== '0',
    );

  const { data: allBusinessesData } = useGetBusinesses('?page=1&pageSize=1000');

  /**
   * Related Businesses select is handled by local component
   * state and not react-hook-form.
   */
  const allBusinessIds = allBusinessesData?.map((biz) => biz.id);

  const determineSelectedCountry = useCallback(
    (countryName: string | null): string | null => {
      if (!countryName) return null;
      let alphaCode = '';
      /**
       * Deal with inconsistencies in country DB data
       */
      if (countryName === 'USA' || countryName === 'US') {
        alphaCode = 'US';
      } else {
        /**
         * Other countries
         */
        const code = countriesData?.find((c) => {
          return c.name === countryName;
        });
        alphaCode = code?.alpha_code ?? '';
      }
      /**
       * Find and return selected country name based on alpha code
       */
      const country = countriesData?.find((c) => {
        return c.alpha_code === alphaCode;
      });
      return country?.name ?? '';
    },
    [countriesData],
  );

  const handleCountries = (
    countries: CountryDTO[] | undefined,
    selectedUser: UserDTO | undefined | null,
  ) => {
    const userResidentialCountry = countries?.filter((c) => {
      /**
       * Handle values that are set in the DB as
       * USA and not United States of America
       */
      if (selectedUser?.country === 'USA') {
        return c.alpha_code === 'US';
      }
      return c.alpha_code === selectedUser?.country;
    })[0];
    const userShippingCountry = countries?.filter((c) => {
      /**
       * Handle values that are set in the DB as
       * USA and not United States of America
       */
      if (selectedUser?.ship_country === 'USA') {
        return c.alpha_code === 'US';
      }
      return c.alpha_code === selectedUser?.ship_country;
    })[0];
    setSelectedResidentialCountryId(
      userResidentialCountry?.id.toString() || null,
    );
    setSelectedShippingCountryId(userShippingCountry?.id.toString() || null);
  };

  useEffect(() => {
    if (countriesData) {
      setSelectedBusinessIds(selectedUserData?.business_ids || []);
      handleCountries(countriesData, selectedUserData);
      reset({
        role_id: selectedUserData?.role_id ?? undefined,
        external_id: selectedUserData?.external_id ?? '',
        first_name: selectedUserData?.first_name ?? '',
        middle_name: selectedUserData?.middle_name ?? '',
        last_name: selectedUserData?.last_name ?? '',
        email: selectedUserData?.email ?? '',
        mobile_phone: selectedUserData?.mobile_phone ?? '',
        address_1: selectedUserData?.address_1 ?? '',
        address_2: selectedUserData?.address_2 ?? '',
        city: selectedUserData?.city ?? '',
        province: selectedUserData?.province ?? null,
        country: selectedUserData
          ? determineSelectedCountry(selectedUserData.country)
          : null,
        postal_code: selectedUserData?.postal_code ?? '',
        ship_address_1: selectedUserData?.ship_address_1 ?? '',
        ship_address_2: selectedUserData?.ship_address_2 ?? '',
        ship_city: selectedUserData?.ship_city ?? '',
        ship_province: selectedUserData?.ship_province || null,
        ship_postal_code: selectedUserData?.ship_postal_code ?? '',
        ship_country: selectedUserData
          ? determineSelectedCountry(selectedUserData.country)
          : null,
        dob: selectedUserData?.dob
          ? dayjs(selectedUserData?.dob).format('MM-DD-YYYY')
          : '',
      });
    }
  }, [selectedUserData, countriesData, reset, determineSelectedCountry]);

  const selectResidentialCountry = async (countryId: string): Promise<void> => {
    setSelectedResidentialCountryId(countryId);
    fetchResidentialProvinces();
  };

  const selectShipCountry = async (countryId: string): Promise<void> => {
    setSelectedShippingCountryId(countryId);
    fetchShippingProvinces();
  };

  const makeActiveCardCountCell = (
    info: CellContext<UserAccountDTO, ReactNode>,
  ) => {
    return <div className="ml-[25%]">{info.getValue()}</div>;
  };

  const formatAvailableBalance = useCallback(
    (
      info: CellContext<UserAccountDTO, ReactNode>,
      centsSize = 'text-lg',
    ): ReactElement => {
      const balance = getBalance(info.row.original.available_balance || 0);
      const [dollars, cents] = balance.split('.');
      return (
        <div className="flex flex-col items-center justify-between xl:flex-row">
          <div>
            <span className="font-semibold">{dollars}</span>
            <span className={`${centsSize} text-text-gray`}>
              {cents ? `.${cents}` : ''}
            </span>
          </div>
          {hasAdminAccess && (
            <button
              id="add-funds"
              type="button"
              onClick={() => {
                setSelectedAccount(info.row.original);
                setIsAddFundsModalOpen(true);
              }}
              className="flex w-[103px] cursor-pointer items-center justify-center rounded-lg bg-surface-primary px-2 py-1 text-xs text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark xl:ml-2"
            >
              <div className="mr-2 flex h-5 w-5 items-center justify-center rounded-full bg-white">
                <Plus />
              </div>
              Add Funds
            </button>
          )}
        </div>
      );
    },
    [hasAdminAccess],
  );
  const columnHelper = createColumnHelper<UserAccountDTO>();

  const accountColumns = useCallback(() => {
    const makeUserAccountNameCell = (
      info: CellContext<UserAccountDTO, unknown>,
    ) => {
      return (
        <div className="flex items-center">
          <BankDetails info={info} />
          <button
            type="button"
            className="text-left text-text-primary hover:underline"
            onClick={() =>
              navigate(
                `/dashboard/users/${info.row.original.user_id}/accounts/${
                  info.row.original.id
                }${hasBusinessAdminRole ? '/deposits' : ''}`,
              )
            }
          >
            <div className="flex">
              <span className="pr-2">{info.row.original.name}</span>
              <span>{maskAccountNumber(info.row.original.account_number)}</span>
            </div>
          </button>
        </div>
      );
    };
    return [
      columnHelper.accessor('name', {
        cell: (info) => makeUserAccountNameCell(info),
        header: 'NAME',
      }),
      columnHelper.accessor('user_account_status_id', {
        header: 'STATUS',
        cell: (info) => tableStatusCell<UserAccountDTO>(info),
      }),
      columnHelper.accessor('active_card_count', {
        header: 'ACTIVE CARDS',
        cell: (info) => makeActiveCardCountCell(info),
      }),
      columnHelper.accessor('available_balance', {
        header: 'BALANCE',
        cell: (info) => formatAvailableBalance(info, 'text-xs'),
      }),
    ];
  }, [columnHelper, formatAvailableBalance, navigate, hasBusinessAdminRole]);

  const historyColumnHelper = createColumnHelper<UserAccountHistoryDTO>();

  const accountHistoryColumns = useMemo(
    () => [
      historyColumnHelper.accessor('event', {
        cell: (info) => info.getValue(),
        header: 'EVENT',
      }),
      historyColumnHelper.accessor('date', {
        header: 'DATE',
        cell: (info) => info.getValue() || '-',
      }),
    ],
    [historyColumnHelper],
  );

  const accountHistory: UserAccountHistoryDTO[] = [];

  if (selectedUserData?.created_at) {
    accountHistory.push({
      event: 'Created',
      date: dayjs(selectedUserData.created_at).format('MMM DD, YYYY'),
    });
  }

  if (selectedUserData?.updated_at) {
    accountHistory.push({
      event: 'Last Updated',
      date: dayjs(selectedUserData?.updated_at).format('MMM DD, YYYY'),
    });
  }

  if (selectedUserData?.kyc_approval_date) {
    accountHistory.push({
      event: 'KYC Approved',
      date: dayjs(selectedUserData.kyc_approval_date).format('MMM DD, YYYY'),
    });
  }

  /**
   * Transforms form data into the correct format
   * and then calls the User API to either create a
   * new user or update an existing user.
   * @param data Form data
   */
  const updateExistingUser = async (
    data: UpdateUserRequestDTO,
  ): Promise<void> => {
    if (!selectedBusinessIds || selectedBusinessIds.length === 0) {
      setBusinessRequiredError('At least one business is required');
      document.documentElement.scrollTo(0, 0);
      return;
    }
    setBusinessRequiredError('');
    const updateRequestBody = prepareUpdateRequestBody({
      data,
      selectedBusinesses: selectedBusinessIds,
      residentialCountries: countriesData || [],
      residentialProvinces: residentialProvincesData || [],
      shipCountries: countriesData || [],
      shipProvinces: shippingProvincesData || [],
    });

    if (userIdQueryParam) {
      try {
        await mutateUpdateUser({
          userId: userIdQueryParam,
          body: updateRequestBody,
        });
        notify.success({
          message: 'Success!',
          description: `User Details have been saved and are now visible to edit`,
          placement: 'topLeft',
        });
      } catch (err) {
        notify.error({
          message: 'Error!',
          description: `There was an error updating the user: ${err.message}. Please try again.`,
          placement: 'topLeft',
        });
      } finally {
        document.documentElement.scrollTo(0, 0);
      }
    }
  };

  const setSelectedBusinesses = (businessIds: number[]) => {
    /**
     * filter for non deleted IDs since deleted ones
     * are also returned with the user's list ofbusiness IDs
     *
     */
    setSelectedBusinessIds(
      [...new Set(businessIds || [])].filter((id) =>
        allBusinessIds?.includes(id),
      ) || [],
    );
  };

  const addFunds = async (data: AddFundsFormValues): Promise<void> => {
    if (data.business_account_id && selectedAccount) {
      const addFundsRequest: TransferFundsRequestDTO = {
        amount: parseFloat(Math.fround(Number(data.amount) * 100).toFixed(2)),
        from_account_id: data.business_account_id,
        to_account_id: selectedAccount?.id,
        transfer_type: 'business_to_user',
      };
      try {
        await mutateAddFunds(addFundsRequest);
        setIsAddFundsModalOpen(false);
        notify.success({
          type: 'success',
          message: 'Success!',
          description: `Successfully added $${
            data.amount
          } to account ${maskAccountNumber(selectedAccount.account_number)}`,
          placement: 'topLeft',
        });
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  };

  const [resetPasswordStarted, setResetPassworStarted] = useState(false);

  const handleResetUserPassword = async (
    data: ResetPasswordFormValues,
  ): Promise<void> => {
    console.log(data, selectedUserData);
    try {
      const result = await resetPassword({
        email: selectedUserData?.email || '',
        ...data,
      });

      if (result && Object.keys(result[0]).length === 0) {
        notify.success({
          type: 'success',
          message: 'Success!',
          description: 'User Password reset successfully',
        });
      }

      setResetPassworStarted(false);
    } catch (error) {
      console.log(error.error);
      notify.error({
        type: 'error',
        message: 'Error!',
        description: error.error,
      });
    }
  };

  const handleSendForgottenPasswordEmail = async (): Promise<void> => {
    const result = await sendForgottenPasswordEmail(loggedInUser?.email || '');

    if (result && result.emailSent) {
      notify.success({
        type: 'success',
        message: 'Success!',
        description: 'Email sent successfully',
      });
    }
    setResetPassworStarted(true);
  };

  return (
    <>
      {notificationContext}
      {selectedUserData && (
        <AddFundsModal
          selectedUser={selectedUserData || null}
          selectedUserAccount={selectedAccount || null}
          businesses={allBusinessesData || []}
          onConfirm={addFunds}
          isModalOpen={isAddFundsModalOpen}
          setIsModalOpen={setIsAddFundsModalOpen}
        />
      )}
      <div className="rounded-lg border border-border-light-gray bg-white p-3 lg:p-6">
        <div className="flex flex-col gap-4 lg:flex-row">
          <div className="flex flex-1 flex-col gap-5 md:border-r-2 md:border-r-gray-100 md:pr-4 lg:pr-6">
            <h2 className="text-xl font-semibold">Personal Details</h2>
            {isLoadingUserData ? (
              <Skeleton active />
            ) : (
              <div className="flex w-full flex-col items-center gap-2 sm:flex-row">
                <div className="flex h-24 w-24 items-center justify-center rounded-full bg-surface-primary-dark text-4xl text-white sm:mr-8 sm:h-24">
                  {createUserInitials(
                    `${selectedUserData?.first_name} ${selectedUserData?.last_name}`,
                  )}
                </div>
                <StatusCard
                  title="KYC Status"
                  statusIdOrName={selectedUserData?.kyc_status || ''}
                  large={false}
                />
              </div>
            )}
            <div className="flex flex-col gap-3">
              {userAccountsData &&
                userAccountsData?.length > 0 &&
                !isLoadingUserAccounts && (
                  <>
                    <span className="text-lg font-semibold">Accounts</span>
                    <Table<UserAccountDTO>
                      data={userAccountsData || []}
                      columns={accountColumns()}
                    />
                  </>
                )}
              {isLoadingUserAccounts && <Skeleton active />}

              <span className="text-lg font-semibold">User History</span>
              {isLoadingUserData && <Skeleton active />}
              {accountHistory?.length > 0 && !isLoadingUserData && (
                <Table<UserAccountHistoryDTO>
                  data={accountHistory || []}
                  columns={accountHistoryColumns}
                />
              )}
            </div>
          </div>
          <form
            className="flex-1"
            onSubmit={handleSubmit((data) => updateExistingUser(data))}
          >
            <fieldset disabled={!hasAdminAccess}>
              <div className="gap-4">
                <div className="border-r-2 border-r-gray-100 pr-2 lg:pr-5">
                  {/* User Info Section */}
                  <section className="">
                    <div className="mb-4 flex gap-3">
                      <span className="text-xl font-semibold">User Info</span>
                    </div>
                    <hr className="mb-4 border border-r-gray-100" />

                    {/* User Role and Related Businesses */}
                    <div className="mb-4 flex w-full flex-col">
                      {/* Not part of react hook form, but handled in state */}
                      {selectedBusinessIds && selectedBusinessIds.length > 0 ? (
                        <>
                          <div className="mb-4 flex w-full flex-col">
                            <div className="mb-4 text-base font-semibold">
                              User Role{' '}
                              <span className="text-text-error-red">
                                *{' '}
                                <span className="text-xs font-normal">
                                  {errors.role_id?.message || ''}
                                </span>
                              </span>
                            </div>
                            <Controller
                              name="role_id"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="role-id"
                                  {...register('role_id', {
                                    required: 'Role is Required',
                                  })}
                                  allowClear
                                  placeholder="Select a role for this user"
                                  size="large"
                                  className="my-1 w-full"
                                  options={roleDropdownItems.map((role) => ({
                                    label: role.name,
                                    value: role.id,
                                  }))}
                                  disabled // not allowed to change role from Cardholder / Regular User
                                  onChange={onChange}
                                  value={value}
                                />
                              )}
                            />
                          </div>
                          <div className="mb-4 text-base font-semibold">
                            Business Relationships{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="text-xs font-normal">
                                {businessRequiredError || ''}
                              </span>
                            </span>
                          </div>
                          <Select
                            id="business-ids"
                            mode="multiple"
                            showSearch
                            placeholder="Select businesses"
                            size="large"
                            className="my-1 w-full"
                            disabled={!hasAdminAccess}
                            filterOption={(input, option) =>
                              (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={
                              allBusinessesData?.map((business) => ({
                                value: business.id,
                                label: business.legal_business_name,
                              })) || []
                            }
                            onChange={(ids) => {
                              setSelectedBusinesses(ids);
                            }}
                            value={selectedBusinessIds}
                          />
                        </>
                      ) : (
                        <Skeleton active />
                      )}
                    </div>

                    {/*
                      First, Middle, Last Name, Email, Mobile Phone, Ext ID
                    */}
                    <fieldset className="flex flex-col flex-wrap gap-3 lg:flex-nowrap">
                      <h4 className="text-base font-semibold">
                        Personal Details
                      </h4>
                      {/* {isLoadingUserData && <Skeleton active />} */}
                      {!isLoadingUserData && (
                        <>
                          {/* First and Middle Name */}
                          <div className="flex flex-col gap-3 md:flex-row">
                            {/* First Name */}
                            <div className="flex w-full flex-col lg:w-1/2">
                              <label htmlFor="first-name">
                                <div className="text-xs font-semibold">
                                  First Name{' '}
                                  <span className="text-text-error-red">
                                    *{' '}
                                    <span className="font-normal">
                                      {errors?.first_name?.message || ''}
                                    </span>
                                  </span>
                                </div>
                                <input
                                  id="first-name"
                                  className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                    hasAdminAccess
                                      ? ''
                                      : 'cursor-not-allowed bg-neutral-100'
                                  }`}
                                  placeholder="First Name"
                                  type="text"
                                  {...register('first_name', {
                                    required: 'First Name is Required',
                                  })}
                                />
                              </label>
                            </div>
                            {/* Middle Name */}
                            <div className="flex w-full flex-col lg:w-1/2">
                              <label htmlFor="middle-name">
                                <div className="text-xs font-semibold">
                                  Middle Name
                                </div>
                                <input
                                  id="middle-name"
                                  className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                    hasAdminAccess
                                      ? ''
                                      : 'cursor-not-allowed bg-neutral-100'
                                  }`}
                                  placeholder="Middle Name"
                                  type="text"
                                  {...register('middle_name')}
                                />
                              </label>
                            </div>
                          </div>
                          {/* Last Name and DOB */}
                          <div className="flex flex-col gap-3 md:flex-row">
                            {/* Last Name */}
                            <div className="flex w-full flex-col">
                              <label htmlFor="last-name">
                                <div className="text-xs font-semibold">
                                  Last Name{' '}
                                  <span className="text-text-error-red">
                                    *{' '}
                                    <span className="font-normal">
                                      {errors?.last_name?.message || ''}
                                    </span>
                                  </span>
                                </div>
                                <input
                                  id="last-name"
                                  className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                    hasAdminAccess
                                      ? ''
                                      : 'cursor-not-allowed bg-neutral-100'
                                  }`}
                                  placeholder="Last Name"
                                  type="text"
                                  {...register('last_name', {
                                    required: 'Last Name is Required',
                                  })}
                                />
                              </label>
                            </div>
                            {/* Date of Birth */}
                            <div className="flex w-full flex-col">
                              <div className="text-xs font-semibold">
                                Date of Birth{' '}
                                <span className="text-text-error-red">
                                  *{' '}
                                  <span className="font-normal">
                                    {errors?.dob?.message || ''}
                                  </span>
                                </span>
                              </div>
                              <Controller
                                control={control}
                                name="dob"
                                rules={{
                                  required: 'Date of Birth is Required',
                                }}
                                render={({ field, fieldState }) => {
                                  return (
                                    <DatePicker
                                      size="large"
                                      placeholder="YYYY-MM-DD"
                                      {...register('dob', {
                                        required: 'Date of Birth is Required',
                                        validate: (value) =>
                                          dateNotInFuture(value)
                                            ? undefined
                                            : 'Date of birth must be not be in the future',
                                      })}
                                      status={
                                        fieldState.error ? 'error' : undefined
                                      }
                                      ref={field.ref}
                                      name={field.name}
                                      className="my-1"
                                      onBlur={field.onBlur}
                                      disabled={!hasAdminAccess}
                                      value={
                                        field.value ? dayjs(field.value) : null
                                      }
                                      onChange={(date) => {
                                        field.onChange(
                                          date ? dayjs(date).toISOString() : '',
                                        );
                                      }}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                          {/* Email and Mobile Phone */}
                          <div className="flex flex-col gap-3 md:flex-row">
                            {/* Email */}
                            <div className="flex w-full flex-col lg:w-1/2">
                              <label htmlFor="email">
                                <div className="text-xs font-semibold">
                                  Email{' '}
                                  <span className="text-text-error-red">
                                    *{' '}
                                    <span className="font-normal">
                                      {errors?.email?.message || ''}
                                    </span>
                                  </span>
                                </div>
                                <input
                                  id="email"
                                  className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                    hasAdminAccess
                                      ? ''
                                      : 'cursor-not-allowed bg-neutral-100'
                                  }`}
                                  placeholder="user@domain.com"
                                  type="text"
                                  {...register('email', {
                                    required: 'Email is Required',
                                    pattern: {
                                      value:
                                        /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                      message: 'Invalid Email Address',
                                    },
                                  })}
                                />
                              </label>
                            </div>
                            {/* Mobile Phone */}
                            <div className="flex w-full flex-col lg:w-1/2">
                              <label htmlFor="mobile_phone">
                                <div className="text-xs font-semibold">
                                  Mobile Phone{' '}
                                  <span className="text-text-error-red">
                                    *{' '}
                                    <span className="font-normal">
                                      {errors?.mobile_phone?.message || ''}
                                    </span>
                                  </span>
                                </div>
                                <input
                                  id="mobile-phone"
                                  className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                    hasAdminAccess
                                      ? ''
                                      : 'cursor-not-allowed bg-neutral-100'
                                  }`}
                                  placeholder="XXX-XXX-XXXX"
                                  type="text"
                                  {...register('mobile_phone', {
                                    required: 'Mobile Phone is Required',
                                    pattern: {
                                      value:
                                        /^(?:\+\d{1,3}\s?)?(?:\(\d{3}\)|\d{3})[-\s]?\d{3}[-\s]?\d{4}$/,
                                      message: 'Invalid Phone Number',
                                    },
                                  })}
                                />
                              </label>
                            </div>
                          </div>
                          {/* External ID */}
                          <div className="flex w-full flex-col">
                            <label htmlFor="external-id">
                              <div className="text-xs font-semibold">
                                External ID
                              </div>
                              <input
                                id="external-id"
                                className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                  hasAdminAccess
                                    ? ''
                                    : 'cursor-not-allowed bg-neutral-100'
                                }`}
                                placeholder="External ID"
                                type="text"
                                {...register('external_id')}
                              />
                            </label>
                          </div>
                        </>
                      )}
                    </fieldset>
                  </section>

                  {/* Residential Address Section */}
                  <section className="mt-4">
                    {/*
                      Country, Address 1 and 2, City, State, and Postal Code
                    */}

                    {!countriesLoading && !isLoadingUserData ? (
                      <fieldset className="flex flex-col flex-wrap gap-3 lg:flex-nowrap">
                        <h4 className="text-base font-semibold">
                          Residential Address
                        </h4>

                        {/* Country */}
                        <div className="flex w-full flex-col">
                          {/* Below pattern prevents PO Boxes */}

                          <div className="text-xs font-semibold">
                            Country{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.country?.message || ''}
                              </span>
                            </span>
                          </div>
                          <Controller
                            name="country"
                            control={control}
                            rules={{
                              required: 'Country is Required',
                            }}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                id="country"
                                showSearch
                                placeholder="Select a country"
                                size="large"
                                className="my-1 w-full"
                                disabled={!hasAdminAccess}
                                filterOption={(input, option) =>
                                  (option?.label ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                {...register('country', {
                                  required: 'Country is Required',
                                })}
                                options={
                                  countriesData?.map((country) => ({
                                    value: country.id,
                                    label: country.name,
                                  })) || []
                                }
                                onChange={(e) => {
                                  selectResidentialCountry(e);
                                  onChange(e);
                                }}
                                value={value}
                              />
                            )}
                          />
                        </div>

                        {/* Address 1 and Address 2 */}
                        <div className="flex flex-col gap-3 md:flex-row">
                          {/* Address 1 */}
                          <div className="flex w-full flex-col lg:w-1/2">
                            <label htmlFor="address-1">
                              <div className="text-xs font-semibold">
                                Address 1{' '}
                                <span className="text-text-error-red">
                                  *{' '}
                                  <span className="font-normal">
                                    {errors?.address_1?.message || ''}
                                  </span>
                                </span>
                              </div>
                              <input
                                id="address-1"
                                className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                  hasAdminAccess
                                    ? ''
                                    : 'cursor-not-allowed bg-neutral-100'
                                }`}
                                placeholder="Address"
                                type="text"
                                {...register('address_1', {
                                  required: 'Address 1 is Required',
                                  pattern: {
                                    value:
                                      /^(?!.*(?:\bP\.?\s?O\.?\s?Box\b|\bPost\s?Office\s?Box\b|\bPO\s?Box\b|\bPO\b|\bPost\b|\bOffice\b|\bPostal\s?(?:Box|Service)\b)).*$/i,
                                    message: 'PO Boxes are not allowed',
                                  },
                                })}
                              />
                            </label>
                          </div>
                          {/* Address 2 */}
                          <div className="flex w-full flex-col lg:w-1/2">
                            <label htmlFor="address-2">
                              <div className="text-xs font-semibold">
                                Address 2{' '}
                                <span className="font-normal text-text-error-red">
                                  {errors?.address_2?.message || ''}
                                </span>
                              </div>
                              <input
                                id="address-2"
                                className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                  hasAdminAccess
                                    ? ''
                                    : 'cursor-not-allowed bg-neutral-100'
                                }`}
                                placeholder="Address 2"
                                type="text"
                                {...register('address_2', {
                                  pattern: {
                                    value:
                                      /^(?!.*(?:\bP\.?\s?O\.?\s?Box\b|\bPost\s?Office\s?Box\b|\bPO\s?Box\b|\bPO\b|\bPost\b|\bOffice\b|\bPostal\s?(?:Box|Service)\b)).*$/i,
                                    message: 'PO Boxes are not allowed',
                                  },
                                })}
                              />
                            </label>
                          </div>
                        </div>

                        {/* City and State */}
                        <div className="flex flex-col gap-3 md:flex-row">
                          {/* City */}
                          <div className="flex w-full flex-col lg:w-1/2">
                            <label htmlFor="city">
                              <div className="text-xs font-semibold">
                                City{' '}
                                <span className="text-text-error-red">
                                  *{' '}
                                  <span className="font-normal">
                                    {errors?.city?.message || ''}
                                  </span>
                                </span>
                              </div>
                              <input
                                id="city"
                                className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                  hasAdminAccess
                                    ? ''
                                    : 'cursor-not-allowed bg-neutral-100'
                                }`}
                                placeholder="City"
                                type="text"
                                {...register('city', {
                                  required: 'City is Required',
                                })}
                              />
                            </label>
                          </div>
                          {/* State */}
                          <div className="flex w-full flex-col lg:w-1/2">
                            <div className="text-xs font-semibold">
                              State{' '}
                              <span className="text-text-error-red">
                                *{' '}
                                <span className="font-normal">
                                  {errors?.province?.message || ''}
                                </span>
                              </span>
                            </div>
                            <Controller
                              name="province"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  showSearch
                                  placeholder="Select a state"
                                  size="large"
                                  className="my-1 w-full"
                                  disabled={!hasAdminAccess}
                                  {...register('province', {
                                    required: 'State is Required',
                                  })}
                                  filterOption={(input, option) =>
                                    (option?.label ?? '')
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={
                                    residentialProvincesData?.map(
                                      (province) => ({
                                        value: province.id,
                                        label:
                                          province.alpha_code || province.name,
                                      }),
                                    ) || []
                                  }
                                  onChange={onChange}
                                  value={value}
                                />
                              )}
                            />
                          </div>
                        </div>

                        {/* Postal Code */}
                        <div className="flex w-full flex-col">
                          <label htmlFor="postal-code">
                            <div className="text-xs font-semibold">
                              Postal Code{' '}
                              <span className="text-text-error-red">
                                *{' '}
                                <span className="font-normal">
                                  {errors?.postal_code?.message || ''}
                                </span>
                              </span>
                            </div>
                            <input
                              id="postal-code"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess
                                  ? ''
                                  : 'cursor-not-allowed bg-neutral-100'
                              }`}
                              placeholder="Postal Code"
                              type="text"
                              {...register('postal_code', {
                                required: 'Postal Code is Required',
                              })}
                            />
                          </label>
                        </div>
                      </fieldset>
                    ) : (
                      <Skeleton active />
                    )}
                  </section>
                </div>

                {/* Shipping Address Section */}
                <section className="mt-4">
                  <div className="mb-4 flex">
                    <span className="text-xl font-semibold">Optional Info</span>
                  </div>
                  <hr className="mb-4 border border-r-gray-100" />
                  {/*
                    Ship Country, Address 1 and 2, City, State, Postal Code 
                  */}
                  {!countriesLoading && !isLoadingUserData ? (
                    <fieldset className="flex flex-col flex-wrap gap-3 lg:flex-nowrap">
                      <h4 className="text-base font-semibold">
                        Shipping Address
                      </h4>
                      {/* Shipping Country */}
                      <div className="flex w-full flex-col">
                        {/* Below pattern prevents PO Boxes */}

                        <div className="text-xs font-semibold">
                          Shipping Country
                        </div>
                        <Controller
                          name="ship_country"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              id="ship-country"
                              showSearch
                              placeholder="Select a shipping country"
                              size="large"
                              className="my-1 w-full"
                              disabled={!hasAdminAccess}
                              filterOption={(input, option) =>
                                (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              {...register('ship_country')}
                              options={
                                countriesData?.map((country) => ({
                                  value: country.id,
                                  label: country.name,
                                })) || []
                              }
                              onChange={(e) => {
                                selectShipCountry(e);
                                onChange(e);
                              }}
                              value={value}
                            />
                          )}
                        />
                      </div>

                      {/* Ship Address 1 and Address 2 */}
                      <div className="flex flex-col gap-3 md:flex-row">
                        {/* Ship Address 1 */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <label htmlFor="ship-address-1">
                            <div className="text-xs font-semibold">
                              Shipping Address 1
                            </div>
                            <input
                              id="ship-address-1"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess
                                  ? ''
                                  : 'cursor-not-allowed bg-neutral-100'
                              }`}
                              placeholder="Shipping Address"
                              type="text"
                              {...register('ship_address_1', {
                                pattern: {
                                  value:
                                    /^(?!.*(?:\bP\.?\s?O\.?\s?Box\b|\bPost\s?Office\s?Box\b|\bPO\s?Box\b|\bPO\b|\bPost\b|\bOffice\b|\bPostal\s?(?:Box|Service)\b)).*$/i,
                                  message: 'PO Boxes are not allowed',
                                },
                              })}
                            />
                          </label>
                          <div className="text-xs text-text-error-red">
                            {errors?.ship_address_1?.message || ''}
                          </div>
                        </div>
                        {/* Ship Address 2 */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <label htmlFor="ship-address-2">
                            <div className="text-xs font-semibold">
                              Shipping Address 2
                            </div>
                            <input
                              id="ship-address-2"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess
                                  ? ''
                                  : 'cursor-not-allowed bg-neutral-100'
                              }`}
                              placeholder="Shipping Address 2"
                              type="text"
                              {...register('ship_address_2', {
                                pattern: {
                                  value:
                                    /^(?!.*(?:\bP\.?\s?O\.?\s?Box\b|\bPost\s?Office\s?Box\b|\bPO\s?Box\b|\bPO\b|\bPost\b|\bOffice\b|\bPostal\s?(?:Box|Service)\b)).*$/i,
                                  message: 'PO Boxes are not allowed',
                                },
                              })}
                            />
                          </label>
                          <div className="text-xs text-text-error-red">
                            {errors?.ship_address_2?.message || ''}
                          </div>
                        </div>
                      </div>

                      {/* Ship City and State */}
                      <div className="flex flex-col gap-3 md:flex-row">
                        {/* Ship City */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <label htmlFor="ship-city">
                            <div className="text-xs font-semibold">
                              Shipping City
                            </div>
                            <input
                              id="ship-city"
                              className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                                hasAdminAccess
                                  ? ''
                                  : 'cursor-not-allowed bg-neutral-100'
                              }`}
                              placeholder="Shipping City"
                              type="text"
                              {...register('ship_city')}
                            />
                          </label>
                        </div>
                        {/* Ship State */}
                        <div className="flex w-full flex-col lg:w-1/2">
                          <div className="text-xs font-semibold">
                            Shipping State
                          </div>

                          <Controller
                            name="ship_province"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                showSearch
                                placeholder="Select a shipping state"
                                size="large"
                                className="my-1 w-full"
                                disabled={!hasAdminAccess}
                                {...register('ship_province')}
                                filterOption={(input, option) =>
                                  (option?.label ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={
                                  shippingProvincesData?.map((province) => ({
                                    value: province.id,
                                    label: province.alpha_code || province.name,
                                  })) || []
                                }
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                        </div>
                      </div>

                      {/* Ship Postal Code */}
                      <div className="flex w-full flex-col">
                        <label htmlFor="ship-postal-code">
                          <div className="text-xs font-semibold">
                            Shipping Postal Code
                          </div>
                          <input
                            id="ship-postal-code"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess
                                ? ''
                                : 'cursor-not-allowed bg-neutral-100'
                            }`}
                            placeholder="Shipping Postal Code"
                            type="text"
                            {...register('ship_postal_code')}
                          />
                        </label>
                      </div>
                    </fieldset>
                  ) : (
                    <Skeleton active />
                  )}
                </section>
              </div>

              {/* Save Button */}
              {hasAdminAccess && (
                <div className="mt-6 flex justify-end">
                  <button
                    className="h-10 w-[25%] items-center rounded-lg bg-surface-primary-dark text-white"
                    type="submit"
                    disabled={updateUserLoading}
                  >
                    {updateUserLoading ? <LoadingSpinner /> : <p>Save</p>}
                  </button>
                </div>
              )}
            </fieldset>
          </form>
        </div>
      </div>

      {hasAdminAccess && (
        <div className="mt-10 rounded-lg border border-border-light-gray bg-white p-3 lg:p-6">
          <div className="flex flex-col items-center justify-center p-0">
            <div className="flex w-full flex-col items-start">
              <h3 className="w-full text-left text-xl font-bold">
                Reset Password
              </h3>
              {!resetPasswordStarted && (
                <button
                  type="button"
                  onClick={() => handleSendForgottenPasswordEmail()}
                >
                  <p>Click here to reset this user&apos;s password.</p>
                </button>
              )}
            </div>

            {resetPasswordStarted && (
              <form
                className="flex w-full flex-col justify-start"
                onSubmit={handleSubmitReset((data) =>
                  handleResetUserPassword(data),
                )}
              >
                <div className="flex w-1/3 auto-rows-max flex-col gap-y-5 sm:min-w-[375px]">
                  <div className="mb-6 flex flex-col items-start">
                    <p className="mb-0 mt-6 text-left">
                      Verify the OTP code sent to your email address.
                    </p>
                    <input
                      id="otp"
                      className="my-1 w-full rounded-lg border border-zinc-200 p-2"
                      placeholder="OTP"
                      type="text"
                      {...registerReset('otp', {
                        required: 'OTP is Required',
                      })}
                    />
                  </div>
                  <div className="mb-3 gap-y-3">
                    <input
                      id="password"
                      className="my-1 w-full rounded-lg border border-zinc-200 p-2"
                      placeholder="Password"
                      type="password"
                      {...registerReset('password', {
                        required: 'Password is Required',
                      })}
                    />
                    <input
                      id="confirm-password"
                      className="my-1 w-full rounded-lg border border-zinc-200 p-2"
                      placeholder="Confirm Password"
                      type="password"
                      {...registerReset('confirmPassword', {
                        required: 'Confirm Password is Required',
                      })}
                    />
                  </div>
                  <button
                    type="button"
                    className="text-red-600"
                    onClick={() => setResetPassworStarted(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="flex h-10 items-center justify-center rounded-lg bg-surface-primary-dark text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
                    type="submit"
                    data-testid="submit-button"
                  >
                    {updateUserLoading ? <LoadingSpinner /> : 'Change Password'}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </>
  );
}
