import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export default function PageFooter(): ReactElement {
  return (
    <div className="mt-auto flex w-full flex-col items-center px-12 py-8 sm:flex-row">
      <Link to="/privacy-policy" className="text-zinc-400">
        Privacy Policy
      </Link>
      <p className="mt-2 text-zinc-400 sm:mt-0 sm:text-white">
        Copyright {new Date().getFullYear()}
      </p>
    </div>
  );
}
