/* eslint-disable @typescript-eslint/no-explicit-any */
import { ForgotPasswordEmail } from '../types/user/forgotPassword';
import { User } from '../types/user/user';
import { UserLoginModel } from '../types/user/userLoginModel';
import { TERN_USER_API_URL } from './Endpoints';
import TernFetchAPI from './TernFetchAPI';

export const login = async (email: string, password: string): Promise<User> => {
  const response = await TernFetchAPI.post<UserLoginModel[]>(
    TERN_USER_API_URL,
    'login',
    {
      email,
      password,
    },
  );
  const {
    data: [
      {
        token,
        refresh_token: refreshToken,
        user: {
          first_name: firstname,
          last_name: lastname,
          mobile_phone: mobilePhone,
          mtu,
          email: responseEmail,
          role_id: roleId,
          business_id: businessId,
          program_id: programId,
          last_active: lastActive,
          user_id: id,
          user_type_id: userTypeId,
        },
      },
    ],
  } = response;
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('id', JSON.stringify(id));
  localStorage.setItem('firstname', firstname);
  localStorage.setItem('lastname', lastname);
  localStorage.setItem('mtu', JSON.stringify(mtu));
  localStorage.setItem('email', responseEmail);
  localStorage.setItem('roleId', JSON.stringify(roleId));
  localStorage.setItem('businessId', businessId);
  localStorage.setItem('programId', JSON.stringify(programId));
  localStorage.setItem('lastActive', lastActive);
  localStorage.setItem('mobilePhone', mobilePhone);
  localStorage.setItem('userTypeId', JSON.stringify(userTypeId));

  return {
    token,
    refreshToken,
    id,
    firstname,
    lastname,
    mtu,
    email: responseEmail,
    businessId: parseInt(businessId),
    programId,
    roleId,
    lastActive,
    mobilePhone,
    userTypeId,
  };
};

export const logout = (): void => {
  localStorage.clear();
};

export const getUser = (): { user: User } => {
  return {
    user: {
      token: localStorage.getItem('token'),
      refreshToken: localStorage.getItem('refreshToken'),
      id: parseInt(localStorage.getItem('id') || '0'),
      firstname: localStorage.getItem('firstname'),
      lastname: localStorage.getItem('lastname'),
      mtu: !!localStorage.getItem('mtu'),
      email: localStorage.getItem('email'),
      roleId: parseInt(localStorage.getItem('roleId') || '0'),
      businessId: parseInt(localStorage.getItem('businessId') || '0'),
      programId: parseInt(localStorage.getItem('programId') || '0'),
      lastActive: localStorage.getItem('lastActive'),
      mobilePhone: localStorage.getItem('mobilePhone'),
      userTypeId: parseInt(localStorage.getItem('userTypeId') || '0'),
    },
  };
};

export const isAuthenticated = (): boolean => {
  return (
    !!localStorage.getItem('token') && !!localStorage.getItem('refreshToken')
  );
};

export const sendForgottenPasswordEmail = async (
  email: string,
): Promise<ForgotPasswordEmail> => {
  const resp = await TernFetchAPI.post(
    TERN_USER_API_URL,
    'forget-password',
    {
      email,
    },
    true,
  );
  const result = resp.data as ForgotPasswordEmail[];
  return result[0];
};

export const resetPassword = async (data: {
  email: string;
  otp: string;
  password: string;
  confirmPassword: string;
}): Promise<any> => {
  const resp = await TernFetchAPI.post(
    process.env.REACT_APP_USER_API_URL || '',
    'users/reset-password',
    {
      email: data?.email,
      otp_code: data?.otp,
      new_password: data?.password,
      confirm_new_password: data?.confirmPassword,
    },
    true,
  );
  return resp.data as any;
};
