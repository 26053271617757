import { Modal } from 'antd';
import React, { BaseSyntheticEvent, ReactElement, useState } from 'react';
import LoadingSpinner from '../../LoadingSpinner';
import { BusinessAccountWireDTO } from '../../../types/transactions/businessAccountWireDTO';

export function UpdateBusinessWireModal({
  type,
  businessWire,
  isModalOpen,
  setIsModalOpen,
  onCancel,
  onConfirm,
}: {
  type: string;
  businessWire: BusinessAccountWireDTO | null;
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  onCancel: (e: BaseSyntheticEvent) => void;
  onConfirm: (e: BaseSyntheticEvent) => void;
}): ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async (e: BaseSyntheticEvent) => {
    setIsLoading(true);
    try {
      await onConfirm(e);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer="">
      <div className="my-10 flex flex-col gap-6">
        <div className="flex flex-col items-center gap-1">
          <p className="text-2xl font-bold">
            Are You Sure You Want to {type} the business wire?
          </p>
        </div>
        <p className="text-xl">Track ID: {businessWire?.track_id}</p>
        <p className="text-xl">Amount: ${businessWire?.amount}</p>
        <p className="text-xl">Post Date: {businessWire?.post_at}</p>
      </div>
      <footer className="flex flex-col gap-6 py-2">
        <button
          onClick={onCancel}
          disabled={isLoading}
          type="button"
          className="w-full rounded-md bg-surface-primary p-3 font-semibold text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
        >
          Cancel
        </button>
        <button
          className={`w-full justify-center hover:border-red-500 hover:bg-red-500 hover:text-white ${
            isLoading
              ? 'border-red-500 bg-red-500'
              : 'border-border-gray bg-surface-light-gray'
          } rounded-md border p-3 font-semibold text-black transition duration-300 ease-in-out`}
          onClick={handleConfirm}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <LoadingSpinner /> : 'Confirm'}
        </button>
      </footer>
    </Modal>
  );
}
