import React, { ReactElement } from 'react';
import {
  getStatusFromStatusIdOrName,
  getStatusTypeFromIdOrName,
} from '../../utils/businessUtils';

export function StatusCard({
  statusIdOrName,
  title,
  large = true,
}: {
  statusIdOrName: number | string | null;
  title: string;
  large?: boolean;
}): ReactElement {
  const statusName =
    statusIdOrName !== null
      ? getStatusFromStatusIdOrName(statusIdOrName || 0)
      : null;
  const formattedStatus = statusName
    ? statusName[0].toUpperCase() + statusName.slice(1).toLowerCase()
    : 'Not yet available';
  const statusType = getStatusTypeFromIdOrName(statusIdOrName);
  return (
    <div
      className={`flex h-full items-center ${
        large ? 'w-full py-8' : 'max-h-[70px] w-full max-w-[200px] md:w-1/2'
      } rounded-lg border border-border-light-gray px-6 ${
        statusType === 'success' ? 'bg-surface-success-green' : ''
      } ${statusType === 'warning' ? 'bg-surface-action-orange' : ''} 
      ${statusType === 'error' ? 'bg-red-200' : ''}
      ${statusType === 'unknown' ? 'bg-gray-200' : ''}`}
    >
      <div className="flex w-full flex-col justify-center">
        <div
          className={`${large ? 'pb-2' : ''} text-sm font-semibold opacity-50`}
        >
          {title}
        </div>
        <p
          className={`${large ? 'text-3xl' : 'text-lg'} font-bold ${
            statusType === 'success' && 'text-text-success-green'
          } ${statusType === 'warning' && 'text-text-action-orange'} 
          ${statusType === 'error' && 'text-text-error-red'}
          ${statusType === 'unknown' && 'text-text-gray'}`}
        >
          {formattedStatus}
        </p>
      </div>
    </div>
  );
}
