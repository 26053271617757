import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { CardModel } from '../../../types/cards/cardModel';
import {
  getBulkTemplateData,
  getCardForBusiness,
  getCardTransactionsForAccount,
  getCardsForAccount,
} from '../../CardApi';
import { CardTransactionModel } from '../../../types/cards/cardTransactionModel';
import {
  BulkAssignCardsTemplateResponse,
  BulkCreateCardsTemplateResponse,
  BulkLoadFundsTemplateResponse,
} from '../../../types/cards/bulkTemplateResponses';

/**
 * React Query queries are used to fetch data from the server.
 * @docs https://tanstack.com/query/v4/docs/react/query-functions/usequery
 *
 * The query key is an array of strings that uniquely identifies the query.
 * Usually it will either be a single string identifier, or the identifier
 * plus a specific ID. This allows caching of queries at a resource level.
 * @docs https://tanstack.com/query/v4/docs/vue/guides/query-keys#if-your-query-function-depends-on-a-variable-include-it-in-your-query-key
 *
 * @param enabled The enabled option can not only be used to permanently
 * disable a query, but also to enable / disable it at a later time.
 * A good example would be a filter form where you only want to fire off
 * the first request once the user has entered a filter value, or a search
 * that shouldn't be executed until the user has entered at least 3 characters.
 *
 * @param staletime is used to set the time in milliseconds that the data
 * should be considered fresh. If the data is older than the stale time,
 * then a new request will be made in the background, but the stale data
 * will still be visible immediately.
 *
 * @param select is used to transform the data returned from the server into the
 * format that the UI expects. This is useful for normalizing data, or
 * for selecting a specific part of a larger response. The argument of
 * the select should match the name of the query key used above it.
 */

export const useGetCardsForAccount = (
  accountId: number,
  enabled = true,
  queryString = '?page=1&pageSize=100',
): UseQueryResult<CardModel[] | null, Error> => {
  return useQuery<CardModel[] | null, Error>(
    ['userAccountCards', accountId],
    () => getCardsForAccount(accountId, queryString),
    {
      enabled,
      select: (userAccountCards) => {
        return (userAccountCards || []).sort((a, b) => {
          let aUpdatedAt = a.updated_at;
          if (aUpdatedAt === null) {
            aUpdatedAt = '-1';
          }
          let bUpdatedAt = b.updated_at;
          if (bUpdatedAt === null) {
            bUpdatedAt = '-1';
          }
          return aUpdatedAt > bUpdatedAt ? 1 : -1;
        });
      },
    },
  );
};

export const useGetCardTransactionsForAccount = (
  cardId: number,
  enabled = true,
): UseQueryResult<CardTransactionModel[] | null, Error> => {
  return useQuery<CardTransactionModel[] | null, Error>(
    ['userAccountCardTransactions', cardId],
    () => getCardTransactionsForAccount(cardId),
    {
      enabled,
    },
  );
};

export const useGetCardForBusiness = (
  businessId: number,
  enabled = true,
  queryString = '?page=1&pageSize=100',
): UseQueryResult<CardModel | null, Error> => {
  return useQuery<CardModel | null, Error>(
    ['businessCards', businessId],
    () => getCardForBusiness(businessId, queryString),
    {
      enabled,
    },
  );
};

export const useGetBulkTemplateData = (
  type: 'loadFunds' | 'createCards' | 'assignCards' | 'createUnassignedCards',
  enabled = true,
): UseQueryResult<
  | BulkLoadFundsTemplateResponse[]
  | BulkCreateCardsTemplateResponse[]
  | BulkAssignCardsTemplateResponse[]
> => {
  return useQuery<
    | BulkLoadFundsTemplateResponse[]
    | BulkCreateCardsTemplateResponse[]
    | BulkAssignCardsTemplateResponse[]
  >([`bulkTemplate-${type}`, type], () => getBulkTemplateData({ type }), {
    enabled,
  });
};
