import React, { ReactElement } from 'react';
import LoadingSpinner from '../../LoadingSpinner';
import { ReactComponent as XIcon } from '../../../assets/x-circle-red.svg';

export function BulkAllRecordsFailed({
  records,
  isLoading,
  downloadAction,
  onCancel,
}: {
  records: unknown[] | [];
  isLoading: boolean;
  downloadAction: (records: unknown[]) => void;
  onCancel: () => void;
}): ReactElement {
  const total = Array.isArray(records) ? records?.length || 0 : records;

  return (
    <div className="flex flex-col justify-center">
      <h2 className="mb-6 text-xl font-medium">Records Failed</h2>
      <div className="flex w-full items-center">
        <p className="mb-6 flex w-full items-baseline">
          <span className="text-3xl">0</span>
          <span className="text-2xl leading-9 text-text-light-gray">
            /{total} Added
          </span>
        </p>
      </div>
      <hr />
      <div className="mt-6 flex w-full items-center rounded-md bg-surface-error-red px-4 py-6">
        <XIcon className="mr-3" />
        <p className="w-full text-sm text-red-50">
          None of your uploads were successful. Please{' '}
          <b>download your report</b> to correct any issues and try again.
        </p>
      </div>
      <footer className="mt-6 flex flex-col gap-4 pb-2">
        <button
          className="flex h-10 w-full items-center justify-center rounded-full border-border-primary bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-light"
          type="button"
          onClick={() => downloadAction(records)}
        >
          Download Report
        </button>
        <button
          className="flex h-10 items-center justify-center rounded-full border border-border-light-gray bg-white p-3 transition duration-300 ease-in-out hover:bg-surface-gray hover:text-white"
          type="button"
          onClick={() => onCancel()}
        >
          {isLoading ? <LoadingSpinner /> : 'Close'}
        </button>
      </footer>
    </div>
  );
}
