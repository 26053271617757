import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Select, Skeleton, notification } from 'antd';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {
  Roles,
  createRoleDropdownList,
  getRoleId,
  isAdminOrMTU,
} from '../../../utils/authUtils';
import { useGetBusinesses } from '../../../api/rq/queries/businessQueries';
import { useCreateRadUser } from '../../../api/rq/mutations/userMutations';
import { CreateRadUserRequestDTO } from '../../../types/user/createRadUserRequestDTO';

/**
 * Component that creates a new user with the 'RAD User' user_type.
 * These users only require minimal information to be created, which
 * is why it is its own component and not part of the CreateNewUser component.
 */
export function CreateNewRadUser(): ReactElement {
  /**
   * Local component state
   */

  const [selectedBusinessIds, setSelectedBusinessIds] = useState<number[]>([]);
  const [businessRequiredError, setBusinessRequiredError] =
    useState<string>('');

  const hasAdminAccess = isAdminOrMTU();

  const navigate = useNavigate();

  /**
   * Query string parameters.
   *
   * The role_id in the params is the role that the user
   * wants to assign to the new user they are creating.
   */
  const [searchParams] = useSearchParams();
  const roleIdQueryParam = searchParams.get('roleId');

  useEffect(() => {
    if (!hasAdminAccess) navigate('/dashboard/users');
  }, [hasAdminAccess, navigate]);

  /**
   * Notification state
   */
  const [notify, notificationContext] = notification.useNotification();

  /**
   * RQ Mutations for API updates
   */
  const { mutateAsync: mutateCreateRadUser, isLoading: createRadUserLoading } =
    useCreateRadUser();
  /**
   * RQ queries for API data
   */
  const { data: allBusinessesData, isLoading: businessesLoading } =
    useGetBusinesses('?page=1&pageSize=1000');

  /**
   * Related Businesses select is handled by local component
   * state and not react-hook-form.
   */
  const allBusinessIds = allBusinessesData?.map((biz) => biz.id);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateRadUserRequestDTO>();

  /**
   * Set the role_id value to the query string parameter
   * that was previously set when the user was asked to
   * select a role before coming to this page.
   */
  useEffect(() => {
    setValue('role_id', parseInt(roleIdQueryParam ?? '0'));
  }, [roleIdQueryParam, setValue]);

  /**
   * Populate role dropdown list based on what role
   * the currently logged in RAD user has.
   */
  const loggedInUserRoleId = getRoleId();
  const roleDropdownItems = createRoleDropdownList(loggedInUserRoleId);
  /**
   * Transforms form data into the correct format
   * and then calls the User API to create a new user.
   * @param data Form data
   */
  const createNewUser = async (
    data: CreateRadUserRequestDTO,
  ): Promise<void> => {
    console.log({ data });
    if (!selectedBusinessIds || selectedBusinessIds.length === 0) {
      setBusinessRequiredError('At least one business is required');
      return;
    }
    setBusinessRequiredError('');
    const createRequestBody = {
      ...data,
      business_ids: selectedBusinessIds,
    };
    try {
      const newRadUser = await mutateCreateRadUser(createRequestBody);
      console.log({ newRadUser });
      if (newRadUser?.id) {
        notify.success({
          message: 'Success!',
          description: `User ${newRadUser?.email} was successfully created.`,
          placement: 'topLeft',
        });
        navigate(`/dashboard/users/${newRadUser?.id}/rad/info?status=success`);
      }
    } catch (err) {
      notify.error({
        message: 'Error!',
        description: `There was an error creating the user:
     ${err.message}. Please try again.`,
        placement: 'topLeft',
      });
    }
  };

  const setSelectedBusinesses = (businessIds: number[]) => {
    /**
     * filter for non deleted IDs since deleted ones
     * are also returned with the user's list of business IDs
     */
    setSelectedBusinessIds(
      [...new Set(businessIds || [])].filter((id) =>
        allBusinessIds?.includes(id),
      ) || [],
    );
  };

  const selectRole = (roleId: number) => {
    if (roleId === Roles.RegularUser) {
      navigate(`/dashboard/users/new?roleId=${roleId}`);
    }
  };

  return (
    <>
      {notificationContext}
      <div className="rounded-lg border border-border-light-gray bg-white p-3 lg:p-6">
        <div className="grid grid-cols-1 gap-4">
          <form onSubmit={handleSubmit((data) => createNewUser(data))}>
            <div className="gap-4 lg:grid lg:grid-cols-2">
              <div className="border-r-2 border-r-gray-100 pr-2 lg:pr-5">
                {/* User Info Section */}
                <section className="col-start-1">
                  <div className="mb-4 flex gap-3">
                    <span className="text-xl font-semibold">User Info</span>
                  </div>
                  <hr className="mb-4 border border-r-gray-100" />

                  {/* Related Businesses and User Role */}
                  <div className="mb-4 flex w-full flex-col">
                    {/*  User Role */}
                    {roleDropdownItems && (
                      <div className="mb-4 flex w-full flex-col">
                        <div className="mb-4 text-base font-semibold">
                          User Role{' '}
                          <span className="text-text-error-red">
                            *{' '}
                            <span className="text-xs font-normal">
                              {errors.role_id?.message || ''}
                            </span>
                          </span>
                        </div>
                        <Controller
                          name="role_id"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Select
                              id="role-id"
                              {...register('role_id', {
                                required: 'Role is Required',
                              })}
                              allowClear
                              placeholder="Select a role for this user"
                              size="large"
                              className="my-1 w-full"
                              options={roleDropdownItems.map((role) => ({
                                label: role.name,
                                value: role.id,
                                disabled: role.disabled,
                              }))}
                              onChange={(id: number) => {
                                selectRole(id);
                                onChange(id);
                              }}
                              value={value}
                            />
                          )}
                        />
                      </div>
                    )}
                    {/* Related Businesses - Not part of react hook form */}
                    {!businessesLoading ? (
                      <>
                        <div className="mb-4 text-base font-semibold">
                          Business Relationships{' '}
                          <span className="text-text-error-red">
                            *{' '}
                            <span className="text-xs font-normal">
                              {businessRequiredError || ''}
                            </span>
                          </span>
                        </div>
                        <Select
                          id="business-ids"
                          mode="multiple"
                          showSearch
                          placeholder="Select businesses"
                          size="large"
                          className="my-1 w-full"
                          disabled={!hasAdminAccess}
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={
                            allBusinessesData?.map((business) => ({
                              value: business.id,
                              label: business.legal_business_name,
                            })) || []
                          }
                          onChange={(ids) => {
                            setSelectedBusinesses(ids);
                          }}
                          value={selectedBusinessIds}
                        />
                      </>
                    ) : (
                      <Skeleton active />
                    )}
                  </div>

                  {/* First, Last Name, Email, Mobile Phone */}
                  <fieldset
                    disabled={!hasAdminAccess}
                    className="flex flex-col flex-wrap gap-3 lg:flex-nowrap"
                  >
                    <h4 className="text-base font-semibold">
                      Personal Details
                    </h4>
                    {/* First and Last Name */}
                    <div className="flex flex-col gap-3 md:flex-row">
                      {/* First Name */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="first-name">
                          <div className="text-xs font-semibold">
                            First Name{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.first_name?.message || ''}
                              </span>
                            </span>
                          </div>
                          <input
                            id="first-name"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="First Name"
                            type="text"
                            {...register('first_name', {
                              required: 'First Name is Required',
                            })}
                          />
                        </label>
                      </div>
                      {/* Last Name */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="last-name">
                          <div className="text-xs font-semibold">
                            Last Name{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.last_name?.message || ''}
                              </span>
                            </span>
                          </div>
                          <input
                            id="last-name"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="Last Name"
                            type="text"
                            {...register('last_name', {
                              required: 'Last Name is Required',
                            })}
                          />
                        </label>
                      </div>
                    </div>
                    {/* Email and Mobile Phone */}
                    <div className="flex flex-col gap-3 md:flex-row">
                      {/* Email */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="email">
                          <div className="text-xs font-semibold">
                            Email{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.email?.message || ''}
                              </span>
                            </span>
                          </div>
                          <input
                            id="email"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="user@domain.com"
                            type="text"
                            {...register('email', {
                              required: 'Email is Required',
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: 'Invalid Email Address',
                              },
                            })}
                          />
                        </label>
                      </div>
                      {/* Mobile Phone */}
                      <div className="flex w-full flex-col lg:w-1/2">
                        <label htmlFor="mobile_phone">
                          <div className="text-xs font-semibold">
                            Mobile Phone{' '}
                            <span className="text-text-error-red">
                              *{' '}
                              <span className="font-normal">
                                {errors?.mobile_phone?.message || ''}
                              </span>
                            </span>
                          </div>
                          <input
                            id="mobile-phone"
                            className={`my-1 w-full rounded-lg border border-zinc-200 p-2 ${
                              hasAdminAccess ? '' : 'cursor-not-allowed'
                            }`}
                            placeholder="XXX-XXX-XXXX"
                            type="text"
                            {...register('mobile_phone', {
                              required: 'Mobile Phone is Required',
                              pattern: {
                                value:
                                  /^(?:\+\d{1,3}\s?)?(?:\(\d{3}\)|\d{3})[-\s]?\d{3}[-\s]?\d{4}$/,
                                message: 'Invalid Phone Number',
                              },
                            })}
                          />
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </section>
              </div>
            </div>

            <div className="mt-8 flex justify-start">
              <button
                className={`h-10 w-[25%] items-center rounded-lg bg-surface-primary-dark text-white ${
                  hasAdminAccess ? '' : 'cursor-not-allowed'
                }`}
                type="submit"
                disabled={!hasAdminAccess || createRadUserLoading}
              >
                {createRadUserLoading ? <LoadingSpinner /> : <p>Save</p>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
