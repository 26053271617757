import { Modal, Radio, Space } from 'antd';
import React, { useState } from 'react';
import LoadingSpinner from '../../LoadingSpinner';

type ExportModalProps = {
  isModalOpen: boolean;
  isLoading: boolean;
  title: string;
  onConfirm: (fileType: string) => void;
  setIsModalOpen: (val: boolean) => void;
};

export function ExportModal({
  isModalOpen,
  onConfirm,
  isLoading,
  title,
  setIsModalOpen,
}: ExportModalProps) {
  const [fileType, setFileType] = useState<string | null>('csv');

  return (
    <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer="">
      <div className="mb-4 flex flex-col gap-4">
        <h3 className="pb-2 text-xl font-semibold">{title}</h3>
        <Radio.Group
          onChange={(e) => setFileType(e.target.value)}
          value={fileType}
        >
          <Space direction="vertical">
            <Radio value="csv">CSV Download</Radio>
            <Radio value="pdf">PDF Download</Radio>
          </Space>
        </Radio.Group>
        <p className="text-base">
          Choose how you would like to download and view your transactions.
        </p>
      </div>
      <footer className="flex flex-col gap-6 py-2">
        <button
          onClick={() => setIsModalOpen(false)}
          disabled={isLoading}
          type="button"
          className="flex h-10 items-center justify-center rounded-full border border-border-primary bg-white p-3 text-text-primary transition duration-300 ease-in-out hover:bg-surface-primary hover:text-white"
        >
          Cancel
        </button>
        <button
          className="flex h-10 w-full items-center justify-center rounded-full border-border-primary bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
          onClick={() => onConfirm(fileType || '')}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <LoadingSpinner /> : 'Download Transactions'}
        </button>
      </footer>
    </Modal>
  );
}
