import React, { ReactElement, useState } from 'react';
import { NavLink, Link, Outlet } from 'react-router-dom';

import { ReactComponent as IntermexLogo } from '../../assets/intermex-logo.svg';
import { ReactComponent as Logo } from '../../assets/tern_logo_white.svg';
import { ReactComponent as Users } from '../../assets/users.svg';
import { ReactComponent as Businesses } from '../../assets/businesses.svg';
import { ReactComponent as LayoutGrid } from '../../assets/layout_grid.svg';
import { ReactComponent as CardWhite } from '../../assets/card-white.svg';
import { useAuthApi } from '../../context/authContext';
import { isBusinessAdmin } from '../../utils/authUtils';

export default function Dashboard(): ReactElement {
  const authState = useAuthApi();
  const [navMobile, setNavMobile] = useState(false);
  const toggleNav = () => setNavMobile(!navMobile);

  const programId = authState?.user?.programId || 0;
  const isBusinessAdminRole = isBusinessAdmin();

  let theme = 'default';
  switch (programId) {
    case 20:
    case 22:
    case 9:
      theme = 'intermex';
      break;
    case 1:
    default:
      theme = 'default';
      break;
  }
  /**
   * @TODO pull this logo from the API
   */
  const renderLogo = () => {
    switch (theme) {
      case 'intermex':
        return (
          <IntermexLogo className="my-5 w-full self-center transition duration-200 ease-in-out hover:scale-125" />
        );
      case 'default':
      default:
        return (
          <Logo className="my-5 w-full self-center transition duration-200 ease-in-out hover:scale-125" />
        );
    }
  };

  return (
    <div className={`${theme} flex min-h-screen bg-neutral-50`}>
      <div
        className={`${
          navMobile ? 'w-3/5 md:flex' : 'w-[56px] 2xl:w-1/5'
        } fixed z-10 col-span-2 flex h-full min-w-[56px] max-w-[215px] flex-col overflow-x-hidden bg-surface-primary-dark px-1 transition-all`}
      >
        <Link to="/dashboard/" className="mb-2 mt-3">
          {renderLogo()}
        </Link>
        <span>
          <hr className="mx-2 mb-5 self-center border-t-2 border-border-gray 2xl:mx-5" />
        </span>
        <div className="space-y-2 2xl:mx-5">
          <div className="flex w-full">
            <NavLink
              id="users-nav-link"
              to="/dashboard/users"
              className={({ isActive }) =>
                `flex items-center w-full h-12 rounded-lg p-3 text-white duration-200 ease-in-out hover:bg-surface-primary-light ${
                  isActive && 'rounded-lg bg-surface-primary-light'
                }`
              }
            >
              <Users
                className={`inline h-full md:w-6 2xl:mr-4 ${
                  navMobile ? 'mr-4 w-auto md:mr-4 md:w-6' : 'w-full'
                }`}
              />
              <span
                className={`2xl:inline ${
                  navMobile ? 'inline md:inline' : 'hidden'
                }`}
              >
                Users
              </span>
            </NavLink>
          </div>
          <div className="flex w-full">
            <NavLink
              id="businesses-nav-link"
              to="/dashboard/businesses"
              className={({ isActive }) =>
                `flex items-center w-full h-12 rounded-lg p-3 text-white duration-200 ease-in-out hover:bg-surface-primary-light ${
                  isActive && 'rounded-lg bg-surface-primary-light'
                }`
              }
            >
              <Businesses
                className={`inline h-full 2xl:mr-4 2xl:w-6 ${
                  navMobile ? 'mr-4 w-auto md:mr-4 md:w-6' : 'w-full'
                }`}
              />
              <span
                className={`2xl:inline ${
                  navMobile ? 'inline md:inline' : 'hidden'
                }`}
              >
                Businesses
              </span>
            </NavLink>
          </div>
          {isBusinessAdminRole && (
            <div className="flex w-full">
              <NavLink
                id="deposits-nav-link"
                to="/dashboard/deposits"
                className={({ isActive }) =>
                  `flex items-center w-full h-12 rounded-lg p-3 text-white duration-200 ease-in-out hover:bg-surface-primary-light ${
                    isActive && 'rounded-lg bg-surface-primary-light'
                  }`
                }
              >
                <CardWhite
                  className={`inline h-full 2xl:mr-4 2xl:w-6 ${
                    navMobile ? 'mr-4 w-auto md:mr-4 md:w-6' : 'w-full'
                  }`}
                />
                <span
                  className={`2xl:inline ${
                    navMobile ? 'inline md:inline' : 'hidden'
                  }`}
                >
                  Deposits
                </span>
              </NavLink>
            </div>
          )}
        </div>
        <button
          className="mb-4 mt-auto flex items-center justify-start p-1 2xl:hidden"
          onClick={toggleNav}
          type="button"
        >
          <span className="flex rounded-full bg-surface-primary-light p-2">
            <LayoutGrid />
          </span>
        </button>
      </div>
      <div className="mb-12 ml-auto mt-6 w-[calc(100%-56px)] transition-all 2xl:w-[calc(100%-215px)]">
        <div className="mx-auto h-full max-w-[1480px] px-6">
          <Outlet context={authState} />
        </div>
      </div>
    </div>
  );
}
