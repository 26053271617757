import { ColumnDef } from '@tanstack/react-table';
import { Result, Skeleton, Tooltip } from 'antd';
import React from 'react';
import Table from '.';
import { ReactComponent as FilterIcon } from '../../assets/filters.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import { isAdminOrMTU } from '../../utils/authUtils';

type TransactionsProps<T> = {
  setIsFilterModalOpen: (isOpen: boolean) => void;
  setIsExportModalOpen: (isOpen: boolean) => void;
  transactionList: T[];
  transactionColumns: ColumnDef<T>[];
  isLoadingTransactions: boolean;
  hasError: boolean;
};

export function Transactions<T>({
  setIsFilterModalOpen,
  setIsExportModalOpen,
  transactionList,
  transactionColumns,
  isLoadingTransactions,
  hasError,
}: TransactionsProps<T>) {
  const hasAdminAccess = isAdminOrMTU();

  return (
    <div className="grid gap-3 rounded-lg border border-border-light-gray bg-white px-2 py-3 md:px-6">
      <div className="row-span-3 flex h-auto w-full flex-col">
        <div className="flex w-full flex-row items-center justify-between gap-3">
          <div className="text-xl">Transactions</div>
          <div className="flex">
            {hasAdminAccess && (
              <Tooltip
                color="#06102B"
                title="Click to export transactions with filters applied if provided"
              >
                <button
                  type="button"
                  disabled={isLoadingTransactions}
                  className={`flex w-auto items-center rounded-md bg-surface-primary-light p-2 font-semibold text-white ${
                    isLoadingTransactions ? 'cursor-not-allowed' : ''
                  }`}
                  onClick={() => setIsExportModalOpen(true)}
                >
                  <DownloadIcon />
                </button>
              </Tooltip>
            )}

            <button
              type="button"
              className="flex items-center rounded-md border border-border-light-gray p-2 text-sm font-semibold sm:ml-5"
              onClick={() => setIsFilterModalOpen(true)}
            >
              <FilterIcon />
              <span className="ml-2">Filters</span>
            </button>
          </div>
        </div>
      </div>
      {!isLoadingTransactions &&
        transactionList &&
        transactionList.length > 0 && (
          <Table<T> data={transactionList} columns={transactionColumns} />
        )}
      {isLoadingTransactions && <Skeleton active />}
      {!isLoadingTransactions &&
        transactionList &&
        transactionList.length === 0 && (
          <div className="mb-2 flex items-center justify-center">
            <p className="font-semibold">No Transactions Found</p>
          </div>
        )}
      {hasError && (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong loading the transactions! Please refresh and try again"
        />
      )}
    </div>
  );
}
