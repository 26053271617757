import { ApiResponseDTO } from '../types/apiResponseDTO';
import { BusinessDTO } from '../types/business/businessDTO';
import { CountryDTO } from '../types/business/countryDTO';
import { ProvinceDTO } from '../types/business/provinceDTO';
import { PayrollDepositDTO } from '../types/transactions/payrollDepositDTO';
import { CreateRadUserRequestDTO } from '../types/user/createRadUserRequestDTO';
import { CreateRadUserResponseDTO } from '../types/user/createRadUserResponseDTO';
import { RadUserDTO } from '../types/user/radUserDTO';
import { UpdateRadUserRequestDTO } from '../types/user/updateRadUserRequestDTO';
import { UserAndACHDetailsDTO } from '../types/user/userAndACHDetailsDTO';
import TernFetchAPI from './TernFetchAPI';
import { TERN_DASHBOARD_API_URL } from './Endpoints';

export const getBusinesses = async (
  queryString = '?page=1&pageSize=1000',
): Promise<BusinessDTO[]> => {
  const businessesResponse = await TernFetchAPI.get<BusinessDTO[]>(
    TERN_DASHBOARD_API_URL,
    `business${queryString}`,
  );
  return businessesResponse.data;
};

export const getUsersAndACHDetails = async (
  businessId: string,
): Promise<UserAndACHDetailsDTO[]> => {
  const usersAndDetailsResponse = await TernFetchAPI.get<
    UserAndACHDetailsDTO[]
  >(TERN_DASHBOARD_API_URL, `businesses/${businessId}/users/bank-details`);
  return usersAndDetailsResponse.data;
};

export const getPayrollDeposits = async (
  businessId: string,
): Promise<PayrollDepositDTO[]> => {
  const payrollDepositResponse = await TernFetchAPI.get<PayrollDepositDTO[]>(
    TERN_DASHBOARD_API_URL,
    `businesses/${businessId}/payroll-deposits`,
  );
  return payrollDepositResponse.data;
};

export const getBusinessesAPIResponse = async (
  queryString: string,
): Promise<ApiResponseDTO<BusinessDTO[]>> => {
  const businessesResponse = await TernFetchAPI.get<BusinessDTO[]>(
    TERN_DASHBOARD_API_URL,
    `business${queryString}`,
  );
  return businessesResponse;
};
export const getCountries = async (): Promise<CountryDTO[]> => {
  const countriesResponse = await TernFetchAPI.get<CountryDTO[]>(
    TERN_DASHBOARD_API_URL,
    `country`,
  );
  return countriesResponse.data;
};

export const getProvinces = async (
  countryId: string,
): Promise<ProvinceDTO[]> => {
  const countriesResponse = await TernFetchAPI.get<ProvinceDTO[]>(
    TERN_DASHBOARD_API_URL,
    `country/${countryId}/province`,
  );
  return countriesResponse.data;
};

/**
 * Creates a Rad User in the database with user_type_id = 3.
 * These users only require minimal information for creation.
 *
 * @param body RAD user request body
 * @returns Newly created user
 */
export const createRadUser = async (
  body: CreateRadUserRequestDTO,
): Promise<CreateRadUserResponseDTO | null> => {
  const userResponse = await TernFetchAPI.post<CreateRadUserResponseDTO[]>(
    TERN_DASHBOARD_API_URL,
    `users`,
    body,
  );
  return userResponse.data ? userResponse.data[0] : null;
};

export const updateRadUser = async (
  userId: number,
  body: UpdateRadUserRequestDTO,
): Promise<CreateRadUserResponseDTO | null> => {
  const userResponse = await TernFetchAPI.patch<CreateRadUserResponseDTO[]>(
    TERN_DASHBOARD_API_URL,
    `users/${userId}`,
    body,
  );
  return userResponse.data ? userResponse.data[0] : null;
};

export const getRadUser = async (
  userId: number,
): Promise<RadUserDTO | null> => {
  const userResponse = await TernFetchAPI.get<RadUserDTO[]>(
    TERN_DASHBOARD_API_URL,
    `users/${userId}`,
  );
  return userResponse.data ? userResponse.data[0] : null;
};

export const naturesOfBusiness = [
  {
    value: 'Corporation',
    label: 'Corporation',
  },
  {
    value: 'LLC',
    label: 'LLC',
  },
  {
    value: 'Non-Profit',
    label: 'Non-Profit',
  },
  {
    value: 'Partnership',
    label: 'Partnership',
  },
  {
    value: 'Sole Proprietor',
    label: 'Sole Proprietor',
  },
  {
    value: 'Trust',
    label: 'Trust',
  },
  {
    value: 'Government',
    label: 'Government',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const businessTypes = [
  {
    value: 'Business',
    label: 'Business',
  },
  {
    value: 'Consumer',
    label: 'Consumer',
  },
];
