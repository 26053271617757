import { Popover } from 'antd';
import React, { ReactElement, memo, useState } from 'react';
import { CellContext } from '@tanstack/react-table';
import { ReactComponent as Info } from '../../assets/information.svg';
import { ReactComponent as BankGray } from '../../assets/bank-gray.svg';
import { UserAccountDTO } from '../../types/user/userAccountDTO';
import { BusinessAccountDTO } from '../../types/business/businessAccountDTO';

function BankDetails({
  info,
}: {
  info:
    | CellContext<UserAccountDTO, unknown>
    | CellContext<BusinessAccountDTO, unknown>;
}): ReactElement {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibleChange = () => {
    setIsVisible(!isVisible);
  };

  const content = (
    <div className="flex flex-col gap-4">
      <p className="mt-1 text-xs font-bold">
        Please use the below information to transfer <br />
        funds to your account within 1-3 business days.
      </p>
      <div className="flex flex-col text-text-gray">
        <span className="text-xs font-semibold">Account Number</span>
        <p className="flex tracking-wider">
          {info.row.original.internal_deposit_account ||
            info.row.original.account_number}
        </p>
      </div>
      <div className="flex flex-col text-text-gray">
        <span className="text-xs font-semibold">Routing Number</span>
        <p className="flex w-full tracking-wider">
          {info.row.original.routing_number}
        </p>
      </div>
    </div>
  );

  return (
    <Popover
      content={content}
      title={
        <div className="flex items-center gap-2">
          <BankGray />
          <h3 className="text-base font-semibold">Bank Details</h3>
        </div>
      }
      placement="topLeft"
      trigger="click"
      open={isVisible}
    >
      <button className="mr-2 mt-1" onClick={handleVisibleChange} type="button">
        <Info />
      </button>
    </Popover>
  );
}

export default memo(BankDetails);
