import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { UserDTO } from '../../../types/user/userDTO';
import {
  getUserAccountHistory,
  getUserTransactions,
  getUserAccounts,
  getUserWithFullName,
  getUsers,
} from '../../UsersApi';
import { UserAccountDTO } from '../../../types/user/userAccountDTO';
import { UserAccountTransactionDTO } from '../../../types/transactions/userAccountTransactionDTO';
import { getCountries, getProvinces, getRadUser } from '../../DashboardApi';
import { CountryDTO } from '../../../types/business/countryDTO';
import { ProvinceDTO } from '../../../types/business/provinceDTO';
import { UserSearchQueryParams } from '../../../types/user/userSearchQueryParams';
import { AccountHistoryDTO } from '../../../types/user/acountHistoryDTO';
import { TransactionSearchQueryParams } from '../../../types/transactions/transactionSearchQueryParams';
import { RadUserDTO } from '../../../types/user/radUserDTO';
import { canViewUser } from '../../../utils/authUtils';

/**
 * React Query queries are used to fetch data from the server.
 * @docs https://tanstack.com/query/v4/docs/react/query-functions/usequery
 *
 * The query key is an array of strings that uniquely identifies the query.
 * Usually it will either be a single string identifier, or the identifier
 * plus a specific ID. This allows caching of queries at a resource level.
 * @docs https://tanstack.com/query/v4/docs/vue/guides/query-keys#if-your-query-function-depends-on-a-variable-include-it-in-your-query-key
 *
 * @param enabled The enabled option can not only be used to permanently
 * disable a query, but also to enable / disable it at a later time.
 * A good example would be a filter form where you only want to fire off
 * the first request once the user has entered a filter value, or a search
 * that shouldn't be executed until the user has entered at least 3 characters.
 *
 * @param staletime is used to set the time in milliseconds that the data
 * should be considered fresh. If the data is older than the stale time,
 * then a new request will be made in the background, but the stale data
 * will still be visible immediately.
 *
 * @param select is used to transform the data returned from the server into the
 * format that the UI expects. This is useful for normalizing data, or
 * for selecting a specific part of a larger response. The argument of
 * the select should match the name of the query key used above it.
 */

export const useGetUserWithFullName = (
  userId: number,
  enabled = true,
): UseQueryResult<UserDTO | null, Error> => {
  return useQuery<UserDTO | null, Error>(
    ['user', userId],
    () => getUserWithFullName(userId),
    {
      enabled,
    },
  );
};

/**
 * Query to fetch a RAD User i.e. a User with
 * user_type_id = 3. This is different than cardholders,
 * and includes Prog/Biz admins, MTUs, platform agents, API accts.
 */
export const useGetRadUser = (
  userId: number,
  enabled = true,
): UseQueryResult<RadUserDTO | null, Error> => {
  return useQuery<RadUserDTO | null, Error>(
    ['user', userId],
    () => getRadUser(userId),
    {
      enabled,
    },
  );
};

export const useGetUserAccounts = (
  userId: number,
  enabled = true,
): UseQueryResult<UserAccountDTO[], Error> => {
  return useQuery<UserAccountDTO[], Error>(
    ['userAccounts', userId],
    () => getUserAccounts(userId),
    {
      enabled,
    },
  );
};

export const useGetUserAccountTransactions = (
  userAccountId: number,
  {
    transactionStatus,
    transactionType,
    amount,
    createdAt,
  }: TransactionSearchQueryParams,
  enabled = true,
  hasBusinessAdminRole = false,
): UseQueryResult<UserAccountTransactionDTO[], Error> => {
  /**
   * Set default querystring
   * Note: can use `display=concise` to exclude 0 dollar amounts
   */
  let queryString = '?page=1&page_size=1000';
  /**
   * Set Transaction Type
   */
  if (transactionType && !hasBusinessAdminRole) {
    queryString = `${queryString}&transaction_types=${transactionType}`;
  }

  if (hasBusinessAdminRole) {
    queryString = `${queryString}&transaction_types=account_transfer,load`;
  }

  /**
   * Set Transaction Status
   */
  if (transactionStatus) {
    queryString = `${queryString}&transaction_statuses=${transactionStatus}`;
  } else {
    queryString = `${queryString}&exclude_statuses=VOIDED,EXPIRED,DECLINED`;
  }
  /**
   * Set Start Date
   */
  if (createdAt) {
    queryString = `${queryString}&start_date=${createdAt}`;
  }
  /**
   * Set Amount Min
   */
  if (amount?.min) {
    queryString = `${queryString}&min_amount=${amount.min}`;
  }
  /**
   * Set Amount Max
   */
  if (amount?.max) {
    queryString = `${queryString}&max_amount=${amount.max}`;
  }

  return useQuery<UserAccountTransactionDTO[], Error>(
    ['userAccountTransactions', queryString],
    () => getUserTransactions(userAccountId, queryString),
    {
      enabled,
    },
  );
};

export const useGetAccountHistory = (
  userAccountId: number,
  enabled = true,
  queryString = '?page=1&pageSize=20',
): UseQueryResult<AccountHistoryDTO[] | null, Error> => {
  return useQuery<AccountHistoryDTO[] | null, Error>(
    ['accountHistory', userAccountId],
    () => getUserAccountHistory(userAccountId, queryString),
    {
      select: (accountHistory) =>
        accountHistory?.sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        }) ?? [],
      enabled,
    },
  );
};

export const useGetCountries = (): UseQueryResult<CountryDTO[], Error> => {
  return useQuery<CountryDTO[], Error>(['countries'], () => getCountries(), {
    staleTime: Infinity, // no need to refetch the same data
    select: (countries) =>
      countries.filter((c) => c.alpha_code === 'US' || c.alpha_code === 'USA'),
  });
};

export const useGetProvinces = (
  countryId: string,
  enabled = true,
): UseQueryResult<ProvinceDTO[], Error> => {
  return useQuery<ProvinceDTO[], Error>(
    ['provinces', countryId],
    () => getProvinces(countryId),
    {
      enabled, // disable this query from automatically running
      staleTime: Infinity, // no need to ever refetch the same countryId data
    },
  );
};

export const useGetUsers = (
  queryString?: string,
): UseQueryResult<UserDTO[], Error> => {
  return useQuery<UserDTO[], Error>(['users'], () => getUsers(queryString), {
    staleTime: 3600, // 1 hour
  });
};

/**
 * Accumulated filters in the query string
 * then fetches users.
 * @param param0 Search term and filters
 */
export const useSearchUsers = ({
  searchTerm,
  businessId,
  programId,
  createdAt,
  kycStatus,
  userStatus,
}: UserSearchQueryParams): UseQueryResult<UserDTO[], Error> => {
  /**
   * Set default querystring
   */
  let queryString =
    '?page=1&pageSize=2000&order_by=created_at&order_direction=desc&include_roles=true';
  /**
   * Set Search Term
   */
  if (searchTerm) {
    queryString = `${queryString}&full_text_search_term=${searchTerm}&full_text_mode=boolean`;
  }
  /**
   * Set Created At
   */
  if (createdAt) {
    queryString = `${queryString}&created_at=${createdAt}`;
  }
  /**
   * Set Business Id
   */
  if (businessId) {
    queryString = `${queryString}&business_id=${businessId}`;
  }
  /**
   * Set Program Id
   */
  if (programId) {
    queryString = `${queryString}&program_id=${programId}`;
  }
  /**
   * Set KYC Status
   */
  if (kycStatus) {
    queryString = `${queryString}&kyc_status=${kycStatus}`;
  }
  /**
   * Set Active Status
   */
  if (userStatus) {
    queryString = `${queryString}&is_active=${userStatus}`;
  }
  return useQuery<UserDTO[], Error>(
    [
      'users',
      { searchTerm, businessId, programId, createdAt, kycStatus, userStatus },
    ],
    () => getUsers(queryString),
    {
      select: (users) =>
        users
          .filter((user) => {
            /**
             * Filter out users that the logged in user shouldn't see
             * based on their role.
             */
            const canView = canViewUser(user);
            if (canView) {
              return user;
            }
            return undefined;
          })
          .filter((user) => !!user),
    },
  );
};
