// biz account
export const getStatusFromStatusIdOrName = (
  idOrName: number | string,
): string => {
  if (typeof idOrName === 'string') return idOrName;
  const statusId = idOrName;
  let status = '';
  switch (statusId) {
    case 1:
      status = 'Active';
      break;
    case 2:
      status = 'On Hold';
      break;
    case 3:
    case 8:
      status = 'Inactive';
      break;
    default:
      status = '-';
      break;
  }
  return status;
};

export const getCardStatusFromStatusId = (status: string | number): string => {
  if (typeof status === 'string') return status;
  switch (status) {
    case 1:
      return 'Active';
    case 2:
      return 'Paused';
    case 3:
      return 'Open';
    case 4:
      return 'Closed';
    case 5:
      return 'Pending Fulfillment';
    case 6:
      return 'Pending Activation';
    case 8:
      return 'Inactive';
    default:
      return '-';
  }
};

export const getStatusTypeFromIdOrName = (
  idOrName: number | string | null,
): string => {
  if (!idOrName) return 'unknown';
  switch (idOrName.toString().toLowerCase()) {
    case '1':
    case 'active':
    case 'approved':
    case 'accepted':
    case 'manual_approval':
    case 'complete':
      return 'success';
    case '2':
    case 'on hold':
    case 'paused':
    case 'pending':
    case 'pending fulfillment':
    case 'pending activation':
    case 'pending_auth_deduction':
    case 'awaiting_processing':
      return 'warning';
    case '3':
    case '8':
    case 'deactivated':
    case 'inactive':
    case 'cancelled':
    case 'rejected':
    case 'closed':
    case 'kycscanfailed':
      return 'error';
    default:
      return 'unknown';
  }
};
