import React from 'react';
import {
  Outlet,
  useMatch,
  useLocation,
  matchPath,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Result } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import DashboardHeader from '../../components/DashboardHeader';
import Button from '../../components/Button';

import { useGetUserWithFullName } from '../../api/rq/queries/userQueries';

function Users() {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { accountId: accountIdQueryParam, userId: userIdQueryParam } =
    useParams();

  /**
   * Only fetch when userIdQueryParam exists
   */
  const { data: selectedUserData } = useGetUserWithFullName(
    parseInt(userIdQueryParam || '0'),
    !!userIdQueryParam && userIdQueryParam !== 'null',
  );

  const userListRoute = useMatch('/dashboard/users');
  const newUserRoute = useMatch('dashboard/users/new');
  const newRadUserRoute = useMatch('dashboard/users/rad/new');
  const userInfoRoute = useMatch(`dashboard/users/${userIdQueryParam}/info`);
  const radUserInfoRoute = useMatch(
    `dashboard/users/${userIdQueryParam}/rad/info`,
  );
  const userTransactionsRoute = matchPath(
    `/dashboard/users/${userIdQueryParam}/accounts/${accountIdQueryParam}`,
    pathname,
  );
  const userBusinessDepositsRoute = matchPath(
    `/dashboard/users/${userIdQueryParam}/accounts/${accountIdQueryParam}/deposits`,
    pathname,
  );
  const userCardsRoute = matchPath(
    `/dashboard/users/${userIdQueryParam}/accounts/${accountIdQueryParam}/cards/*`,
    pathname,
  );

  const determineBreadcrumbText = () => {
    if (
      userInfoRoute ||
      radUserInfoRoute ||
      userTransactionsRoute ||
      userCardsRoute ||
      userBusinessDepositsRoute
    ) {
      return `${selectedUserData?.first_name || ''} ${
        selectedUserData?.last_name || ''
      }`;
    }
    if (newUserRoute || newRadUserRoute) return 'Create New User';
    return '';
  };

  const renderUserHeaderSubtext = () => {
    const breadcrumbText = determineBreadcrumbText();
    return (
      <div className="flex items-baseline space-x-2">
        <Button
          onClick={() => {
            navigate('/dashboard/users');
          }}
          id="users"
          className="text-xl font-semibold md:text-2xl xl:text-3xl"
        >
          Users
        </Button>
        {!userListRoute && (
          <div className="inline pb-[1px] xl:pb-[2px]">
            <ChevronRight className="" />
          </div>
        )}
        <span className="text-lg text-text-gray md:text-xl xl:text-2xl xl:leading-9">
          {breadcrumbText}
        </span>
      </div>
    );
  };

  return (
    <>
      <DashboardHeader subtext={renderUserHeaderSubtext()} />
      <ErrorBoundary
        fallback={
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong! Please refresh and try again"
          />
        }
      >
        <Outlet />
      </ErrorBoundary>
    </>
  );
}

export default Users;
