// TODO: Shift config to use full path to root of API rather than
// appending strings and detecting local env.
export const TERN_USER_API_URL: string =
  process.env.TERN_USER_API_URL ||
  `${process.env.REACT_APP_USER_API_URL || ''}/users`;

// Dashboard API config already takes full path
// Allow using the TERN_ prefix, though the value will not need to change.
export const TERN_DASHBOARD_API_URL: string =
  process.env.TERN_DASHBOARD_API_URL ||
  process.env.REACT_APP_DASHBOARD_API_URL ||
  '';
