import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { Modal, Select } from 'antd';
import { WireSearchQueryParams } from '../../../types/transactions/wireSearchQueryParams';

/**
 * Modal for showing filters a user can apply
 * to the list of business account transactions.
 */
export function BusinessWireFiltersModal({
  isFilterModalOpen,
  setFilters,
  setIsFilterModalOpen,
}: {
  isFilterModalOpen: boolean;
  setFilters: Dispatch<SetStateAction<WireSearchQueryParams>>;
  setIsFilterModalOpen: Dispatch<SetStateAction<boolean>>;
}): ReactElement {
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [trackIdFilter, setTrackIdFilter] = useState<string>('');
  const [amountFilter, setAmountFilter] = useState<{
    min: number | string;
    max: number | string;
  }>({
    min: '',
    max: '',
  });

  /**
   * Filters the list of transactions by provided filters.
   *
   * If the filter was *not* set, the current iterated transaction
   * automatically passes the filter test.
   *
   * If the filter is set, the filter value is compared to
   * the current iterated transaction value. If the values match,
   * the transaction passes the filter test. If they do not, the
   * transaction does not get returned in the filtered list.
   *
   */
  const applyFilters = () => {
    setFilters({
      status: statusFilter,
      trackId: trackIdFilter,
      amount: {
        min: amountFilter.min.toString() ?? '',
        max: amountFilter.max.toString() ?? '',
      },
    });
    setIsFilterModalOpen(false);
  };

  /**
   * Sets all local state filter variables back
   * to their defaults/placeholders
   */
  const clearFilters = () => {
    setStatusFilter(null);
    setTrackIdFilter('');
    setAmountFilter({ min: '', max: '' });
  };

  const handleTrackIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrackIdFilter(e.target.value);
  };

  const handleMaxAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setAmountFilter((prevState) => ({
        max: '',
        min: prevState.min,
      }));
      return;
    }
    /**
     * Only allow positive and negative integers
     */
    const passed = /^-?([0-9]+)?$/.test(e.target.value);
    if (!passed) return;
    setAmountFilter((prevState) => ({
      min: prevState.min,
      max: e.target.value || '',
    }));
  };

  const handleMinAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setAmountFilter((prevState) => ({
        min: '',
        max: prevState.max,
      }));
      return;
    }
    /**
     * Only allow positive and negative integers
     */
    const passed = /^-?([0-9]+)?$/.test(e.target.value);
    if (!passed) return;
    setAmountFilter((prevState) => ({
      min: e.target.value || '',
      max: prevState.max,
    }));
  };

  return (
    <Modal
      open={isFilterModalOpen}
      onCancel={() => setIsFilterModalOpen(false)}
      footer=""
    >
      <h2 className="text-xl">Select Business Wire Filters</h2>
      <div className="py-2">
        <div className="text-xs font-semibold">Status</div>
        <Select
          id="wire_status"
          allowClear
          placeholder="Select a Wire Status"
          size="large"
          className="my-1 w-full"
          options={[
            { label: 'Cancelled', value: 'Cancelled' },
            { label: 'Approved', value: 'Approved' },
            { label: 'Pending', value: 'Pending' },
          ]}
          value={statusFilter}
          onChange={(type: string) => setStatusFilter(type)}
        />
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold">Amount</div>
        <div className="flex items-center gap-6">
          <input
            className="my-1 w-full rounded-lg border border-zinc-200 p-2"
            placeholder="Enter a Minimum Amount"
            type="text"
            value={amountFilter.min}
            onChange={handleMinAmountChange}
          />{' '}
          -
          <input
            className="my-1 w-full rounded-lg border border-zinc-200 p-2"
            placeholder="Enter a Maximum Amount"
            value={amountFilter.max}
            type="text"
            onChange={handleMaxAmountChange}
          />
        </div>
      </div>
      <div className="py-2">
        <div className="text-xs font-semibold">Track ID</div>
        <div className="flex items-center gap-6">
          <input
            className="my-1 w-full rounded-lg border border-zinc-200 p-2"
            placeholder="Enter track ID"
            type="text"
            value={trackIdFilter}
            onChange={handleTrackIdChange}
          />{' '}
        </div>
      </div>
      {/* <div className="py-2">
        <div className="text-xs font-semibold">Amount</div>
        <div className="flex items-center gap-6">
          <input
            className="my-1 w-full rounded-lg border border-zinc-200 p-2"
            placeholder="Enter a Minimum Amount"
            type="text"
            value={amountFilter.min}
            onChange={handleMinAmountChange}
          />{' '}
          -
          <input
            className="my-1 w-full rounded-lg border border-zinc-200 p-2"
            placeholder="Enter a Maximum Amount"
            value={amountFilter.max}
            type="text"
            onChange={handleMaxAmountChange}
          />
        </div>
      </div> */}
      <div className="flex justify-between gap-2 py-2">
        <button
          type="button"
          className="w-full rounded-full border border-border-primary bg-white p-3 text-text-primary transition duration-300 ease-in-out hover:bg-surface-primary hover:text-white"
          onClick={clearFilters}
        >
          Clear Filters
        </button>
        <button
          type="button"
          onClick={applyFilters}
          className="w-full rounded-full bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark"
        >
          Search
        </button>
      </div>
    </Modal>
  );
}
