import { Modal, Select, Tooltip } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import LoadingSpinner from '../../LoadingSpinner';
import { getUserWithFullName } from '../../../api/UsersApi';
import { UserDTO } from '../../../types/user/userDTO';
import { ReactComponent as ChevronRightSmall } from '../../../assets/chevron-right-small.svg';
import { AddCardFormValues } from '../../../types/cards/addCardFormValues';
import { ReplaceCardFormValues } from '../../../types/cards/replaceCardFormValues';
import { CardModel } from '../../../types/cards/cardModel';

type AddOrReplaceCardModalProps = {
  isModalOpen: boolean;
  selectedCard: CardModel | null;
  selectedUser: UserDTO | null;
  title: string;
  setSelectedUser: (val: UserDTO | null) => void;
  onConfirm: (data: AddCardFormValues | ReplaceCardFormValues) => Promise<void>;
  setIsModalOpen: (val: boolean) => void;
};

export function AddOrReplaceCardModal({
  onConfirm,
  selectedCard,
  isModalOpen,
  title,
  selectedUser,
  setSelectedUser,
  setIsModalOpen,
}: AddOrReplaceCardModalProps): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showShippingAddress, setShowShippingAddress] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const { userId: userIdQueryParam, accountId: userAccountIdQueryParam } =
    useParams();

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AddCardFormValues>({
    defaultValues: {
      card_type: selectedCard ? selectedCard.type : null,
      user_account_id: parseInt(userAccountIdQueryParam || '0'),
    },
  });

  /**
   * Fetch the user instead of passing it via router outlet because
   * there is a common use case of switching from the card or transactions
   * tab, to the user details tab to update info, and back. Without this
   * fetch, the data would be stale as the user from the router context
   * is not fetched again.
   */
  useEffect(() => {
    (async () => {
      if (userIdQueryParam) {
        const id = parseInt(userIdQueryParam);
        const user = await getUserWithFullName(id);
        setSelectedUser(user);
      }
    })();
  }, [userIdQueryParam, setSelectedUser]);

  useEffect(() => {
    if (selectedCard && selectedCard.type === 'PHYSICAL')
      setShowShippingAddress(true);
    else setShowShippingAddress(false);
  }, [selectedCard]);

  const clearModal = () => {
    setError('');
    setValue('card_type', null);
  };

  const handleConfirm = async (data: AddCardFormValues): Promise<void> => {
    const cardType = getValues('card_type');
    if (
      cardType === 'PHYSICAL' &&
      (!selectedUser?.ship_address_1 ||
        !selectedUser.ship_city ||
        !selectedUser.ship_province ||
        !selectedUser.ship_postal_code ||
        !selectedUser.ship_country)
    ) {
      setError(
        'User does not have a full shipping address. Please provide one.',
      );
      return;
    }
    setError('');
    setIsLoading(true);
    try {
      await onConfirm(data);
      clearModal();
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const createUserInitials = (user: UserDTO | null): string => {
    return `${user?.first_name.slice(0, 1) ?? ''}${
      user?.last_name.slice(0, 1) ?? ''
    }`;
  };

  const goToUserShippingAddress = () => {
    navigate(`/dashboard/users/${selectedUser?.id}/info`);
  };

  return (
    <Modal
      width="400px"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer=""
    >
      <form
        className="grid auto-rows-max gap-y-5"
        onSubmit={handleSubmit((data) => handleConfirm(data))}
      >
        <div className="mb-4 flex flex-col">
          <h3 className="pb-2 text-xl font-semibold">{title}</h3>
          <div className="mb-2 text-text-error-red">{error || ''}</div>
          <div className="text-xs font-semibold">
            Card Type{' '}
            <span className="text-text-error-red">
              *{' '}
              <span className="font-normal">
                {errors?.card_type?.message || ''}
              </span>
            </span>
          </div>{' '}
          <Controller
            name="card_type"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                allowClear
                disabled={!!selectedCard}
                {...register('card_type', {
                  required: 'Card Type is required',
                })}
                placeholder="Select a Type of Card"
                size="large"
                className="my-1 w-full"
                options={['PHYSICAL', 'VIRTUAL'].map((cardType) => ({
                  label: cardType,
                  value: cardType,
                }))}
                value={value}
                onChange={(type) => {
                  if (type === 'PHYSICAL') setShowShippingAddress(true);
                  if (type === 'VIRTUAL') setShowShippingAddress(false);
                  onChange(type);
                }}
              />
            )}
          />
          {showShippingAddress && (
            <div className="flex w-full flex-col justify-center py-2">
              <h3 className="font-semibold">Shipping Address</h3>
              <Tooltip
                color="#06102B"
                title="Click to add/edit shipping address"
              >
                <button
                  type="button"
                  onClick={() => goToUserShippingAddress()}
                  className="mt-1 flex items-center justify-between rounded-lg border border-border-light-gray p-4"
                >
                  <div className="flex gap-2">
                    <div className="flex h-8 w-8 items-center justify-center rounded-full bg-surface-icon-bg-light text-text-primary">
                      {createUserInitials(selectedUser)}
                    </div>
                    <div className="flex flex-col items-start justify-center">
                      <p className="font-[500]">
                        {selectedUser?.first_name} {selectedUser?.last_name}
                      </p>
                      <p className="text-start text-text-light-gray">
                        {selectedUser?.full_ship_address}
                      </p>
                    </div>
                  </div>
                  <ChevronRightSmall />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
        <footer className="flex py-2">
          <button
            className="flex h-10 w-full items-center justify-center rounded-full border-border-primary bg-surface-primary p-3 text-white transition duration-300 ease-in-out hover:bg-surface-primary-dark "
            type="submit"
          >
            {isLoading ? <LoadingSpinner /> : title}
          </button>
        </footer>
      </form>
    </Modal>
  );
}
